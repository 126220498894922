<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6198 11L16.0909 10.6107L12.0345 7.625H7.87476C6.49404 7.625 5.37476 8.74429 5.37476 10.125V27C5.37476 28.3807 6.49404 29.5 7.87475 29.5H28.1248C29.5055 29.5 30.6247 28.3807 30.6247 27L30.6248 13.5C30.6248 12.1193 29.5055 11 28.1248 11H17.2765H16.6198ZM7.87476 5.625C5.38947 5.625 3.37476 7.63972 3.37476 10.125V27C3.37476 29.4853 5.38947 31.5 7.87475 31.5H28.1248C30.61 31.5 32.6247 29.4853 32.6247 27L32.6248 13.5C32.6248 11.0147 30.61 9 28.1248 9H17.2765L12.9887 5.84397C12.7955 5.70173 12.5618 5.625 12.3218 5.625H7.87476ZM24.0002 17.998C24.5525 17.998 25.0002 18.4458 25.0002 18.998V20.998C25.0002 21.5503 24.5525 21.998 24.0002 21.998C23.448 21.998 23.0002 21.5503 23.0002 20.998V18.998C23.0002 18.4458 23.448 17.998 24.0002 17.998ZM21.0005 16.0057C21.0005 15.4534 20.5527 15.0057 20.0005 15.0057C19.4482 15.0057 19.0005 15.4534 19.0005 16.0057V24.0057C19.0005 24.558 19.4482 25.0057 20.0005 25.0057C20.5528 25.0057 21.0005 24.558 21.0005 24.0057V16.0057ZM15.9993 16.502C16.5516 16.502 16.9993 16.9497 16.9993 17.502V22.502C16.9993 23.0542 16.5516 23.502 15.9993 23.502C15.447 23.502 14.9993 23.0542 14.9993 22.502V17.502C14.9993 16.9497 15.447 16.502 15.9993 16.502ZM12.9995 18.998C12.9995 18.4458 12.5518 17.998 11.9995 17.998C11.4472 17.998 10.9995 18.4458 10.9995 18.998V20.998C10.9995 21.5503 11.4472 21.998 11.9995 21.998C12.5518 21.998 12.9995 21.5503 12.9995 20.998V18.998Z"
      fill="#7B4DFF"
    />
  </svg>
</template>
