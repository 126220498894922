<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.8125 8C2.8125 5.68731 4.68731 3.8125 7 3.8125H19C21.3127 3.8125 23.1875 5.68731 23.1875 8V18C23.1875 20.3127 21.3127 22.1875 19 22.1875H7C4.68731 22.1875 2.8125 20.3127 2.8125 18V8Z"
      stroke="currentColor"
      stroke-width="1.625"
    />
    <path
      d="M7 8.33333H15M11 7V8.33333M11.6988 14.6667C10.6806 13.6119 9.85382 12.371 9.27458 11M13.3333 17H18M12.3333 19L15.6667 12.3333L19 19M13.5007 8.33333C12.8554 12.1801 10.3798 15.4063 7 17.086"
      stroke="currentColor"
      stroke-width="1.63"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
