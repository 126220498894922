<script setup lang="ts">
import { ref, onUnmounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import IconEmpty from '@/components/icons/IconEmpty.vue'
import IconProhibit from '@/components/icons/IconProhibit.vue'

import { type VideoTemplate, getVideoTemplateList } from '@/api'

const { t } = useI18n()

const emit = defineEmits(['update:modelValue', 'selected:done'])
const props = withDefaults(
  defineProps<{
    modelValue: boolean // v-model默认的名字
    'onSelected:done': (
      materials: VideoTemplate[],
      animation: VideoTemplate['animations'][0]
    ) => void
    language?: string
    resolution?: string
    limit?: number
    isNoTitle?: boolean
    isSubtitleStyle?: boolean
  }>(),
  {
    resolution: '1920x1080',
    limit: 1
  }
)

const dialogVisible = computed({
  get: () => props.modelValue,
  set: (nv) => {
    emit('update:modelValue', nv)
  }
})

const resolution = ref(props.resolution)
const isLandscape = computed(() => {
  const arr = props.resolution.split('x')
  return Number(arr[0]) > Number(arr[1])
})

const selectedList = ref<VideoTemplate[]>([])
const selectedLanguage = ref('')

const tabCategory = ref('style')
const selectedAnimation = ref<VideoTemplate['animations'][0] | null>()

let requestTask: AbortController | null = null
const isLoading = ref(false)
const currentPage = ref(1)
const dataList = ref<VideoTemplate[]>([])
const total = ref(0)
const background = ref(false)
const pagerCount = ref(7)
const layout = ref('prev, pager, next, jumper, ->, total')
const pageSizes = [12, 24, 36, 48, 60, 72, 84, 96]
const pageSize = ref(pageSizes[0])
/*const searchName = ref('')
const searchSubmitedText = ref('')
const isSearching = ref(false)*/

const fetchData = () => {
  abortRequest()
  dataList.value = []
  total.value = 0
  isLoading.value = true

  const controller = new AbortController()
  requestTask = controller

  //searchSubmitedText.value = searchName.value

  getVideoTemplateList(
    {
      language: selectedLanguage.value.split('-')[0],
      isLandscape: isLandscape.value,
      //searchName: searchName.value,
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      orderBy: 'id',
      order: 'asc'
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      total.value = res.data.data.total
      if (res.data.data.current === currentPage.value && res.data.data.size === pageSize.value) {
        dataList.value = res.data.data.records || []
      }
      if (dataList.value.length) {
        selectedList.value = [dataList.value[0]]
        selectedAnimation.value = null
        if (selectedList.value[0].animations[1]) {
          selectedAnimation.value = selectedList.value[0].animations[1]
        }
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isLoading.value = false
      //isSearching.value = false
    })
}
const handleSizeChange = (val: number) => {
  pageSize.value = val
  currentPage.value = 1
  fetchData()
}
const handleCurrentChange = (val: number) => {
  currentPage.value = val
  fetchData()
}
const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  abortRequest()
})
/*const searchSubmit = () => {
  isSearching.value = true
  currentPage.value = 1
  fetchData()
}*/

const getData = () => {
  if (props.resolution) {
    if (resolution.value !== props.resolution) {
      selectedList.value = []

      currentPage.value = 1
      dataList.value = []
      total.value = 0
    }

    resolution.value = props.resolution
  }

  if (props.language) {
    if (selectedLanguage.value !== props.language) {
      selectedList.value = []

      currentPage.value = 1
      dataList.value = []
      total.value = 0
    }

    selectedLanguage.value = props.language
  }

  if (!dataList.value.length) {
    fetchData()
    return
  }
}

const selectedCancel = () => {
  //selectedList.value = []
  dialogVisible.value = false
}
const selectedDone = () => {
  emit('selected:done', [...selectedList.value], selectedAnimation)
  selectedCancel()
}

const isInSelectedList = (material: VideoTemplate, materials?: VideoTemplate[]) => {
  let found = false
  if (!materials) {
    materials = selectedList.value
  }
  materials.some((obj) => {
    found = obj.id === material.id
    return found
  })
  return found
}

const selectItem = (material: VideoTemplate) => {
  if (isInSelectedList(material)) {
    selectedList.value.some((obj, index) => {
      if (obj.id === material.id) {
        selectedList.value.splice(index, 1)
        return true
      }
      return false
    })
    return
  }
  if (props.limit === 1) {
    selectedList.value = [material]
    return
  }
  if (props.limit <= selectedList.value.length) {
    return
  }
  selectedList.value.push(material)
}

const selectAnimation = (item: VideoTemplate['animations'][0]) => {
  selectedAnimation.value = item
}

const getThumbnailUrl = (url: string) => {
  if (props.isSubtitleStyle) {
    return url
      .replace('/templates/full-style/', '/templates/subtitle-style/')
      .replace('/1x/H', '/3x/')
      .replace('/1x/W', '/3x/')
  }
  return url.replace('/1x/', '/2x/')
}
const getBgUrl = (url: string) => {
  return `${url.split('/templates/')[0]}/templates/full-bg/1x/${url.split('/').slice(-1)}`.replace(
    '.png',
    '.webp'
  )
}
</script>

<template>
  <el-dialog
    :class="{ 'choose-video-template-dialog-with-tabs': isSubtitleStyle }"
    v-model="dialogVisible"
    :title="t('选择字幕模板')"
    :width="isSubtitleStyle ? 644 : 756"
    draggable
    align-center
    @open="getData"
  >
    <el-tabs v-model="tabCategory" v-if="isSubtitleStyle">
      <el-tab-pane :label="t('样式')" name="style" />
      <el-tab-pane :label="t('动画')" name="animation" />
    </el-tabs>

    <div class="lists" v-loading="isLoading" v-show="tabCategory == 'style'">
      <div
        class="list-item-wrapper"
        :class="{
          selected: isInSelectedList(item),
          'is-landscape': isLandscape,
          'is-subtitle-style': isSubtitleStyle
        }"
        v-for="item in dataList"
        :key="item.id"
        @click="selectItem(item)"
      >
        <div class="list-item-content">
          <el-image :src="getBgUrl(item.thumbnail)" v-if="!isSubtitleStyle" />
          <el-image
            :class="{ 'is-no-title': !isSubtitleStyle && isNoTitle }"
            :src="getThumbnailUrl(item.thumbnail)"
          />
          <label class="el-checkbox g-checkbox">
            <span class="el-checkbox__input" :class="{ 'is-checked': isInSelectedList(item) }">
              <input class="el-checkbox__original" type="checkbox" @click="selectItem(item)" />
              <span class="el-checkbox__inner"></span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="lists" v-loading="isLoading" v-show="tabCategory == 'animation'">
      <div
        class="list-item-wrapper is-subtitle-style"
        :class="{
          selected:
            item.name == selectedAnimation?.name || (!selectedAnimation && item.name == 'none')
        }"
        v-for="(item, index) in selectedList[0]?.animations"
        :key="index"
        @click="selectAnimation(item)"
      >
        <div class="list-item-content">
          <el-image :src="item.image" v-if="item.image" />
          <div v-else>
            <el-icon><IconProhibit /></el-icon>
          </div>
          <label class="el-checkbox g-checkbox">
            <span
              class="el-checkbox__input"
              :class="{
                'is-checked':
                  item.name == selectedAnimation?.name ||
                  (!selectedAnimation && item.name == 'none')
              }"
            >
              <input class="el-checkbox__original" type="checkbox" @click="selectAnimation(item)" />
              <span class="el-checkbox__inner"></span>
            </span>
          </label>
        </div>
      </div>
    </div>

    <el-empty class="g-empty" v-if="!isLoading && !dataList.length">
      <template #image>
        <el-icon><IconEmpty /></el-icon>
      </template>
    </el-empty>
    <el-pagination
      class="g-pagination"
      :background="background"
      v-model:page-size="pageSize"
      :total="total"
      :pager-count="pagerCount"
      v-model:current-page="currentPage"
      :layout="layout"
      :page-sizes="pageSizes"
      :disabled="isLoading"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      v-if="total > pageSizes[0]"
    />
    <template #footer>
      <el-button @click="selectedCancel">{{ t('取消') }}</el-button>
      <el-button type="primary" :disabled="!selectedList.length" @click="selectedDone">
        <span>{{ t('选择') }}</span>
        <span v-if="selectedList.length && props.limit > 1">({{ selectedList.length }})</span>
      </el-button>
    </template>
  </el-dialog>
</template>

<style lang="less">
.el-dialog.choose-video-template-dialog-with-tabs {
  .el-dialog__header {
    padding-bottom: 0;
  }
}
</style>

<style scoped lang="less">
.el-tabs {
  --el-tabs-header-height: 20px;
  --el-border-color-light: transparent;

  :deep(.el-tabs__header) {
    margin: 13px 0 17px 0;

    .el-tabs__item {
      &:hover {
        --el-color-primary: var(--el-color-primary-dark-2) !important;
      }
    }

    .el-tabs__active-bar {
      --el-color-primary: transparent;
    }
  }
}

.lists {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.list-item-wrapper {
  width: 162px;
  height: 287px;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;

  &.selected {
    border: 1px solid var(--el-color-primary);
  }

  .list-item-content {
    display: flex;
    position: relative;

    .el-image {
      position: absolute;
      left: 0;
      top: 0;

      width: 160px;
      height: 285px;
      margin: 1px;
      border-radius: 8px;
      overflow: hidden;
      display: inline-block;

      &.is-no-title {
        clip: rect(60px, 160px, 285px, 0);
      }
    }

    .el-checkbox {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 20px;
      height: 20px;
      justify-content: center;
    }
  }

  &.is-landscape {
    width: 222px;
    height: 125px;

    .list-item-content {
      .el-image {
        width: 220px;
        height: 123px;

        &.is-no-title {
          clip: rect(30px, 220px, 123px, 0);
        }
      }
    }
  }

  &.is-subtitle-style {
    width: 132px;
    height: 92px;
    border-width: 2px;

    .list-item-content {
      height: 100%;

      .el-checkbox {
        display: none;
      }

      :deep(.el-image__error),
      :deep(.el-image__placeholder),
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 1px;
        border-radius: 6px;
        background: var(--my-color-y3);
      }

      .el-image {
        width: 130px;
        height: 90px;
      }

      .el-icon {
        font-size: 36px;
        color: var(--my-color-white-70);
      }
    }
  }
}
</style>
