<template>
  <svg width="41" height="45" viewBox="0 0 41 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9904 7.64393C13.9904 5.64912 16.1489 4.40149 17.8774 5.39716L30.137 12.4588C31.8686 13.4562 31.8686 15.9549 30.137 16.9523L17.8774 24.014C16.1489 25.0096 13.9904 23.762 13.9904 21.7672L13.9904 7.64393Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.22864 0.400391C7.61529 0.400391 9.55005 2.33515 9.55005 4.7218V40.5895C9.55005 42.9762 7.61529 44.9109 5.22864 44.9109C2.84199 44.9109 0.907227 42.9762 0.907227 40.5895V4.7218C0.907227 2.33515 2.84199 0.400391 5.22864 0.400391Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.098 20.7336C41.2791 22.8075 40.5552 25.4462 38.4812 26.6272L7.36705 44.345C5.29309 45.526 2.65442 44.8021 1.47341 42.7282C0.292404 40.6542 1.01629 38.0155 3.09025 36.8345L34.2044 19.1167C36.2784 17.9357 38.917 18.6596 40.098 20.7336Z"
      fill="currentColor"
    />
  </svg>
</template>
