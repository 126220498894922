<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 0.125C2.18934 0.125 1.9375 0.37684 1.9375 0.6875C1.9375 0.99816 2.18934 1.25 2.5 1.25H11.5C11.8107 1.25 12.0625 0.99816 12.0625 0.6875C12.0625 0.37684 11.8107 0.125 11.5 0.125H2.5ZM12.625 4.625C12.625 4.00368 12.1213 3.5 11.5 3.5H2.5C1.87868 3.5 1.375 4.00368 1.375 4.625V12.5C1.375 13.1213 1.87868 13.625 2.5 13.625H11.5C12.1213 13.625 12.625 13.1213 12.625 12.5L12.625 4.625ZM2.5 2.375C1.25736 2.375 0.25 3.38236 0.25 4.625V12.5C0.25 13.7426 1.25736 14.75 2.5 14.75H11.5C12.7426 14.75 13.75 13.7426 13.75 12.5L13.75 4.625C13.75 3.38236 12.7426 2.375 11.5 2.375H2.5ZM9.39101 7.68003C10.0792 8.06711 10.0792 9.05789 9.39101 9.44497L6.25889 11.2068C5.58396 11.5864 4.75 11.0987 4.75 10.3243V6.80068C4.75 6.02629 5.58395 5.53856 6.25889 5.91821L9.39101 7.68003ZM8.66515 8.5625L5.875 6.99304V10.132L8.66515 8.5625Z"
      fill="currentColor"
    />
  </svg>
</template>
