<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { genFileId } from 'element-plus'
import type { UploadInstance, UploadProps, UploadRawFile } from 'element-plus'

import { ElMessage, ElMessageBox } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import type { BaseResponse, Attachment } from '@/api'
import { config } from '@/config'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { generateUUID } from '@/utils'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()

const emit = defineEmits(['upload:success', 'upload:progress'])
const props = withDefaults(
  defineProps<{
    projId?: string
    category?: string
    'onUpload:progress'?: (percent: number) => void
    'onUpload:success': (attachment: Attachment) => void
    autoUpload?: boolean
    limit?: number
  }>(),
  {
    projId: '',
    category: '',
    autoUpload: true,
    limit: 1
  }
)
defineExpose({
  getUploadRef: () => {
    return uploadRef
  }
})

const accept = ['image/jpeg', 'image/png', 'image/apng', 'image/webp']
const uploadUrl = `${config.baseUrl}/api/attachment/upload?req_id={req_id}`

const uploadRef = ref<UploadInstance>()
const uploadAction = ref<UploadProps['action']>(uploadUrl)
const uploadHeaders: UploadProps['headers'] = {}
if (config.useJwtInHeader && userStore.token) {
  uploadHeaders.Authorization = `Bearer ${userStore.token}`
}
const withCredentials: UploadProps['withCredentials'] =
  config.baseUrl.split('://')[1] !== location.host
const uploadData: UploadProps['data'] = (rawFile) => {
  const data: {
    [key: string]: string | number
  } = {}
  data['projId'] = props.projId
  data['category'] = props.category
  data['filesize'] = rawFile.size
  data['lastModified'] = rawFile.lastModified
  return data
}
const handleProgress: UploadProps['onProgress'] = (evt, uploadFile, uploadFiles) => {
  let total = 0
  let uploaded = 0
  uploadFiles.forEach((file) => {
    if (!file.raw) {
      return
    }
    if (file.uid === uploadFile.uid) {
      file.percentage = evt.percent
    }
    const { size } = file.raw
    total += size
    uploaded += (file.percentage! / 100) * size
  })
  emit('upload:progress', (uploaded / total) * 100)
}
const handleChange: UploadProps['onChange'] = (uploadFile) => {
  if (uploadFile.status === 'ready') {
    if (!props.autoUpload && uploadFile.raw && !beforeUpload(uploadFile.raw)) {
      handleRemove(uploadFile.raw)
    }
  } else if (uploadFile.status === 'success') {
    if (uploadFile.raw) {
      handleRemove(uploadFile.raw)
    }

    const response = uploadFile.response as BaseResponse<Attachment>

    if (response.code === 401) {
      ElMessageBox.confirm(t('登录过期，需重新登录'), '', {
        type: 'warning'
      })
        .then(() => {
          router.push({ name: 'signin', params: { from: route.fullPath } })
        })
        .catch(() => {})
      return
    }
    if (response.code || !response.data?.url) {
      console.error('onChange success?', uploadFile, response)
      ElMessage.error(response.message || t('上传失败'))
      return
    }

    emit('upload:success', response.data)
  } else if (uploadFile.status === 'fail') {
    if (uploadFile.raw) {
      handleRemove(uploadFile.raw)
    }

    const response = uploadFile.response as BaseResponse<null> | null
    ElMessage.error(response?.message || t('上传异常'))
  }
}
const handleExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  if (props.limit === 1) {
    uploadRef.value!.clearFiles()
    const file = files[0] as UploadRawFile
    file.uid = genFileId()
    uploadRef.value!.handleStart(file)
    return
  }
  ElMessage.error(t('上传最多{max}个图片', { max: props.limit - uploadFiles.length }))
}
const beforeUpload: UploadProps['beforeUpload'] = (rawFile) => {
  if (!accept.includes(rawFile.type)) {
    ElMessage.error(t('必须是{format}格式', { format: t('WEBP、JPG、JPEG、PNG') }))
    return false
  } else if (rawFile.size / 1024 / 1024 > 10) {
    ElMessage.error(t('文件体积不能大于{size}', { size: '10MB' }))
    return false
  }
  uploadAction.value = uploadUrl.replace('{req_id}', generateUUID().replace(/-/g, ''))
  return true
}

const handleRemove = (rawFile: UploadRawFile) => {
  uploadRef.value?.handleRemove(rawFile)
}
</script>

<template>
  <el-upload
    ref="uploadRef"
    :action="uploadAction"
    :headers="uploadHeaders"
    :multiple="props.limit > 1"
    :data="uploadData"
    :with-credentials="withCredentials"
    :show-file-list="false"
    :accept="accept.join(',')"
    :on-progress="handleProgress"
    :on-change="handleChange"
    :on-exceed="handleExceed"
    :before-upload="beforeUpload"
    :auto-upload="props.autoUpload"
    :limit="props.limit"
    v-bind="$attrs"
  >
    <template #trigger>
      <slot></slot>
    </template>
  </el-upload>
</template>

<style scoped lang="less"></style>
