<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#7B4DFF" fill-opacity="0.15" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5555 13.889C18.5555 13.4594 18.9038 13.1112 19.3333 13.1112C19.7629 13.1112 20.1111 13.4594 20.1111 13.889V15.4445H27.8889V13.889C27.8889 13.4594 28.2371 13.1112 28.6667 13.1112C29.0962 13.1112 29.4444 13.4594 29.4444 13.889V15.4445H31.7778C33.496 15.4445 34.8889 16.8374 34.8889 18.5557V21.6668V31.7779C34.8889 33.4961 33.496 34.889 31.7778 34.889H16.2222C14.504 34.889 13.1111 33.4961 13.1111 31.7779V21.6668V18.5556C13.1111 16.8374 14.504 15.4445 16.2222 15.4445H18.5555V13.889ZM27.8889 17.0001V18.5557C27.8889 18.9852 28.2371 19.3334 28.6667 19.3334C29.0962 19.3334 29.4444 18.9852 29.4444 18.5557V17.0001H31.7778C32.6369 17.0001 33.3333 17.6965 33.3333 18.5557V20.889H14.6667V18.5556C14.6667 17.6965 15.3631 17.0001 16.2222 17.0001H18.5555V18.5557C18.5555 18.9852 18.9038 19.3334 19.3333 19.3334C19.7629 19.3334 20.1111 18.9852 20.1111 18.5557V17.0001H27.8889ZM33.3333 22.4445H14.6667V31.7779C14.6667 32.637 15.3631 33.3334 16.2222 33.3334H31.7778C32.6369 33.3334 33.3333 32.637 33.3333 31.7779V22.4445ZM17 27.889C17 27.4594 17.3482 27.1112 17.7778 27.1112H30.2222C30.6518 27.1112 31 27.4594 31 27.889C31 28.3185 30.6518 28.6668 30.2222 28.6668H17.7778C17.3482 28.6668 17 28.3185 17 27.889Z"
      fill="#7B4DFF"
    />
  </svg>
</template>
