<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="rotate"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2.62494C12.5208 2.62494 15.375 5.47913 15.375 8.99994C15.375 9.1658 15.3687 9.3301 15.3563 9.49263L14.4671 8.60347C14.2474 8.3838 13.8913 8.3838 13.6716 8.60347C13.4519 8.82314 13.4519 9.1793 13.6716 9.39897L15.338 11.0654C15.4815 11.2089 15.6912 11.2639 15.8867 11.2095C16.0822 11.155 16.2332 10.9994 16.2819 10.8024C16.4245 10.2244 16.5 9.62062 16.5 8.99994C16.5 4.85781 13.1421 1.49994 9 1.49994C6.45915 1.49994 4.21359 2.76396 2.85786 4.69496C2.67935 4.94921 2.74075 5.30003 2.995 5.47854C3.24926 5.65705 3.60008 5.59565 3.77859 5.34139C4.93274 3.69752 6.84114 2.62494 9 2.62494ZM2.11327 6.79041C1.91776 6.84489 1.76675 7.00046 1.71813 7.19751C1.57552 7.77546 1.5 8.37927 1.5 8.99994C1.5 13.1421 4.85787 16.4999 9 16.4999C11.5455 16.4999 13.7947 15.2313 15.1496 13.2943C15.3277 13.0397 15.2657 12.689 15.0111 12.5109C14.7566 12.3328 14.4058 12.3949 14.2278 12.6494C13.0743 14.2984 11.1628 15.3749 9 15.3749C5.47919 15.3749 2.625 12.5208 2.625 8.99994C2.625 8.83409 2.63132 8.66978 2.64373 8.50725L3.53879 9.40231C3.75846 9.62197 4.11461 9.62197 4.33428 9.40231C4.55395 9.18264 4.55395 8.82648 4.33428 8.60681L2.662 6.93453C2.51848 6.79101 2.30878 6.73594 2.11327 6.79041Z"
      fill="#7B4DFF"
    />
    <path
      d="M10.6875 6.74998H7.3125V11.8125M7.3125 8.99998H10.6875"
      stroke="#7B4DFF"
      stroke-width="1.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped lang="less">
.loading {
  .rotate {
    animation: loading-rotate 1s linear infinite;
    transform-origin: center;
  }
}

@keyframes loading-rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
