<template>
  <svg width="204" height="34" viewBox="0 0 204 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 15.5V18.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M12 14V20" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M22 9V25" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M32 14V20" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M42 15V19" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M52 13V21" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M62 11.5V22.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M72 8.5V25.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M82 2V32" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M92 7.5L92 26.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M102 10V24" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M112 12V22" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M122 13.5V20.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M132 15V19" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M142 13.5V20.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M152 10V24" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M162 5V29" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M172 11.5V22.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M182 14.5V19.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M192 11.5V22.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
    <path d="M202 14.5V19.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" />
  </svg>
</template>
