<script setup lang="ts">
import { ref, watch } from 'vue'
/*import {
  VideoPlay as IconVideoPlay,
  VideoPause as IconVideoPause
} from '@element-plus/icons-vue'*/

import IconVideoPlay from '@/components/icons/IconVideoPlay.vue'
import IconVideoPause from '@/components/icons/IconVideoPause.vue'

const emit = defineEmits(['play', 'timeupdate'])
const props = withDefaults(
  defineProps<{
    src: string
    size?: number
    id?: string
  }>(),
  {
    size: 32
  }
)
const audioRef = ref<HTMLAudioElement>()
const paused = ref<boolean | undefined>(true)
const percentage = ref(0)
const audioSrc = ref('')

const play = () => {
  if (audioSrc.value) {
    audioRef.value?.play()
  }
  audioSrc.value = props.src
  updateState()
  paused.value = false
  emit('play')
}
const pause = () => {
  audioRef.value?.pause()
  updateState()
}
const updateState = () => {
  paused.value = audioRef.value?.paused
}
const updateProgress = () => {
  emit('timeupdate', audioRef.value?.currentTime)
  percentage.value = (audioRef.value?.currentTime! / audioRef.value?.duration! || 0) * 100

  if (audioRef.value?.ended) {
    pause()
  }
}

watch(
  () => props.src,
  () => {
    pause()
  }
)

defineExpose({
  id: props.id,
  el: audioRef.value,
  paused,
  percentage,
  play,
  pause,
  stop: pause
})
</script>

<template>
  <div class="player" :id="props.id" @click="(event: MouseEvent) => event.stopPropagation()">
    <audio ref="audioRef" autoplay :src="audioSrc" @timeupdate="updateProgress" />
    <el-progress
      :percentage="percentage"
      type="circle"
      :stroke-width="2"
      color="#7B4DFF"
      :width="props.size"
      stroke-linecap="butt"
      :style="`--play-icon-size: ${props.size}px`"
    >
      <el-icon @click="play" v-if="paused"><IconVideoPlay /></el-icon>
      <el-icon @click="pause" v-else><IconVideoPause /></el-icon>
    </el-progress>
  </div>
</template>

<style scoped lang="less">
.player {
  display: flex;

  audio {
    display: none;
  }

  .el-progress {
    :deep(.el-progress-circle) {
      --el-fill-color-light: var(--my-color-black-20);

      .el-progress-circle__track {
        stroke-width: 3.15; // 6.3 / 2
      }

      .el-progress-circle__path {
        transition:
          stroke-dasharray 0.4s ease 0s,
          stroke 0.6s ease 0s,
          opacity 0s ease 0s !important;
      }
    }

    :deep(.el-progress__text) {
      text-align: unset;

      .el-icon {
        font-size: var(--play-icon-size, 32px);
        color: var(--my-color-black-100);
        cursor: pointer;
        border-radius: var(--play-icon-size, 32px);
        overflow: hidden;

        &:hover {
          color: var(--el-color-primary);
        }
      }
    }
  }
}
</style>
