<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import router from '@/router'

import { config, urls } from '@/config'

import SignInItems from './SignInItems.vue'

const { t } = useI18n()
const route = useRoute()

defineProps<{
  redirectUri?: string
}>()

const checkAgree = () => {
  return true
}

const signInWithTarget = (event: MouseEvent, type: 'email' | 'phone') => {
  if (type === 'email') {
    router.push({ name: 'signup', params: { from: route.params.from || '-' } })
  } else {
    router.push({ name: 'signup', params: { from: `phone${route.params.from || ''}` } })
  }
}
</script>

<template>
  <div class="title">{{ t('欢迎使用{object}', { object: config.siteTitle }) }}</div>
  <div class="slogan">{{ t('无限AI视频，释放营销想象') }}</div>
  <div class="items">
    <SignInItems
      showSignInWithEmail
      x-showSignInWithPhone
      :clickItem="signInWithTarget"
      :checkAgree="checkAgree"
      :redirectUri="redirectUri"
    />
  </div>
  <div
    class="agree"
    v-html="
      t('登录即表示您接受{terms_of_service}和{privacy_policy}。', {
        terms_of_service: `<a class='el-link is-underline' target='${urls.terms_of_service.target}' href='${urls.terms_of_service.url}'>${t('服务条款')}</a>`,
        privacy_policy: `<a class='el-link is-underline' target='${urls.terms_of_service.target}' href='${urls.privacy_policy.url}'>${t('隐私政策')}</a>`
      })
    "
  ></div>
</template>

<style scoped lang="less">
.el-link,
.accept,
.slogan,
.title {
  font-family:
    SF Pro Text,
    var(--el-font-family);
}

.title {
  margin-top: 50px;
  text-align: center;

  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color: var(--my-color-x1);
}

.slogan {
  margin: 6px 0 48px;
  text-align: center;

  font-size: 14px;
  line-height: 22px;
  color: var(--my-color-black-50);
}

.items {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;

  .links {
    max-width: 384px;
  }
}

.agree {
  margin-bottom: 60px;
  text-align: center;

  font-size: 14px;
  line-height: 22px;
  color: var(--my-color-black-40);

  :deep(.el-link) {
    color: var(--my-color-black-89-90);
    vertical-align: baseline;

    &.is-underline {
      &:after {
        bottom: 2px;
      }

      &:hover:after {
        border-color: var(--my-color-black-89-90);
      }

      &:not(:hover):after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 0;
        // bottom: 0;
        border-bottom: 1px solid var(--my-color-black-89-90);
      }
    }
  }
}
</style>
