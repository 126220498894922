<script setup lang="ts">
import { computed, onMounted, ref, reactive } from 'vue'
import {
  ElForm,
  ElFormItem,
  ElButton,
  ElCheckboxGroup,
  ElRadioGroup,
  ElMessage,
  type FormInstance
} from 'element-plus'
import type { vMegFormSchema } from '@/components/feedback/type'
import { getSurveyInfo, submitFeedback, type SurveyInfo } from '@/api'
import IconClose from '@/components/icons/IconClose.vue'
import { fetchSubscriptionPlanAndCreditDetails, useProjStore } from '@/stores/proj'
import FeedbackSuccess from '@/components/feedback/FeedbackSuccess/FeedbackSuccess.vue'
import i18n from '@/locales/i18n'
import { mergeUserStore, useUserStore } from '@/stores/user'
import FeedbackError from '@/components/feedback/FeedbackError/FeedbackError.vue'
import IconLoading from '@/components/icons/IconLoading.vue'

// debug 或者开发时用
const defaultConfig = {
  form: {
    title: '我们真的想知道您是谁？',
    description:
      '我们重视您的意见！您的反馈对 VMEG 及我们的创作工具的改进至关重要。感谢您帮助我们提升体验！✨',
    showNum: true,
    fields: [
      {
        type: 'radio',
        label: '您的角色是什么？',
        options: [
          {
            value: 'Business owner',
            label: '企业主'
          },
          {
            value: 'Marketing/advertising professional',
            label: '市场/广告专业人士'
          },
          {
            value: 'Content Creator',
            label: '内容创作者'
          },
          {
            value: 'Other',
            label: '其他'
          }
        ],
        required: true
      },
      {
        type: 'radio',
        label: '您所在的行业是什么？',
        options: [
          {
            value: 'Retail',
            label: '零售'
          },
          {
            value: 'eCommerce',
            label: '电子商务'
          },
          {
            value: 'Finance',
            label: '金融'
          },
          {
            value: 'Technology',
            label: '技术'
          },
          {
            value: 'Education',
            label: '教育'
          },
          {
            value: 'Real Estate',
            label: '房地产'
          },
          {
            value: 'Other',
            label: '其他'
          }
        ],
        required: true
      },
      {
        type: 'radio',
        label: '您是如何首次了解到 VMEG 的？',
        options: [
          {
            value: 'Google',
            label: '谷歌'
          },
          {
            value: 'Youtube',
            label: 'YouTube'
          },
          {
            value: 'Linkedin',
            label: '领英'
          },
          {
            value: 'Twitter',
            label: '推特'
          },
          {
            value: 'Facebook, Instagram',
            label: '脸书，Instagram'
          },
          {
            value: 'Forums, Blogs, Newsletters',
            label: '论坛，博客，新闻稿'
          },
          {
            value: 'Other',
            label: '其他'
          }
        ],
        required: true
      },
      {
        type: 'checkbox',
        label: '希望通过 VMEG 解决哪些问题？',
        options: [
          {
            value: 'Simplifying the video editing process',
            label: '简化视频编辑过程'
          },
          {
            value: 'Reducing video production costs',
            label: '降低视频制作成本'
          },
          {
            value: 'Solving language translation issues',
            label: '解决语言翻译问题'
          },
          {
            value: 'Enhancing creativity and inspiration',
            label: '增强创造力和灵感'
          },
          {
            value: 'Improving video marketing effectiveness',
            label: '提高视频营销效果'
          },
          {
            value: 'Other',
            label: '其他'
          }
        ],
        required: true
      },
      {
        type: 'radio',
        label: '您对我们的哪个产品最感兴趣？',
        options: [
          {
            value: 'Clips to Videos',
            label: '短视频转长视频'
          },
          {
            value: 'Video Translation',
            label: '视频翻译'
          },
          {
            value: 'Both are of interest',
            label: '两者都感兴趣'
          },
          {
            value: 'Not interested at this time',
            label: '此时不感兴趣'
          }
        ],
        required: true
      }
    ]
  }
}

const props = defineProps<{
  surveyType: string
}>()

const emit = defineEmits(['close', 'complete'])

const t = i18n.global.t

const userStore = useUserStore()
const projStore = useProjStore()
const loadingForm = ref(false)
const loadingSubmit = ref(false)
const loadError = ref(false)
const submitSuccess = ref(false)

const formRef = ref<FormInstance>()
const formConfig = ref<vMegFormSchema>()
const formData = reactive<Record<string, any>>({})

const surveyInfo = ref<SurveyInfo>()

const handleSuccess = () => {
  submitSuccess.value = true

  mergeUserStore(userStore, {
    feedbackTypes: [...userStore.feedbackTypes, surveyInfo.value?.type].filter((v) => v) as string[]
  })
  fetchSubscriptionPlanAndCreditDetails(projStore)
}

const submitForm = async (formEl?: FormInstance) => {
  const pass = await formEl?.validate()
  if (!pass || !surveyInfo.value?.id) return

  // 校验
  loadingSubmit.value = true
  try {
    const resp = await submitFeedback({
      id: surveyInfo.value.id,
      projId: projStore.projId,
      answers: JSON.stringify(formData)
    })

    if (resp.data.code !== 0) {
      return ElMessage.error(resp.data.errorMsg || t('提交失败'))
    }

    // next show congratulation
    handleSuccess()
  } catch (e) {
    ElMessage.error(t('提交失败'))
    console.error(e)
  } finally {
    loadingSubmit.value = false
  }
}

const initSurvey = async () => {
  if (!props.surveyType) return

  loadingForm.value = true
  loadError.value = false

  try {
    const resp = await getSurveyInfo({ type: props.surveyType })
    surveyInfo.value = resp.data.data
    formConfig.value = JSON.parse(resp.data.data.questions).form as vMegFormSchema
  } catch (error) {
    loadError.value = true
    console.error('get form config error', error)
  } finally {
    loadingForm.value = false
  }
}

const handleClose = () => {
  emit('close')
}

const isFormValid = computed(() => {
  return formConfig.value?.fields.every((item) => {
    const value = formData[item.label]

    if (!item.required) return true

    if (Array.isArray(value)) {
      return value.length > 0 // 如果是数组，检查是否有元素
    }

    // 特殊逻辑
    if (typeof value === 'number' && value === 0) {
      return true
    }

    // '', null, undefined
    return !!value // 否则检查是否为空字符串
  })
})

onMounted(async () => {
  initSurvey()
})
</script>

<template>
  <div class="feedback-form-header">
    <h1 class="title">
      {{ t(formConfig?.title || '') }}
    </h1>
    <IconClose class="close" @click="handleClose" v-if="!submitSuccess" />
  </div>
  <FeedbackSuccess v-if="submitSuccess" @handle-start="emit('complete')" />
  <FeedbackError v-if="loadError" @handle-retry="initSurvey" />
  <div class="feedback-loading-container" v-if="loadingForm">
    <div class="feedback-loading-icon">
      <IconLoading />
    </div>
  </div>
  <div
    v-if="!loadingForm && !submitSuccess && formConfig && formData"
    class="feedback-form-container"
  >
    <div class="feedback-form-description" v-if="formConfig.description">
      {{ t(formConfig.description) }}
    </div>
    <div class="feedback-form-body">
      <el-form :model="formData" ref="formRef" label-position="top" :show-message="false">
        <el-form-item
          v-for="(field, idx) in formConfig.fields"
          :key="field.label"
          :label="`${idx + 1}. ${t(field.label)}`"
          :prop="field.label"
          :rules="
            field.required
              ? [
                  {
                    required: true,
                    message: t('此选项必填')
                  }
                ]
              : []
          "
        >
          <el-radio-group v-model="formData[field.label]" v-if="field.type === 'radio'">
            <div v-for="option in field.options" :key="option.value">
              <el-radio :value="option.value">{{ t(option.label || option.value) }}</el-radio>
            </div>
          </el-radio-group>

          <el-checkbox-group v-model="formData[field.label]" v-if="field.type === 'checkbox'">
            <div v-for="option in field.options" :key="option.value">
              <el-checkbox :value="option.value">{{ t(option.label || option.value) }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="feedback-form-footer">
      <el-button
        type="primary"
        @click="submitForm(formRef)"
        :loading="loadingSubmit"
        :disabled="!isFormValid"
      >
        {{ t('提交') }}
      </el-button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import './index.less';
</style>
