import { createI18n } from 'vue-i18n'

import { config as localeConfig } from '@/locales'
import { getCookie, getDefaultBrowserLanguage } from '@/utils'

// https://vue-i18n.intlify.dev

import en from './common/en'
import zh from './common/zh'

type MessageSchema = typeof en

const locale =
  getCookie(localeConfig.localeCookieName) || getDefaultBrowserLanguage(localeConfig.disabled) // 首先从缓存里拿，没有的话就用浏览器语言
const i18n = createI18n<{ message: MessageSchema }, 'en' | 'zh'>({
  legacy: false, // must set `false`, to use Composition API
  locale,
  fallbackLocale: locale === 'zh' || locale.startsWith('zh-') ? 'zh' : 'en',
  missingWarn: import.meta.env.DEV,
  fallbackWarn: false,
  messages: {
    en,
    zh
  }
})

export default i18n
