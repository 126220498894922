<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import type { UploadInstance } from 'element-plus'

import { Picture, Plus } from '@element-plus/icons-vue'
/*import {
  Folder as IconFolder,
} from '@element-plus/icons-vue'*/

import IconFolder from '@/components/icons/IconFolder.vue'

import UploadAttachment from '@/components/UploadAttachment.vue'
import LibraryAttachment from '@/components/LibraryAttachment.vue'

import { type Attachment } from '@/api'
import { useProjStore } from '@/stores/proj'

const { t } = useI18n()
const projStore = useProjStore()

const emit = defineEmits(['upload:change', 'upload:progress'])
const props = withDefaults(
  defineProps<{
    'onUpload:change': (attachments: Attachment[]) => void
    'onUpload:progress'?: (percent: number) => void
    product?: string
    fileFormat?: string
    url?: string
  }>(),
  {
    product: 'clips-to-videos',
    fileFormat: 'WEBP, JPG, JPEG, PNG',
    url: ''
  }
)

const uploadProgress = ref(0)
const uploadRef = ref<typeof UploadAttachment>()
const libraryRef = ref<typeof LibraryAttachment>()

const getUploadInput = (myUploadRef: typeof uploadRef) => {
  const ref = myUploadRef.value?.getUploadRef()?.value as UploadInstance | null
  const div = ref?.$el as HTMLDivElement
  let input: HTMLInputElement | null = null
  if (div) {
    input = div.querySelector('.el-upload__input')
  }
  return input
}
const uploadInputAction = (event: Event) => {
  const input = event.target as HTMLInputElement | null
  if (input && input.dataset.command === 'chose-from-device') {
    input.dataset.command = ''
    return
  }
  event.preventDefault()
}
onMounted(() => {
  const inputFile = getUploadInput(uploadRef)
  if (inputFile) {
    inputFile.onkeydown = inputFile.onclick = uploadInputAction
  }
})

const handleCommand = (command: string | number | object) => {
  if (command === 'chose-from-device') {
    const input = getUploadInput(uploadRef)
    if (input) {
      input.dataset.command = 'chose-from-device'
      input.value = ''
      input.click()
    } else {
      console.error(command)
    }
  } else if (command === 'chose-from-library') {
    libraryRef.value?.open()
  }
}

const onUploadProgress = (percentage: number) => {
  uploadProgress.value = percentage

  emit('upload:progress', percentage)
}
const onUploadSuccess = (attachment: Attachment) => {
  selectedDone(attachment)
}

const selectedDone = (attachment: Attachment) => {
  emit('upload:change', [attachment])
}
</script>

<template>
  <UploadAttachment
    ref="uploadRef"
    class="choose-file-container-attachment"
    :projId="projStore.projId"
    category="brand_logo"
    @upload:progress="onUploadProgress"
    @upload:success="onUploadSuccess"
    drag
    v-loading="uploadProgress > 0 && uploadProgress != 100"
    element-loading-background="transparent"
  >
    <el-dropdown
      class="choose-file-dropdown"
      popper-class="choose-file-popper"
      placement="bottom-start"
      @command="handleCommand"
      :disabled="uploadProgress > 0 && uploadProgress != 100"
    >
      <div class="choose-file-placeholder">
        <div class="choose-file-upload-progress" v-if="uploadProgress > 0 && uploadProgress != 100">
          {{ `${uploadProgress.toFixed(2)}%` }}
        </div>
        <div class="choose-file-upload-preview" v-else>
          <el-image fit="contain" :src="props.url" v-if="props.url" />
          <el-icon class="choose-file-plus" v-else>
            <Plus />
          </el-icon>
        </div>
        <div class="choose-file-tips" v-if="!props.url">
          {{ props.fileFormat }}
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="chose-from-device" :icon="IconFolder">
            {{ t('来自本地磁盘') }}
          </el-dropdown-item>
          <el-dropdown-item command="chose-from-library" :icon="Picture">
            {{ t('来自我的素材库') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </UploadAttachment>

  <LibraryAttachment @selected:done="selectedDone" ref="libraryRef" />
</template>

<style lang="less">
.el-popper.choose-file-popper {
  --el-dropdown-menuItem-hover-fill: var(--my-color-y2);
  margin-top: -6px;

  .el-popper__arrow {
    display: none;
  }
}
</style>
<style scoped lang="less">
.choose-file-container-attachment {
  width: 52px;
  height: 52px;

  border-radius: 8px;
  border: 1px solid var(--my-color-primary-50);

  :deep(.el-upload) {
    height: 100%;

    .el-upload-dragger {
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      border: none;
      --el-fill-color-blank: transparent;

      border-radius: 8px;
      cursor: default;
      --el-upload-dragger-padding-horizontal: 0px;
      --el-upload-dragger-padding-vertical: 0px;

      .choose-file-dropdown {
        width: 100%;
        height: 100%;

        &.is-disabled {
          --el-text-color-placeholder: var(--my-color-black-89-90);
        }

        .choose-file-placeholder {
          width: 100%;
          position: relative;

          &:focus-visible {
            outline: none;
          }

          .choose-file-upload-progress {
            position: absolute;
            top: 3px;
            font-size: 10px;
            width: 100%;
          }

          .choose-file-upload-preview {
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            .el-image {
              width: 100%;
              height: 100%;
            }

            .choose-file-plus {
              color: var(--el-color-primary);
            }
          }

          .choose-file-tips {
            position: absolute;
            bottom: 2px;
            font-size: 6px;
            color: var(--my-color-black-40);
          }
        }
      }
    }
  }

  :deep(.el-loading-mask) {
    .el-loading-spinner {
      --el-loading-spinner-size: 20px;
    }
  }
}
</style>
