<script setup lang="ts">
import { onUnmounted, ref } from 'vue'

import { useI18n } from 'vue-i18n'

import type { TabsPaneContext } from 'element-plus'

import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import { VideoPlay } from '@element-plus/icons-vue'

import IconEmpty from '@/components/icons/IconEmpty.vue'

import SoundWave from '@/components/icons/SoundWave.vue'

import { getMaterialList, type Material } from '@/api'
import { videoPlay } from '@/common'
import { useProjStore } from '@/stores/proj'
import { humanDuration } from '@/utils'

const { t } = useI18n()
const projStore = useProjStore()

const emit = defineEmits(['selected:done', 'selected:replace'])
const props = withDefaults(
  defineProps<{
    product?: string
    category?: 'video' | 'image' | 'audio' | 'voice'
    categories?: ('video' | 'image' | 'audio' | 'voice')[]
    limit?: number
  }>(),
  {
    product: 'clips-to-videos',
    category: 'video',
    categories: () => ['video', 'image'],
    limit: 1
  }
)
defineExpose({
  open: (list: Material[], replace: Material | null = null, category?: typeof props.category) => {
    selectedList.value = list
    replaceOriginal.value = replace
    if (category) {
      tabCategory.value = category
    }

    dialogVisible.value = true
    currentPage.value = 1
    fetchData()
  }
})

const tabCategory = ref(props.category)
const selectedList = ref<Material[]>([])
const replaceOriginal = ref<Material | null>()

const dialogVisible = ref(false)

let requestTask: AbortController | null = null
const isLoading = ref(false)
const currentPage = ref(1)
const dataList = ref<Material[]>([])
const total = ref(0)
const background = ref(false)
const pagerCount = ref(7)
const layout = ref('prev, pager, next, jumper, ->, total')
const pageSizes = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const pageSize = ref(pageSizes[0])
/*const searchTitle = ref('')
const searchSubmitedText = ref('')
const isSearching = ref(false)*/

const fetchData = () => {
  abortRequest()
  dataList.value = []
  total.value = 0
  isLoading.value = true

  const controller = new AbortController()
  requestTask = controller

  //searchSubmitedText.value = searchTitle.value

  getMaterialList(
    {
      projId: projStore.projId,
      product: props.product,
      category: tabCategory.value,
      //searchTitle: searchTitle.value,
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      orderBy: 'id',
      order: 'desc'
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      total.value = res.data.data.total
      if (res.data.data.current === currentPage.value && res.data.data.size === pageSize.value) {
        dataList.value = res.data.data.records || []

        selectedList.value = selectedList.value.map((obj) => {
          if (obj.createTime === null || obj.updateTime === null) {
            // 新建的数据，没有返回时间
            dataList.value.some((material) => {
              if (obj.id === material.id) {
                obj = material // 使用带时间的后台数据，保证数据一摸一样
                return true
              }
              return false
            })
          }
          // @ts-expect-error
          delete obj.uid
          // @ts-expect-error
          delete obj.status
          return {
            ...obj // 新对象堆栈，保证数据一摸一样，才能正常使用多选
          }
        })
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isLoading.value = false
      //isSearching.value = false
    })
}
const handleSizeChange = (val: number) => {
  pageSize.value = val
  currentPage.value = 1
  fetchData()
}
const handleCurrentChange = (val: number) => {
  currentPage.value = val
  fetchData()
}
const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  abortRequest()
})
/*const searchSubmit = () => {
  isSearching.value = true
  currentPage.value = 1
  fetchData()
}*/

const handleTabClickCategory = (tab: TabsPaneContext) => {
  tabCategory.value = String(tab.paneName) as typeof props.category
  currentPage.value = 1
  fetchData()
}
const selectedCancel = () => {
  selectedList.value = []
  replaceOriginal.value = null
  dialogVisible.value = false
}
const selectedDone = () => {
  emit('selected:done', [...selectedList.value])
  selectedCancel()
}

const isInSelectedList = (material: Material, materials?: Material[]) => {
  let found = false
  if (!materials) {
    materials = selectedList.value
  }
  materials.some((obj) => {
    found = obj.id === material.id
    return found
  })
  return found
}
const replaceAction = (material: Material) => {
  emit('selected:replace', material)
  selectedCancel()
}

const selectOne = (material: Material) => {
  if (props.limit !== 1) {
    return
  }
  if (isInSelectedList(material)) {
    selectedList.value = []
    return
  }
  selectedList.value = [material]
}
</script>

<template>
  <el-dialog
    class="choose-file-dialog choose-file-dialog-with-tabs"
    v-model="dialogVisible"
    width="700px"
    draggable
    align-center
  >
    <template #header>
      <div class="choose-file-dialog-header">
        <span>{{ t('我的素材库') }}</span>
        <small v-if="selectedList.length >= props.limit">
          {{ t('最多上传{max}个文件', { max: props.limit }) }}
        </small>
      </div>
    </template>
    <div class="choose-file-dialog-body" v-loading="isLoading">
      <el-tabs v-model="tabCategory" @tab-click="handleTabClickCategory">
        <template v-for="(cate, index) in props.categories" :key="index">
          <el-tab-pane :label="t('视频')" :name="cate" v-if="cate == 'video'" />
          <el-tab-pane :label="t('图像')" :name="cate" v-if="cate == 'image'" />
          <el-tab-pane :label="t('音频')" :name="cate" v-if="cate == 'audio'" />
          <el-tab-pane :label="t('声音')" :name="cate" v-if="cate == 'voice'" />
        </template>
      </el-tabs>
      <el-checkbox-group
        class="choose-file-dialog-wrapper"
        v-model="selectedList"
        :max="replaceOriginal ? 1 : props.limit"
        :disabled="!!replaceOriginal || props.limit == 1"
      >
        <div
          class="choose-file-item"
          :class="{ 'choose-file-item-pointer': replaceOriginal }"
          v-for="material in dataList"
          :key="material.id"
        >
          <div
            class="thumbnail audio"
            @click="replaceOriginal && replaceAction(material)"
            v-if="['audio', 'voice'].includes(material.category)"
          >
            <el-icon><SoundWave /></el-icon>
          </div>
          <el-image
            class="thumbnail"
            fit="contain"
            :src="material.url"
            @click="replaceOriginal && replaceAction(material)"
            v-if="material.category == 'image'"
          />
          <el-image
            class="thumbnail"
            fit="contain"
            :src="material.url"
            @click="replaceOriginal && replaceAction(material)"
            v-if="material.category == 'video'"
          />
          <div class="media-duration" v-if="material.duration">
            {{ humanDuration(material.duration) }}
          </div>
          <el-icon class="media-play" v-if="material.duration" @click="videoPlay(material, true)">
            <VideoPlay />
          </el-icon>
          <div class="chose-media" v-if="!replaceOriginal || isInSelectedList(material)">
            <el-checkbox
              class="g-checkbox"
              :class="{ 'is-not-disabled': props.limit == 1 }"
              @click="selectOne(material)"
              :value="material"
            />
          </div>
        </div>
      </el-checkbox-group>
      <el-empty class="g-empty" v-if="!isLoading && !dataList.length">
        <template #image>
          <el-icon><IconEmpty /></el-icon>
        </template>
      </el-empty>
      <el-pagination
        class="g-pagination"
        :background="background"
        v-model:page-size="pageSize"
        :total="total"
        :pager-count="pagerCount"
        v-model:current-page="currentPage"
        :layout="layout"
        :page-sizes="pageSizes"
        :disabled="isLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="total > pageSizes[0]"
      />
    </div>
    <template #footer v-if="!replaceOriginal">
      <div class="choose-file-dialog-footer">
        <el-button @click="selectedCancel">{{ t('取消') }}</el-button>
        <el-button type="primary" :disabled="!selectedList.length" @click="selectedDone">
          <span>{{ t('选择') }}</span>
          <span v-if="props.limit != 1 && selectedList.length">({{ selectedList.length }})</span>
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="less">
.el-dialog.choose-file-dialog.choose-file-dialog-with-tabs {
  .el-dialog__header {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .el-dialog.choose-file-dialog {
    --el-dialog-width: 100% !important;
  }
}
</style>

<style scoped lang="less">
.el-dialog.choose-file-dialog {
  .choose-file-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    small {
      font-size: 12px;
      color: var(--my-color-black-40);
    }
  }

  .choose-file-dialog-body {
    .el-tabs {
      --el-tabs-header-height: 20px;
      --el-border-color-light: transparent;

      :deep(.el-tabs__header) {
        margin: 13px 0 17px 0;

        .el-tabs__item {
          &:hover {
            --el-color-primary: var(--el-color-primary-dark-2) !important;
          }
        }

        .el-tabs__active-bar {
          --el-color-primary: transparent;
        }
      }
    }

    .choose-file-dialog-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }

  .choose-file-dialog-footer {
    display: flex;
    justify-content: center;

    .el-button > :deep(span) {
      gap: 6px;
    }
  }
}

.choose-file-item {
  display: flex;
  position: relative;

  &.choose-file-item-pointer {
    cursor: pointer;
  }

  .thumbnail {
    width: 120px;
    height: 90px;
    border-radius: 8px;
    background: var(--my-color-black-100);

    &.audio {
      height: 50px;

      background: var(--my-color-black-10);
      display: flex;
      align-items: center;
      justify-content: center;

      .el-icon {
        font-size: 100px;
        color: var(--el-color-primary);
      }
    }
  }

  .media-duration {
    position: absolute;
    left: 6px;
    bottom: 6px;
    height: 16px;
    line-height: 16px;
    padding: 0 4px;
    border-radius: 4px;
    font-size: 10px;
    background: var(--my-color-black-50);
    color: var(--my-color-white-100);
  }

  .media-play {
    position: absolute;
    right: 6px;
    top: 6px;
    --my-handle-icon-size: 16px;
    font-size: var(--my-handle-icon-size);
    color: var(--el-color-primary);

    display: none; // flex
    align-items: center;
    justify-content: center;
    gap: 6px;

    > div {
      --my-handle-wrapper-padding: 4px;
      width: calc(var(--my-handle-wrapper-size) + var(--my-handle-wrapper-padding));
      height: calc(var(--my-handle-wrapper-size) + var(--my-handle-wrapper-padding));
      background: var(--my-color-white-100);
      padding: var(--my-handle-wrapper-padding);
      border-radius: 4px;
      cursor: pointer;
    }

    .el-icon {
      display: flex;
    }
  }

  &:hover {
    .media-play {
      display: flex;
    }
  }

  .media-play {
    right: unset;
    left: 6px;
    z-index: 1;
    padding: 2px;
    border-radius: 4px;
    background: var(--my-color-black-50);
    color: var(--my-color-white-100);
    cursor: pointer;
  }

  .chose-media {
    position: absolute;
    width: 100%;
    height: 100%;

    .el-checkbox {
      &.is-not-disabled {
        :deep(.el-checkbox__input) {
          &.is-disabled {
            cursor: pointer;

            .el-checkbox__inner {
              background-color: var(--el-checkbox-bg-color);
              border: var(--el-checkbox-input-border);
              cursor: pointer;

              &:after {
                cursor: pointer;
              }
            }
          }
        }
      }

      &.is-not-disabled.is-checked {
        :deep(.el-checkbox__input) {
          &.is-disabled {
            .el-checkbox__inner {
              background-color: var(--el-checkbox-checked-bg-color);
              border-color: var(--el-checkbox-checked-input-border-color);

              &:after {
                border-color: var(--el-checkbox-checked-icon-color);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .choose-file-dialog {
    .choose-file-dialog-body {
      .choose-file-dialog-wrapper {
        gap: 11px;
      }
    }
  }

  .choose-file-item {
    .thumbnail {
      width: 107px;
      height: 80px;
    }

    .media-play {
      display: flex;
    }
  }
}
</style>
