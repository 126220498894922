export const isMobile = () => {
  return !!navigator.userAgent.match(/ Mobile/i)
}

export const generateUUID = () => {
  let d = new Date().getTime()
  if (
    typeof window !== 'undefined' &&
    window.performance &&
    typeof window.performance.now === 'function'
  ) {
    d += performance.now() // use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export const isValidEmail = (str: string): boolean => {
  return !!String(str)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const isValidPhoneNumber = (str: string) => {
  return /^1[3-9]\d{9}$/.test(String(str).trim())
}

export function getCookie(cname: string) {
  if (typeof document === 'undefined') {
    return ''
  }
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function setCookie(cname: string, cvalue: string, exdays: number = 7) {
  if (typeof document === 'undefined') {
    return
  }
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getDefaultBrowserLanguage(isDisabled: boolean) {
  if (isDisabled) {
    return 'en'
  }
  return navigator.language || 'en' // 获取浏览器的语言
}

/**
 * Format duration or time as human-readable text.
 *
 * @param totalMilliseconds Number of milliseconds.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanDuration = (totalMilliseconds: number, dp: number = 0) => {
  function padZero(number: number) {
    return number.toString().padStart(2, '0')
  }
  const totalSeconds = totalMilliseconds / 1000
  let hours = Math.floor(totalSeconds / 3600)
  let minutes = Math.floor((totalSeconds % 3600) / 60)
  let seconds = Math.floor(totalSeconds % 60)
  if (!dp && Number(totalSeconds.toFixed(2).split('.')[1]) > 50) {
    if (seconds < 60) {
      seconds += 1
    } else {
      seconds = 0
      if (minutes < 60) {
        minutes += 1
      } else {
        minutes = 0
        hours += 1
      }
    }
  }
  const dps = dp ? (String(totalSeconds).split('.')[1] || '').substring(0, dp) : ''
  return `${hours ? `${hours}:` : ''}${padZero(minutes)}:${padZero(seconds)}${dps ? `.${dps}` : ''}`
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}
