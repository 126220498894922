<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48074 12.4148L10.9959 6.90723L9.14029 5.05166L3.60434 10.5801C3.44712 10.7371 3.34667 10.942 3.31884 11.1624L3.1045 12.8599L4.8628 12.7033C5.09622 12.6825 5.31492 12.5804 5.48074 12.4148ZM12.56 5.34525L11.7035 6.20061L9.84788 4.34503L10.7057 3.48841C11.2191 2.97568 12.0512 2.97702 12.563 3.49141C13.0734 4.00444 13.0721 4.83387 12.56 5.34525ZM13.2719 2.7861C12.3701 1.87968 10.9038 1.87732 9.99905 2.78082L2.89771 9.87248C2.58329 10.1865 2.38238 10.5962 2.32672 11.0371L2.06055 13.1451C2.00727 13.567 2.35743 13.9303 2.78109 13.8926L4.95148 13.6994C5.41831 13.6578 5.85573 13.4536 6.18736 13.1224L13.2666 6.05284C14.169 5.15171 14.1713 3.69013 13.2719 2.7861ZM7.83325 12.6667C7.55711 12.6667 7.33325 12.8906 7.33325 13.1667C7.33325 13.4429 7.55711 13.6667 7.83325 13.6667H13.4999C13.7761 13.6667 13.9999 13.4429 13.9999 13.1667C13.9999 12.8906 13.7761 12.6667 13.4999 12.6667H7.83325Z"
      fill="currentColor"
    />
  </svg>
</template>
