<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.35019 5L5.3123 3.5H3.5C2.94772 3.5 2.5 3.94772 2.5 4.5V12C2.5 12.5523 2.94771 13 3.5 13H12.5C13.0523 13 13.5 12.5523 13.5 12L13.5 6C13.5 5.44772 13.0523 5 12.5 5H7.35019ZM3.5 2.5C2.39543 2.5 1.5 3.39543 1.5 4.5V12C1.5 13.1046 2.39543 14 3.5 14H12.5C13.6046 14 14.5 13.1046 14.5 12L14.5 6C14.5 4.89543 13.6046 4 12.5 4H7.67854L5.77287 2.59732C5.68698 2.5341 5.58313 2.5 5.47648 2.5H3.5Z"
      fill="currentColor"
    />
  </svg>
</template>
