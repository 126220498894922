<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterView, useRoute, type RouteLocationNormalized } from 'vue-router'

import type { MenuInstance } from 'element-plus'
import en from 'element-plus/dist/locale/en.mjs'
import zhCn from 'element-plus/dist/locale/zh-CN.mjs'

import { User } from '@element-plus/icons-vue'

import IconLogo from '@/components/icons/IconLogo.vue'

import CreditInfo from '@/components/CreditInfo.vue'
import SignInDialog from '@/components/SignInDialog.vue'
import NeedHelp from '@/components/NeedHelp.vue'

import { getSignedInfo, userSignOut } from '@/api'
import {
  config as siteConf,
  type Menu,
  getMenus,
  getHomeMenus,
  type HomeMenu,
  keepAliveViews
} from '@/config'
import { config as localeConfig } from '@/locales'
import i18nHome from '@/locales/i18n-home'
import router from '@/router'
import { useUserStore, updateUserStore } from '@/stores/user'
import { useProjStore, updateProjData, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { isMobile, getCookie, setCookie, getDefaultBrowserLanguage } from '@/utils'

import Store from '@/videoEditor/store'

Store.useProvider()

/*import { useDark, useToggle } from '@vueuse/core'

// css ::view-transition https://element-plus.gitee.io/
const isDark = useDark()
const toggleDark = useToggle(isDark)*/
/*import { useDark } from '@vueuse/core'
useDark({
  initialValue: 'light' // disable vite-plugin-vue-devtools & vite-plugin-inspect
})*/

const config = siteConf

const { t, locale: i18nLocale } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()

const menuTrigger: string = isMobile() ? 'click' : 'hover'
const refMainMenu = ref<MenuInstance>()
const refUserMenu = ref<MenuInstance>()

const refHomeMenu = ref<MenuInstance>()
const menuHamburgerActive = ref(false)

const signInDialogVisible = ref(false)

const getMenuActive = () => {
  const name = String(route.name)
  let path = menusMap.value[name]?.path
  if (!path) {
    path = menusMap.value[name.split('.').slice(0, -1).join('.')]?.path
  }
  return path || route.path
}
const isInApp = (type: string, menu?: HomeMenu) => {
  const name = String(route.name)
  if (type === 'active' && menu) {
    return menu.pages?.includes(name) || false
  }
  if (type === 'info') {
    return ['user'].includes(name) || name.startsWith('vmeg.')
  }
  let hit = false
  if (type === 'menu') {
    homeMenus.value.some((menu) => {
      if (menu.routes?.includes(name)) {
        hit = true
      }
      return hit
    })
  }
  return hit
}
const menus = ref<Menu[]>([])
const menusMap = ref<Record<string, Menu>>({})
const homeMenus = ref<HomeMenu[]>([])
router.afterEach((to: RouteLocationNormalized) => {
  updateMenus(to)

  if (menuHamburgerActive.value) {
    closeHambuger()
  }
  signInDialogVisible.value = false
})
const updateMenus = (route: RouteLocationNormalized) => {
  const obj = getMenus(userStore, projStore, route.name)
  menus.value = obj.list
  menusMap.value = obj.map
  homeMenus.value = getHomeMenus(userStore, projStore, route.name).list
}
projStore.$subscribe((mutation, state) => {
  if (state.projId) {
    updateMenus(route)
  }
})

const localeSetting = ref(en)
const setLocale = (locale: string, notSetCookie: boolean = false) => {
  if (!locale || localeConfig.disabled) {
    if (!locale) {
      notSetCookie = true
      setCookie(localeConfig.localeCookieName, locale)
    }
    locale = getDefaultBrowserLanguage(localeConfig.disabled)
  }
  if (locale === 'zh' || String(locale).startsWith('zh-')) {
    localeSetting.value = zhCn
  } else {
    localeSetting.value = en
  }
  i18nLocale.value = locale
  // @ts-expect-error
  i18nHome.global.locale.value = locale
  if (!notSetCookie) {
    setCookie(localeConfig.localeCookieName, locale)
  }

  if (!import.meta.env.SSR) {
    const routeTitle = route.meta.title as (() => string) | undefined
    const routeIntro = route.meta.intro as (() => string) | undefined
    document.title = `${config.siteTitle}${routeIntro ? `-${routeIntro()}` : routeTitle ? `-${routeTitle()}` : ''}`
    document.documentElement.setAttribute('lang', locale)
  }
}
const locale =
  getCookie(localeConfig.localeCookieName) || getDefaultBrowserLanguage(localeConfig.disabled) // 首先从缓存里拿，没有的话就用浏览器语言
if (localeConfig.locales.includes(locale) || localeConfig.locales.includes(locale.split('-')[0])) {
  setLocale(locale, true)
}

const storage = import.meta.env.SSR
  ? {
      getItem: () => {},
      setItem: () => {},
      removeItem: () => {}
    }
  : config.saveSignedInfoToLocalStorage
    ? localStorage
    : sessionStorage
const cachedViews = ref<string[]>([])

const userInfoKey = 'userInfo'
const projectInfoKey = 'projectInfo'

const clearUserAndProjectCache = () => {
  storage.removeItem(userInfoKey)
  storage.removeItem(projectInfoKey)
}

const initUserAndProjectInfo = () => {
  const userStr = storage.getItem(userInfoKey)
  const projStr = storage.getItem(projectInfoKey)

  if (userStr && projStr) {
    try {
      const userCache: typeof userStore = JSON.parse(userStr)
      const projCache: typeof projStore = JSON.parse(projStr)

      if (userCache.exp > Math.round(Date.now() / 1000)) {
        userStore.$patch(userCache)
      } else {
        console.warn('User token has expired')
        return clearUserAndProjectCache()
      }

      if (projCache) {
        projStore.$patch(projCache)
      } else {
        console.warn('projectInfoKey has expired')
        return clearUserAndProjectCache()
      }
    } catch (e) {
      clearUserAndProjectCache()
      console.error(e)
    }
  }
}
const subscribeStoreChanges = () => {
  userStore.$subscribe((mutation, state) => {
    if (state.id) {
      cachedViews.value = keepAliveViews
      setLocale(state.locale)

      storage.setItem(
        userInfoKey,
        JSON.stringify(
          Object.assign({}, state, {
            token: config.useJwtInHeader ? state.token : ''
          })
        )
      )
    } else {
      cachedViews.value = []
      storage.removeItem(userInfoKey)
      if (route.meta.requiresAuth) {
        router.push({ name: 'home' })
      }
    }
  })

  projStore.$subscribe((mutation, state) => {
    if (state.projId) {
      storage.setItem(projectInfoKey, JSON.stringify(Object.assign({}, state)))
    } else {
      storage.removeItem(projectInfoKey)
    }
  })
}

const gotoHome = () => {
  router.push({ name: 'home' })
}

const handleMainMenu = () => {
  refUserMenu.value?.close('sub-menu-user')
}
const handleUserMenu = (index: string) => {
  if (index === 'sub-menu-user' && !userStore.id) {
    handleMainMenu()
  }

  refMainMenu.value?.close('sub-menu-more')
}

const switchHambuger = () => {
  if (menuHamburgerActive.value) {
    closeHambuger()
    return
  }
  const cls = 'el-popup-parent--hidden'
  document.body.classList.add(cls)
  menuHamburgerActive.value = true
}

const closeHambuger = () => {
  homeMenus.value.forEach((menu) => {
    if (menu.subMenus?.length) {
      refHomeMenu.value?.close(menu.title())
    }
  })
  const cls = 'el-popup-parent--hidden'
  document.body.classList.remove(cls)
  menuHamburgerActive.value = false
}

if (!import.meta.env.SSR) {
  window.addEventListener('resize', () => {
    closeHambuger()
  })
}

const clickHomeMenuItem = (menu: HomeMenu) => {
  closeHambuger()
  if (menu.url) {
    if (!menu.target || menu.target === '_self' || isMobile()) {
      window.location.href = menu.url
    } else if (menu.target === '_parent') {
      window.parent.location.href = menu.url
    } else if (menu.target === '_top') {
      ;(window.top || window.parent).location.href = menu.url
    } else {
      window.open(menu.url)
    }
  }
}

const gotoWorkspace = () => {
  if (userStore.roles?.includes('admin')) {
    router.push({ name: 'admin.user-projects' })
    return
  }

  // router.push({ name: 'vmeg.create-video', params: { projId: projStore.projId || '-' } })
  router.push({ name: 'vmeg.video-translation', params: { projId: projStore.projId || '-' } })
}

const doSignout = () => {
  userSignOut().finally(() => {
    userStore.$reset()
    projStore.$reset()
  })
}

const checkSignedInfo = () => {
  getSignedInfo()
    .then((res) => {
      if (!res.data.data?.user) {
        return
      }

      const { email, phone } = res.data.data.user
      updateUserStore(userStore, res.data.data)
      updateProjData(projStore, res.data.data.projectInfo)

      if (!userStore.roles?.includes('admin')) {
        fetchSubscriptionPlanAndCreditDetails(projStore)
        setTimeout(() => {
          fetchSubscriptionPlanAndCreditDetails(projStore)
        }, 5000) // 付费后后台可能延后生效。此处延时再次查询一下
      }

      if (!email && !phone) {
        router.push({ name: 'user', params: { from: 'email' } })
        return
      }
      if (route.params.from && route.params.from !== '-') {
        router.push({ path: String(route.params.from) })
        return
      }

      // router.push({ name: 'home' }) // App.vue 中不能router home
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      console.log('get signed info', err)
    })
}

onMounted(() => {
  initUserAndProjectInfo()
  subscribeStoreChanges()

  if (!import.meta.env.SSR) {
    checkSignedInfo()
  }
})
</script>

<template>
  <el-config-provider :locale="localeSetting">
    <el-container class="app-container">
      <el-aside class="app-aside aside-signin" width="776px" v-if="route.meta.showAsideLarge">
        <div class="logo-wrapper">
          <div class="logo" @click="gotoHome">
            <IconLogo />
          </div>
        </div>
        <div class="aside-video">
          <iframe
            width="560"
            height="315"
            :src="`https://www.youtube.com/embed/7DpES-XbtJc?mute=1&autoplay=${isMobile() ? 0 : 1}&iv_load_policy=3&loop=1&modestbranding=1&playsinline=1&rel=0`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div class="aside-slogan">{{ t('无限AI视频，释放营销想象') }}</div>
      </el-aside>
      <el-container class="app-container-container" :class="{ 'with-aside': route.meta.showAside }">
        <el-header
          class="app-header"
          :class="[String(route.name)].concat(isInApp('menu') ? ['is-app'] : [])"
        >
          <div class="app-header-wrapper">
            <div class="space-wrapper">
              <div class="logo-wrapper">
                <div class="logo" @click="gotoHome">
                  <IconLogo />
                </div>
              </div>
            </div>
            <div class="menu-wrapper">
              <el-menu
                mode="horizontal"
                :default-active="getMenuActive()"
                :router="true"
                :menu-trigger="menuTrigger"
                :unique-opened="true"
                @open="handleMainMenu"
                @select="handleMainMenu"
                ref="refMainMenu"
                :ellipsis="menus.length > 2"
                :ellipsis-sub-menu-props="{
                  teleported: false
                }"
                v-if="!route.meta.hideMenu"
              >
                <el-menu-item v-for="menu in menus" :key="menu.path" :index="menu.path">
                  {{ menu.title() }}
                </el-menu-item>
              </el-menu>
            </div>
            <div
              class="home-menu-wrapper"
              :class="{ active: menuHamburgerActive }"
              v-if="homeMenus.length"
            >
              <el-menu
                ref="refHomeMenu"
                :mode="menuHamburgerActive ? 'vertical' : 'horizontal'"
                :ellipsis="false"
                :unique-opened="true"
                :menu-trigger="menuTrigger"
              >
                <template v-for="(menu, index) in homeMenus" :key="index">
                  <el-sub-menu :index="menu.title()" v-if="menu.subMenus?.length">
                    <template #title>{{ menu.title() }}</template>
                    <el-menu-item
                      :xindex="`${index}-${idx}`"
                      @click="clickHomeMenuItem(subMenu)"
                      v-for="(subMenu, idx) in menu.subMenus"
                      :key="idx"
                    >
                      <el-link
                        :underline="false"
                        :href="subMenu.url || 'javascript:void(0)'"
                        :target="subMenu.target"
                      >
                        {{ subMenu.title() }}
                        <small class="flag" v-if="subMenu.flag">{{ subMenu.flag() }}</small>
                      </el-link>
                    </el-menu-item>
                  </el-sub-menu>
                  <el-menu-item
                    :class="{ 'is-active': isInApp('active', menu) }"
                    :xindex="`${index}`"
                    @click="clickHomeMenuItem(menu)"
                    v-else
                  >
                    <el-link
                      :underline="false"
                      :href="menu.url || 'javascript:void(0)'"
                      :target="menu.target"
                    >
                      {{ menu.title() }}
                    </el-link>
                  </el-menu-item>
                </template>
              </el-menu>
            </div>
            <div
              class="info-wrapper mobile"
              v-if="['home', 'pricing'].includes(String(route.name))"
            >
              <el-button round @click="gotoWorkspace">{{ t('开始吧') }}</el-button>
            </div>
            <button
              class="menu-hamburger"
              :class="{ active: menuHamburgerActive }"
              @click="switchHambuger"
              v-if="homeMenus.length"
            >
              <span class="hamburger-1"></span>
              <span class="hamburger-2"></span>
              <span class="hamburger-3"></span>
            </button>
            <div class="info-wrapper" v-if="isInApp('info')">
              <CreditInfo />
            </div>
            <div class="info-wrapper" v-if="['home', 'pricing'].includes(String(route.name))">
              <el-button round @click="gotoWorkspace">{{ t('开始吧') }}</el-button>
            </div>
            <div class="user-wrapper">
              <el-menu
                mode="horizontal"
                :default-active="route.path"
                :router="true"
                :menu-trigger="menuTrigger"
                :unique-opened="true"
                @open="handleUserMenu"
                @select="handleUserMenu"
                ref="refUserMenu"
                :ellipsis="false"
              >
                <el-sub-menu index="sub-menu-user" :teleported="false">
                  <template #title>
                    <div
                      style="color: red; margin-left: 10px"
                      v-if="userStore.roles?.includes('admin')"
                    >
                      管理员
                    </div>
                    <el-icon
                      class="user-icon"
                      @click="route.name !== 'signin' && (signInDialogVisible = true)"
                      v-if="!userStore.id"
                    >
                      <User />
                    </el-icon>
                    <div class="user-icon" v-if="userStore.id">
                      <el-avatar :size="32" :src="userStore.avatar" v-if="userStore.avatar" />
                      <el-avatar :size="32" :icon="User" v-else />
                    </div>
                    <button class="menu-hamburger">
                      <span class="hamburger-1"></span>
                      <span class="hamburger-2"></span>
                      <span class="hamburger-3"></span>
                    </button>
                  </template>

                  <template v-for="(menu, index) in homeMenus" :key="index">
                    <el-sub-menu
                      class="home-menu-shadow"
                      :index="menu.title()"
                      v-if="menu.subMenus?.length"
                    >
                      <template #title>{{ menu.title() }}</template>
                      <el-menu-item
                        :xindex="`${index}-${idx}`"
                        @click="clickHomeMenuItem(subMenu)"
                        v-for="(subMenu, idx) in menu.subMenus"
                        :key="idx"
                      >
                        <el-link
                          :underline="false"
                          :href="subMenu.url || 'javascript:void(0)'"
                          :target="subMenu.target"
                        >
                          {{ subMenu.title() }}
                          <small class="flag" v-if="subMenu.flag">{{ subMenu.flag() }}</small>
                        </el-link>
                      </el-menu-item>
                    </el-sub-menu>
                    <el-menu-item
                      class="home-menu-shadow"
                      :class="{ 'is-active': isInApp('active', menu) }"
                      :xindex="`${index}`"
                      @click="clickHomeMenuItem(menu)"
                      v-else
                    >
                      <el-link
                        :underline="false"
                        :href="menu.url || 'javascript:void(0)'"
                        :target="menu.target"
                      >
                        {{ menu.title() }}
                      </el-link>
                    </el-menu-item>
                  </template>
                  <el-divider v-if="homeMenus.length" />

                  <el-menu-item index="/user/-" class="user-info-wrapper" v-if="userStore.id">
                    <el-avatar :size="32" :src="userStore.avatar" v-if="userStore.avatar" />
                    <el-avatar :size="32" :icon="User" v-else />
                    <div class="user-info">
                      <div>{{ userStore.name || t('个人资料') }}</div>
                      <div v-if="userStore.email">
                        <small>{{ userStore.email }}</small>
                      </div>
                    </div>
                  </el-menu-item>
                  <el-divider v-if="userStore.id" />
                  <CreditInfo :isInMenu="true" v-if="userStore.id" />
                  <el-menu-item index="/signout" v-if="userStore.id" @click="doSignout">
                    {{ t('退出') }}
                  </el-menu-item>
                  <el-menu-item index="" @click="signInDialogVisible = true" v-if="!userStore.id">{{
                    t('登录')
                  }}</el-menu-item>
                </el-sub-menu>
              </el-menu>
            </div>
          </div>
        </el-header>
        <el-main
          class="app-main"
          :class="
            [String(route.name)]
              .concat(route.meta.showAside ? ['app-main-flex', 'app-main-full'] : [])
              .concat((route.meta.mainClasses as string[] | undefined) || [])
          "
        >
          <el-aside class="app-aside" width="240px" v-if="route.meta.showAside">
            <div class="aside-fixed">
              <el-menu
                class="main-menu"
                :default-active="getMenuActive()"
                :router="true"
                :menu-trigger="menuTrigger"
                :unique-opened="true"
                @open="handleMainMenu"
                @select="handleMainMenu"
                :ellipsis-sub-menu-props="{
                  teleported: false
                }"
              >
                <el-menu-item v-for="menu in menus" :key="menu.path" :index="menu.path">
                  <template #title>
                    <el-icon>
                      <component :is="menu.icon" v-if="menu.icon" />
                    </el-icon>
                    <span>{{ menu.title() }}</span>
                  </template>
                </el-menu-item>
              </el-menu>
            </div>
          </el-aside>
          <div class="app-main-view">
            <RouterView v-slot="{ Component }">
              <KeepAlive :include="cachedViews">
                <component :is="Component" :key="route.path" v-if="route.meta.keepAliveView" />
              </KeepAlive>
              <component :is="Component" v-if="!route.meta.keepAliveView" />
            </RouterView>
          </div>
        </el-main>
        <el-footer class="app-footer">
          <div class="site-info">
            <div class="copyright">
              <el-text>{{ config.copyright }}</el-text>
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-container>
    <SignInDialog v-model="signInDialogVisible" />
    <NeedHelp />
  </el-config-provider>
</template>

<style lang="less">
/* 顶部菜单 */
.el-popper .el-menu--popup,
.el-menu .el-sub-menu {
  --el-border-radius-small: 6px;
  --el-box-shadow-light: 0 5px 5px -3px var(--my-color-black-10),
    0 8px 10px 1px var(--my-color-black-6), 0 3px 14px 2px var(--my-color-black-5);
}

/* 顶部菜单 & 省略号更多菜单 */
.el-popper .el-menu--popup {
  padding: 12px;

  .el-menu-item {
    border-radius: 3px;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &.is-active {
      background-color: var(--el-menu-hover-bg-color); // 保持hover状态下一样的背景色

      &:hover {
        background-color: var(--my-color-primary-12); // is-active状态下hover，背景色比is-active更深
      }
    }
  }
}
/* 顶部省略号更多菜单 */
//.el-sub-menu.el-sub-menu__hide-arrow:not(.is-opened) .el-sub-menu__title:hover {
.el-sub-menu.el-sub-menu__hide-arrow .el-sub-menu__title:hover {
  background-color: var(--el-menu-hover-bg-color);
}

body.el-popup-parent--hidden {
  .el-header {
    &.pricing {
      overflow: unset;
      border-bottom-width: 1px;
      height: var(--el-header-height);
    }
  }
}
</style>

<style scoped lang="less">
.logo-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--my-color-black-89-90);

  .logo {
    display: flex;
    cursor: pointer;

    svg {
      width: 129px;
      height: 42px;
    }
  }
}

.aside-signin {
  width: var(--el-aside-width, 776px);
  height: 100vh;
  z-index: 6;
  background: linear-gradient(136.8deg, #ff763c54 9.2%, #ff3c8254 29.66%, #7b4dff54 61.12%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-content: space-evenly;

  + .app-container-container {
    .app-header {
      border-bottom-color: transparent;

      .logo-wrapper {
        display: none;
      }
    }
  }

  .logo-wrapper {
    justify-content: center;
    height: 64px;

    .logo {
      svg {
        width: 196px;
        height: 100%;
      }
    }
  }

  .aside-video {
    width: 696px;
    height: 391.5px;
    margin: 0 auto;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }

  .aside-slogan {
    font-family: var(--el-font-family);
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: var(--my-color-black-50);
  }
}

.aside-fixed {
  position: fixed;
  z-index: 4;
  background: var(--my-color-white-100);
  width: var(--el-aside-width, 240px);
  height: calc(100vh - var(--el-header-height));

  .main-menu {
    margin: 32px 16px;
    background-color: transparent;
    border-right: none;

    --el-menu-item-height: 48px;
    --el-menu-text-color: var(--my-color-black-40);

    .el-menu-item {
      margin-bottom: 15px;
      font-size: 16px;
      border-radius: 52px;

      &:hover {
        color: var(--el-menu-active-color); // 保持和is-active情况下一样的颜色
      }

      &.is-active {
        background-color: var(--el-menu-hover-bg-color); // 保持hover状态下一样的背景色

        &:hover {
          background-color: var(
            --my-color-primary-12
          ); // is-active状态下hover，背景色比is-active更深
        }
      }

      .el-icon {
        font-size: 22px;
      }
    }
  }
}

.el-header {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  background: var(--el-menu-bg-color);
  --my-border-bottom-width: 1px;
  border-bottom: solid var(--my-border-bottom-width) var(--el-menu-border-color);

  .home-menu-wrapper {
    display: flex;
    align-items: center;

    flex: 1;

    .el-menu.el-menu--horizontal {
      display: flex;
      justify-content: center;

      > .el-menu-item.is-active {
        border-bottom: 2px solid transparent;
      }
    }
  }

  .space-wrapper {
    flex: 1;
  }

  &.pricing,
  &.home {
    position: fixed !important;
    background: var(--my-color-white-100);

    + .app-main {
      margin-top: var(--el-header-height) !important;
    }
  }

  &.pricing {
    overflow: hidden;
    border-bottom-width: 0;
    height: calc(var(--el-header-height) - var(--my-border-bottom-width));
  }

  &.pricing,
  &.home,
  &.is-app {
    .space-wrapper {
      flex: unset;
    }
  }

  &.is-app {
    .space-wrapper {
      margin-right: 100px;
    }

    .home-menu-wrapper {
      flex: 1;

      .el-menu {
        justify-content: flex-start;
      }
    }
  }

  .menu-wrapper {
    display: flex;
    align-items: center;

    .el-menu.el-menu--horizontal {
      > .el-menu-item.is-active {
        border-bottom: 2px solid transparent;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          width: 33.33%;
          border-bottom: 2px solid var(--el-menu-active-color);
        }
      }
    }
  }

  .menu-hamburger {
    --nav-height: 100%;

    --text-color: var(--el-text-color-primary);

    width: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    height: var(--nav-height);

    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    display: none;

    & > span {
      background-color: var(--text-color);
      border-radius: 10px;
      height: 2px;
      margin: 2px 0;
      transition: var(--el-transition-all);
      width: 100%;

      &.hamburger-1 {
        width: 50%;
      }

      &.hamburger-3 {
        width: 75%;
      }
    }
  }

  .is-opened .menu-hamburger,
  .menu-hamburger.active {
    .hamburger-1 {
      transform-origin: bottom;
      transform: rotate(45deg) translate(13px);
    }

    .hamburger-2 {
      transform-origin: top;
      transform: rotate(-45deg);
    }

    .hamburger-3 {
      transform-origin: bottom;
      width: 50%;
      transform: translate(2px, -10px) rotate(45deg);
    }
  }

  .info-wrapper {
    display: flex;
    align-items: center;

    &.mobile {
      display: none;
    }

    .el-button {
      padding: 12px 24px;
      height: 48px;
      //border: 1px solid var(--my-color-x5);
      border-radius: 30px;
      font-size: 16px;
      color: var(--my-color-black-89-90);
    }
  }

  &.pricing {
    .info-wrapper {
      .el-button {
        border-color: transparent;
      }
    }
  }

  .user-wrapper {
    .user-icon {
      margin: 0 10px;

      .el-avatar {
        vertical-align: middle;

        :deep(.el-icon) {
          margin-right: 0;
        }
      }
    }

    .el-menu.el-menu--horizontal {
      display: contents;

      > .el-sub-menu {
        --el-menu-hover-text-color: var(--el-menu-text-color);
        --el-menu-hover-bg-color: var(--my-color-y2);

        .el-menu-item {
          border-radius: 3px;

          &.is-active {
            --el-menu-active-color: var(--el-menu-text-color); // 保持和普通情况下一样的颜色
            background-color: var(--el-menu-hover-bg-color); // 保持hover状态下一样的背景色

            &:hover {
              background-color: var(--my-color-y1); // is-active状态下hover，背景色比is-active更深
            }
          }
        }

        .user-info-wrapper {
          --el-menu-horizontal-sub-item-height: 1.5;
          padding: 6px 10px;
          margin-top: 0;
          gap: 8px;

          .el-avatar {
            display: none;

            :deep(.el-icon) {
              margin-right: 0;
            }
          }

          .user-info {
            display: inline-block;

            small {
              color: var(--my-color-black-40);
            }
          }
        }

        :deep(.el-menu--popup) {
          padding: 12px;
        }

        .el-divider.el-divider--horizontal {
          --my-margin-lr: 8px;
          width: calc(100% - var(--my-margin-lr) * 2);
          margin: -0.5px var(--my-margin-lr);
          height: 0.5px;
          border-top-width: 0.5px;
        }

        :deep(.el-sub-menu__title) {
          padding: 0;

          .el-sub-menu__icon-arrow {
            display: none;
          }
        }

        .home-menu-shadow {
          display: none;

          + .el-divider {
            display: none;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .el-menu.el-menu--horizontal {
    /* Fallback (display: inline-flex; flex-grow: 1) for (display: -webkit-box) */
    display: inline-flex;
    flex-grow: 1;
    /* Fallback (display: contents) for (display: -webkit-box) */
    display: contents;
    display: -webkit-box;
    /* For .el-header (border-bottom: solid 1px ...) */
    border-bottom: none;

    height: calc(var(--el-menu-horizontal-height) - var(--my-border-bottom-width));
    overflow-y: hidden;

    :deep(.el-sub-menu) {
      /* For .el-sub-menu__hide-arrow not middle */
      height: 100%;

      &.el-sub-menu__hide-arrow {
        width: 70px;

        .el-icon.el-sub-menu__icon-more {
          width: 30px;
          font-size: 30px;
        }
      }
    }
  }
}

.el-header.app-header,
.el-main.app-main {
  &.pricing {
    &::before,
    &::after {
      content: '';

      min-width: 1120px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1000px;
      z-index: -1;
      background: linear-gradient(143.06deg, #ff763c2e 8.62%, #ff3c822e 33.85%, #7b4dff2e 66.63%);
    }

    &::after {
      background: linear-gradient(0deg, #fff, #fff0);
    }
  }
}

.el-menu.el-menu--horizontal {
  :deep(.el-menu--popup) {
    min-width: unset;
  }
}

small.flag {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  color: var(--my-color-black-20);
  border: 1px solid var(--my-color-black-6);
  border-radius: 30px 30px 30px 0;
  padding: 4px 5px;
  margin-left: 5px;
}

.el-main.app-main {
  min-height: calc(100vh - var(--el-header-height) - var(--el-footer-height));
  margin-top: var(--el-header-height);

  &.app-main-full {
    padding: 0;

    .app-aside {
      + .app-main-view {
        padding: var(--el-main-padding);
      }
    }
  }

  &.app-main-flex {
    display: flex;

    .app-aside {
      + .app-main-view {
        flex: 1;
      }
    }
  }

  .app-aside {
    .aside-fixed {
      border-right: 1px solid var(--el-menu-border-color);
    }
  }
}

.el-footer {
  .site-info {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .copyright {
      display: flex;
      justify-content: center;
      flex: 1;
      gap: 24px;
      white-space: nowrap;
    }

    .el-link,
    .el-text {
      font-size: 12px;
      color: var(--el-text-color-secondary);
      font-weight: normal;
    }

    .el-link {
      &:hover {
        color: var(--el-color-primary);
      }
    }
  }
}

.el-header.app-header {
  --el-header-padding: 0 16px 0 32px;
}

:deep(.plan-info-wrapper).is-in-menu,
.with-aside.app-container-container .app-header .menu-wrapper {
  display: none;
}

@media screen and (max-width: 768px) {
  :deep(.plan-info-wrapper).is-in-menu {
    display: block;
  }

  .with-aside.app-container-container .app-header .menu-wrapper {
    display: flex;
  }

  .el-aside {
    display: none;

    &.aside-signin {
      + .app-container-container {
        .app-header {
          border-bottom-color: var(--el-menu-border-color);

          .logo-wrapper {
            display: flex;
          }
        }
      }
    }
  }

  .el-header.app-header {
    --el-header-padding: 0 16px;

    .home-menu-wrapper {
      display: none;

      &.active {
        display: block;

        position: absolute;
        left: 0;
        top: var(--el-header-height);
        width: 100%;
        height: calc(100vh - var(--el-header-height));
        background: var(--my-color-white-100);

        overflow-y: auto;
      }
    }

    .app-header-wrapper {
      > .menu-hamburger {
        display: flex;
      }
    }

    &.is-app {
      .space-wrapper {
        margin-right: 0;
      }

      .app-header-wrapper {
        > .menu-hamburger {
          display: none;
        }
      }

      .user-wrapper {
        .el-menu.el-menu--horizontal {
          > .el-sub-menu {
            :deep(.el-tooltip__trigger) {
              border-bottom: 0;

              .user-icon {
                display: none;
              }

              .menu-hamburger {
                display: flex;
              }
            }

            .home-menu-shadow {
              display: block;

              + .el-divider {
                display: block;
              }
            }

            .user-info-wrapper {
              padding: 6px 10px;

              .el-avatar {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    &.pricing {
      .home-menu-wrapper {
        &.active {
          background: transparent;

          &::after {
            content: '';

            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: var(--my-color-white-100);

            z-index: -2;
          }
        }
      }
    }

    .menu-wrapper {
      flex: 1;

      .el-menu.el-menu--horizontal {
        // display: flex 和 display: -webkit-box 在最终效果上不理想
        // 如果超过2个菜单展示不下了，不使用 display: flex 就会换行被隐藏，使用 display: flex 就会造成最右侧头像被挤出视口
        // display: contents 和 display: -webkit-box 相对理想，但是超过2个菜单会造成只有三个点（更多）显示，其他一个都不显示
        display: contents;

        .el-menu-item {
          --el-menu-item-height: 1.5;
          text-align: center;
          white-space: unset; // display: flex / display: contents 下才有效
        }
      }
    }

    .logo-wrapper {
      .logo {
        display: flex;
        justify-content: flex-start;

        svg {
          width: 73px;
          height: 24px;
        }
      }
    }

    .info-wrapper {
      display: none;

      &.mobile {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        margin-right: 12px;
      }

      .el-button {
        padding: 8px 15px;
        height: 32px;
        font-size: 14px;
      }
    }
  }

  .el-header.app-header,
  .el-main.app-main {
    &.pricing {
      &::before,
      &::after {
        min-width: unset;
      }
    }
  }
}

/* Only Safari 10.1+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .el-header.app-header {
      .menu-wrapper {
        .el-menu.el-menu--horizontal {
          // display: flex 和 display: -webkit-box 在最终效果上不理想
          // 如果超过2个菜单展示不下了，不使用 display: flex 就会换行被隐藏，使用 display: flex 就会造成最右侧头像被挤出视口
          // display: contents 和 display: -webkit-box 相对理想，但是超过2个菜单会造成只有三个点（更多）显示，其他一个都不显示
          display: flex;
        }
      }
    }
  }
}
</style>
