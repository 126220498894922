<script setup lang="ts">
import {
  ref,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  inject,
  watch,
  watchEffect,
  nextTick
} from 'vue'

import { useI18n } from 'vue-i18n'

import type { fabric } from 'fabric'

import { ElMessage, type ElMain } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import { VideoPause as IconPause, VideoPlay as IconPlay } from '@element-plus/icons-vue'

import IconVideoPlay from '@/components/icons/IconVideoPlay.vue'

import AnimationPlayer from '@/components/videoEditor/AnimationPlayer.vue'

import {
  type Material,
  type TranslateScript,
  type VoiceTemplate,
  type VideoTemplate,
  type Subtitle,
  type TTSResult,
  subtitleSplit,
  genTTS
} from '@/api'

import '@/videoEditor/fabric-utils'
import {
  refAnimationPlayer,
  refPlayerWrapper,
  refPlayerToolbar,
  stageConfig,
  onMountedExec,
  onUnmountedExec,
  onBeforeUnmountActions,
  addVideo,
  addAudio,
  addText,
  changeStageSize,
  loadFont,
  loadingFont,
  initElements,
  doAnimationSeek,
  previewAnimationSeek
} from '@/videoEditor/main'
import type { CoreData, VideoEditorElement } from '@/videoEditor/types'
import Store from '@/videoEditor/store'
import { formatTimeToMinSecMili } from '@/videoEditor/utils'

const { t } = useI18n()

/** 依赖注入 start */
// 核心数据
const coreData: CoreData | undefined = inject(Store.coreData)
/** 依赖注入 end */

onMounted(() => {
  onMountedExec(coreData)
})
onBeforeUnmount(onBeforeUnmountActions)
onUnmounted(onUnmountedExec)

const props = defineProps<{
  material: Material
  translateScripts: TranslateScript[]
  voiceTemplate: VoiceTemplate
  targetLanguage: string
  videoTemplate?: VideoTemplate
  splitByGrapheme?: boolean
}>()

const loadMediaMetadataProgress = ref(1)
const loadingVideo = ref(false)
const loadingTTS = ref(false)
const loadingTTSError = ref('')
const loadingSubtitle = ref(false)
const loadingSubtitleError = ref('')
const subtitles = ref<Subtitle[]>([])
const videoDuration = ref(0)

stageConfig.value = {
  width: 800,
  height: 450,
  scale: 1
}

onMounted(() => {
  if (!coreData) {
    return
  }

  coreData.tracks = [
    {
      type: 'captions',
      trackId: 'captions-1',
      elements: []
    },
    {
      type: 'audios',
      trackId: 'audios-1',
      elements: [],
      isMuted: false
    },
    {
      type: 'videos',
      trackId: 'videos-1',
      elements: [],
      isMuted: true,
      isPrimary: true
    }
  ]

  const [width, height] = props.material.resolution.split('x').map((str) => Number(str))

  nextTick(() => {
    changeStageSize({ width, height })
  })

  if (props.videoTemplate) {
    loadFont(props.videoTemplate.config.subtitle.normal.font_name).finally(() => {
      loadPercent(10)
    })

    loadingSubtitle.value = true
    subtitleSplit({
      language: props.targetLanguage,
      resolution: {
        width,
        height
      },
      scripts: props.translateScripts.map((script) => {
        return {
          text: script.text,
          start: script.start_time,
          end: script.end_time
        }
      })
    })
      .then((res) => {
        if (res.data.code) {
          loadingSubtitleError.value =
            t('字幕生成异常') + (res.data.message ? ' ' + res.data.message : '')
          ElMessage.error({
            message: loadingSubtitleError.value,
            duration: 5 * 1000
          })
          return
        }

        subtitles.value = res.data.data
        doAddText(coreData, width, height)
      })
      .catch((err) => {
        if (err.code === 'ERR_CANCELED' || err.status === 401) {
          return
        }
        loadingSubtitleError.value = t('字幕生成失败') + (err.message ? ' ' + err.message : '')
        ElMessage.error({
          message: loadingSubtitleError.value,
          duration: 5 * 1000
        })
      })
      .finally(() => {
        loadPercent(10)
        loadingSubtitle.value = false
      })
  } else {
    loadPercent(20)
  }

  loadingVideo.value = true
  addVideo(
    {
      name: props.material.name,
      width,
      height,
      selectable: false,
      clipStart: 0,
      clipEnd: -1,
      src: getMediaSrc(props.material)
    },
    'videos-1',
    coreData.tracks[2].elements,
    (element: VideoEditorElement, dom: HTMLVideoElement) => {
      loadingVideo.value = false
      loadPercent(30)

      const { duration } = dom
      videoDuration.value = duration

      if (props.videoTemplate) {
        doAddText(coreData, width, height)
      }

      if (!props.voiceTemplate?.voiceId) {
        loadPercent()
        return
      }
      doGetTTS(coreData)
    }
  )
})

const loadPercent = (percentage: number = 50) => {
  loadMediaMetadataProgress.value += percentage
}

watch(
  () => loadMediaMetadataProgress.value,
  (val) => {
    if (val >= 100) {
      initElements(false)
    }
  }
)

const getMediaSrc = (material: Material) => {
  const { url, previewVideo, address } = material
  const filename = (previewVideo ? previewVideo : address).split('/').slice(-1).join('')
  return `${url.split('/').slice(0, -1).join('/')}/${filename}`
}

const doAddText = (coreData: CoreData, width: number, height: number) => {
  const duration = videoDuration.value
  if (!duration || !subtitles.value.length || !props.videoTemplate) {
    return
  }

  const { subtitle } = props.videoTemplate.config
  const { padding, normal, background } = subtitle

  const minSize = Math.min(width, height)

  const left = 80 // HARDCODE
  const top = height - minSize * padding.bottom
  const w = width - left * 2
  const fontSize = minSize * normal.font_size_ratio
  const strokeWidth = normal.outline_width ? 2 * normal.outline_width * fontSize : -1
  const shadowOffset = normal.shadow_offset ? normal.shadow_offset * fontSize : undefined
  const shadow = normal.shadow_color
    ? ({
        color: normal.shadow_color,
        offsetX: shadowOffset,
        offsetY: shadowOffset
      } as fabric.Shadow)
    : undefined
  const lineHeight = 1 + normal.line_spacing_percent

  subtitles.value.some((script, index) => {
    if (script.start > duration) {
      return true
    }
    let end = script.end
    if (end > duration) {
      end = duration
    }
    addText(
      {
        subType: 'caption',
        name: props.translateScripts[index]?.source_text || script.text,
        left,
        top,
        width: w,
        height: -1,
        originY: 'bottom',
        selectable: false,
        text: script.text,
        editable: false,
        fontSize,
        fontFamily: normal.font_name,
        fill: normal.font_color,
        stroke: normal.outline_color,
        strokeWidth,
        textBackgroundColor: background?.color || undefined,
        shadow,
        lineHeight,
        splitByGrapheme: props.splitByGrapheme || false,
        start: script.start * 1000,
        end: end * 1000,
        clipStart: 0,
        clipEnd: (script.end - script.start) * 1000
      },
      'captions-1',
      coreData.tracks[0].elements,
      () => {}
    )
    return false
  })
}

const doGetTTS = (coreData: CoreData) => {
  const duration = videoDuration.value
  if (!duration) {
    return
  }
  getTTS(duration)
    .then((data) => {
      if (!data?.audio) {
        loadPercent()
        return
      }
      addAudio(
        {
          name: 'TTS',
          start: 0,
          end: duration * 1000,
          clipStart: 0,
          clipEnd: duration * 1000,
          src: data.audio
        },
        'audios-1',
        coreData.tracks[1].elements,
        () => {
          loadPercent()
        }
      )
    })
    .catch(() => {
      loadPercent()
    })
}
const getTTS = (duration: number): Promise<TTSResult | void> => {
  const scripts: Subtitle[] = []
  props.translateScripts.some((script) => {
    if (script.start_time > duration) {
      return true
    }
    scripts.push({
      text: script.text,
      start: script.start_time,
      end: script.end_time
    })
    return false
  })
  if (!scripts.length) {
    return Promise.resolve()
  }
  return new Promise((resolve, reject) => {
    loadingTTS.value = true
    genTTS({
      language: props.targetLanguage,
      voice: {
        provider: props.voiceTemplate.provider,
        voice_id: props.voiceTemplate.voiceId,
        speed: 1,
        tags: props.voiceTemplate.tags
      },
      video: props.material.address,
      scripts
    })
      .then((res) => {
        if (res.data.code) {
          loadingTTSError.value =
            t('AI声音生成异常') + (res.data.message ? ' ' + res.data.message : '')
          ElMessage.error({
            message: loadingTTSError.value,
            duration: 5 * 1000
          })
          resolve()
          return
        }

        resolve(res.data.data)
      })
      .catch((err) => {
        if (err.code === 'ERR_CANCELED' || err.status === 401) {
          resolve()
          return
        }
        loadingTTSError.value = t('AI声音生成失败') + (err.message ? ' ' + err.message : '')
        ElMessage.error({
          message: loadingTTSError.value,
          duration: 5 * 1000
        })
        reject(err)
      })
      .finally(() => {
        loadingTTS.value = false
      })
  })
}

const playbackRates = [
  {
    value: 0.1,
    label: '0.1x'
  },
  {
    value: 0.5,
    label: '0.5x'
  },
  {
    value: 1,
    label: '1x'
  },
  {
    value: 1.5,
    label: '1.5x'
  },
  {
    value: 2,
    label: '2x'
  },
  {
    value: 3,
    label: '3x'
  }
]

const animationCurrentTimeInMs = ref(0)
const setAnimationCurrentTimeInMs = () => {
  animationCurrentTimeInMs.value = refAnimationPlayer.value?.animationCurrentTimeInMs || 0
}
setAnimationCurrentTimeInMs()
watchEffect(setAnimationCurrentTimeInMs)
</script>

<template>
  <el-main class="player-wrapper" ref="refPlayerWrapper">
    <div class="player-viewport">
      <div
        class="player-box"
        v-loading="!refAnimationPlayer?.animationDurationInMs || refAnimationPlayer?.isWaiting"
      >
        <AnimationPlayer
          :stageConfig="stageConfig"
          backgroundColor="black"
          ref="refAnimationPlayer"
        />
      </div>
    </div>
    <div class="player-toolbar" ref="refPlayerToolbar">
      <div class="player-progress">
        <el-progress
          :style="{
            width: `${stageConfig.width}px`
          }"
          :percentage="loadMediaMetadataProgress"
          indeterminate
          :format="
            (percentage: number) => (percentage == 100 ? '渲染...' : `${Math.round(percentage)}%`)
          "
          v-if="!refAnimationPlayer?.animationDurationInMs"
        />
        <el-slider
          :style="{
            width: `${stageConfig.width}px`
          }"
          v-model="animationCurrentTimeInMs"
          :disabled="!refAnimationPlayer?.animationDurationInMs"
          :format-tooltip="(timeInMs: number) => formatTimeToMinSecMili(timeInMs)"
          :step="0.1"
          :max="refAnimationPlayer?.animationDurationInMs"
          @change="doAnimationSeek"
          @input="previewAnimationSeek"
          v-else
        />
      </div>
      <div class="player-controls">
        <div class="player-play">
          <el-icon
            :disabled="!refAnimationPlayer?.animationDurationInMs || refAnimationPlayer?.isWaiting"
            @click="
              refAnimationPlayer?.setAnimationPlayOrPause(!refAnimationPlayer?.isAnimationPlaying)
            "
          >
            <IconPause
              v-if="
                refAnimationPlayer?.isAnimationPlaying ||
                (refAnimationPlayer?.animationCurrentTimeInMs && refAnimationPlayer?.isWaiting)
              "
            />
            <IconPlay v-else />
          </el-icon>
          <el-text>{{
            `${formatTimeToMinSecMili(
              refAnimationPlayer?.animationCurrentTimeInMs || 0
            )} / ${formatTimeToMinSecMili(refAnimationPlayer?.animationDurationInMs || 0)}`
          }}</el-text>
        </div>
        <el-select
          class="playback-rates"
          size="small"
          v-if="refAnimationPlayer"
          :model-value="refAnimationPlayer.animationPlaybackRate"
          @change="refAnimationPlayer.setPlaybackRate"
          value-key="value"
        >
          <el-option
            v-for="item in playbackRates"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>

    <el-icon
      class="play-icon"
      :disabled="!refAnimationPlayer?.animationDurationInMs || refAnimationPlayer?.isWaiting"
      @click="refAnimationPlayer?.setAnimationPlayOrPause(!refAnimationPlayer?.isAnimationPlaying)"
      v-if="
        !(
          refAnimationPlayer?.isAnimationPlaying ||
          (refAnimationPlayer?.animationCurrentTimeInMs && refAnimationPlayer?.isWaiting)
        )
      "
    >
      <IconVideoPlay />
    </el-icon>

    <div
      class="loading-info"
      v-if="
        loadingVideo ||
        loadingTTS ||
        loadingTTSError ||
        loadingSubtitle ||
        loadingSubtitleError ||
        loadingFont
      "
    >
      <div v-if="loadingVideo">{{ t('视频加载中') }}...</div>
      <div v-if="loadingTTS">{{ t('AI声音生成中') }}...</div>
      <div v-if="loadingTTSError">{{ loadingTTSError }}</div>
      <div v-if="loadingSubtitle">{{ t('字幕生成中') }}...</div>
      <div v-if="loadingSubtitleError">{{ loadingSubtitleError }}</div>
      <div v-if="loadingFont">{{ t('字体加载中') }}...</div>
    </div>
  </el-main>
</template>

<style scoped lang="less">
.player-wrapper {
  --player-toolbar-height: 44px;
}

.player-wrapper {
  position: relative;

  .play-icon.el-icon {
    position: absolute;
    --icon-size: 50px;
    top: calc((100% - var(--player-toolbar-height) - var(--icon-size)) / 2);
    left: calc((100% - var(--icon-size)) / 2);
    width: var(--icon-size);
    height: var(--icon-size);
    border-radius: var(--icon-size);
    background: var(--my-color-white-60);
    font-size: 40px;

    &:hover {
      cursor: pointer;

      svg {
        color: var(--el-color-primary);
      }
    }
  }

  .loading-info {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--my-color-black-60);
    border-radius: 10px;
    font-size: 12px;
    color: var(--my-color-white-100);
    z-index: 2001;

    > div {
      padding: 2px 8px;
    }
  }

  .player-box {
    pointer-events: none;
  }
}

.player-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden;

  .player-viewport {
    display: flex;
    justify-content: center;
  }

  .player-toolbar {
    height: var(--player-toolbar-height);

    .player-progress {
      display: flex;
      justify-content: center;

      .el-progress {
        height: 20px;
      }

      .el-slider {
        height: 20px;
        overflow: hidden;

        :deep(.el-slider__runway) {
          border-radius: 0;

          .el-slider__button-wrapper {
            width: 20px;
            height: 20px;
            top: -7px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .el-slider__bar {
            background-color: var(--el-text-color-secondary);
            border-radius: 0;
          }

          .el-slider__button {
            width: 2px;
            height: 6px;
            border: none;
            border-radius: 0;
          }
        }
      }
    }

    .player-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .player-play {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .el-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        cursor: pointer;

        &:hover {
          color: var(--el-color-primary);
        }
      }

      .el-text {
        font-family:
          Roboto,
          Open Sans,
          Source Sans Pro,
          Noto Sans,
          Bebas Neue,
          Helvetica Neue,
          Microsoft YaHei,
          '微软雅黑',
          DIN,
          Helvetica,
          Inter,
          SF Pro display;
        font-feature-settings: 'tnum';
        font-variant-numeric: tabular-nums;
      }

      .el-select.playback-rates {
        width: 42px;
        height: 18px;

        :deep(.el-select__wrapper) {
          text-align: right;
          padding: 0;
          min-height: 18px;
          background: transparent;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
