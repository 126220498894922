<script setup lang="ts">
import SuccessIcon from '@/assets/redeem/success@2x.png'
import i18n from '@/locales/i18n'

const t = i18n.global.t
</script>

<template>
  <div class="success-wrapper result-wrapper">
    <img :src="SuccessIcon" alt="" class="success-icon" />
    <p class="title">{{ t('恭喜您!') }}</p>
    <p class="description">
      {{ t('你已成功提交问卷，获得了5个积分，并解锁了付费功能作为奖励。尽情享受吧！') }}
    </p>
    <el-button @click="$emit('handle-start')" type="primary" class="btn">
      {{ t('立即使用') }}
    </el-button>
  </div>
</template>

<style scoped lang="less">
.result-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    margin: 0;
    color: var(--my-color-black-89-90);
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .description {
  }

  .btn {
    height: 40px;
    font-size: 16px;
    padding: 8px 31px;
    margin: 100px 0;
  }
}
.success-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .success-icon {
    margin: 100px 0 32px;
    width: 200px;
  }

  .description {
    margin: 0;
    line-height: 24px;
    background-image: linear-gradient(91.14deg, #ff8d3a 3.08%, #ff5fbf 26.39%, #7b4dff 64.83%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 500;
    min-width: 240px; // fit mobile
    max-width: 430px;
  }
}
</style>
