export default {
  'Try for free': '免费体验',
  'Natively Supports Multi-platforms': '原生支持多平台',
  'How VMEG Works': 'VMEG是如何工作的',
  'Transform your inventory and idea into vivid, dynamic and diverse short videos instantly for your brand, product, etc.':
    '将您的库存和创意瞬间转化为您的品牌、产品等生动、动态、多样的短视频。',
  Input: '输入',
  'Video/image/Text/URL': '视频/图片/文本/链接',
  'AI crafting': '人工智能制作',
  'Understanding, generation and x-effects': '理解、生成和 X 效应',
  Output: '输出',
  'Instant, vivid, diverse and editable videos': '即时、生动、多样、可编辑的视频',
  'Clips to Videos': '切片到视频',
  'Instantly mix your scattered product clips to elegant marketing videos':
    '立即将分散的产品剪辑混合到精美的营销视频中',
  'Upload your raw product clips': '上传您的原始产品剪辑',
  'Fill in the product name and unique selling points': '填写产品名称和独特卖点',
  'One click': '一键',
  'Platform compatible, diverse and audio-vision matched videos will be available instantly':
    '平台兼容、多样化、音视频匹配的视频将立即可用',
  'Comming Soon': '敬请期待',
  'Long Video to Shorts': '长视频转短视频',
  'Automatically spot the highlights of your long live video and revitalize it with shorts':
    '自动发现长直播视频的亮点并通过短片使其焕发活力',
  'Upload your local file or Enter the video link': '上传本地文件或输入视频链接',
  'Massive short but topic-focused videos will be generated for easier sharing and spreading':
    '将生成海量短视频，聚焦主题，更方便分享和传播',
  'Video Translation': '视频翻译',
  'Break Down Language Barriers with Video Translation: Connect, Engage, and Expand Globally':
    '通过视频翻译打破语言障碍：在全球范围内进行联系、参与和扩展',
  'Upload your original video': '上传您的原始视频',
  'Choose the language you want to translate into': '选择您想要翻译成的语言',
  'Preview or edit the translated text of the video': '预览或编辑视频的翻译文本',
  'Select a suitable AI voice or clone voice': '选择合适的AI语音或克隆语音',
  'on Generate, and wait just a few minutes for the video translation to be completed':
    '点击生成按钮，只需等待几分钟即可完成视频翻译',
  'Product URL to Videos': '产品URL到视频',
  'Instantly turn your product webpage into dynamic marketing videos effortlessly':
    '立即轻松地将您的产品网页变成动态营销视频',
  'Enter the link to the product page': '输入产品页面的链接',
  'Confirm or edit the product details extracted by AI': '确认或编辑AI提取的产品详细信息',
  'Vibrant advertisement videos will be generated specifically for your product':
    '将为您的产品专门生成充满活力的广告视频',
  'Images to Videos': '图片转视频',
  'Revitalize your photos in the era of short video popularity': '短视频流行时代让你的照片焕发活力',
  'Upload your product images': '上传您的产品图片',
  'Fill in the product name and selling points': '填写产品名称和卖点',
  'Unlimited original marketing videos will be delivered': '无限量原创营销视频投放',
  'Product Name': '产品名称',
  spacious: '宽敞的',
  versatile: 'Versatile',
  stylish: 'Stylish',
  comfortable: '舒服的',
  'Product Features': '产品特点',
  'Automatic Video Subtitle': '自动视频字幕',
  'Product Values': '产品价值',
  'Automatically generate subtitles for your videos, making them more accessible and understandable.':
    '自动为您的视频生成字幕，使它们更易于理解。',
  'Eye-catching Titles': '引人注目的标题',
  'Generate captivating titles with One click, ensuring your content grabs attention effortlessly.':
    '一键生成引人入胜的标题，确保您的内容轻松吸引注意力。',
  'Align Audio&Vision Precisely': '精确对齐音频和视觉',
  'Videos are generated using the correlations of clips and subtitles by the cutting-edge multimodal LLM.':
    '通过尖端的多模态大语言模型，利用剪辑和字幕的相关性生成视频。',
  'One-click Post': '一键发帖',
  'With a simple click, distribute your ad videos to various video platforms, effortlessly expanding your reach.':
    '只需单击一下，即可将您的广告视频分发到各种视频平台，轻松扩大您的覆盖范围。',
  'Post on Schedule': '按计划发布',
  'Set ad video delivery schedules to ensure timely reach out to target audience.':
    '设置广告视频投放时间表，确保及时覆盖目标受众。',
  'Multi-platform Compatible': '多平台兼容',
  'Intelligently adjust ad video sizes for perfect presentation on platforms like TikTok, YouTube, and more.':
    '智能调整广告视频尺寸，在 TikTok、YouTube 等平台上完美呈现。',
  'Top-notch Video Templates': '一流的视频模板',
  'Access a wide range of video templates to create professional-level video content quickly.':
    '访问各种视频模板，快速创建专业级视频内容。',
  'Video Editing Like MS Word': '像MS Word一样进行视频编辑',
  'Access a simple and user-friendly video editing tool that allows you to edit videos quickly.':
    '使用简单且用户友好的视频编辑工具，可让您快速编辑视频。',
  'Efficiency improvement': '效率提升',
  'Compare the quantity of short videos produced by VMEG within a timeframe to those created purely through manual labor.':
    '将VMEG在一段时间内制作的短视频数量与纯手工制作的短视频数量进行比较。',
  'Labor cost reduction': '降低劳动力成本',
  'When considering the production of advertising videos of comparable quality, evaluate the cost difference between creating a single short video using the VMEG tool versus employing solely manual methods.':
    '在考虑制作具有相当质量的广告视频时，评估使用 VMEG 工具创建单个短视频与仅采用手动方法之间的成本差异。',
  'Effectiveness increase': '效能提升',
  'Compare the usage and impact of short video content created by VMEG across various advertising platforms to that of manually produced content within a specific timeframe.':
    '将 VMEG 在各个广告平台上创建的短视频内容与特定时间范围内手动制作的内容的使用情况和影响进行比较。',
  '{value}x': '{value}倍',
  'User Comments': '用户评论',

  'We Chat': '微信群',
  'Need Help?': '需要帮助？',

  'Loading error': '加载异常',
  'Loading failed': '加载失败',
  'Payment status query failed': '支付状态查询失败',
  'Scan code to pay': '扫码支付',
  'Please use WeChat to scan the QR code to pay': '请使用微信扫码支付',
  'Scan code payment error': '扫码支付异常',
  'WeChat Pay': '微信支付',
  'Beta Pricing': '试运营定价',
  'Pricing Plans': '定价计划',
  'Discover the perfect fit for your needs - our pricing plans have you covered.':
    '发现最适合您需求的产品 - 我们的定价计划可以满足您的需求。',
  Monthly: '每月',
  '{count} Months': '{count}月',
  Annually: '每年',
  'Save {amountSaved}%': '节省{amountSaved}%',
  'Expiring monthly': '每月过期',
  Free: '免费',
  Standard: '标准',
  Enterprise: '企业',
  "Let's talk": '议价',
  credit: '积分',
  credits: '积分',
  mo: '月',
  month: '月',
  year: '年',
  'Limited-time offer': '限时特惠', // 后台下发数据
  'Clips to Videos about {count} videos': '切片到视频大约{count}个视频',
  'Video Translation about {minute} minutes': '视频翻译大约{minute}分钟',
  'Requiring higher limit or custom features?': '需要更高的上限或自定义功能？',
  "Let's talk!": '来和我们谈谈！',
  'Get started': '开始吧',
  'Plan Detail': '计划详情',
  'No Watermark': '无水印',
  'Credit/Month': '积分/月',
  Speed: '速度',
  'Cloud Storage': '云储存',
  'Video Ratio': '视频比例',
  'Automatic Selling Point Generation': '自动卖点生成',
  'Video Resolution': '视频分辨率',
  Customizable: '可定制',
  'High Speed': '高速',
  'All Features': '所有功能',
  'Customized Features': '定制功能',
  'Customized Credits': '定制积分',
  'Priority Customer Support': '优先客户支持',
  Fundamental: '基本',
  'Video Duration Selection': '视频时长选择',
  'Max Video Duration Per Video': '每个视频的最大时长',
  'Translation Proofread': '翻译校对',
  'With Lip Sync': '唇形同步',
  'AI Voice': 'AI声音',
  'Voice Clone': '声音克隆',
  'Frequently Asked Questions': '常见问题',

  time: {
    symbol: {
      second: '秒'
    }
  },
  pricing: {
    faqs: [
      {
        question: 'VMEG可以免费试用吗？',
        answer:
          '当然，VMEG为用户提供免费计划来测试我们的产品。但是，要解锁更高的信用额度和高级功能，您需要升级到付费订阅。'
      },
      {
        question: '未使用的积分是否可以延续到下一个计费周期？',
        answer: '不幸的是，任何未使用的积分都不会结转到下一个计费周期。'
      },
      {
        question: '按月订阅与按年订阅有何不同？',
        answer:
          '包月订阅每月自动续订，而包年订阅则每年续订一次（从购买之日起）。与按月付款相比，选择按年订阅在12个月内更具成本效益。'
      },
      {
        question: '计划之间切换的流程是什么？',
        answer:
          '您可以在帐户界面中轻松切换计划。一旦您进行切换，新计划立即生效。任何以前获得的积分将仍然有效，并根据您原来的计费周期到期。'
      },
      {
        question: '我可以随时取消订阅吗？',
        answer:
          '当然，您可以随时取消订阅。取消后，您现有的积分将在当前计费周期结束之前可用。除非您重新激活，否则订阅不会续订。'
      },
      {
        question: '视频翻译服务如何定价？',
        answer: '视频翻译服务以1分钟为增量定价，因此即使您的视频少于1分钟，您仍需按整分钟付费。'
      }
    ]
  }
}
