export const getAudioInfo = (
  url: string
): Promise<{
  duration: number
}> => {
  return new Promise((resolve, reject) => {
    let audio: HTMLAudioElement | null = document.createElement('audio')
    audio.preload = 'metadata'
    audio.onloadedmetadata = () => {
      resolve({
        duration: audio?.duration! || 0
      })
      audio = null
    }
    audio.onerror = (event: string | Event) => {
      reject(event)
      audio = null
    }
    audio.src = url
  })
}
