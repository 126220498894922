import { ElMessageBox } from 'element-plus'
import { h, type VNode } from 'vue'
import i18n from '@/locales/i18n'
import type { ElMessageBoxOptions } from 'element-plus/es/components/message-box/src/message-box.type'

const t = i18n.global.t

const wrapCommonContainer = (content: string | VNode) => {
  return h('div', { class: 'vmeg-primary-confirm-box' }, content)
}

// 提取了一下常用的字段，方便复用，不是必须的，根据需求来
const baseProps: ElMessageBoxOptions = {
  showClose: true,
  confirmButtonText: t('好的')
}

const commonAlert = async (options: ElMessageBoxOptions) => {
  const { message, title, ...rest } = options
  await ElMessageBox.alert(message, title, rest)
}

const languageKey2I18nKeyMap: { [key: string]: string } = {
  es: '西班牙语',
  en: '英语',
  hi: '印地语',
  ja: '日语',
  ru: '俄语',
  uk: '乌克兰语',
  sv: '瑞典语',
  zh: '中文',
  pt: '葡萄牙语',
  nl: '荷兰语',
  tr: '土耳其语',
  fr: '法语',
  de: '德语',
  id: '印度尼西亚语',
  ko: '韩语',
  it: '意大利语'
}

export const alertLanguageUnSupport = async (originLanguageKey: string, onClick: () => void) => {
  await commonAlert({
    ...baseProps,
    message: wrapCommonContainer(
      h('p', null, [
        h(
          'span',
          null,
          t('抱歉，原始视频语言为{originLanguage}，目前不支持该语言。请更换视频并重新上传。', {
            originLanguage: t(languageKey2I18nKeyMap[originLanguageKey] || originLanguageKey)
          })
        ),
        h(
          'span',
          {
            onClick,
            class: 'click-here'
          },
          t('点击此处')
        ),
        h('span', null, t('查看支持的语言列表。'))
      ])
    )
  })
}

export const alertOriginAndTargetLanguageSame = async () => {
  await commonAlert({
    ...baseProps,
    message: wrapCommonContainer(t('视频的原始语言与目标翻译语言相同。请编辑并重新提交。'))
  })
}

export const alertCantDetectLanguage = async () => {
  await commonAlert({
    ...baseProps,
    message: wrapCommonContainer(t('抱歉，我们无法确认原始视频的语言。请手动选择原始视频的语言。'))
  })
}
