import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import Service from './axios'

import type { SignedInfo } from '@/stores/user'
import type {
  SubscriptionPlanAndCreditDetail,
  CreditDetailMemoGenerate,
  CreditDetailMemoVideoTranslation,
  CreditDetailMemoTranslateScript,
  CreditDetailMemoTranslateText,
  CreditDetailMemoDownload,
  CreditDetailMemoSubscription
} from '@/stores/proj'

export interface BaseResponse<T> {
  code: number // 0 | ...
  errorCode: string
  errorMsg: string // 不用于前端展示，主要用于返回后台异常log信息
  message: string // 不管正常还是异常，只要非空就需要前端展示，大部分情况下是发生异常时出现
  data: T
}

export interface PageResponse<T> {
  records: T[]
  total: number
  current: number
  pages: number
  size: number
}

export interface UserAccount {
  id: string
  name: string
  avatar: string
  email: string
  emailVerified: number
  phone: string
  phoneVerified: number
  locale: string
  roles: string
  providerId: string
  providerUserId: string
  utmSource: string
  lastLoginIp: string
  lastLoginAddr: string
  lastLoginTime: string
  createTime: string | null
  updateTime: string | null
}

/**
 * 前端使用OAuth2使用code登录
 */
export const oauth2SignIn = (
  params: {
    code: string
    state?: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<SignedInfo | null>>> => {
  options = options || {}
  return Service({
    url: '/api/account/oauth2/sign-in',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 登录信息获取
 */
export const getSignedInfo = (
  params?: {},
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<SignedInfo | null>>> => {
  options = options || {}
  return Service({
    url: '/api/account/signed-info',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 用户注册/登录
 */
export const userSignUp = (
  data: {
    name: string
    phone: string
    phoneValidateCode: string
    email: string
    emailValidateCode: string
    utmSource?: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<SignedInfo | null>>> => {
  options = options || {}
  return Service({
    url: '/api/account/sign-up',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 用户登出
 */
export const userSignOut = (
  params = {},
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<null>>> => {
  options = options || {}
  return Service({
    url: '/api/account/sign-out',
    method: 'DELETE',
    ...params,
    ...options
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = (
  params?: {},
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<UserAccount>>> => {
  options = options || {}
  return Service({
    url: '/api/account/get',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 修改用户信息
 */
export const updateUserInfo = (
  data: {
    id: string
    name: string
    email: string
    emailValidateCode: string
    phone: string
    phoneValidateCode: string
    avatar: string
    locale: string | null
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<UserAccount>>> => {
  options = options || {}
  return Service({
    url: '/api/account/update',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 发送邮件/短信验证码
 */
export const sendValidateCode = (
  data: {
    type: 'email' | 'phone'
    target: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<null>>> => {
  options = options || {}
  return Service({
    url: '/api/common/send-validate-code',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取项目列表/指定项目
 * 早期设计问题生产的接口，为了做多项目划分
 * 有些过度设计了并且造成了一些问题，所以先去掉了
 * 将projectInfo放到登录信息一起返回作为全局业务信息，通过useProjStore取就行【理论上登录了就会有projectInfo】
 */
export interface Project {
  id: string
  title: string
  config: object
  createTime: string | null
  updateTime: string | null
}
// export const getProjList = (
//   params: {
//     id?: string
//     searchTitle?: string
//     currentPage?: number // 1 | ...
//     pageSize?: number // 1 | 10 | ...
//     orderBy?: string // 'id' | 'create_time' | ...
//     order?: 'asc' | 'desc'
//   },
//   options?: AxiosRequestConfig & {
//     signal?: AbortController['signal']
//     timeout?: number // Milliseconds
//   }
// ): Promise<AxiosResponse<BaseResponse<PageResponse<Project>>>> => {
//   options = options || {}
//   return Service({
//     url: '/api/project/list',
//     method: 'GET',
//     params,
//     ...options
//   })
// }

/**
 * 获取首页公告
 */
export interface Banner {
  emoji?: string
  title?: string
  subtitle?: string
  link?: string
  html?: string
}
export const getBanner = (): Promise<Banner> => {
  return fetch(`${import.meta.env.VITE_PUBLIC_PATH}/banner.json`, {
    method: 'GET'
  }).then((resp) => {
    if (resp.status !== 200) {
      throw new Error(resp.statusText || String(resp.status))
    }
    return resp.json()
  })
}

/**
 * 获取首页演示视频
 */
export interface VideoCase {
  title: string
  url: string
  poster: string
}
export const getVideoCases = (): Promise<VideoCase[]> => {
  return fetch('/assets/video-cases.json', {
    method: 'GET'
  }).then((resp) => {
    if (resp.status !== 200) {
      throw new Error(resp.statusText || String(resp.status))
    }
    return resp.json()
  })
}

/**
 * 获取首页用户评论
 */
export interface UserComments {
  name: string
  avatar: string
  comment: string
}
export const getUserComments = (): Promise<UserComments[]> => {
  return fetch('/assets/user-comments.json', {
    method: 'GET'
  }).then((resp) => {
    if (resp.status !== 200) {
      throw new Error(resp.statusText || String(resp.status))
    }
    return resp.json()
  })
}

export interface Attachment {
  id: string
  projId: string
  category: string
  name: string
  url: string // cdn link of address
  address: string
  mimeType: string
  fileHash: string
  filesize: number
  createTime: string | null
  updateTime: string | null
  createdBy: string | undefined
  lastModifiedBy: string | undefined
}

/**
 * 获取素材列表/指定素材
 */
export interface Material {
  id: string
  projId: string
  product: string
  category: string
  name: string
  url: string // cdn link of thumbnail or address
  address: string
  thumbnail: string
  audio: string
  previewVideo: string
  resolution: string
  mimeType: string
  fileHash: string
  filesize: number
  duration: number
  createTime: string | null
  updateTime: string | null
}
export const getMaterialList = (
  params: {
    id?: string
    projId: string
    product: string
    category?: string
    searchName?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<Material>>>> => {
  options = options || {}
  return Service({
    url: '/api/material/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取附件列表/指定附件
 */
export const getAttachmentList = (
  params: {
    id?: string
    projId?: string
    category?: string
    searchName?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<Attachment>>>> => {
  options = options || {}
  return Service({
    url: '/api/attachment/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取指定产品的推荐产品特性
 */
export const genRecommendProductFeatures = (
  data: {
    projId: string
    productName: string
    language: string
    languageName: string
    languageNativeName: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<string[]>>> => {
  options = options || {}
  return Service({
    url: '/api/video/recommend-product-features',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 创建视频生成任务
 */
export interface GenerateVideoTaskCreateMessage {
  request_id: string
  request_source: 'web'
  proj_id: string
  parent_task_id: string
  task_id: string
  task_type: 'clips2video'
  user_id: string
  task_params: {
    media_urls: string[]
    product_params: {
      product_name: string
      product_features: string[]
      price?: number | null
      currency: string
      currency_symbol: string
      promotion: string
      brand: {
        name: string
        logo: string
      }
    }
    video_params: {
      video_resolution_ratio: string
      video_resolution: string
      video_locale: string
      video_language: string
      video_duration: string
      video_duration_desc: string
      video_numbers: {
        ae: number
        mix: number
      }
      speech_mute: boolean
      speech: Voice
      text: VideoSubtitleConfig
      video_bgm: 'auto' | string
    }
  }
  config: {
    env: string
    version: '1.0'
  }
}
export const createGenerateVideoTask = (
  data: {
    projId: string
    title: string
    config: GenerateVideoTaskCreateMessage
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<GenerateVideoTask>>> => {
  options = options || {}
  return Service({
    url: '/api/video/task/generate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 重新执行视频生成任务
 */
export const regenerateVideoTask = (
  data: {
    id: string
    projId: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<GenerateVideoTask>>> => {
  options = options || {}
  return Service({
    url: '/api/video/task/regenerate',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 取消视频生成任务
 */
export const cancelGenerateVideoTask = (
  data: {
    id: string
    projId: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<GenerateVideoTask>>> => {
  options = options || {}
  return Service({
    url: '/api/video/task/cancel',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取视频生成任务列表/指定视频生成任务
 */
export interface GenerateVideoTask {
  id: string
  projId: string
  product: string
  requestId: string
  parentTaskId: string
  title: string
  config: GenerateVideoTaskCreateMessage
  quantityTotal: number
  quantityCompleted: number
  thumbnails: string
  thumbnailUrls: string[]
  state: string
  message: string
  logs: string
  createTime: string | null
  updateTime: string | null
}
export const getGenerateTaskList = (
  params: {
    id?: string
    projId: string
    product: string
    searchTitle?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<GenerateVideoTask>>>> => {
  options = options || {}
  return Service({
    url: '/api/video/task/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取视频生成任务列表/指定视频生成任务
 */
export interface GeneratedVideo {
  id: string
  projId: string
  requestId: string
  parentTaskId: string
  taskId: string
  address: string
  addressUrl: string
  thumbnail: string
  thumbnailUrl: string
  resolution: string
  filesize: number
  duration: number
  downloadCount: number
  projectFile: string
  projectFileUrl: string
  createTime: string | null
  updateTime: string | null
}
export const getGeneratedVideoList = (
  params: {
    id?: string
    projId: string
    taskId: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<GeneratedVideo>>>> => {
  options = options || {}
  return Service({
    url: '/api/video/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取视频下载地址
 */
export const genVideoDownloadUrl = (
  data: {
    id: string
    projId: string
    taskId?: string
    downloadFilename?: string
    isQueryCostQuotas?: boolean
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<
  AxiosResponse<
    BaseResponse<GeneratedVideo> & {
      costQuotas?: number | null
    }
  >
> => {
  options = options || {}
  return Service({
    url: '/api/video/gen-download-url',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取演示数据
 */
export const getDemoData = (): Promise<object> => {
  return fetch('/assets/demo.json', {
    method: 'GET'
  }).then((resp) => {
    if (resp.status !== 200) {
      throw new Error(resp.statusText || String(resp.status))
    }
    return resp.json()
  })
}

/**
 * 兑换积分: 目前只有appsumo code
 */
export const redeemWithCode = (
  data: {
    codeType: number
    code: string
    projectId: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<string[]>>> => {
  options = options || {}
  return Service({
    url: '/api/redeem/purchase-by-code',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 管理员-创建兑换码
 */
export type ReedemCodeResponse = {
  data?: string[]
}
export enum RedeemCodeType {
  AppSumoCode = 1
}
export const genRedeemCodes = (
  data: {
    codeType: number
    count: number
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<string[]>>> => {
  options = options || {}
  return Service({
    url: '/api/admin/generate-redeem-code',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 管理员-创建兑换码
 */
export const refundRedeemCodes = (
  data: {
    codeType: number
    code: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<never>>> => {
  options = options || {}
  return Service({
    url: '/api/admin/refund-code',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 管理员-获取全部用户项目列表/指定用户的项目
 */
export type UserProject = UserAccount & {
  projId?: string
}
export const getAdminUserProjectList = (
  params: {
    id?: string
    searchIdOrNameOrEmailOrPhone?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<UserProject>>>> => {
  options = options || {}
  return Service({
    url: '/api/admin/user-project',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 管理员-获取礼品卡列表/指定礼品卡
 */
export const getAdminUserGiftList = (
  params: {
    packageName: string
    id?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<GoodsInfo>>>> => {
  options = options || {}
  return Service({
    url: '/api/admin/user-gift',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 管理员-赠送礼品卡给用户的项目
 */
export const createAdminUserGiftHistory = (
  data: {
    userId: string
    projId: string
    goodsId: string
    paymentStateDesc: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<OrderInfo>>> => {
  options = options || {}
  return Service({
    url: '/api/admin/user-gift-create-history',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 管理员-获取用户获得礼品卡历史列表/指定获得礼品卡历史
 */
export const getAdminUserGiftHistoryList = (
  params: {
    userId: string
    goodsPackageName: string
    id?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<OrderInfo>>>> => {
  options = options || {}
  return Service({
    url: '/api/admin/user-gift-history',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 管理员-更新用户最后登录地点
 */
export const updateLastLoginAddr = (
  data: {
    id: string
    lastLoginIp?: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<UserProject>>> => {
  options = options || {}
  return Service({
    url: `${['localhost'].includes(location.hostname) ? '' : 'https://vmeg.gthree.cn'}/api/admin/update-last-login-addr`,
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取项目积分详情列表
 */
export interface CreditDetail {
  group_id: string
  sub_id: string
  price_id: string
  frozen_id?: string
  idempotency_key: string
  operation:
    | 'new_subscription'
    | 'recurring_charge'
    | 'consume'
    | 'frozen'
    | 'unfrozen'
    | 'auto_unfrozen'
    | 'commit'
    | 'cancel_subscription'
  quota_change: number
  quota_remaining: number
  quota_frozen: number
  create_time: number
  memo?:
    | CreditDetailMemoGenerate
    | CreditDetailMemoVideoTranslation
    | CreditDetailMemoTranslateScript
    | CreditDetailMemoTranslateText
    | CreditDetailMemoDownload
    | CreditDetailMemoSubscription
}
export interface CreditDetails {
  error?: string | null
  code?: number
  cursor?: string | null
  count: number
  items: CreditDetail[]
}
export const getCreditDetailList = (
  params: {
    projId: string
    subId?: string
    limit?: number
    startingAfter?: number
    endingBefore?: number
    cursor?: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<CreditDetails>>> => {
  options = options || {}
  return Service({
    url: '/api/project/get-credit-detail-list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取指定项目订阅计划及积分详情
 */
export const getSubscriptionPlanAndCreditDetails = (
  params: {
    projId: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<SubscriptionPlanAndCreditDetail>>> => {
  options = options || {}
  return Service({
    url: '/api/project/get-subscription-plan-and-credit-details',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 取消订阅
 */
export const cancelSubscription = (
  data: {
    projId: string
    subId: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<null>>> => {
  options = options || {}
  return Service({
    url: '/api/project/cancel-subscription',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取商品列表
 */
export interface GoodsInfo {
  id: string
  category: string
  name: string
  price: number
  currency: string
  summary: string
  description: string
  packageName: string
  packageLevel: number
  packagePeriod: number
  packageType: string
  packageConfigs: string
  packageConfig: {
    priceId: string
    paymentLink?: string
    currencySymbol: string
    credits: number
    periodDesc: string
    creditEach: number
    videoCount: number
    videoTranslationMinutes: number
    tips?: string
    features?: {
      title: string
      support?: boolean
      text?: string
      value?: string | number
    }[]
  }
  createTime: string | null
  updateTime: string | null
}
export const getGoodsList = (
  params: {
    category?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<GoodsInfo>>>> => {
  options = options || {}
  return Service({
    url: '/api/store/goods/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取订单列表/指定订单
 */
export interface OrderInfo {
  id: string
  projId: string
  //...
  goodsName: string
  //...
  goodsSnapshot: string
  //...
  deliveryNo: string
  //...
  deliveryTime: string
  //...
  paymentStateDesc: string
  //...
  createTime: string | null
  updateTime: string | null
}
export const getOrderList = (
  params: {
    outTradeNo?: string
    projId: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<OrderInfo>>>> => {
  options = options || {}
  return Service({
    url: '/api/store/order/list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取微信支付链接
 */
export interface PaymentRes {
  outTradeNo: string
  wxPaymentLink: string
}
export const genWeChatPaymentLink = (
  data: {
    projId: string
    goodsId: string
    quantity: number
    price: number
    total: number
    orderId?: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PaymentRes>>> => {
  options = options || {}
  return Service({
    url: '/api/payment/wx/pay',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取声音示例列表
 */
export type VoiceTemplate = Material & {
  language: string
  provider: string
  voiceId: string
  tags: string[]
}
export const getVoiceTemplateList = (
  params: {
    language?: string
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<VoiceTemplate>>>> => {
  options = options || {}
  return Service({
    url: '/api/public/get-voice-template-list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取字幕示例列表
 */
export interface VideoTemplate {
  id: string
  name: string
  language: string
  isLandscape: 0 | 1
  thumbnail: string
  config: {
    title: {
      font_name: string
      font_color: string
      outline_color?: string
      outline_width?: number
      shadow_color?: string
      shadow_offset?: number
      font_size_ratio: number
      padding: {
        left?: number
        top: number
      }
      align?: string
      background?: {
        color: string
        width_ratio: number
        corner_radius: number
      }
    }
    subtitle: {
      padding: {
        bottom: number
      }
      normal: {
        font_size_ratio: number
        line_spacing_percent: number
        font_name: string
        font_color: string
        outline_color?: string
        outline_width?: number
        shadow_color?: string
        shadow_offset?: number
      }
      highlight: {
        font_color: string
      }
      background?: {
        color: string
        width_ratio: number
        corner_radius: number
      }
    }
  }
  animations: { name: VideoSubtitleConfig['animation']; image?: string }[]
  createTime: string
  updateTime: string
}
export const getVideoTemplateList = (
  params: {
    language?: string
    isLandscape?: boolean
    currentPage?: number // 1 | ...
    pageSize?: number // 1 | 10 | ...
    orderBy?: string // 'id' | 'create_time' | ...
    order?: 'asc' | 'desc'
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<PageResponse<VideoTemplate>>>> => {
  options = options || {}
  return Service({
    url: '/api/public/get-video-template-list',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 获取视频脚本（ASR）及翻译或发起任务
 */
export interface TranslateScript {
  $index?: number
  $empty?: boolean
  start_time: number // 如：1.05，对应原文的起始时间，单位秒
  end_time: number // 如：1.5，对应原文的终止时间，单位秒
  text: string // 翻译后的文本
  source_text: string
  word_range?: [number, number]
}
export const genTranslateScript = (
  data: {
    projId: string
    originalLanguage: string
    targetLanguage: string
    audio: string
    queryResult?: boolean
    forceRedo?: boolean
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<
  AxiosResponse<
    BaseResponse<TranslateScript[] | null> & {
      asrReady: boolean | null
    }
  >
> => {
  options = options || {}
  return Service({
    url: '/api/video/translation/translate/script',
    method: 'POST',
    data,
    ...options
  })
}

export const doTranslateScripts = (
  data: {
    projId: string
    originalLanguage: string
    targetLanguage: string
    audio: string
    scripts: TranslateScript[]
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<
  AxiosResponse<
    BaseResponse<TranslateScript[] | null> & {
      asrReady: boolean | null
    }
  >
> => {
  options = options || {}
  return Service({
    url: '/api/video/translation/translate/scripts',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取翻译
 */
export interface TranslateText {
  text: string
}
export const doTranslate = (
  data: {
    projId: string
    originalLanguage: string
    targetLanguage: string
    text: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<TranslateText>>> => {
  options = options || {}
  return Service({
    url: '/api/video/translation/translate/text',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 创建视频翻译渲染任务
 */
export interface VideoTranslationCostDetail {
  basic: number
  voiceClone?: number
  lipSync?: number
  hd?: number
}
export interface Voice {
  clone?: {
    // 选择TTS时不填（与voice_id互斥）
    from_video?: boolean // 仅用于前端还原展示
    audio_files: string[] // 从音频文件中克隆（s3://xxx.mp3）
  }
  provider: string // mobvoi（序列猴子）、openai、elevenlabs
  voice_id?: string // 克隆声音时不填（与clone互斥）
  speed: number // TTS语速
  tags: string[]
}
export interface VideoSubtitleConfig {
  // 标题与字幕配置
  font_path: string // 模版配置使用到的字体目录路径
  template: object | null // 模版配置
  title?: string
  subtitle: boolean // 是否显示字幕
  animation?: 'none' | 'word_in' // 字幕滚动动画
}
export interface Subtitle {
  text: string // 翻译后的文本
  start: number // 如：1.05，对应原文的起始时间，单位秒
  end: number // 如：1.5，对应原文的终止时间，单位秒
}
export interface VideoTranslationTaskCreateMessage {
  id: string // 该任务的唯一标识，后台赋值
  memo: {
    // 该任务的备注信息，在结果通知中会透传该信息
    proj_id: string
    task_id: string // 后台赋值
    request_id: string // 后台赋值
    //... // 后台赋值
    video_id: string // 仅用于前端还原展示
    video_duration: number // 用于前端还原展示、计价
    video_resolution: string // 用于前端还原展示、计价
    cost_detail: VideoTranslationCostDetail // 用于前端还原展示、计价
  }
  video: string // s3://xxx.mp4
  audio: string // s3://xxx.mp3
  voice?: Voice
  language: {
    source: string // 如：zh | zh-CN
    target: string // 如：en | en-US
  }
  scripts: (Subtitle & {
    source_text?: string // 仅用于前端还原展示
  })[]
  text: VideoSubtitleConfig
  watermarks?: {
    // 默认无水印
    image: string // 图片水印路径
    position: [number, number] // 在视频中的相对位置，默认0, 0，表示左上角（百分比）
    offset: [number, number] // 在视频中的绝对位置，默认0, 0，单位像素
    anchor_point: [number, number] // 图片的锚点，默认左上角（百分比）
    width: number // 小于1时，表示占视频宽度（长与宽的最小值）的百分比；大于1时，表示像素值
  }[]
  lipsync?: {
    // 唇语同步，缺失或为null时表示不要唇语同步
    enable: boolean
  }
  hd?: {
    // 超分配置，缺失或为null时表示不要超分。lipsync没有开启时，hd也不会开启
    enable: boolean
  }
  output: {
    build_folder: string // s3://yyy。后台赋值
    video?: string // xyz.mp4，视频输出文件名。默认为video.mp4
    thumbnail?: string // xyz.webp，预览图输出文件名。默认为thumbnail.jpg
    fps?: number // 如：30，视频fps，默认与原视频一致
  }
}
export type VideoTranslateTask = GenerateVideoTask & {
  config: VideoTranslationTaskCreateMessage
}
export const createVideoTranslateTask = (
  data: {
    projId: string
    title: string
    config: VideoTranslationTaskCreateMessage
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<VideoTranslateTask>>> => {
  options = options || {}
  return Service({
    url: '/api/video/translation/task/create',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 重新执行视频翻译渲染任务
 */
export const retryVideoTranslateTask = (
  data: {
    id: string
    projId: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<VideoTranslateTask>>> => {
  options = options || {}
  return Service({
    url: '/api/video/translation/task/retry',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 字幕分屏
 */
export const subtitleSplit = (
  data: {
    language: string
    resolution: {
      width: number
      height: number
    }
    scripts: Subtitle[]
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<Subtitle[]>>> => {
  options = options || {}
  return Service({
    url: '/api/public/subtitle-split',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 生成TTS（返回url）
 */
export interface TTSResult {
  audio: string // 音频文件url
  bgm?: string // 背景音乐url
}
export const genTTS = (
  data: {
    language: string
    voice: Voice
    video: string
    scripts: Subtitle[]
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<TTSResult>>> => {
  options = options || {}
  return Service({
    url: '/api/public/gen-tts',
    method: 'POST',
    data,
    timeout: 60 * 1000,
    ...options
  })
}

/**
 * 记录Rewardful和项目的关系
 */
export const recordReferral = (
  data: {
    referralId: string
    projId: string
  },
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<TTSResult>>> => {
  options = options || {}
  return Service({
    url: '/api/payment/record-referral',
    method: 'POST',
    data,
    timeout: 60 * 1000,
    ...options
  })
}

/**
 * 检测视频语言（返回检测结果）
 */
export interface AutoDetectReqData {
  audio: string
  projId: string
}
export const autoDetectLanguage = (
  data: AutoDetectReqData,
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<{ language: string; confidence: number }>>> => {
  options = options || {}
  return Service({
    url: '/api/video/translation/translate/auto-detect-language',
    method: 'POST',
    data,
    ...options
  })
}

/**
 * 获取问卷配置
 */
export interface getSurveyInfoReq {
  type: string
}
export interface SurveyInfo {
  id: string
  questions: string
  type: string
}
export const getSurveyInfo = (
  params: getSurveyInfoReq,
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<SurveyInfo>>> => {
  options = options || {}
  return Service({
    url: '/api/feedback/get-survey',
    method: 'GET',
    params,
    ...options
  })
}

/**
 * 提交问卷答案
 */
export interface submitFeedbackReq {
  id: string
  projId: string
  answers: string // 序列化表单即可
}
export const submitFeedback = (
  data: submitFeedbackReq,
  options?: AxiosRequestConfig & {
    signal?: AbortController['signal']
    timeout?: number // Milliseconds
  }
): Promise<AxiosResponse<BaseResponse<any>>> => {
  options = options || {}
  return Service({
    url: '/api/feedback/submit-feedback',
    method: 'POST',
    data,
    ...options
  })
}
