<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { InfoFilled } from '@element-plus/icons-vue'

const { t } = useI18n()

const dialogVisibleTips = ref(false)

const showTips = () => {
  dialogVisibleTips.value = true
}

defineExpose({
  showTips
})

type OutputStyle = {
  name: string
  rowspan: number
}
const languageTableColumns = reactive<{
  columnInputLanguage: string[]
  columnOutputLanguage: string[]
  columnOutputStyle: Array<null | string | OutputStyle>
}>({
  columnInputLanguage: [
    'Chinese',
    'English',
    'French',
    'German',
    'Indonesian',
    'Italian',
    'Japanese',
    'Korean',
    'Portuguese',
    'Spanish',
    'Turkish'
  ],
  columnOutputStyle: [
    {
      name: 'American-style',
      rowspan: 6
    },
    null,
    null,
    null,
    null,
    null,
    {
      name: 'Australian-style',
      rowspan: 6
    },
    null,
    null,
    null,
    null,
    null,
    'Chinese-style',
    'French-style',
    'German-style',
    'Indonesian-style',
    'Irish-style',
    'Italian-style',
    'Japanese-style',
    'Korean-style',
    'Portuguese-style',
    'Spainish-style',
    'Turkish-style'
  ],
  columnOutputLanguage: [
    'Chinese',
    'English',
    'French',
    'German',
    'Japanese',
    'Spanish',
    'Chinese',
    'English',
    'French',
    'German',
    'Japanese',
    'Spanish',
    'Chinese',
    'French',
    'German',
    'Indonesian',
    'English',
    'Italian',
    'Japanese',
    'Korean',
    'Portuguese',
    'Spanish',
    'Turkish'
  ]
})

const maxRowNum = Math.max(
  languageTableColumns.columnInputLanguage.length,
  languageTableColumns.columnOutputStyle.length,
  languageTableColumns.columnOutputLanguage.length
)
</script>

<template>
  <el-icon class="upload-info" @click="showTips"><InfoFilled /></el-icon>

  <el-dialog
    v-model="dialogVisibleTips"
    :title="t('视频翻译提示')"
    append-to-body
    modal-class="upload-info-dialog"
    draggable
    destroy-on-close
  >
    <h3>{{ t('视频要求') }}</h3>
    <ol>
      <li>{{ t('视频时长范围') }}: <b>10s - 120s</b></li>
      <li>{{ t('视频输入的最大分辨率限制为') }} <b>4096*4096</b></li>
      <li>{{ t('视频仅支持单人配音') }}</li>
      <li>
        {{
          t('避免在视频中遮挡面部，尽量减小和缓慢头部动作，确保面部大小适当，以保证唇形同步效果')
        }}
      </li>
      <li>
        {{ t('确保视频中没有嘈杂的背景或背景音乐，以避免影响声音克隆') }}
      </li>
    </ol>
    <h3>{{ t('语言支持') }}</h3>
    <table class="language-support-table">
      <thead>
        <tr>
          <th>{{ t('输入') }}</th>
          <th colspan="2">{{ t('输出') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(_, idx) in maxRowNum" :key="idx">
          <td>{{ languageTableColumns.columnInputLanguage[idx] || '' }}</td>
          <td
            :rowspan="(languageTableColumns.columnOutputStyle[idx] as OutputStyle)?.rowspan"
            v-if="languageTableColumns.columnOutputStyle[idx]"
            width="120"
          >
            {{
              (languageTableColumns.columnOutputStyle[idx] as OutputStyle)?.name ||
              languageTableColumns.columnOutputStyle[idx] ||
              ''
            }}
          </td>
          <td v-if="languageTableColumns.columnOutputLanguage[idx]" width="140">
            {{ languageTableColumns.columnOutputLanguage[idx] }}
          </td>
        </tr>
      </tbody>
    </table>
    <template #footer>
      <el-button type="primary" @click="dialogVisibleTips = false">{{ t('知道了！') }}</el-button>
    </template>
  </el-dialog>
</template>

<style lang="less">
.upload-info-dialog {
  .el-dialog__header {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: var(--my-color-black-89-90);
    }
  }

  .el-dialog__footer {
    text-align: center;
  }
}
</style>
<style scoped lang="less">
.el-upload-dragger {
  //position: relative;

  .upload-info {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 20px;
    cursor: pointer;
  }
}

h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: var(--my-color-black-89-90);
}

ol {
  padding-left: var(--el-main-padding);
  font-size: 16px;
  line-height: 24px;
  color: var(--my-color-black-89-90);
}

.language-support-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 6px;

  thead {
    text-align: left;
    height: 40px;

    tr {
      th {
        &:not(:first-child) {
          padding-left: 10px;
        }
      }
    }
  }

  tbody {
    tr {
      td:first-child {
        min-width: 120px;
      }

      td {
        border: none;
        color: #777;
        padding: 3px 0;
        vertical-align: top;
        border: none;
        font-size: 12px;

        &:not(:first-child) {
          padding-left: 10px;
        }

        //&:not(:last-child) {
        //  border-right: 1px solid var(--my-color-black-10);
        //}
        //
        //&:not(:empty) {
        //  border-bottom: 1px solid var(--my-color-black-10);
        //}
      }
    }
  }
}
</style>
