<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { Close } from '@element-plus/icons-vue'

import IconVoiceFromAudio from '@/components/icons/IconVoiceFromAudio.vue'
import IconVoiceFromVideo from '@/components/icons/IconVoiceFromVideo.vue'

import UploadMaterialWrapper from '@/components/UploadMaterialOne.vue'

import type { Material, VoiceTemplate } from '@/api'
import { costQuotasMap } from '@/config'
import { getAudioInfo } from '@/utils/media'
import { ElMessage } from 'element-plus'
import type { UploadRawFile } from 'element-plus/es/components/upload/src/upload'

const { t } = useI18n()

const emit = defineEmits(['update:modelValue', 'selected:done'])
const props = defineProps<{
  modelValue: boolean // v-model默认的名字
  materialList: Material[]
  'onSelected:done': (materials: VoiceTemplate[]) => void
}>()

const dialogVisible = computed({
  get: () => props.modelValue,
  set: (nv) => {
    emit('update:modelValue', nv)
  }
})

const maxFileSize = 20 // MB
const selectedIndex = ref(0)
const uploadRef = ref<typeof UploadMaterialWrapper>()
const uploadList = ref<Material[]>([])

const selectVoiceFromVideo = () => {
  selectedIndex.value = 0
}

const selectVoiceFromAudio = () => {
  if (!uploadList.value.length) {
    uploadRef.value?.getUploadTypeRef()?.handleOpen()
    return
  }
  selectedIndex.value = 1
}

watch(
  () => uploadList.value.length,
  (val) => {
    if (val) {
      selectedIndex.value = 1
    }
  }
)

const clearFiles = (event?: MouseEvent) => {
  if (event) {
    event.stopPropagation()
  }
  uploadList.value = []
  if (selectedIndex.value === 1) {
    selectedIndex.value = -1
  }
}

const selectedCancel = () => {
  dialogVisible.value = false
}
const selectedDone = () => {
  emit('selected:done', [
    ...(selectedIndex.value === 1
      ? uploadList.value.map((material) => {
          const item = material as VoiceTemplate
          item.tags = [t('从上传音频文件中克隆')]
          return Object.assign({}, item)
        })
      : props.materialList.map((material) => {
          const item = material as VoiceTemplate
          item.tags = [t('从原视频文件中克隆')]
          return Object.assign({}, item)
        }))
  ])
  clearFiles()
  selectedCancel()
}

const costQuotas = computed(() => {
  if (!props.materialList.length) {
    return 0
  }
  const seconds = props.materialList[0].duration / 1000
  const minutes = Math.ceil(seconds / 60)
  const voiceClone = costQuotasMap['video-translation-voice-clone']
  return voiceClone * minutes
})

const beforeAudioUpload = async (file: UploadRawFile) => {
  const info = await getAudioInfo(URL.createObjectURL(file))

  if (info?.duration && info.duration < 10) {
    ElMessage.error(t('为了保证克隆效果，上传的音频必须至少10秒。'))
    return false
  }

  return true
}
</script>

<template>
  <el-dialog
    v-model="dialogVisible"
    :title="t('声音克隆')"
    width="500px"
    draggable
    align-center
    destroy-on-close
    @open="selectVoiceFromVideo"
  >
    <div class="my-menu">
      <div
        class="my-menu-item"
        :class="{ selected: selectedIndex == 0 }"
        @click="selectVoiceFromVideo"
      >
        <el-icon><IconVoiceFromVideo /></el-icon>
        <div class="my-menu-content">
          <div class="my-menu-title">{{ t('从原视频文件') }}</div>
          <div class="my-menu-desc">{{ t('使用原始视频克隆声音') }}</div>
        </div>
      </div>
      <UploadMaterialWrapper
        ref="uploadRef"
        v-model="uploadList"
        product="video-translation"
        category="voice"
        :categories="['voice']"
        :file-format="t('MP3、WAV')"
        :max-file-size="maxFileSize"
        trigger="click"
        is-custom
        :before-upload="beforeAudioUpload"
      >
        <div
          class="my-menu-item"
          :class="{ selected: selectedIndex == 1 }"
          @click="selectVoiceFromAudio"
        >
          <el-icon><IconVoiceFromAudio /></el-icon>
          <div class="my-menu-content">
            <div class="my-menu-title">{{ t('从上传的音频文件') }}</div>
            <div class="my-menu-desc">
              {{
                t('上传音频文件（MP3、WAV），{size}以内并且10秒以上', { size: `${maxFileSize}MB` })
              }}
            </div>
            <div class="my-menu-ret" v-if="uploadList.length">
              <div>
                <span>{{ uploadList[0]?.name }}</span>
                <el-icon @click="clearFiles"><Close /></el-icon>
              </div>
            </div>
          </div>
        </div>
      </UploadMaterialWrapper>
    </div>
    <template #footer>
      <div class="my-footer">
        <div>
          <span class="my-light-text">{{ t('消耗') }}</span>
          {{ `${costQuotas != 1 ? costQuotas + ' ' + t('积分值') : t('1积分')}` }}
        </div>
        <div>
          <el-button @click="selectedCancel">{{ t('取消') }}</el-button>
          <el-button
            type="primary"
            :disabled="selectedIndex < 0 || (selectedIndex == 1 && !uploadList.length)"
            @click="selectedDone"
          >
            {{ t('确认') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="less">
.my-menu {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .my-menu-item {
    padding: 24px;
    border: 1.2px solid var(--my-color-black-10);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    gap: 12px;
    position: relative;
    z-index: 1;

    &.selected {
      border-color: var(--el-color-primary);
    }

    > .el-icon {
      font-size: 36px;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background: var(--my-color-primary-10);
    }

    .my-menu-content {
      font-family:
        SF Pro Text,
        var(--el-font-family);

      .my-menu-title {
        font-size: 18px;
        line-height: 28px;
        color: var(--my-color-black-89-90);
        text-align: left;
      }

      .my-menu-desc {
        display: flex;
        justify-content: flex-start;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        color: var(--my-color-black-40);
      }

      .my-menu-ret {
        font-size: 14px;
        line-height: 24px;
        color: var(--my-color-black-89-90);
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;

        > div {
          display: flex;
          align-items: center;
          gap: 8px;

          padding: 2px 8px 2px 12px;
          border-radius: 4px;
          background: var(--my-color-black-6);
          cursor: default;

          .el-icon {
            font-size: 16px;

            &:hover {
              color: var(--el-color-primary);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.my-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .my-light-text {
    color: var(--my-color-black-40);
  }
}
</style>
