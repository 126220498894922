<script setup lang="ts">
import ErrorIcon from '@/assets/redeem/error@2x.png'
</script>

<template>
  <div class="error-wrapper result-wrapper">
    <img :src="ErrorIcon" alt="" class="error-icon" />
    <p class="title">Data loading failed</p>
    <p class="description">Oh, it looks like the internet connection just failed.</p>
    <el-button @click="$emit('handle-retry')" type="primary" class="btn">Try it again</el-button>
  </div>
</template>

<style scoped lang="less">
.result-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    margin: 0;
    color: var(--my-color-black-89-90);
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .description {
  }

  .btn {
    height: 40px;
    font-size: 16px;
    padding: 8px 31px;
    margin: 100px 0;
  }
}
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .error-icon {
    margin: 100px 0 32px;
    width: 130px;
  }

  .description {
    margin: 0;
    line-height: 24px;
    color: #888;
    font-size: 16px;
    font-weight: 500;
    min-width: 240px; // fit mobile
    max-width: 430px;
  }
}
</style>
