import axios from 'axios'
import VueAxios from 'vue-axios'

import router from '@/router/guards'

import './main.less'

import { createApp } from './main'

const { app } = createApp()

app.use(router)

app.use(VueAxios, axios)

app.mount('#app')
