<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#7B4DFF" fill-opacity="0.15" />
    <g clip-path="url(#clip0_217_1446)">
      <path
        d="M11.5555 32.5557H30.2222C31.5109 32.5557 32.5555 31.511 32.5555 30.2223V11.5557"
        stroke="#7B4DFF"
        stroke-width="1.55556"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="0.78 2.33"
      />
      <path
        d="M36.4445 15.4443L17.7778 15.4443C16.4891 15.4443 15.4445 16.489 15.4445 17.7777L15.4445 36.4443"
        stroke="#7B4DFF"
        stroke-width="1.55556"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_217_1446">
        <rect width="28" height="28" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
</template>
