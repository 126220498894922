<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#7B4DFF" fill-opacity="0.15" />
    <path
      d="M24.3677 13.8889H16.2222C14.9336 13.8889 13.8889 14.9336 13.8889 16.2222V31.7778C13.8889 33.0665 14.9336 34.1111 16.2222 34.1111H31.7778C33.0664 34.1111 34.1111 33.0618 34.1111 31.7732C34.1111 27.8738 34.1111 28.6287 34.1111 23.7367"
      stroke="#7B4DFF"
      stroke-width="1.55556"
      stroke-linecap="round"
    />
    <path
      d="M21.6438 23.5271L30.484 14.6869C31.2813 13.8896 32.574 13.8896 33.3713 14.6869V14.6869C34.1687 15.4843 34.1687 16.777 33.3713 17.5743L24.5311 26.4145L21.162 26.8963L21.6438 23.5271Z"
      stroke="#7B4DFF"
      stroke-width="1.55556"
      stroke-linejoin="round"
    />
  </svg>
</template>
