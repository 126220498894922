<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.41528 4.33325C3.62036 4.33325 2.16528 5.78833 2.16528 7.58325V18.4166C2.16528 20.2115 3.62036 21.6666 5.41529 21.6666H16.6278C18.4227 21.6666 19.8778 20.2115 19.8778 18.4166V17.9681L20.7928 18.7564C22.1972 19.9661 24.3736 18.9684 24.3736 17.1148V8.88505C24.3736 7.03143 22.1972 6.03369 20.7928 7.24349L19.8778 8.03176V7.58325C19.8778 5.78833 18.4227 4.33325 16.6278 4.33325H5.41528ZM3.79028 7.58325C3.79028 6.68579 4.51782 5.95825 5.41528 5.95825H16.6278C17.5252 5.95825 18.2528 6.68579 18.2528 7.58325V9.80409C18.2528 10.1218 18.4379 10.4103 18.7267 10.5427C19.0155 10.6751 19.3549 10.627 19.5956 10.4197L21.8534 8.47466C22.2045 8.17221 22.7486 8.42164 22.7486 8.88505V17.1148C22.7486 17.5782 22.2045 17.8276 21.8534 17.5252L19.5956 15.5802C19.3549 15.3728 19.0155 15.3248 18.7267 15.4572C18.4379 15.5896 18.2528 15.8781 18.2528 16.1958V18.4166C18.2528 19.3141 17.5252 20.0416 16.6278 20.0416H5.41529C4.51782 20.0416 3.79028 19.3141 3.79028 18.4166V7.58325ZM14.5946 14.8392C16.0251 14.0305 16.0251 11.9699 14.5946 11.1612L10.7362 8.97995C9.3279 8.18384 7.58403 9.20123 7.58403 10.8189L7.58403 15.1814C7.58403 16.7991 9.3279 17.8165 10.7361 17.0204L14.5946 14.8392ZM13.7948 12.5758C14.125 12.7624 14.125 13.2379 13.7948 13.4246L9.93644 15.6058C9.61146 15.7895 9.20903 15.5547 9.20903 15.1814L9.20903 10.8189C9.20903 10.4456 9.61146 10.2108 9.93645 10.3946L13.7948 12.5758Z"
      fill="currentColor"
    />
  </svg>
</template>
