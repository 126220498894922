<script setup lang="ts">
import { ref, onMounted, onUnmounted, onActivated, onDeactivated } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ElMessage, type MessageBoxState } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import { ArrowRight, Picture, Loading } from '@element-plus/icons-vue'
/*import {
  MoreFilled as IconMore,
  Download as IconDownload,
  CircleClose as IconCircleClose,
  Clock as IconClock
} from '@element-plus/icons-vue'*/

import IconMore from '@/components/icons/IconMore.vue'
import IconDownload from '@/components/icons/IconDownload.vue'
import IconClock from '@/components/icons/IconClock.vue'
import IconCircleClose from '@/components/icons/IconCircleClose.vue'
import IconStateRunning from '@/components/icons/IconStateRunning.vue'
import IconEmpty from '@/components/icons/IconEmpty.vue'
import IconVideo from '@/components/icons/IconVideo.vue'
import IconVideoPlay from '@/components/icons/IconVideoPlay.vue'

import {
  type VideoTranslateTask,
  type GeneratedVideo,
  type VideoTranslationCostDetail,
  getGenerateTaskList,
  getGeneratedVideoList,
  genVideoDownloadUrl,
  retryVideoTranslateTask
} from '@/api'
import { costQuotasMap } from '@/config'
import { videoDownload, getLanguages } from '@/common'
import { useUserStore } from '@/stores/user'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { showVTSubmitConfirm } from '@/utils/creditsCostConfirm'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()

userStore.$subscribe((mutation, state) => {
  if (!state.id) {
    unmounted()
  }
})

const isInit = ref(true) // 解决window.history.state.forward是否存在判断是否返回不准确问题
const activated = ref(true) // 避免失活后全局监听事件仍然执行
onActivated(() => {
  if (!userStore.roles?.includes('admin')) {
    replaceUrlParams()
  }

  activated.value = true
  if (!route.meta.isBack && !isInit.value) {
    // 如果不是返回还是需要请求更新数据的
    abortRequest()
    currentPage.value = 1
    isLoading.value = false

    dataList.value = []
    total.value = 0
    fetchData()
  }
  isInit.value = false
})
onDeactivated(() => {
  activated.value = false
})

let requestTask: AbortController | null = null
const isLoading = ref(false)
const currentPage = ref(1)
const dataList = ref<VideoTranslateTask[]>([])
const total = ref(0)
const background = ref(false)
const pagerCount = ref(7)
const layout = ref('prev, pager, next, jumper, ->, total')
const pageSizes = [12, 24, 36, 48, 60, 72, 84, 96]
const pageSize = ref(pageSizes[0])
/*const searchTitle = ref('')
const searchSubmitedText = ref('')
const isSearching = ref(false)*/

let requestTimer: ReturnType<typeof setTimeout> | null = null
const clearRequestTimeout = () => {
  if (requestTimer) {
    clearTimeout(requestTimer)
    requestTimer = null
  }
}

const replaceUrlParams = () => {
  history.replaceState(
    {},
    '',
    location.hash
      .replace(/\/-$/, `/${projStore.projId || '-'}`)
      .replace(/\/\d+$/, `/${projStore.projId || '-'}`)
  )
}

onMounted(() => {
  fetchData()
})

const thumbnailsMax = 1
const fetchData = async (isRefresh: boolean = false) => {
  if (!isRefresh) {
    playItem.value = null
  }

  clearRequestTimeout()
  abortRequest()

  if (!isRefresh) {
    dataList.value = []
    total.value = 0
    isLoading.value = true
  }

  const controller = new AbortController()
  requestTask = controller

  getGenerateTaskList(
    {
      projId: projStore.projId,
      product: 'video-translation',
      //searchTitle: searchTitle.value,
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      orderBy: 'id',
      order: 'desc'
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      total.value = res.data.data.total
      if (res.data.data.current === currentPage.value && res.data.data.size === pageSize.value) {
        dataList.value = (res.data.data.records || []) as VideoTranslateTask[]

        let needRefresh = false
        dataList.value = dataList.value.map((item) => {
          if (['pending', 'running'].includes(item.state)) {
            needRefresh = true
          }
          for (let i = 0; i < thumbnailsMax; i++) {
            if (!item.thumbnailUrls[i]) {
              item.thumbnailUrls[i] = ''
            }
          }
          item.thumbnailUrls = item.thumbnailUrls.slice(0, thumbnailsMax)
          return item
        })

        if (needRefresh) {
          clearRequestTimeout()
          requestTimer = setTimeout(() => {
            fetchData(true)
          }, 10000)
        }
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      if (isRefresh) {
        clearRequestTimeout()
        requestTimer = setTimeout(() => {
          fetchData(true)
        }, 10000)
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isLoading.value = false
      //isSearching.value = false
    })
}
const handleSizeChange = (val: number) => {
  pageSize.value = val
  currentPage.value = 1
  fetchData()
}
const handleCurrentChange = (val: number) => {
  currentPage.value = val
  fetchData()
}
const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  unmounted()
})
const unmounted = () => {
  abortRequest()
  clearRequestTimeout()
}
/*const searchSubmit = () => {
  isSearching.value = true
  currentPage.value = 1
  fetchData()
}*/

const submitId = ref('')
const redoTask = async (item: VideoTranslateTask) => {
  if (submitId.value) {
    return
  }

  const resolution = item.config.memo.video_resolution

  const seconds = item.config.memo.video_duration / 1000
  const minutes = Math.ceil(seconds / 60)
  const basic = costQuotasMap['video-translation-basic']
  const costDetail: VideoTranslationCostDetail = {
    basic: basic * minutes
  }
  let costQuotas = basic
  if (item.config.voice?.clone?.audio_files.length) {
    const voiceClone = costQuotasMap['video-translation-voice-clone']
    costQuotas += voiceClone
    costDetail.voiceClone = voiceClone * minutes
  }
  if (item.config.lipsync?.enable) {
    const lipSync = costQuotasMap['video-translation-lip-sync']
    costQuotas += lipSync
    costDetail.lipSync = lipSync * minutes
  }
  if (item.config.hd?.enable) {
    const hd = costQuotasMap['video-translation-hd']
    costQuotas += hd
    costDetail.hd = hd * minutes
  }
  costQuotas *= minutes

  if (costQuotas <= 0) {
    doCreateAction(item, costQuotas, costDetail)
    return
  }

  if (projStore.total_remaining < costQuotas) {
    submitId.value = item.id
    await fetchSubscriptionPlanAndCreditDetails(projStore)
    submitId.value = ''
  }

  showVTSubmitConfirm(
    {
      title: t('提交视频翻译任务'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      seconds,
      minutes,
      resolution,
      costDetail,
      feedbackTypes: userStore.feedbackTypes
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true

          doCreateAction(item, costQuotas, costDetail, instance, done)
        } else {
          done()
        }
      }
    }
  )
}
const doCreateAction = (
  item: VideoTranslateTask,
  costQuotas: number,
  costDetail: VideoTranslationCostDetail,
  instance?: MessageBoxState,
  done?: () => void
) => {
  submitId.value = item.id
  retryVideoTranslateTask({
    id: item.id,
    projId: item.projId
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('重新生成异常'))
        return
      }
      ElMessage.success(res.data.message || t('重新生成成功'))
      item.state = res.data.data.state
      item.createTime = res.data.data.createTime
      if (costQuotas > 0) {
        projStore.$patch({
          total_remaining: projStore.total_remaining - costQuotas
        })
        fetchSubscriptionPlanAndCreditDetails(projStore)
      }
      clearRequestTimeout()
      requestTimer = setTimeout(() => {
        fetchData(true)
      }, 10000)
      if (done) {
        done()
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('重新生成失败'))
    })
    .finally(() => {
      if (instance) {
        instance.confirmButtonLoading = false
      }

      submitId.value = ''
    })
}

const detailMap = ref<{
  [key: string]: GeneratedVideo
}>({})
const playLoadingItem = ref<VideoTranslateTask | null>()
const playItem = ref<VideoTranslateTask | null>()
const playVideo = (item: VideoTranslateTask) => {
  if (detailMap.value[item.id]) {
    playItem.value = item
    return
  }
  if (playLoadingItem.value) {
    return
  }
  playLoadingItem.value = item
  getGeneratedVideoList({
    projId: item.projId,
    taskId: item.id,
    currentPage: 1,
    pageSize: 1
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      if (!res.data.data.records[0]) {
        ElMessage.error(`${t('加载异常')}.`)
        return
      }
      const list = res.data.data.records
      detailMap.value[item.id] = list[0]
      playItem.value = item
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      playLoadingItem.value = null
    })
}

const downloadItem = ref<VideoTranslateTask | null>()
const downloadVideo = async (event: MouseEvent, item: VideoTranslateTask) => {
  event.stopPropagation()

  if (downloadItem.value) {
    return
  }

  downloadItem.value = item
  let list: GeneratedVideo[] = []
  try {
    if (detailMap.value[item.id]) {
      list = [detailMap.value[item.id]]
    } else {
      const res = await getGeneratedVideoList({
        projId: item.projId,
        taskId: item.id,
        currentPage: 1,
        pageSize: 1
      })
      list = res.data.data.records || []
    }
  } catch (e) {
    downloadItem.value = null
    const err = e as Error
    ElMessage.error(err.message || t('下载异常'))
    return
  }
  if (!list.length) {
    downloadItem.value = null
    ElMessage.error(t('下载失败'))
    return
  }

  detailMap.value[item.id] = list[0]

  downloadVideoAction(list[0], 0)
}
const downloadVideoAction = (
  item: GeneratedVideo,
  costQuotas: number,
  instance?: MessageBoxState,
  done?: () => void
) => {
  //downloadItem.value = item
  const taskData = downloadItem

  const title = taskData.value?.title || item.resolution
  const downloadFilename = `${title}-${item.addressUrl.split('/').slice(-1).join('').split('?')[0]}`

  genVideoDownloadUrl({
    id: item.id,
    projId: item.projId,
    taskId: item.taskId,
    downloadFilename
  })
    .then((res) => {
      if (res.data.code) {
        if (res.data.errorCode === 'TOO_MANY_REQUEST_FOR_DOWNLOAD') {
          ElMessage.error(res.data.message)
          return
        }
        //videoDownload(item.addressUrl, downloadFilename)
        return
      }
      const url = item.addressUrl
      const isCloudfront =
        url.includes('.cloudfront.') ||
        url.includes('res.vmeg.pro') ||
        url.includes('cdn.gthree.cn')
      if (isCloudfront) {
        videoDownload(url, downloadFilename, true)
      } else {
        videoDownload(res.data.data.addressUrl, downloadFilename, true)
      }
      item.downloadCount += 1
      projStore.$patch({
        total_remaining: projStore.total_remaining - costQuotas
      })

      if (done) {
        done()
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      //videoDownload(item.addressUrl, downloadFilename)
    })
    .finally(() => {
      if (instance) {
        instance.confirmButtonLoading = false
      }

      setTimeout(() => {
        downloadItem.value = null

        fetchSubscriptionPlanAndCreditDetails(projStore)
      }, 2000)
    })
}

const getLanguageTitle = (value: string) => {
  let lang = ''
  getLanguages('video-translation').some((language) => {
    if (language.value === value) {
      lang = language.label()
    }
    return !!lang
  })
  return lang || value
}
</script>

<template>
  <el-breadcrumb class="g-breadcrumb" :separator-icon="ArrowRight">
    <el-breadcrumb-item
      :to="{ name: 'admin.user-projects' }"
      v-if="userStore.roles?.includes('admin')"
    >
      管理员-用户项目
    </el-breadcrumb-item>
    <el-breadcrumb-item>{{ t('我的任务') }}</el-breadcrumb-item>
  </el-breadcrumb>

  <div class="task-list" v-loading="isLoading">
    <div class="task-list-item" v-for="item in dataList" :key="item.id">
      <div
        class="task-list-item-imgs"
        :class="{
          'is-single': thumbnailsMax < 2,
          'is-canceled': item.state == 'canceled',
          'is-failed': item.state == 'failed',
          'is-finished': item.state == 'finished'
        }"
      >
        <div class="thumbnail" v-if="playItem?.id == item.id && detailMap[item.id]">
          <video
            autoplay
            preload="none"
            controls
            playsinline
            controlsList="nodownload"
            oncontextmenu="return false;"
            :src="detailMap[item.id].addressUrl"
            :poster="detailMap[item.id].thumbnailUrl"
          ></video>
        </div>
        <template v-for="(url, index) in item.thumbnailUrls" :key="index" v-else>
          <el-image class="thumbnail" fit="cover" :src="url" v-if="url">
            <template #error>
              <el-icon><Picture /></el-icon>
            </template>
          </el-image>
          <el-image class="thumbnail" v-else>
            <template #error>
              <el-icon><IconVideo /></el-icon>
            </template>
          </el-image>
        </template>
        <div
          class="play-video"
          @click="playVideo(item)"
          v-if="
            item.state == 'finished' &&
            (!playLoadingItem || playLoadingItem.id != item.id) &&
            playItem?.id != item.id
          "
        >
          <el-icon><IconVideoPlay /></el-icon>
        </div>
        <el-icon class="is-loading" v-if="playLoadingItem?.id == item.id"><Loading /></el-icon>

        <div class="state is-pending" v-if="item.state == 'pending'">
          <el-icon><IconClock /></el-icon>
          {{ t('等待中') }}
        </div>
        <div class="state is-running" v-if="item.state == 'running'">
          <el-icon><IconStateRunning /></el-icon>
          {{ t('运行中') }}
        </div>
        <div class="state is-failed" v-if="['canceled', 'failed'].includes(item.state)">
          <el-icon><IconCircleClose /></el-icon>
          {{ item.state == 'canceled' ? t('已取消') : t('已失败') }}
        </div>
      </div>

      <div class="task-list-item-info">
        <div class="left">
          <div class="title">{{ item.title }}</div>
          <div class="time">
            <div>
              {{
                new Date(
                  (item.createTime || '').replace(' ', 'T').split('.')[0] + '+00:00'
                ).toLocaleString()
              }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="language">{{ getLanguageTitle(item.config.language.target) }}</div>
          <div class="info" v-if="!['pending', 'canceled', 'failed'].includes(item.state)">
            <div class="actions">
              <div
                class="download"
                :class="{ disabled: downloadItem }"
                v-if="item.state == 'finished' && !userStore.roles?.includes('admin')"
                @click="downloadVideo($event, item)"
              >
                <el-icon v-if="!downloadItem || downloadItem.id != item.id">
                  <IconDownload />
                </el-icon>
                <el-icon class="is-loading" v-if="downloadItem?.id == item.id"><Loading /></el-icon>
              </div>
              <el-dropdown style="display: none">
                <el-icon><IconMore /></el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>{{ t('编辑') }}</el-dropdown-item>
                    <el-dropdown-item>{{ t('重新翻译') }}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
          <div class="button" v-if="['canceled', 'failed'].includes(item.state)">
            <el-button
              type="primary"
              size="small"
              :loading="submitId == item.id"
              :disabled="!!submitId && submitId != item.id"
              @click="redoTask(item)"
            >
              {{ t('重新生成') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-empty class="g-empty" v-if="!isLoading && !dataList.length">
      <template #image>
        <el-icon><IconEmpty /></el-icon>
      </template>
    </el-empty>
  </div>

  <el-pagination
    class="g-pagination"
    :background="background"
    v-model:page-size="pageSize"
    :total="total"
    :pager-count="pagerCount"
    v-model:current-page="currentPage"
    :layout="layout"
    :page-sizes="pageSizes"
    :disabled="isLoading"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    v-if="total > pageSizes[0]"
  />
</template>

<style scoped lang="less">
.task-list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  .task-list-item {
    .task-list-item-imgs {
      --my-thumbnail-size: 127.5px;
      --my-thumbnail-gap: 1px;

      width: calc(var(--my-thumbnail-size) * 2 + var(--my-thumbnail-gap));
      height: calc(var(--my-thumbnail-size) * 2 + var(--my-thumbnail-gap));
      background: var(--my-color-x7);
      border-radius: 8px;
      overflow: hidden;
      //cursor: pointer;

      display: flex;
      flex-wrap: wrap;
      gap: var(--my-thumbnail-gap);

      position: relative;

      .el-icon.is-loading,
      .play-video {
        --my-video-icon-size: 50px;

        position: absolute;
        top: calc((100% - var(--my-video-icon-size)) / 2);
        left: calc((100% - var(--my-video-icon-size)) / 2);
        font-size: var(--my-video-icon-size);
        border-radius: var(--my-video-icon-size);
        color: var(--my-color-black-40);
      }

      .play-video {
        display: flex;
        cursor: pointer;
        background: var(--my-color-white-60);

        &:hover {
          color: var(--el-color-primary);
        }
      }

      .thumbnail {
        width: var(--my-thumbnail-size);
        height: var(--my-thumbnail-size);
        background: var(--my-color-x9);

        font-size: 32px;
        color: var(--my-color-primary-12);

        display: flex;
        align-items: center;
        justify-content: center;

        video {
          width: 100%;
          height: 100%;
        }
      }

      .state {
        position: absolute;
        bottom: 8px;
        right: 8px;
        padding: 2px 8px 2px 4px;
        border-radius: 30px;
        background: var(--my-color-white-89-90);

        display: flex;
        align-items: center;
        gap: 4px;

        font-size: 12px;
        line-height: 16px;
        color: var(--my-color-black-60);

        &.is-running,
        &.is-pending {
          color: var(--el-color-primary);
        }

        .el-icon {
          font-size: 16px;
        }
      }

      &.is-single,
      &.is-canceled,
      &.is-failed,
      &.is-finished {
        --my-thumbnail-size: 128px;
        --my-thumbnail-gap: 0px;
      }

      &.is-canceled,
      &.is-failed {
        .thumbnail {
          color: var(--my-color-black-10);
          background: var(--my-color-x8);
        }
      }

      &.is-single,
      &.is-canceled,
      &.is-failed {
        .thumbnail {
          font-size: 44px;
          width: 100%;
          height: 100%;

          &:not(:first-child) {
            display: none;
          }
        }
      }
    }

    .task-list-item-info {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;

      .left {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 156px;
        overflow: hidden;

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: var(--my-color-black-89-90);

          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        .time {
          display: flex;
          justify-content: space-between;

          font-size: 12px;
          line-height: 16px;
          color: var(--my-color-black-60);
        }
      }

      .right {
        .language {
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          color: var(--my-color-black-60);
          text-transform: capitalize;
        }

        .info {
          font-size: 12px;
          line-height: 16px;
          color: var(--my-color-black-60);
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 3px;

          .el-icon {
            font-size: 16px;
            color: var(--my-color-black-40);
          }

          height: 100%;

          .actions {
            display: flex;
            gap: 12px;

            .download {
              &.disabled {
                cursor: not-allowed;
              }
            }

            .el-icon {
              font-size: 18px;
              color: var(--my-color-black-60);
              cursor: pointer;
              outline: none;
            }

            .el-dropdown {
              outline: none;
            }
          }
        }

        .button {
          height: 100%;
          display: flex;
          align-items: center;

          .el-button {
            padding: 0 8px;
            margin-top: -14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .task-list {
    --my-gap: 12px;
    gap: var(--my-gap);

    .task-list-item {
      .task-list-item-imgs {
        --my-thumbnail-size: calc(
          (100vw - var(--my-gap) - (var(--el-main-padding) + var(--my-thumbnail-gap)) * 2) / 4
        );

        .thumbnail {
          font-size: 18px;
        }

        &.is-single,
        &.is-canceled,
        &.is-failed,
        &.is-finished {
          --my-thumbnail-size: calc((100vw - var(--my-gap) - var(--el-main-padding) * 2) / 4);
        }

        &.is-canceled,
        &.is-failed {
          .thumbnail {
            font-size: 38px;
          }
        }
      }

      .task-list-item-info {
        flex-direction: column;
        gap: 8px;
        padding: 8px 0 4px;

        .left {
          width: 165px;

          .title {
            line-height: 22px;
          }
        }

        .right {
          --my-info-height: 32px;

          .info {
            height: var(--my-info-height);
          }

          .button {
            .el-button {
              --el-button-size: var(--my-info-height);
              padding: 0 12px;
            }
          }
        }
      }
    }
  }
}
</style>
