// TODO：有时间改造成vue组件
import { h, type VNode } from 'vue'
import i18n from '@/locales/i18n'
import type { VideoTranslationCostDetail } from '@/api'
import FeedbackGuideInfo from '@/components/feedback/FeedbackGuideInfo/FeedbackGuideInfo.vue'
import { ElMessageBox } from 'element-plus'
import type { ElMessageBoxOptions } from 'element-plus/es/components/message-box/src/message-box.type'
import router from '@/router'

const t = i18n.global.t
const surveyBtnOpts: ElMessageBoxOptions = {
  showCancelButton: false,
  showConfirmButton: false
}

const neverSubmitFeedback: (feedbackTypes?: string[]) => boolean = (feedbackTypes = []) =>
  !feedbackTypes?.length

export const generateFeedbackGuideVNode: (
  onFeedbackClose?: (type: 'close' | 'done') => void
) => VNode = (onFeedbackClose) => {
  return h(FeedbackGuideInfo, {
    onCloseFeedbackModal: onFeedbackClose
  })
}

export interface ConfirmMessage {
  title: string
  costQuotas: number
  totalRemaining: number
  feedbackTypes?: string[]
}
export const generateConfirmMessage: (args: ConfirmMessage) => VNode = ({
  title,
  costQuotas,
  totalRemaining,
  feedbackTypes
}) => {
  const expandRemaining = totalRemaining < costQuotas

  const klass = expandRemaining ? ' g-confirm-warning' : ''

  const messageVNode = h(
    'div',
    { class: 'g-confirm-info' },
    t('此操作将消耗您的积分。请在继续之前确认以下详细信息。')
  )

  const detailsVNode = h('div', { class: 'g-confirm-details' }, [
    h('table', [
      h('tr', [h('td', t('操作')), h('th', title)]),
      h('tr', [h('td', t('消费积分')), h('th', costQuotas)]),
      h('tr', [h('td', t('剩余积分')), h('th', { class: klass }, totalRemaining)])
    ])
  ])

  const nodes = [messageVNode, detailsVNode]
  if (expandRemaining && neverSubmitFeedback(feedbackTypes)) {
    nodes.push(generateFeedbackGuideVNode())
  }

  return h('div', nodes)
}

// vt任务的信息要复杂一些，单独维护
export interface VTConfirmMessage {
  title: string
  costQuotas: number
  totalRemaining: number
  seconds: number
  minutes: number
  resolution: string
  costDetail: VideoTranslationCostDetail
  feedbackTypes?: string[]
}
export const generateVTConfirmMessage: (args: VTConfirmMessage) => VNode = ({
  title,
  costQuotas,
  totalRemaining,
  seconds,
  minutes,
  resolution,
  costDetail,
  feedbackTypes
}) => {
  const expandRemaining = totalRemaining < costQuotas

  const detailVNode: VNode[] = []
  const titleMap = {
    basic: '基础视频翻译',
    voiceClone: '声音克隆',
    lipSync: '唇型同步',
    hd: '增强人脸视频'
  }

  // 生成费用详情的 VNode
  Object.keys(costDetail).forEach((k) => {
    const key = k as keyof VideoTranslationCostDetail
    detailVNode.push(
      h('tr', { key }, [
        h('td', t(titleMap[key])),
        h('th', costDetail[key] !== 1 ? `${costDetail[key]} ${t('积分值')}` : t('1积分'))
      ])
    )
  })

  const klass = expandRemaining ? ' g-confirm-warning' : ''

  const messageVNode = h(
    'div',
    { class: 'g-confirm-info' },
    t('此操作将消耗您的积分。请在继续之前确认以下详细信息。')
  )

  const originalVideoVNode = h('div', { class: 'g-confirm-info' }, [
    h('div', t('原始视频')),
    h('div', [t('视频分辨率') + ': ' + resolution]),
    h('div', [
      t('视频时长') +
        ': ' +
        seconds +
        t('time.symbol.second', 's') +
        (import.meta.env.DEV
          ? ` (${t('消耗')} ${minutes !== 1 ? minutes + ' ' + t('分钟') : t('1分钟')})`
          : '')
    ])
  ])

  const detailsVNode = h('div', { class: 'g-confirm-details' }, [
    h('table', [
      h('tr', [h('td', t('操作')), h('th', t(title))]),
      h('tr', [
        h('td', t('过程')),
        h('th', h('table', detailVNode)) // detail should be a VNode or valid content
      ]),
      h('tr', [h('td', t('消费积分')), h('th', costQuotas)]),
      h('tr', [h('td', t('剩余积分')), h('th', { class: klass }, totalRemaining)])
    ])
  ])

  const nodes = [messageVNode, originalVideoVNode, detailsVNode]

  if (expandRemaining && neverSubmitFeedback(feedbackTypes)) {
    nodes.push(generateFeedbackGuideVNode())
  }

  return h('div', nodes)
}

export const generateFreeUserGuideMessage = (feedbackTypes: string[]) => {
  return h('div', [
    h('text', t('您是免费用户，不用担心！升级您的计划以解锁更多功能和优势。')),
    neverSubmitFeedback(feedbackTypes) && generateFeedbackGuideVNode()
  ])
}

export const showFreeUserGuide = (feedbackTypes: string[] = []) => {
  ElMessageBox.confirm(generateFreeUserGuideMessage(feedbackTypes), t('解锁更多功能和积分'), {
    confirmButtonText: t('升级'),
    ...surveyBtnOpts
  }).catch(() => {})

  return
}

export const showCommonConfirm = (
  message: VNode,
  messageConfig: ConfirmMessage,
  confirmOptions: ElMessageBoxOptions
) => {
  const { totalRemaining, costQuotas, feedbackTypes } = messageConfig

  if (totalRemaining < costQuotas) {
    const opts: ElMessageBoxOptions = {
      confirmButtonText: t('升级'),
      dangerouslyUseHTMLString: true,
      distinguishCancelAndClose: true
    }

    // 如果没有填过问卷，会多一个按钮引导，虽然message里也有，但是不够明显
    if (neverSubmitFeedback(feedbackTypes)) {
      ElMessageBox.confirm(message, t('确认提交'), {
        ...opts,
        ...surveyBtnOpts
      }).catch(() => {})
    } else {
      ElMessageBox.confirm(message, t('确认提交'), {
        ...opts
      })
        .then(() => {
          router.push({ name: 'pricing' })
        })
        .catch(() => {})
    }
    return
  }

  ElMessageBox.confirm(message, t('确认提交'), {
    confirmButtonText: t('确认'),
    dangerouslyUseHTMLString: true,
    ...confirmOptions
  }).catch(() => {})
}

export const showSubmitConfirm = (
  messageConfig: ConfirmMessage,
  confirmOptions: ElMessageBoxOptions
) => {
  const message = generateConfirmMessage(messageConfig)

  showCommonConfirm(message, messageConfig, confirmOptions)
}

export const showVTSubmitConfirm = (
  messageConfig: VTConfirmMessage,
  confirmOptions: ElMessageBoxOptions
) => {
  const message = generateVTConfirmMessage(messageConfig)

  showCommonConfirm(message, messageConfig, confirmOptions)
}
