<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.375 10.125C2.75372 10.125 2.25 9.62131 2.25 9C2.25 8.37869 2.75372 7.875 3.375 7.875C3.99628 7.875 4.5 8.37869 4.5 9C4.5 9.62131 3.99628 10.125 3.375 10.125Z"
      fill="currentColor"
    />
    <path
      d="M7.875 9C7.875 9.62131 8.37872 10.125 9 10.125C9.62128 10.125 10.125 9.62131 10.125 9C10.125 8.37869 9.62128 7.875 9 7.875C8.37872 7.875 7.875 8.37869 7.875 9Z"
      fill="currentColor"
    />
    <path
      d="M13.5 9C13.5 9.62131 14.0037 10.125 14.625 10.125C15.2463 10.125 15.75 9.62131 15.75 9C15.75 8.37869 15.2463 7.875 14.625 7.875C14.0037 7.875 13.5 8.37869 13.5 9Z"
      fill="currentColor"
    />
  </svg>
</template>
