<template>
  <svg
    width="240"
    height="200"
    viewBox="0 0 240 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="238"
      height="198"
      rx="15"
      stroke="#7B4DFF"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <rect x="10" y="10" width="220" height="180" rx="12" fill="#7B4DFF" fill-opacity="0.1" />
    <path
      d="M141 74.8039C145 77.1133 145 82.8868 141 85.1962L109.5 103.383C105.5 105.692 100.5 102.805 100.5 98.1865L100.5 61.8135C100.5 57.1947 105.5 54.3079 109.5 56.6173L141 74.8039Z"
      fill="#7B4DFF"
      fill-opacity="0.18"
    />
    <rect x="10" y="138" width="220" height="52" rx="12" fill="#7B4DFF" fill-opacity="0.1" />
    <g>
      <path d="M20 162.5V165.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M30 161V167" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M40 156V172" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M50 161V167" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M60 162V166" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M70 160V168" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M80 158.5V169.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M90 155.5V172.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M100 149V179" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M110 154.5L110 173.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M120 157V171" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M130 159V169" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M140 160.5V167.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M150 162V166" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M160 160.5V167.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M170 157V171" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M180 152V176" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M190 158.5V169.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M200 161.5V166.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M210 158.5V169.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
      <path d="M220 161.5V166.5" stroke="#7B4DFF" stroke-width="3" stroke-linecap="round" />
    </g>
  </svg>
</template>

<style scoped lang="less">
g {
  > :nth-child(n) {
    animation: loading-wave 2s linear infinite;
  }

  > :nth-child(2n) {
    animation: loading-wave 2s linear infinite;
    animation-delay: 0.5s;
  }

  > :nth-child(3n) {
    animation: loading-wave 2s linear infinite;
    animation-delay: 1s;
  }

  @keyframes loading-wave {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
</style>
