import { shallowRef } from 'vue'
import type { Component } from 'vue'
import type { RouteRecordRaw, RouteParamsRaw, RouteRecordName } from 'vue-router'

/*import {
  VideoCamera as IconVideoCamera,
  DocumentChecked as IconDocumentChecked
} from '@element-plus/icons-vue'*/

import IconVideoCamera from '@/components/icons/IconVideoCamera.vue'
import IconDocumentChecked from '@/components/icons/IconDocumentChecked.vue'
import IconTranslation from '@/components/icons/IconTranslation.vue'

import type { UserStore } from '@/stores/user'
import type { ProjStore } from '@/stores/proj'

import i18n from '@/locales/i18n'
const { t } = i18n.global

export const config = {
  siteTitle: 'VMEG',
  copyright: '',
  company: 'PixRipple Technology Limited',
  email: 'info@pixripple.com',
  baseUrl: 'https://vmeg.pro',
  useJwtInHeader: false, // false: use cookie
  saveSignedInfoToLocalStorage: false,
  useHashHistory: true,
  signedInRedirectRoute: '/video-translation/-'
}
if (!import.meta.env.SSR) {
  if (
    ['www.vmeg.pro', 'test.vmeg.pro', 'dev.vmeg.pro', 'app.gthree.cn', 'vmeg.gthree.cn'].includes(
      location.hostname
    )
  ) {
    config.baseUrl = `//${location.hostname}`
  } else if (
    ['localhost'].includes(location.hostname) ||
    location.hostname.startsWith('192.168.')
  ) {
    config.baseUrl = `http://${location.hostname}:8090`
  }
}

export const costQuotasMap = {
  'clips-to-videos': 3,
  'video-translation-basic': 1, // 1分钟
  'video-translation-lip-sync': 1, // 1分钟
  'video-translation-hd': 1, // 1分钟
  'video-translation-voice-clone': 2, // 1分钟
  'translate-text': 0.1,
  'translate-script': 0.5,
  'download-video': 0.5
}

export const isAppPages = () => {
  return (
    location.hash !== '#/' &&
    !location.href.includes('/pricing') &&
    !location.href.includes('/signin') &&
    !location.href.includes('/signup') &&
    !location.href.includes('/terms-of-service') &&
    !location.href.includes('/privacy-policy')
  )
}

let sourceQuery = ''
if (!import.meta.env.SSR) {
  sourceQuery = location.search
  const url = new URL(location.href)
  const utmSource = url.searchParams.get('utm_source')
  if (utmSource) {
    if (
      ['www.vmeg.pro', 'vmeg.pro', 'localhost'].includes(url.hostname) &&
      navigator.language.startsWith('zh')
    ) {
      url.protocol = 'https:'
      url.hostname = 'vmeg.gthree.cn'
      url.port = ''
      location.replace(url)
    }
  }
}

export const urls = {
  terms_of_service: {
    target: '_self',
    url: config.useHashHistory
      ? `/${sourceQuery}#/terms-of-service`
      : `/terms-of-service${sourceQuery}`
  },
  privacy_policy: {
    target: '_self',
    url: config.useHashHistory ? `/${sourceQuery}#/privacy-policy` : `/privacy-policy${sourceQuery}`
  }
}

export type Menu = {
  title: () => string
  auth: (userStore: UserStore) => boolean
  name: string
  params: RouteParamsRaw
  path: string
  icon: Component | null
  routes: string[]
}

const initMenus = (projStore: ProjStore) => {
  const menus: Menu[] = [
    // clips-to-videos
    {
      title: () => t('创建视频'),
      auth: (userStore: UserStore) => !userStore.roles?.includes('admin'),
      name: 'vmeg.create-video',
      params: {
        projId: projStore.projId || '-'
      },
      path: '/create-video/:projId',
      icon: shallowRef(IconVideoCamera),
      routes: ['vmeg.create-video', 'vmeg.my-tasks', 'vmeg.my-tasks.details']
    },
    {
      title: () => t('我的任务'),
      auth: () => true,
      name: 'vmeg.my-tasks',
      params: {
        projId: projStore.projId || '-'
      },
      path: '/my-tasks/:projId',
      icon: shallowRef(IconDocumentChecked),
      routes: ['vmeg.create-video', 'vmeg.my-tasks', 'vmeg.my-tasks.details']
    },
    // video-translation
    {
      title: () => t('视频翻译'),
      auth: (userStore: UserStore) => !userStore.roles?.includes('admin'),
      name: 'vmeg.video-translation',
      params: {
        projId: projStore.projId || '-'
      },
      path: '/video-translation/:projId',
      icon: shallowRef(IconTranslation),
      routes: ['vmeg.video-translation', 'vmeg.video-translation.my-tasks']
    },
    {
      title: () => t('我的任务'),
      auth: () => true,
      name: 'vmeg.video-translation.my-tasks',
      params: {
        projId: projStore.projId || '-'
      },
      path: '/video-translation-tasks/:projId',
      icon: shallowRef(IconDocumentChecked),
      routes: ['vmeg.video-translation', 'vmeg.video-translation.my-tasks']
    },
    // subscription-plan
    {
      title: () => t('订阅计划'),
      auth: () => true,
      name: 'vmeg.subscription-plan',
      params: {
        projId: projStore.projId || '-'
      },
      path: '/subscription-plan/:projId',
      icon: null,
      routes: ['vmeg.subscription-plan', 'vmeg.credit-details']
    },
    {
      title: () => t('积分详情'),
      auth: () => true,
      name: 'vmeg.credit-details',
      params: {
        projId: projStore.projId || '-'
      },
      path: '/credit-details/:projId',
      icon: null,
      routes: ['vmeg.subscription-plan', 'vmeg.credit-details']
    },
    // others
    {
      title: () => t('首页'),
      auth: () => true,
      name: 'home',
      params: {
        projId: projStore.projId || '-'
      },
      path: '/',
      icon: null,
      routes: ['signin', 'signup', 'error', 'terms-of-service', 'privacy-policy']
    }
  ]
  return menus
}

export type HomeMenu = {
  title: () => string
  flag?: () => string
  url: string
  target: string
  params?: RouteParamsRaw
  pages?: string[] // 点亮
  routes?: string[] // 显示
  subMenus?: HomeMenu[]
}

const initHomeMenus = (projStore: ProjStore, userStore: UserStore) => {
  const isAdmin = userStore.roles?.includes('admin')
  const menus: HomeMenu[] = [
    {
      title: () => '',
      url: '',
      target: '',
      pages: ['clips-to-videos', 'video-translation', 'subscription-plan', 'user-profile'],
      routes: [
        // clips-to-videos
        'vmeg.create-video',
        'vmeg.my-tasks',
        'vmeg.my-tasks.details',

        // video-translation
        'vmeg.video-translation',
        'vmeg.video-translation.my-tasks',

        // subscription-plan
        'vmeg.subscription-plan',
        'vmeg.credit-details',

        // user-profile
        'user'
      ],
      subMenus: [
        {
          title: () => t('切片到视频'),
          url: config.useHashHistory
            ? `/${sourceQuery}#/${isAdmin ? 'my-tasks' : 'create-video'}/:projId`
            : `/${isAdmin ? 'my-tasks' : 'create-video'}/:projId${sourceQuery}`,
          params: {
            projId: projStore.projId || '-'
          },
          target: '_self',
          pages: ['vmeg.create-video', 'vmeg.my-tasks', 'vmeg.my-tasks.details']
        },
        {
          title: () => t('视频翻译'),
          url: config.useHashHistory
            ? `/${sourceQuery}#/${isAdmin ? 'video-translation-tasks' : 'video-translation'}/:projId`
            : `/${isAdmin ? 'video-translation-tasks' : 'video-translation'}/:projId${sourceQuery}`,
          params: {
            projId: projStore.projId || '-'
          },
          target: '_self',
          pages: ['vmeg.video-translation', 'vmeg.video-translation.my-tasks']
        }
      ]
    },
    {
      title: () => t('产品'),
      url: '',
      target: '',
      subMenus: [
        {
          title: () => t('切片到视频'),
          url: '/clips-to-videos/',
          target: '_self'
        },
        {
          title: () => t('视频翻译'),
          url: '/video-translation/',
          target: '_self'
          /*},
        {
          title: () => t('长视频转短视频'),
          flag: () => t('敬请期待'),
          url: '',
          target: '_self'
        },
        {
          title: () => t('产品URL到视频'),
          flag: () => t('敬请期待'),
          url: '',
          target: '_self'
        },
        {
          title: () => t('图片转视频'),
          flag: () => t('敬请期待'),
          url: '',
          target: '_self'
        },
        {
          title: () => t('脚本到视频'),
          flag: () => t('敬请期待'),
          url: '',
          target: '_self'
        },
        {
          title: () => t('头像'),
          flag: () => t('敬请期待'),
          url: '',
          target: '_self'*/
        }
      ]
    },
    {
      title: () => t('频道'),
      url: '',
      target: '',
      subMenus: [
        {
          title: () => 'Instagram',
          url: '/channel/instagram/',
          target: '_self'
        },
        {
          title: () => 'TikTok',
          url: '/channel/tiktok/',
          target: '_self'
        },
        {
          title: () => 'Facebook',
          url: '/channel/facebook/',
          target: '_self'
        },
        {
          title: () => 'YouTube',
          url: '/channel/youtube/',
          target: '_self'
        }
      ]
    },
    {
      title: () => t('定价'),
      url: config.useHashHistory ? `/${sourceQuery}#/pricing` : `/pricing${sourceQuery}`,
      target: '_self'
    },
    {
      title: () => t('博客'),
      url: '/blog/',
      target: '_self'
    },
    /*{
      title: () => t('功能'),
      url: '',
      target: '_self'
    },
    {
      title: () => t('解决方案'),
      url: '',
      target: '_self'
    },*/
    {
      title: () => t('支持'),
      url: '',
      target: '',
      subMenus: [
        {
          title: () => t('隐私政策'),
          url: '/pixripple-privacy-policy/',
          target: '_self'
        },
        {
          title: () => t('服务条款'),
          url: '/pixripple-terms-of-service/',
          target: '_self'
        }
      ]
    }
  ]
  return menus
}

export const keepAliveViews: string[] = []

export const setMenuPath = (routes: RouteRecordRaw[]) => {
  routes.forEach((route: RouteRecordRaw) => {
    if (route.meta?.keepAliveView) {
      keepAliveViews.push(route.meta.keepAliveView as string)
    }
  })
}

export const getMenus = (
  userStore: UserStore,
  projStore: ProjStore,
  name: RouteRecordName | null | undefined
) => {
  const list: Menu[] = []
  initMenus(projStore).forEach((menu: Menu) => {
    if (menu.routes.includes(String(name)) && menu.auth(userStore)) {
      menu.path = menu.path.replace(/\/:(\w+)/g, (pattern: string, match: string) => {
        return `/${menu.params[match]}`
      })
      list.push(menu)
    }
  })
  const map: Record<string, Menu> = list.reduce((result: Record<string, Menu>, menu: Menu) => {
    result[menu.name] = menu
    return result
  }, {})
  return {
    list,
    map
  }
}

export const getHomeMenus = (
  userStore: UserStore,
  projStore: ProjStore,
  name: RouteRecordName | null | undefined
) => {
  const list: HomeMenu[] = []
  const menus = initHomeMenus(projStore, userStore)
  menus.forEach((homeMenu: HomeMenu) => {
    if (homeMenu.routes?.includes(String(name)) && homeMenu.subMenus) {
      list.push(
        ...homeMenu.subMenus.map((menu) => {
          if (menu.params) {
            menu.url = menu.url.replace(/\/:(\w+)/g, (pattern: string, match: string) => {
              return menu.params ? `/${menu.params[match]}` : match
            })
          }
          if (!menu.routes) {
            menu.routes = homeMenu.routes || []
          }
          return menu
        })
      )
    }
  })
  if (!list.length && ['home', 'pricing'].includes(String(name))) {
    list.push(
      ...menus.filter((menu) => {
        return !menu.routes
      })
    )
  }
  return {
    list
  }
}
