<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#7B4DFF" fill-opacity="0.15" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.7778 14.6668H16.2222C15.3631 14.6668 14.6666 15.3632 14.6666 16.2223V31.7779C14.6666 32.637 15.3631 33.3334 16.2222 33.3334H31.7778C32.6369 33.3334 33.3333 32.637 33.3333 31.7779V16.2223C33.3333 15.3632 32.6369 14.6668 31.7778 14.6668ZM16.2222 13.1112C14.504 13.1112 13.1111 14.5041 13.1111 16.2223V31.7779C13.1111 33.4961 14.504 34.889 16.2222 34.889H31.7778C33.496 34.889 34.8889 33.4961 34.8889 31.7779V16.2223C34.8889 14.5041 33.496 13.1112 31.7778 13.1112H16.2222ZM17 30.2223C17 29.7928 17.3482 29.4445 17.7778 29.4445H30.2222C30.6518 29.4445 31 29.7928 31 30.2223C31 30.6519 30.6518 31.0001 30.2222 31.0001H17.7778C17.3482 31.0001 17 30.6519 17 30.2223ZM17.7778 25.5557C17.3482 25.5557 17 25.9039 17 26.3334C17 26.763 17.3482 27.1112 17.7778 27.1112H30.2222C30.6518 27.1112 31 26.763 31 26.3334C31 25.9039 30.6518 25.5557 30.2222 25.5557H17.7778Z"
      fill="#7B4DFF"
    />
    <path
      d="M21.0908 19.3863C21.3481 19.5364 21.3481 19.9081 21.0908 20.0581L17.5848 22.1033C17.3256 22.2545 17 22.0675 17 21.7674L17 17.6771C17 17.3769 17.3256 17.1899 17.5848 17.3412L21.0908 19.3863Z"
      fill="#7B4DFF"
    />
  </svg>
</template>
