import zh from './zh'

const en: {
  [key: string]: string | object
} = {}
for (const [key, value] of Object.entries(zh)) {
  if (typeof value === 'object') {
    continue
  }
  en[key] = key
}

export default Object.assign(en, {
  time: {
    symbol: {
      second: 's'
    }
  },
  pricing: {
    faqs: [
      {
        question: 'Is VMEG free to try out？',
        answer:
          "Absolutely, VMEG offers a free plan for users to test out our product. However, to unlock higher credit limits and premium features, you'll need to upgrade to a paid subscription."
      },
      {
        question: 'Do unused credits carry over to the next billing cycle?',
        answer: 'Unfortunately, any unused credits do not carry forward to the next billing period.'
      },
      {
        question: 'How does a monthly subscription differ from an annual subscription?',
        answer:
          'A monthly subscription automatically renews each month, while an annual subscription renews once a year (from the purchase date). Opting for an annual subscription is more cost-effective over a 12-month period compared to monthly payments.'
      },
      {
        question: 'What is the process for switching between plans?',
        answer:
          'You can easily switch plans within your account interface. Once you make the switch, the new plan takes effect immediately. Any previously acquired credits will remain valid and expire based on your original billing cycle.'
      },
      {
        question: 'Can I cancel my subscription at any time?',
        answer:
          "Of course, you can cancel your subscription at any time. Upon cancellation, your existing credits will be available until the end of the current billing cycle. The subscription won't renew unless you reactivate it."
      },
      {
        question: 'How is the video translation service priced?',
        answer:
          "The video translation service is priced based on 1-minute increments, so even if your video is less than 1 minute, you'll still be charged for a full minute."
      }
    ]
  }
}) as typeof zh
