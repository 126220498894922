export const localesMap = {
  //'en-US': 'English (United States)',
  //'zh-CN': '中文（简体）',
  en: 'English',
  zh: '中文'
}

export const config = {
  disabled: import.meta.env.VITE_DOMAIN !== 'gthree.cn', // 正式环境暂时禁用多语言支持
  localeCookieName: 'locale',
  localesMap,
  locales: Object.keys(localesMap)
}
