<script setup lang="ts">
import { onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { getSignedInfo } from '@/api'
import { config, urls } from '@/config'
import router from '@/router'
import { useUserStore, updateUserStore } from '@/stores/user'
import { fetchSubscriptionPlanAndCreditDetails, updateProjData, useProjStore } from '@/stores/proj'

import SignInItems from '@/components/SignInItems.vue'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()

let signinErrCount = 0
let requestTask: AbortController | null = null
let requestTimer: ReturnType<typeof setTimeout> | null = null

const signinUrl = ref('')
const signinMessage = ref('')
const signinQrCodeBase64 = ref('')
const signinDialogVisible = ref(false)
const signinIframeDialogVisible = ref(false)

onUnmounted(() => {
  stopCheckSignin()
})

const startCheckSignin = () => {
  clearRequestTimeout()
  requestTimer = setTimeout(doCheckSignin, 3000)
}

// TODO: doCheckSignin

const doCheckSignin = () => {
  const controller = new AbortController()
  requestTask = controller
  getSignedInfo(
    {},
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (!res.data.data?.user) {
        startCheckSignin()
        return
      }

      const { email, phone } = res.data.data.user

      if (!email && !phone) {
        router.push({ name: 'user', params: { from: 'email' } })
        return
      }

      updateUserStore(userStore, res.data.data)
      updateProjData(projStore, res.data.data.projectInfo)

      fetchSubscriptionPlanAndCreditDetails(projStore)

      if (route.params.from && route.params.from !== '-') {
        router.push({ path: String(route.params.from) })
        return
      }

      router.push({ name: 'home' })
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      signinErrCount++
      if (signinErrCount < 3) {
        startCheckSignin()
        return
      }
      signinMessage.value = t('登录状态查询失败')
    })
}

const clearRequestTimeout = () => {
  if (requestTimer) {
    clearTimeout(requestTimer)
    requestTimer = null
  }
}

const stopCheckSignin = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
  clearRequestTimeout()
}

const checkAgree = () => {
  return true
}

const signInWithTarget = (event: MouseEvent, type: 'email' | 'phone') => {
  if (type === 'email') {
    router.push({ name: 'signup', params: { from: route.params.from || '-' } })
  } else {
    router.push({ name: 'signup', params: { from: `phone${route.params.from || ''}` } })
  }
}
</script>

<template>
  <div class="signin-page">
    <div class="signin-wrapper">
      <div class="title">{{ t('欢迎使用{object}', { object: config.siteTitle }) }}</div>
      <div class="slogan">{{ t('无限AI视频，释放营销想象') }}</div>
      <div class="signin-body">
        <SignInItems
          showSignInWithEmail
          x-showSignInWithPhone
          :clickItem="signInWithTarget"
          :checkAgree="checkAgree"
          :redirectUri="String(route.params.from || '')"
        />
        <div
          class="agree"
          v-html="
            t('登录即表示您接受{terms_of_service}和{privacy_policy}。', {
              terms_of_service: `<a class='el-link is-underline' target='${urls.terms_of_service.target}' href='${urls.terms_of_service.url}'>${t('服务条款')}</a>`,
              privacy_policy: `<a class='el-link is-underline' target='${urls.terms_of_service.target}' href='${urls.privacy_policy.url}'>${t('隐私政策')}</a>`
            })
          "
        ></div>
      </div>
    </div>
    <el-dialog
      class="signin-iframe"
      v-model="signinIframeDialogVisible"
      width="322px"
      draggable
      align-center
      destroy-on-close
    >
      <iframe
        allowtransparency="true"
        frameborder="0"
        scrolling="no"
        style="width: 282px; height: 385px"
        :src="signinUrl"
        v-if="signinIframeDialogVisible"
      ></iframe>
    </el-dialog>
    <el-dialog
      class="signin-qrcode"
      v-model="signinDialogVisible"
      :title="t('微信扫码登录')"
      width="242px"
      draggable
      align-center
      destroy-on-close
      @close="stopCheckSignin"
    >
      <div class="signin-image-box" v-if="!signinMessage">
        <el-image class="signin-image" :src="signinQrCodeBase64" />
        <div class="signin-message">{{ t('请使用微信扫码授权登录') }}</div>
      </div>
      <div class="signin-image-box" v-if="signinMessage">
        <el-result icon="error" :title="t('登录异常')" :sub-title="signinMessage" />
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.signin-page {
  height: calc(
    100vh - var(--el-header-height) - var(--el-footer-height) - var(--el-main-padding) -
      var(--el-main-padding)
  );
  display: flex;

  :deep(.el-dialog) {
    .el-dialog__body {
      padding: 15px 20px;
    }

    &.signin-qrcode {
      .el-dialog__body {
        padding: 15px 19px;
      }

      .el-dialog__header {
        padding-bottom: 10px;
        padding-top: 10px;
        margin-right: 0;

        .el-dialog__headerbtn {
          top: -4px;
        }

        .el-dialog__title {
          color: #6cbe5e;
        }
      }

      .signin-image-box {
        height: 200px;
      }

      .signin-image {
        width: 170px;
        height: 170px;
        border: 1px solid var(--my-color-y2);
      }

      .signin-message {
        height: 24px;
        text-align: center;
        color: #409eff;
      }
    }

    &.signin-iframe {
      .el-dialog__header {
        padding: 0;
        margin-right: 0;

        .el-dialog__headerbtn {
          z-index: 1;
        }
      }
    }
  }

  .signin-wrapper {
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;

    .title {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      color: var(--el-text-color-regular);
    }

    .slogan {
      font-size: 14px;
      line-height: 22px;
      color: var(--my-color-black-50);
    }

    .signin-body {
      max-width: 428px;
      min-width: 320px;
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .links {
        max-width: 384px;
      }
    }
  }
}

.agree {
  margin-bottom: 60px;
  text-align: center;

  font-size: 14px;
  line-height: 22px;
  color: var(--my-color-black-40);

  :deep(.el-link) {
    color: var(--my-color-black-89-90);
    vertical-align: baseline;

    &.is-underline {
      &:after {
        bottom: 2px;
      }

      &:hover:after {
        border-color: var(--my-color-black-89-90);
      }

      &:not(:hover):after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 0;
        // bottom: 0;
        border-bottom: 1px solid var(--my-color-black-89-90);
      }
    }
  }
}

.slogan {
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .slogan {
    visibility: visible;
  }
}
</style>
