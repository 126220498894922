import { provide, ref, reactive } from 'vue'
import type { CoreData, EditorElement } from './types'

/**
 * This file define global states in project
 */

/** 常量、数据相关 start */
// 核心数据
const coreData = Symbol()

// 动画播放帧率
const animationDrawingFps = Symbol()

// 当前选中素材
const selectedElement = Symbol()
/** 常量、数据相关 start */

const useProvider = (): void => {
  /** init data start */
  // 常量、数据相关
  const $animationDrawingFps = ref(30)
  const $coreData = reactive<CoreData>({
    // 全局设置
    globalSetting: {
      stage: {
        width: 1920,
        height: 1080,
        scale: 1
      },
      // @ts-ignore
      subtitles: {
        styles: {
          normal: {
            font_name: '',
            color: '',
            outline_color: ''
          },
          highlight: {
            font_name: '',
            color: '',
            outline_color: ''
          }
        }
      }
    },
    // 动画特效
    animations: [],
    // 轨道列表
    tracks: []
  })
  const $selectedElement = ref<EditorElement | null>()
  /** init data end */

  /** provide start */
  // 常量、数据相关
  provide(coreData, $coreData)
  provide(animationDrawingFps, $animationDrawingFps)
  provide(selectedElement, $selectedElement)
  /** provide end */
}

export default {
  useProvider,
  // 常量、数据相关
  coreData,
  animationDrawingFps,
  selectedElement
}
