<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.99992" cy="8.00016" r="6.16667" stroke="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.3516 5.64473C6.15633 5.44947 5.83975 5.44948 5.64449 5.64474C5.44923 5.84001 5.44923 6.15659 5.6445 6.35185L7.29273 8.00005L5.6445 9.64825C5.44923 9.84351 5.44923 10.1601 5.64449 10.3554C5.83975 10.5506 6.15633 10.5506 6.3516 10.3554L7.99984 8.70715L9.64806 10.3553C9.84332 10.5506 10.1599 10.5506 10.3552 10.3553C10.5504 10.1601 10.5504 9.84347 10.3552 9.64821L8.70696 8.00005L10.3552 6.35188C10.5504 6.15662 10.5504 5.84004 10.3552 5.64477C10.1599 5.44951 9.84332 5.44951 9.64806 5.64477L7.99984 7.29295L6.3516 5.64473Z"
      fill="currentColor"
    />
  </svg>
</template>
