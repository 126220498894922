<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { config } from '@/config'
import { getCookie } from '@/utils'

const { t } = useI18n()

const props = defineProps<{
  redirectUri?: string
  showSignInWithEmail?: boolean
  showSignInWithPhone?: boolean
  checkAgree?: (event: MouseEvent) => void
  clickItem?: (event: MouseEvent, type: 'email' | 'phone') => void
}>()

let sourceQuery = location.search.replace('?', '')
const url = new URL(location.href)
let utmSource = url.searchParams.get('utm_source')
const utmSourceExists = !!utmSource
if (!utmSource) {
  utmSource = url.searchParams.get('ref')
}
if (!utmSource) {
  utmSource = getCookie('utm_source')
}
if (
  !utmSource &&
  document.referrer &&
  !document.referrer.includes('vmeg.pro') &&
  !document.referrer.includes('gthree.cn') &&
  !document.referrer.includes('localhost') &&
  !document.referrer.includes('192.168.')
) {
  utmSource = document.referrer
    .replace('http://', '')
    .replace('https://', '')
    .split('/')[0]
    .split('?')[0]
    .split('#')[0]
}
if (!utmSourceExists && utmSource) {
  sourceQuery =
    (sourceQuery ? `${sourceQuery.replace('utm_source=', '')}&` : '') + `utm_source=${utmSource}`
}

const redirect =
  props.redirectUri && props.redirectUri !== '-' ? props.redirectUri : config.signedInRedirectRoute
const redirectQuery = `?redirect_url=${encodeURIComponent(
  redirect.includes('://')
    ? redirect.includes('?')
      ? `${redirect}${sourceQuery ? `&${sourceQuery}` : ''}`
      : `${redirect}${sourceQuery ? `?${sourceQuery}` : ''}`
    : config.useHashHistory
      ? `${sourceQuery ? `?${sourceQuery}` : ''}#${redirect}`
      : `${redirect}${sourceQuery ? `?${sourceQuery}` : ''}`
)}`

const signInWithTarget = (event: MouseEvent, type: 'email' | 'phone') => {
  if (props.checkAgree?.(event)) {
    props.clickItem?.(event, type)
  }
}

const isCN = import.meta.env.VITE_DOMAIN === 'gthree.cn'
</script>

<template>
  <div class="links">
    <el-link
      @click="props.checkAgree"
      :href="`${config.baseUrl}/oauth2/authorization/discord${redirectQuery}`"
      :underline="false"
      v-if="!isCN"
    >
      <div class="link-icon link-1"></div>
      {{ t('使用{object}登录', { object: 'Discord' }) }}
    </el-link>
    <el-link
      @click="props.checkAgree"
      :href="`${config.baseUrl}/oauth2/authorization/google${redirectQuery}`"
      :underline="false"
      v-if="!isCN"
    >
      <div class="link-icon link-2"></div>
      {{ t('使用{object}登录', { object: 'Google' }) }}
    </el-link>
    <el-link
      @click="(event: MouseEvent) => signInWithTarget(event, 'email')"
      :underline="false"
      v-if="props.showSignInWithEmail"
    >
      <div class="link-icon link-3"></div>
      {{ t('使用{object}登录', { object: t('邮箱') }) }}
    </el-link>
    <el-link
      @click="(event: MouseEvent) => signInWithTarget(event, 'phone')"
      :underline="false"
      v-if="props.showSignInWithPhone || isCN"
    >
      <div class="link-icon link-4"></div>
      {{ t('使用{object}登录', { object: t('手机号码') }) }}
    </el-link>
  </div>
</template>

<style scoped lang="less">
.el-link {
  font-family:
    SF Pro Text,
    var(--el-font-family);
}

.links {
  .el-link {
    margin-bottom: 24px;

    display: flex;
    justify-content: left;
    padding: 0 70px;
    height: 56px;
    border: 1px solid var(--my-color-black-20);
    border-radius: 80px;

    font-size: 18px;
    line-height: 21px;
    color: var(--my-color-x3);

    &:hover {
      border-color: var(--my-color-primary-40);
      background-color: var(--my-color-primary-6);
    }

    .link-icon {
      width: 32px;
      height: 32px;
      background: url(@/assets/icons@2x.webp) no-repeat;
      background-size: cover;
      margin-right: 16px;

      &.link-1 {
        // width: 24px;
        // height: 24px;
        // background-position: -176px 0;
        background-position: -234px 0;
      }

      &.link-2 {
        // width: 24px;
        // height: 24px;
        // background-position: -308px 0;
        background-position: -410px 0;
      }

      &.link-3 {
        // width: 24px;
        // height: 24px;
        // background-position: -352px 0;
        background-position: -469px 0;
      }

      &.link-4 {
        // width: 24px;
        // height: 24px;
        // background-position: -352px 0;
        background-position: -469px 0;

        &::after {
          content: 'SMS';
          font-size: 8px;
          font-weight: 300;
          color: var(--my-color-white-89-90);
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .links {
    .el-link {
      padding: 0 60px;
    }
  }
}
</style>
