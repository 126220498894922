<script setup lang="ts">
import { onUnmounted, ref } from 'vue'

import { useI18n } from 'vue-i18n'

import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import IconEmpty from '@/components/icons/IconEmpty.vue'

import { getAttachmentList, type Attachment } from '@/api'
import { useProjStore } from '@/stores/proj'

const { t } = useI18n()
const projStore = useProjStore()

const emit = defineEmits(['selected:done'])
defineExpose({
  open: () => {
    dialogVisible.value = true
    currentPage.value = 1
    fetchData()
  }
})

const dialogVisible = ref(false)

let requestTask: AbortController | null = null
const isLoading = ref(false)
const currentPage = ref(1)
const dataList = ref<Attachment[]>([])
const total = ref(0)
const background = ref(false)
const pagerCount = ref(7)
const layout = ref('prev, pager, next, jumper, ->, total')
const pageSizes = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const pageSize = ref(pageSizes[0])
/*const searchTitle = ref('')
const searchSubmitedText = ref('')
const isSearching = ref(false)*/

const fetchData = () => {
  abortRequest()
  dataList.value = []
  total.value = 0
  isLoading.value = true

  const controller = new AbortController()
  requestTask = controller

  //searchSubmitedText.value = searchTitle.value

  getAttachmentList(
    {
      projId: projStore.projId,
      category: 'brand_logo',
      //searchTitle: searchTitle.value,
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      orderBy: 'id',
      order: 'desc'
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      total.value = res.data.data.total
      if (res.data.data.current === currentPage.value && res.data.data.size === pageSize.value) {
        dataList.value = res.data.data.records || []
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isLoading.value = false
      //isSearching.value = false
    })
}
const handleSizeChange = (val: number) => {
  pageSize.value = val
  currentPage.value = 1
  fetchData()
}
const handleCurrentChange = (val: number) => {
  currentPage.value = val
  fetchData()
}
const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  abortRequest()
})
/*const searchSubmit = () => {
  isSearching.value = true
  currentPage.value = 1
  fetchData()
}*/

const selectAttachment = (attachment: Attachment) => {
  emit('selected:done', attachment)
  dialogVisible.value = false
}
</script>

<template>
  <el-dialog
    class="choose-file-dialog"
    v-model="dialogVisible"
    :title="t('我的素材库')"
    width="700px"
    draggable
    align-center
  >
    <div class="choose-file-dialog-body" v-loading="isLoading">
      <div class="choose-file-dialog-wrapper">
        <div
          class="choose-file-item choose-file-item-pointer"
          v-for="attachment in dataList"
          :key="attachment.id"
        >
          <el-image
            class="thumbnail"
            fit="contain"
            :src="attachment.url"
            @click="selectAttachment(attachment)"
          />
        </div>
      </div>
      <el-empty class="g-empty" v-if="!isLoading && !dataList.length">
        <template #image>
          <el-icon><IconEmpty /></el-icon>
        </template>
      </el-empty>
      <el-pagination
        class="g-pagination"
        :background="background"
        v-model:page-size="pageSize"
        :total="total"
        :pager-count="pagerCount"
        v-model:current-page="currentPage"
        :layout="layout"
        :page-sizes="pageSizes"
        :disabled="isLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="total > pageSizes[0]"
      />
    </div>
  </el-dialog>
</template>

<style lang="less">
@media screen and (max-width: 768px) {
  .el-dialog.choose-file-dialog {
    --el-dialog-width: 100% !important;
  }
}
</style>

<style scoped lang="less">
.el-dialog.choose-file-dialog {
  .choose-file-dialog-body {
    .choose-file-dialog-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
}

.choose-file-item {
  display: flex;
  position: relative;

  &.choose-file-item-pointer {
    cursor: pointer;
  }

  .thumbnail {
    width: 120px;
    height: 90px;
    border-radius: 8px;
    background: var(--my-color-black-100);
  }
}

@media screen and (max-width: 768px) {
  .choose-file-dialog {
    .choose-file-dialog-body {
      .choose-file-dialog-wrapper {
        gap: 11px;
      }
    }
  }

  .choose-file-item {
    .thumbnail {
      width: 107px;
      height: 80px;
    }
  }
}
</style>
