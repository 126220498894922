<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 3V2.5C10 2.22386 9.77614 2 9.5 2H6.5C6.22386 2 6 2.22386 6 2.5V3H10ZM11 3V2.5C11 1.67157 10.3284 1 9.5 1H6.5C5.67157 1 5 1.67157 5 2.5V3H4.00087H3H2.5C2.22386 3 2 3.22386 2 3.5C2 3.77614 2.22386 4 2.5 4H3.04167L3.42014 13.0833C3.46477 14.1545 4.34622 15 5.4184 15H10.5816C11.6538 15 12.5352 14.1545 12.5799 13.0833L12.9583 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H13H11.9991H11ZM11.9575 4H11H10H6H5H4.04253L4.41927 13.0416C4.44159 13.5773 4.88231 14 5.4184 14H10.5816C11.1177 14 11.5584 13.5773 11.5807 13.0416L11.9575 4ZM6 6.5C6 6.22386 6.22386 6 6.5 6C6.77614 6 7 6.22386 7 6.5V11.5C7 11.7761 6.77614 12 6.5 12C6.22386 12 6 11.7761 6 11.5V6.5ZM9.5 6C9.22386 6 9 6.22386 9 6.5V11.5C9 11.7761 9.22386 12 9.5 12C9.77614 12 10 11.7761 10 11.5V6.5C10 6.22386 9.77614 6 9.5 6Z"
      fill="currentColor"
    />
  </svg>
</template>
