<script setup lang="ts">
import { ref } from 'vue'
import FeedbackForm from '@/components/feedback/FeedbackForm/FeedbackForm.vue'
import { ElMessageBox } from 'element-plus'

defineProps({
  dialogVisible: {
    type: Boolean
  }
})

const emit = defineEmits(['update:dialogVisible', 'close']) // 定义事件

const form = ref({
  surveyId: '',
  answers: ''
})

const surveyType = ref('1') // 后面可以抽离成组件

// 只关闭自己
const closeDialog = () => {
  emit('update:dialogVisible', false) // 发射事件来关闭对话框
  emit('close', 'close') // 发射事件来关闭对话框
}

// 成功以后的点击立即创建的回调，不仅要关闭自己，还要关闭外部弹窗，让用户进行重新提交
const doneAndClose = () => {
  emit('update:dialogVisible', false) // 发射事件来关闭对话框
  emit('close', 'done') // 发射事件来关闭对话框
  ElMessageBox.close()
}
</script>

<template>
  <div class="feedback-modal-container">
    <el-dialog
      :model-value="dialogVisible"
      :show-close="false"
      width="680"
      top="5vh"
      destroy-on-close
      :close-on-press-escape="false"
    >
      <FeedbackForm :survey-type="surveyType" @close="closeDialog" @complete="doneAndClose" />
    </el-dialog>
  </div>
</template>

<style>
/* 可以根据需要添加样式 */
.feedback-modal-container {
  .el-dialog__header {
    padding: 0;
  }
}
</style>
