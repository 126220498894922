<template>
  <svg width="196" height="64" viewBox="0 0 196 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="rotate rotate-a"
      d="M20.6557 11.7161C20.6557 9.05633 23.5336 7.39282 25.8384 8.72038L42.1845 18.1359C44.4932 19.4658 44.4932 22.7974 42.1845 24.1273L25.8384 33.5428C23.5336 34.8704 20.6557 33.2069 20.6557 30.5471L20.6557 11.7161Z"
      fill="#FFB321"
    />
    <path
      class="rotate rotate-b"
      d="M8.97343 2.05811C12.1556 2.05811 14.7353 4.63779 14.7353 7.81999V55.6436C14.7353 58.8258 12.1556 61.4055 8.97343 61.4055C5.79123 61.4055 3.21155 58.8258 3.21155 55.6436V7.81999C3.21155 4.63779 5.79123 2.05811 8.97343 2.05811Z"
      fill="#FF6B86"
    />
    <path
      class="rotate rotate-c"
      d="M55.4659 29.1688C57.0405 31.934 56.0754 35.4523 53.3101 37.0269L11.8245 60.6507C9.05924 62.2253 5.54101 61.2602 3.96634 58.4949C2.39166 55.7296 3.35684 52.2114 6.12212 50.6367L47.6077 27.013C50.3729 25.4383 53.8912 26.4035 55.4659 29.1688Z"
      fill="#7B4DFF"
    />
    <path
      class="blink blink-v"
      d="M78.8728 16.1208C77.6809 16.1208 76.8319 17.2782 77.1898 18.4151L86.0777 46.6457C86.309 47.3805 86.9903 47.8802 87.7606 47.8802H92.5501C93.3205 47.8802 94.0018 47.3805 94.2331 46.6457L103.121 18.4151C103.479 17.2782 102.63 16.1208 101.438 16.1208H99.5882C98.8178 16.1208 98.1366 16.6206 97.9052 17.3554L90.1554 41.9713L82.4056 17.3554C82.1742 16.6206 81.4929 16.1208 80.7226 16.1208H78.8728Z"
      fill="currentColor"
    />
    <path
      class="blink blink-m"
      d="M106.917 17.8853C106.917 16.9108 107.706 16.1208 108.681 16.1208H111.451C112.139 16.1208 112.765 16.5211 113.053 17.1461L120.394 33.0575L127.211 17.1889C127.489 16.5409 128.126 16.1208 128.832 16.1208H131.618C132.593 16.1208 133.383 16.9108 133.383 17.8853V46.1158C133.383 47.0903 132.593 47.8802 131.618 47.8802H129.854C128.88 47.8802 128.09 47.0903 128.09 46.1158V28.5538L122.13 42.4268C121.528 43.8304 119.547 43.8567 118.907 42.4696L112.21 27.9532V46.1158C112.21 47.0903 111.42 47.8802 110.445 47.8802H108.681C107.706 47.8802 106.917 47.0903 106.917 46.1158V17.8853Z"
      fill="currentColor"
    />
    <path
      class="blink blink-e"
      d="M159.847 19.6499C159.847 20.6244 159.057 21.4143 158.082 21.4143H138.674C137.699 21.4143 136.909 20.6244 136.909 19.6499V17.8855C136.909 16.911 137.699 16.1211 138.674 16.1211H158.082C159.057 16.1211 159.847 16.911 159.847 17.8855V19.6499ZM159.847 32.883C159.847 33.8575 159.057 34.6475 158.082 34.6475H138.674C137.699 34.6475 136.909 33.8575 136.909 32.883V31.1186C136.909 30.1442 137.699 29.3542 138.674 29.3542H158.082C159.057 29.3542 159.847 30.1442 159.847 31.1186V32.883ZM136.909 46.116C136.909 47.0905 137.699 47.8804 138.674 47.8804H158.082C159.057 47.8804 159.847 47.0905 159.847 46.116V44.3516C159.847 43.3771 159.057 42.5872 158.082 42.5872H138.674C137.699 42.5872 136.909 43.3771 136.909 44.3516V46.116Z"
      fill="currentColor"
    />
    <path
      class="blink blink-g"
      d="M178.626 20.5315C173.401 20.5315 168.67 25.3762 168.67 32.0002C168.67 38.6242 173.401 43.4689 178.626 43.4689C181.131 43.4689 183.025 43.0388 184.473 41.9574V36.5288H180.391C179.416 36.5288 178.626 35.7389 178.626 34.7644V33C178.626 32.0256 179.416 31.2356 180.391 31.2356L187.119 31.2356C188.581 31.2356 189.766 32.4205 189.766 33.8822V43.1438C189.766 43.833 189.497 44.495 189.017 44.9891C186.056 48.0334 182.234 48.7621 178.626 48.7621C169.93 48.7621 163.377 40.9675 163.377 32.0002C163.377 23.0328 169.93 15.2383 178.626 15.2383C182.677 15.2383 186.307 16.9673 188.992 19.7015C189.675 20.3967 189.537 21.5143 188.771 22.1175L187.385 23.2096C186.62 23.8127 185.52 23.6667 184.801 23.0093C183.073 21.43 180.918 20.5315 178.626 20.5315Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="less">
.rotate {
  animation: loading-rotate 2.4s linear 1;
}

.rotate-a {
  transform-origin: 16.4744898% 32.625%; // (20.66+23.26/2)/196*100 (8+25.76/2)/64*100
  animation-duration: 1.6s;
  //animation-iteration-count: infinite;
}

.rotate-b {
  animation-delay: -1.6s;
}

.rotate-c {
  animation-delay: -0.8s;
}

@keyframes loading-rotate {
  to {
    transform: rotate(1turn);
  }
}

.blink {
  animation: blink 1.4s linear 1;
}

.blink-v {
  animation-delay: 0.2s;
}

.blink-m {
  animation-delay: 0.4s;
}

.blink-e {
  animation-delay: 0.6s;
}

.blink-g {
  animation-delay: 0.8s;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
