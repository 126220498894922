<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99878 2.5C10.344 2.5 10.6238 2.77982 10.6238 3.125V11.1996L13.3281 8.49526C13.5722 8.25118 13.9679 8.25118 14.212 8.49526C14.456 8.73933 14.456 9.13506 14.212 9.37914L10.4407 13.1504C10.1966 13.3945 9.80092 13.3945 9.55684 13.1504L5.7856 9.37914C5.54152 9.13506 5.54152 8.73933 5.7856 8.49526C6.02968 8.25118 6.42541 8.25118 6.66948 8.49526L9.37378 11.1996V3.125C9.37378 2.77982 9.6536 2.5 9.99878 2.5ZM3.75 13.5417C3.75 13.1965 3.47018 12.9167 3.125 12.9167C2.77982 12.9167 2.5 13.1965 2.5 13.5417V15.4167C2.5 16.5673 3.43274 17.5 4.58333 17.5H15.4167C16.5673 17.5 17.5 16.5673 17.5 15.4167V13.5417C17.5 13.1965 17.2202 12.9167 16.875 12.9167C16.5298 12.9167 16.25 13.1965 16.25 13.5417V15.4167C16.25 15.8769 15.8769 16.25 15.4167 16.25H4.58333C4.1231 16.25 3.75 15.8769 3.75 15.4167V13.5417Z"
      fill="currentColor"
    />
  </svg>
</template>
