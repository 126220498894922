<template>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M128.476 68.568C136.947 73.8675 135.609 86.5931 126.222 90.0155L22.7786 127.726C14.4435 130.765 5.80689 124.021 6.73423 115.198L16.8352 19.0932C17.7626 10.2702 27.6126 5.46912 35.1338 10.1743L128.476 68.568Z"
      fill="#EAEAEA"
    />
    <path
      d="M18.4724 43.1303C18.4494 39.8167 21.1169 37.1118 24.4305 37.0887L97.3964 36.5809C100.71 36.5578 103.415 39.2254 103.438 42.539L103.607 66.7425C103.619 68.5912 102.779 70.3426 101.329 71.4897L62.9926 101.821C61.9441 102.65 60.6486 103.106 59.3116 103.115L24.8917 103.355C21.5781 103.378 18.8732 100.71 18.8501 97.3964L18.4724 43.1303Z"
      fill="#D0D0D0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.6149 40.3644C30.3194 40.018 27.367 42.4088 27.0206 45.7044L21.4806 98.414C21.1342 101.71 23.525 104.662 26.8206 105.008L71.8016 109.736L107.109 96.8943L111.555 54.5893C111.901 51.2937 109.511 48.3414 106.215 47.995L33.6149 40.3644Z"
      fill="#F9F9F9"
    />
    <path
      d="M27.0213 45.7044C27.3676 42.4089 30.32 40.0181 33.6156 40.3645L106.216 47.9951C109.511 48.3414 111.902 51.2938 111.556 54.5894L110.994 59.934L26.4595 51.0491L27.0213 45.7044Z"
      fill="#E6E6E6"
    />
    <path
      d="M75.7097 78.2341C77.2817 79.6002 77.0176 82.1132 75.196 83.1226L62.6307 90.0854C60.5078 91.2617 57.9394 89.5615 58.1931 87.1477L59.9153 70.7619C60.169 68.3481 63.0348 67.219 64.8667 68.8111L75.7097 78.2341Z"
      fill="#CCCCCC"
    />
    <path
      d="M137 16C137 13.7909 135.209 12 133 12H116C113.791 12 112 13.7909 112 16V29C112 31.2091 113.791 33 116 33H119V36.0332C119 36.8511 119.928 37.3231 120.589 36.8412L125.857 33H133C135.209 33 137 31.2091 137 29V16Z"
      fill="#D0D0D0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M129.03 19.5303C129.323 19.2374 129.323 18.7626 129.03 18.4697C128.737 18.1768 128.263 18.1768 127.97 18.4697L124.75 21.6893L121.53 18.4697C121.237 18.1768 120.763 18.1768 120.47 18.4697C120.177 18.7626 120.177 19.2374 120.47 19.5303L123.689 22.75L120.47 25.9697C120.177 26.2626 120.177 26.7374 120.47 27.0303C120.763 27.3232 121.237 27.3232 121.53 27.0303L124.75 23.8107L127.97 27.0303C128.263 27.3232 128.737 27.3232 129.03 27.0303C129.323 26.7374 129.323 26.2626 129.03 25.9697L125.811 22.75L129.03 19.5303Z"
      fill="white"
    />
  </svg>
</template>
