<script setup lang="ts">
import { config as siteConf } from '@/config'
import { discordUrl } from '@/constrants/vars'

const config = siteConf
</script>

<template>
  <div class="block-footer" v-bind="$attrs">
    <div class="content">
      <div class="logo"></div>
      <div class="info">
        <el-text>{{ config.company }}</el-text>
        <el-link :href="`mailto:${config.email}`">{{ config.email }}</el-link>
      </div>
      <div class="links">
        <el-link
          class="link link-5"
          target="_blank"
          :underline="false"
          aria-label="Discord"
          :href="discordUrl"
        ></el-link>
        <!-- <el-link
          class="link link-1"
          target="_blank"
          :underline="false"
          aria-label="Facebook"
          href="https://www.facebook.com/profile.php?id=61561385600709"
        ></el-link> -->
        <el-link
          class="link link-4"
          target="_blank"
          :underline="false"
          aria-label="Instagram"
          href="https://www.instagram.com/vmeg.pro/"
        ></el-link>
        <el-link
          class="link link-7"
          target="_blank"
          :underline="false"
          aria-label="Twitter"
          href="https://twitter.com/vmeg_pro"
        ></el-link>
        <el-link
          class="link link-2"
          target="_blank"
          :underline="false"
          aria-label="Twitter"
          href="https://www.youtube.com/channel/UCNbKdHS5vDjfHuKpt-kkMNA"
        ></el-link>
        <!-- <div class="link link-3"></div>
        <div class="link link-6"></div> -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.el-link,
.el-text {
  font-family: var(--el-font-family);
}

.block-footer {
  height: 200px;
  background: var(--my-color-y2);

  .content {
    width: 1120px;
    margin: 0 auto;

    padding-top: 50px;

    display: flex;
    gap: 50px;

    font-size: 16px;
    line-height: 24px;
    color: var(--my-color-x4);

    .logo {
      width: 91px;
      height: 30px;
      background: url(@/assets/logo.svg) no-repeat;
      background-size: contain;
    }

    .info {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 1;
    }

    .links {
      display: flex;
      align-items: center;
      gap: 20px;

      .link {
        flex-shrink: 0;

        width: 32px;
        height: 32px;
        background: url(@/assets/icons@2x.webp) no-repeat;
        background-size: cover;
        cursor: pointer;

        &.link-2 {
          background-position: -58px 0;
        }

        &.link-3 {
          background-position: -116px 0;
        }

        &.link-4 {
          background-position: -176px 0;
        }

        &.link-5 {
          background-position: -234px 0;
        }

        &.link-6 {
          background-position: -292px 0;
        }

        &.link-7 {
          background-position: -352px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .block-footer {
    display: flex;
    align-items: center;

    .content {
      width: unset;
      padding: 0;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      font-size: 14px;

      .links {
        display: flex;
        align-items: center;
        gap: 20px;

        .link {
          width: 24px;
          height: 24px;

          &.link-2 {
            background-position: -44px 0;
          }

          &.link-3 {
            background-position: -88px 0;
          }

          &.link-4 {
            background-position: -132px 0;
          }

          &.link-5 {
            background-position: -176px 0;
          }

          &.link-6 {
            background-position: -220px 0;
          }

          &.link-7 {
            background-position: -264px 0;
          }
        }
      }
    }
  }
}
</style>
