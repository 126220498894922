<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#7B4DFF" fill-opacity="0.15" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.5955 14.4917C29.5646 14.4917 28.7289 15.3274 28.7289 16.3584C28.7289 17.3893 29.5646 18.225 30.5955 18.225C31.6265 18.225 32.4622 17.3893 32.4622 16.3584C32.4622 15.3274 31.6265 14.4917 30.5955 14.4917ZM26.9789 16.3584C26.9789 14.3609 28.5981 12.7417 30.5955 12.7417C32.593 12.7417 34.2122 14.3609 34.2122 16.3584C34.2122 18.3558 32.593 19.975 30.5955 19.975C29.5104 19.975 28.5368 19.4971 27.8739 18.7402L21.368 22.5353C21.5263 22.9944 21.6122 23.4872 21.6122 24C21.6122 24.5129 21.5263 25.0056 21.368 25.4647L27.8739 29.2598C28.5368 28.503 29.5104 28.025 30.5955 28.025C32.593 28.025 34.2122 29.6443 34.2122 31.6417C34.2122 33.6391 32.593 35.2584 30.5955 35.2584C28.5981 35.2584 26.9789 33.6391 26.9789 31.6417C26.9789 31.3586 27.0114 31.083 27.073 30.8186L20.4852 26.9757C19.6622 27.9055 18.4598 28.4917 17.1205 28.4917C14.6399 28.4917 12.6289 26.4807 12.6289 24C12.6289 21.5194 14.6399 19.5084 17.1205 19.5084C18.4598 19.5084 19.6622 20.0945 20.4852 21.0243L27.073 17.1815C27.0114 16.9171 26.9789 16.6415 26.9789 16.3584ZM14.3789 24C14.3789 22.4859 15.6064 21.2584 17.1205 21.2584C18.6347 21.2584 19.8622 22.4859 19.8622 24C19.8622 25.5142 18.6347 26.7417 17.1205 26.7417C15.6064 26.7417 14.3789 25.5142 14.3789 24ZM28.7289 31.6417C28.7289 30.6108 29.5646 29.775 30.5955 29.775C31.6265 29.775 32.4622 30.6108 32.4622 31.6417C32.4622 32.6726 31.6265 33.5084 30.5955 33.5084C29.5646 33.5084 28.7289 32.6726 28.7289 31.6417Z"
      fill="#7B4DFF"
    />
  </svg>
</template>
