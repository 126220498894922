<script setup lang="ts">
import SignInBox from './SignInBox.vue'

defineProps<{
  redirectUri?: string
}>()
</script>

<template>
  <el-dialog
    style="border-radius: 12px"
    width="480px"
    :close-on-click-modal="false"
    v-bind="$attrs"
  >
    <SignInBox :redirectUri="redirectUri" />
  </el-dialog>
</template>

<style scoped lang="less">
:deep(.el-dialog) {
  border-radius: 12px;
}
</style>
