<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#7B4DFF" fill-opacity="0.15" />
    <rect
      x="13.8889"
      y="16.2222"
      width="16.3333"
      height="17.8889"
      rx="2.33333"
      stroke="#7B4DFF"
      stroke-width="1.55556"
    />
    <path
      d="M34.1111 30.2222V16.9999C34.1111 14.8522 32.37 13.1111 30.2222 13.1111H17.7778"
      stroke="#7B4DFF"
      stroke-width="1.55556"
      stroke-linecap="round"
    />
    <rect x="17" y="29.4443" width="10.1111" height="1.55556" rx="0.777778" fill="#7B4DFF" />
    <rect
      x="17.7778"
      y="20.1111"
      width="8.55556"
      height="6.22222"
      rx="1.16667"
      stroke="#7B4DFF"
      stroke-width="1.55556"
    />
  </svg>
</template>
