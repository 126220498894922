<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3.625"
      y="1.625"
      width="11.75"
      height="16.75"
      rx="1.375"
      stroke="currentColor"
      stroke-width="1.25"
    />
  </svg>
</template>
