<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { Picture } from '@element-plus/icons-vue'

import { type GenerateVideoTask } from '@/api'

const { t } = useI18n()

defineProps<{
  taskData: GenerateVideoTask | undefined
  noMore?: boolean
}>()
</script>

<template>
  <div class="block">
    <h2>{{ t('产品素材') }}</h2>
    <div class="thumbnails">
      <el-image
        class="thumbnail"
        fit="contain"
        :src="url"
        v-for="(url, index) in taskData?.config.task_params.media_urls"
        :key="index"
      >
        <template #error>
          <el-icon><Picture /></el-icon>
        </template>
      </el-image>
    </div>
  </div>
  <div :class="{ 'more-content': !noMore }">
    <div class="block">
      <h2>{{ t('产品信息') }}</h2>
      <table>
        <tr>
          <td>{{ t('产品名称') }}</td>
          <td>{{ taskData?.title }}</td>
        </tr>
        <tr>
          <td>{{ t('产品特性') }}</td>
          <td>{{ taskData?.config.task_params.product_params.product_features.join(', ') }}</td>
        </tr>
        <tr v-if="taskData?.config.task_params.product_params.price">
          <td>{{ t('销售价格') }}</td>
          <td>
            {{ taskData.config.task_params.product_params.currency_symbol
            }}{{ taskData.config.task_params.product_params.price }}
          </td>
        </tr>
        <tr v-if="taskData?.config.task_params.product_params.promotion">
          <td>{{ t('推广信息') }}</td>
          <td>{{ taskData.config.task_params.product_params.promotion }}</td>
        </tr>
        <tr v-if="taskData?.config.task_params.product_params.brand.name">
          <td>{{ t('品牌名称') }}</td>
          <td>{{ taskData.config.task_params.product_params.brand.name }}</td>
        </tr>
        <tr v-if="taskData?.config.task_params.product_params.brand.logo">
          <td>{{ t('品牌图标') }}</td>
          <td>
            <el-image
              class="thumbnail"
              fit="contain"
              :src="taskData.config.task_params.product_params.brand.logo"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
          </td>
        </tr>
      </table>
    </div>
    <div class="block">
      <h2>{{ t('视频属性') }}</h2>
      <table>
        <tr style="display: none">
          <td>{{ t('视频时长') }}</td>
          <td>
            {{
              taskData?.config.task_params.video_params.video_duration == 'any'
                ? t('任意')
                : taskData?.config.task_params.video_params.video_duration_desc ||
                  taskData?.config.task_params.video_params.video_duration
            }}
          </td>
        </tr>
        <tr>
          <td>{{ t('视频分辨率') }}</td>
          <td>{{ taskData?.config.task_params.video_params.video_resolution }}</td>
        </tr>
        <tr>
          <td>{{ t('视频语言') }}</td>
          <td>{{ taskData?.config.task_params.video_params.video_language }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped lang="less">
.block {
  margin-bottom: 24px;
}

.more-content {
  display: block;
}

h2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--my-color-black-89-90);
  margin: 0 0 12px;
}

.thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: var(--my-color-primary-60);
  background: var(--my-color-black-100);

  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .thumbnail {
    width: 56px;
    height: 42px;
  }
}

table {
  display: flex;
  flex-direction: column;
  gap: 12px;

  tr {
    display: flex;
  }

  td:first-child {
    font-size: 12px;
    line-height: 16px;
    color: var(--my-color-black-89-90);
    width: 40%;
  }

  td:last-child {
    font-size: 12px;
    line-height: 16px;
    color: var(--my-color-black-40);
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .block {
    margin-bottom: 18px;
  }

  .more-content {
    display: none;
  }
}
</style>
