import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  createWebHashHistory,
  type RouteRecordRaw
} from 'vue-router'
import { config, setMenuPath } from '@/config'
import i18n from '@/locales/i18n'

import HomeView from '../views/HomeView.vue'
import PricingView from '@/views/PricingView.vue'

import CreateVideoView from '@/views/CreateVideoView.vue'
import GenerateTasksView from '@/views/GenerateTasksView.vue'
import GenerateTaskDetailsView from '@/views/GenerateTaskDetailsView.vue'
import VideoTranslationView from '@/views/VideoTranslationView.vue'
import VideoTranslationTasksView from '@/views/VideoTranslationTasksView.vue'

import SignInView from '@/views/SignInView.vue'
import SignUpView from '@/views/SignUpView.vue'

const { t } = i18n.global

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: () => t('首页'),
      intro: () => t('无限AI视频，释放营销想象'),
      bodyClasses: ['width-1120-full-layout'],
      hideMenu: true
    },
    component: HomeView
  },

  {
    path: '/create-video/:projId',
    name: 'vmeg.create-video',
    meta: {
      title: () => t('创建视频'),
      showAside: true,
      keepAliveView: CreateVideoView.__name,
      requiresAuth: true
    },
    component: CreateVideoView
  },
  {
    path: '/my-tasks/:projId',
    name: 'vmeg.my-tasks',
    meta: {
      title: () => t('我的任务'),
      showAside: true,
      keepAliveView: GenerateTasksView.__name,
      requiresAuth: true
    },
    component: GenerateTasksView
  },
  {
    path: '/my-tasks/:projId/:taskId',
    alias: '/my-tasks/:projId/:taskId/:requestId', // requestId 用于关联邮件
    name: 'vmeg.my-tasks.details',
    meta: {
      title: () => t('任务详情'),
      showAside: true,
      requiresAuth: true
    },
    component: GenerateTaskDetailsView
  },

  {
    path: '/video-translation/:projId',
    name: 'vmeg.video-translation',
    meta: {
      title: () => t('视频翻译'),
      showAside: true,
      keepAliveView: VideoTranslationView.__name,
      requiresAuth: true
    },
    component: VideoTranslationView
  },
  {
    path: '/video-translation-tasks/:projId',
    alias: '/video-translation-tasks/:projId/:taskId/:requestId', // requestId 用于关联邮件
    name: 'vmeg.video-translation.my-tasks',
    meta: {
      title: () => t('我的任务'),
      showAside: true,
      //keepAliveView: VideoTranslationTasksView.__name,
      requiresAuth: true
    },
    component: VideoTranslationTasksView
  },

  {
    path: '/subscription-plan/:projId',
    name: 'vmeg.subscription-plan',
    meta: {
      title: () => t('订阅计划'),
      showAside: true,
      requiresAuth: true
    },
    component: () => import('@/views/SubscriptionPlanListView.vue')
  },
  {
    path: '/credit-details/:projId',
    name: 'vmeg.credit-details',
    meta: {
      title: () => t('积分详情'),
      showAside: true,
      requiresAuth: true
    },
    component: () => import('@/views/CreditDetailListView.vue')
  },

  {
    path: '/pricing',
    name: 'pricing',
    meta: {
      title: () => t('定价'),
      bodyClasses: ['width-1120-full-layout']
    },
    component: PricingView
  },

  {
    path: '/admin/user-projects',
    name: 'admin.user-projects',
    meta: {
      title: () => '管理员-用户项目',
      keepAliveView: 'AdminUserProjectsView',
      requiresAuth: true
    },
    component: () => import('@/views/AdminUserProjectsView.vue')
  },
  {
    path: '/admin/redeem-manage',
    name: 'admin.redeem-manage',
    meta: {
      title: () => '管理员-兑换项目',
      keepAliveView: 'RedeemManageView',
      requiresAuth: true
    },
    component: () => import('@/views/RedeemManageView.vue')
  },

  {
    path: '/error/:message',
    name: 'error',
    meta: {
      title: () => t('异常信息')
    },
    component: () => import('@/views/ErrorView.vue')
  },

  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    meta: {
      title: () => t('服务条款')
    },
    component: () => import('@/views/TermsOfServiceView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: () => t('隐私政策')
    },
    component: () => import('@/views/PrivacyPolicyView.vue')
  },

  {
    path: '/signin/:from',
    alias: '/signin',
    name: 'signin',
    meta: {
      title: () => t('登录'),
      showAsideLarge: true
    },
    component: SignInView
  },
  {
    path: '/signout',
    name: 'signout',
    component: SignInView
  },
  {
    path: '/signup/:from',
    alias: '/signup',
    name: 'signup',
    meta: {
      title: () => t('注册'),
      showAsideLarge: true
    },
    // component: () => import('@/views/SignUpView.vue')
    component: SignUpView
  },
  {
    path: '/user/:from',
    alias: '/user',
    name: 'user',
    meta: {
      // showAside: true,
      title: () => t('个人资料'),
      bodyClasses: ['single-page-layout'],
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (UserView.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/UserView.vue')
  },
  {
    path: '/code-redeem/:codeType?/:code?/',
    alias: '/code-redeem',
    name: 'code-redeem',
    meta: {
      // showAside: true,
      title: () => t('权益兑换'), // TODO：确认文案
      bodyClasses: ['single-page-layout', 'without-header'],
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (UserView.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/ReedemView.vue')
  }
]

setMenuPath(routes)

const router = createRouter({
  history: import.meta.env.SSR
    ? createMemoryHistory()
    : config.useHashHistory
      ? createWebHashHistory()
      : createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
