<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M20.4167 43.75H17.5C10.7345 43.75 5.25 38.2655 5.25 31.5C5.25 25.8247 9.114 21.0665 14.35 19.6735C15.7535 13.4242 21.3255 8.75 28 8.75C35.7315 8.75 42 15.0185 42 22.75C42 22.8025 41.993 22.8532 41.9912 22.9057C46.9595 23.737 50.75 28.0455 50.75 33.25C50.75 39.0495 46.0495 43.75 40.25 43.75H35.5833"
        stroke="currentColor"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28 24.5V45.5"
        stroke="currentColor"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 31.5L28 24.5L35 31.5"
        stroke="currentColor"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
