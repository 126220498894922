<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1671 7.00016C13.1671 10.1298 10.63 12.6668 7.50041 12.6668C4.37079 12.6668 1.83374 10.1298 1.83374 7.00016C1.83374 3.87055 4.37079 1.3335 7.50041 1.3335C10.63 1.3335 13.1671 3.87055 13.1671 7.00016ZM14.1671 7.00016C14.1671 10.6821 11.1823 13.6668 7.50041 13.6668C3.81851 13.6668 0.83374 10.6821 0.83374 7.00016C0.83374 3.31826 3.81851 0.333496 7.50041 0.333496C11.1823 0.333496 14.1671 3.31826 14.1671 7.00016ZM4.16724 8.0002C4.62747 8.0002 5.00057 7.62711 5.00057 7.16687C5.00057 6.70663 4.62747 6.33354 4.16724 6.33354C3.707 6.33354 3.3339 6.70663 3.3339 7.16687C3.3339 7.62711 3.707 8.0002 4.16724 8.0002ZM7.50073 8.00024C7.96097 8.00024 8.33407 7.62715 8.33407 7.16691C8.33407 6.70667 7.96097 6.33358 7.50073 6.33358C7.04049 6.33358 6.6674 6.70667 6.6674 7.16691C6.6674 7.62715 7.04049 8.00024 7.50073 8.00024ZM11.6674 7.16691C11.6674 7.62715 11.2943 8.00024 10.8341 8.00024C10.3738 8.00024 10.0007 7.62715 10.0007 7.16691C10.0007 6.70667 10.3738 6.33358 10.8341 6.33358C11.2943 6.33358 11.6674 6.70667 11.6674 7.16691Z"
      fill="currentColor"
    />
  </svg>
</template>
