<script setup lang="ts">
import { ref, onMounted, onUnmounted, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ElMessage, ElMessageBox, type MessageBoxState } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import { ArrowRight, Picture, Loading } from '@element-plus/icons-vue'
//import { Download as IconDownload } from '@element-plus/icons-vue'

import IconDownload from '@/components/icons/IconDownload.vue'
import IconEmpty from '@/components/icons/IconEmpty.vue'
import IconVideo from '@/components/icons/IconVideo.vue'
import IconDArrowDown from '@/components/icons/IconDArrowDown.vue'

import GenerateTaskDetailsPart from './GenerateTaskDetailsPart.vue'

import {
  type GenerateVideoTask,
  type GeneratedVideo,
  getGenerateTaskList,
  getGeneratedVideoList,
  genVideoDownloadUrl,
  regenerateVideoTask,
  cancelGenerateVideoTask
} from '@/api'
import { videoDownload } from '@/common'
import { config, costQuotasMap } from '@/config'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { humanDuration } from '@/utils'
import { generateConfirmMessage, showSubmitConfirm } from '@/utils/creditsCostConfirm'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()

const drawer = ref(false)
const taskData = ref<GenerateVideoTask>()

let requestTask: AbortController | null = null
const isLoading = ref(false)
const currentPage = ref(1)
const dataList = ref<GeneratedVideo[]>([])
const total = ref(0)
const background = ref(false)
const pagerCount = ref(7)
const layout = ref('prev, pager, next, jumper, ->, total')
const pageSizes = [12, 24, 36, 48, 60, 72, 84, 96]
const pageSize = ref(pageSizes[0])

let requestTask1: AbortController | null = null
let requestTimer: ReturnType<typeof setTimeout> | null = null
let requestTimer1: ReturnType<typeof setTimeout> | null = null
const clearRequestTimeout = () => {
  if (requestTask1) {
    requestTask1.abort()
    requestTask1 = null
  }
  if (requestTimer) {
    clearTimeout(requestTimer)
    requestTimer = null
  }
  if (requestTimer1) {
    clearTimeout(requestTimer1)
    requestTimer1 = null
  }
}
const fetchTaskData = (isInit: boolean = false) => {
  const controller = new AbortController()
  requestTask1 = controller

  getGenerateTaskList(
    {
      id: String(route.params.taskId),
      projId: String(route.params.projId),
      product: 'clips-to-videos',
      pageSize: 1
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        if (isInit) {
          fetchData()
        }
        return
      }
      const record = (res.data.data.records || [])[0] || null
      const states = ['pending', 'running']
      if (record && states.includes(record.state)) {
        if (requestTimer) {
          clearTimeout(requestTimer)
          requestTimer = null
        }
        requestTimer = setTimeout(fetchTaskData, 10000)
        if (taskData.value && taskData.value.quantityCompleted !== record.quantityCompleted) {
          if (requestTimer1) {
            clearTimeout(requestTimer1)
            requestTimer1 = null
          }
          requestTimer1 = setTimeout(() => {
            fetchData(true)
          }, 10000)
        } else if (isInit) {
          fetchData()
        }
      } else if (taskData.value && states.includes(taskData.value.state)) {
        fetchData(true)
      } else if (isInit) {
        fetchData()
      }
      taskData.value = record
      if (taskData.value) {
        const s3UrlProtocol = 's3://'
        const attachmentLink = `${config.baseUrl}/api/attachment/link/`
        taskData.value.config.task_params.media_urls = (
          taskData.value.config.task_params.media_urls || []
        )
          .filter((url) => {
            return !!url
          })
          .map((url) => {
            const link = url.replace(s3UrlProtocol, attachmentLink)
            const extName = link.split('?')[0].split('.').pop() || ''
            if (['jpg', 'jpeg', 'png', 'apng', 'webp', 'gif'].includes(extName)) {
              return link
            }
            return link.replace(`.${extName}`, '.webp') // webp HARDCODE
          })
        taskData.value.config.task_params.product_params.brand.logo = (
          taskData.value.config.task_params.product_params.brand.logo || ''
        ).replace(s3UrlProtocol, attachmentLink)
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
      if (isInit) {
        fetchData()
      }
    })
}
onMounted(() => {
  isLoading.value = true
  fetchTaskData(true)
})

const fetchData = (slient: boolean = false) => {
  abortRequest()
  dataList.value = []
  total.value = 0
  if (!slient) {
    isLoading.value = true
  }

  const controller = new AbortController()
  requestTask = controller

  getGeneratedVideoList(
    {
      projId: String(route.params.projId),
      taskId: String(route.params.taskId),
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      orderBy: 'create_time',
      order: 'asc'
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      total.value = res.data.data.total
      if (res.data.data.current === currentPage.value && res.data.data.size === pageSize.value) {
        dataList.value = res.data.data.records || []

        const quantityTotalMin = taskData.value?.quantityTotal || 4
        if (
          dataList.value.length < quantityTotalMin &&
          (!taskData.value || ['pending', 'running'].includes(taskData.value?.state || ''))
        ) {
          const count = quantityTotalMin - dataList.value.length
          for (let i = 0; i < count; i++) {
            dataList.value.push({
              // @ts-expect-error
              id: i
            })
          }
        }
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isLoading.value = false
    })
}
const handleSizeChange = (val: number) => {
  pageSize.value = val
  currentPage.value = 1
  fetchData()
}
const handleCurrentChange = (val: number) => {
  currentPage.value = val
  fetchData()
}
const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  abortRequest()
  clearRequestTimeout()
})

const isSubmitting = ref(false)
const cancelTask = (item: GenerateVideoTask) => {
  isSubmitting.value = true
  cancelGenerateVideoTask({
    id: item.id,
    projId: item.projId
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('取消异常'))
        return
      }
      ElMessage.success(res.data.message || t('取消成功'))
      item.state = res.data.data.state
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('取消失败'))
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

const redoTask = async (item: GenerateVideoTask) => {
  if (isSubmitting.value) {
    return
  }

  const costQuotas = costQuotasMap['clips-to-videos']

  if (costQuotas <= 0) {
    doCreateAction(item, costQuotas)
    return
  }

  if (projStore.total_remaining < costQuotas) {
    isSubmitting.value = true
    await fetchSubscriptionPlanAndCreditDetails(projStore)
    isSubmitting.value = false
  }

  showSubmitConfirm(
    {
      title: t('将剪辑提交到视频任务'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      feedbackTypes: userStore.feedbackTypes
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true

          doCreateAction(item, costQuotas, instance, done)
        } else {
          done()
        }
      }
    }
  )
}
const doCreateAction = (
  item: GenerateVideoTask,
  costQuotas: number,
  instance?: MessageBoxState,
  done?: () => void
) => {
  isSubmitting.value = true
  regenerateVideoTask({
    id: item.id,
    projId: item.projId
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('重新生成异常'))
        return
      }
      ElMessage.success(res.data.message || t('重新生成成功'))
      item.state = res.data.data.state
      item.createTime = res.data.data.createTime

      if (requestTimer) {
        clearTimeout(requestTimer)
        requestTimer = null
      }
      requestTimer = setTimeout(() => {
        fetchTaskData(true)
      }, 10000)
      if (costQuotas > 0) {
        projStore.$patch({
          total_remaining: projStore.total_remaining - costQuotas
        })
        fetchSubscriptionPlanAndCreditDetails(projStore)
      }
      if (done) {
        done()
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('重新生成失败'))
    })
    .finally(() => {
      if (instance) {
        instance.confirmButtonLoading = false
      }

      isSubmitting.value = false
    })
}

const downloadItem = ref<GeneratedVideo | null>()
const downloadVideo = async (event: MouseEvent, item: GeneratedVideo) => {
  event.stopPropagation()

  if (downloadItem.value) {
    return
  }

  let needCostQuotas = true
  let downloadCount = 0
  dataList.value.some((data) => {
    if (data.id === item.id && data.downloadCount) {
      // 已经下载过本视频
      needCostQuotas = false
      return true
    }
    downloadCount += data.downloadCount
    return false
  })
  if (needCostQuotas && downloadCount === 0) {
    // 没有下载过本组任何视频
    needCostQuotas = false
  }

  let costQuotas = needCostQuotas ? costQuotasMap['download-video'] : 0

  if (needCostQuotas) {
    downloadItem.value = item
    const res = await genVideoDownloadUrl({
      id: item.id,
      projId: item.projId,
      taskId: item.taskId,
      isQueryCostQuotas: true
    }) // 查询后台验证看看是否真的需要扣费
    if (!res.data.code && res.data.costQuotas !== null && res.data.costQuotas !== undefined) {
      costQuotas = res.data.costQuotas
    }
    downloadItem.value = null
  }

  if (projStore.total_remaining < costQuotas) {
    downloadItem.value = item
    await fetchSubscriptionPlanAndCreditDetails(projStore)
    downloadItem.value = null
  }

  if (!costQuotas) {
    downloadVideoAction(item, costQuotas)
    return
  }

  showSubmitConfirm(
    {
      title: t('下载生成的视频'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      feedbackTypes: userStore.feedbackTypes
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true

          downloadVideoAction(item, costQuotas, instance, done)
        } else {
          done()
        }
      }
    }
  )
}
const downloadVideoAction = (
  item: GeneratedVideo,
  costQuotas: number,
  instance?: MessageBoxState,
  done?: () => void
) => {
  downloadItem.value = item

  const title = taskData.value?.title || item.resolution
  const downloadFilename = `${title}-${item.addressUrl.split('/').slice(-1).join('').split('?')[0]}`

  genVideoDownloadUrl({
    id: item.id,
    projId: item.projId,
    taskId: item.taskId,
    downloadFilename
  })
    .then((res) => {
      if (res.data.code) {
        if (res.data.errorCode === 'TOO_MANY_REQUEST_FOR_DOWNLOAD') {
          ElMessage.error(res.data.message)
          return
        }
        //videoDownload(item.addressUrl, downloadFilename)
        return
      }
      const url = item.addressUrl
      const isCloudfront =
        url.includes('.cloudfront.') ||
        url.includes('res.vmeg.pro') ||
        url.includes('cdn.gthree.cn')
      if (isCloudfront) {
        videoDownload(url, downloadFilename, true)
      } else {
        videoDownload(res.data.data.addressUrl, downloadFilename, true)
      }
      item.downloadCount += 1
      projStore.$patch({
        total_remaining: projStore.total_remaining - costQuotas
      })

      if (done) {
        done()
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      //videoDownload(item.addressUrl, downloadFilename)
    })
    .finally(() => {
      if (instance) {
        instance.confirmButtonLoading = false
      }

      setTimeout(() => {
        downloadItem.value = null

        fetchSubscriptionPlanAndCreditDetails(projStore)
      }, 2000)
    })
}
</script>

<template>
  <el-breadcrumb class="g-breadcrumb" :separator-icon="ArrowRight">
    <el-breadcrumb-item
      :to="{ name: 'admin.user-projects' }"
      v-if="userStore.roles?.includes('admin')"
    >
      管理员-用户项目
    </el-breadcrumb-item>
    <el-breadcrumb-item :to="{ name: 'vmeg.my-tasks', params: { projId: route.params.projId } }">
      {{ t('我的任务') }}
    </el-breadcrumb-item>
    <el-breadcrumb-item>{{ taskData?.title || t('任务详情') }}</el-breadcrumb-item>
  </el-breadcrumb>

  <div class="main">
    <el-aside width="308px">
      <GenerateTaskDetailsPart :task-data="taskData" />
      <div class="more-tips" @click="drawer = true">
        <div>{{ t('更多信息') }}</div>
        <el-icon><IconDArrowDown /></el-icon>
      </div>
    </el-aside>
    <el-drawer destroy-on-close v-model="drawer" direction="btt">
      <GenerateTaskDetailsPart :task-data="taskData" no-more />
    </el-drawer>

    <div class="content">
      <div class="header">
        <div class="left">
          <div class="title">{{ t('生成视频') }}</div>
          <div class="subtitle">
            <div v-if="taskData?.state == 'finished'">
              {{
                t('您的产品营销视频已成功生成{count}条。', {
                  count: Math.max(taskData?.quantityCompleted, dataList.length)
                })
              }}
            </div>
            <div v-if="taskData?.state && taskData.state != 'finished'">
              {{ t('您的{count}个产品营销视频正在生成中。', { count: taskData?.quantityTotal }) }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="button" v-if="taskData?.state == 'pending'">
            <el-button size="large" :loading="isSubmitting" @click="cancelTask(taskData)">
              {{ t('取消') }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="video-list" v-loading="isLoading">
        <template v-if="dataList.length">
          <div class="video-list-item" v-for="item in dataList" :key="item.id">
            <el-image
              class="thumbnail"
              fit="cover"
              :src="item.thumbnailUrl"
              v-if="item.thumbnailUrl && !item.addressUrl"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
            <div class="thumbnail" v-if="item.thumbnailUrl && item.addressUrl">
              <video
                preload="none"
                controls
                playsinline
                controlsList="nodownload"
                oncontextmenu="return false;"
                :src="item.addressUrl"
                :poster="item.thumbnailUrl"
              ></video>
            </div>
            <el-image class="thumbnail" v-if="!item.thumbnailUrl && !item.addressUrl">
              <template #error>
                <el-icon><IconVideo /></el-icon>
                <el-text v-if="taskData && ['pending', 'running'].includes(taskData.state)">{{
                  `${t('视频生成中')}...`
                }}</el-text>
              </template>
            </el-image>

            <div class="duration" v-if="item.duration">{{ humanDuration(item.duration) }}</div>
            <div
              class="download"
              :class="{ disabled: downloadItem }"
              v-if="item.duration && !userStore.roles?.includes('admin')"
              @click="downloadVideo($event, item)"
            >
              <el-icon v-if="!downloadItem || downloadItem.id != item.id">
                <IconDownload />
              </el-icon>
              <el-icon class="is-loading" v-if="downloadItem?.id == item.id"><Loading /></el-icon>
            </div>
          </div>
        </template>

        <el-empty
          class="g-empty"
          v-if="taskData && ['canceled', 'failed'].includes(taskData.state)"
        >
          <template #image>
            <el-icon><IconEmpty /></el-icon>
          </template>
          <template #description>
            <div v-if="taskData?.state == 'canceled'">
              {{ t('您的视频生成任务已取消，您可以点击下方按钮重新生成。') }}
            </div>
            <div v-if="taskData?.state == 'failed'">
              {{ t('抱歉，视频生成任务失败，您可以点击下方按钮重新生成。') }}
            </div>
            <el-button
              type="primary"
              :loading="isSubmitting"
              @click="redoTask(taskData)"
              v-if="taskData && ['canceled', 'failed'].includes(taskData.state)"
            >
              {{ t('重新生成') }}
            </el-button>
          </template>
        </el-empty>
      </div>

      <el-pagination
        class="g-pagination"
        :background="background"
        v-model:page-size="pageSize"
        :total="total"
        :pager-count="pagerCount"
        v-model:current-page="currentPage"
        :layout="layout"
        :page-sizes="pageSizes"
        :disabled="isLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="total > pageSizes[0]"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
.main {
  display: flex;
  gap: 40px;

  .el-aside {
    width: var(--el-aside-width, 308px);
    padding: 16px 24px 24px;
    border-radius: 8px;
    background: var(--my-color-x11);

    .more-tips {
      display: none;
    }
  }

  :deep(.el-drawer) {
    height: 76vh !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .el-drawer__header {
      margin: 0;

      .el-drawer__close-btn {
        margin-top: -10px;
        margin-right: -14px;
        color: var(--my-color-black-40);
        font-size: 16px;
      }
    }

    .el-drawer__body {
      margin-top: -26px;
    }
  }

  .content {
    //width: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .left {
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          color: var(--my-color-black-89-90);
          margin: 0 0 4px;
        }

        .subtitle {
          font-size: 14px;
          line-height: 24px;
          color: var(--my-color-black-40);
        }
      }

      .right {
        .button {
          width: 110px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: right;
        }
      }
    }

    .video-list {
      display: flex;
      flex-wrap: wrap;
      --my-gap: 24px;
      gap: var(--my-gap);

      .video-list-item {
        --my-thumbnail-width: 175px;
        --my-thumbnail-height: calc(307px + 30px);
        --my-thumbnail-size: 32px;

        width: var(--my-thumbnail-width);
        height: var(--my-thumbnail-height);

        position: relative;

        .thumbnail {
          width: 100%;
          height: calc(100% - 30px);
          background: var(--my-color-primary-10);
          border-radius: 7px;
          overflow: hidden;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 18px;

          video {
            width: 100%;
            height: 100%;
          }

          .el-icon {
            font-size: var(--my-thumbnail-size);
            color: var(--my-color-primary-18);
          }

          .el-text {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: var(--my-color-primary-40);
            padding: 0 12px;
          }
        }

        .duration {
          position: absolute;
          top: 6px;
          right: 6px;
          padding: 0 4px;
          border-radius: 4px;
          background: var(--my-color-black-50);
          font-size: 10px;
          line-height: 16px;
          color: var(--my-color-white-100);
        }

        .download {
          position: absolute;
          bottom: 2px;
          font-size: 20px;
          display: flex;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    gap: 18px;
    flex-direction: column;

    .el-aside {
      --el-aside-width: 100% !important;

      padding: 8px 12px 12px;

      .more-tips {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: -6px;

        font-size: 12px;
        line-height: 16px;
        color: var(--my-color-black-89-90);

        .el-icon {
          font-size: 16px;
          color: var(--my-color-black-40);
        }
      }
    }

    .content {
      .header {
        margin-bottom: 16px;
      }

      .video-list {
        --my-gap: 12px;

        .video-list-item {
          --my-thumbnail-width: calc((100vw - var(--el-main-padding) * 2 - var(--my-gap)) / 2);
          --my-thumbnail-height: calc(var(--my-thumbnail-width) * 1.75757576 + 30px);
        }
      }
    }
  }
}
</style>
