<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DropdownInstance, UploadInstance, UploadFile, UploadUserFile } from 'element-plus'

import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import { ArrowDown, Plus, Close, VideoPlay } from '@element-plus/icons-vue'
/*import {
  EditPen as IconEditPen,
  Folder as IconFolder,
  UploadFilled as IconUpload
} from '@element-plus/icons-vue'*/

import IconEditPen from '@/components/icons/IconEditPen.vue'
import IconFolder from '@/components/icons/IconFolder.vue'
import IconLibraryMaterial from '@/components/icons/IconLibraryMaterial.vue'
import IconUpload from '@/components/icons/IconUpload.vue'
import SoundExtract from '@/components/icons/SoundExtract.vue'

import UploadMaterial from '@/components/UploadMaterial.vue'
import LibraryMaterial from '@/components/LibraryMaterial.vue'

import { type Material } from '@/api'
import { videoPlay } from '@/common'
import { useProjStore } from '@/stores/proj'
import { humanDuration } from '@/utils'
import type { UploadRawFile } from 'element-plus/es/components/upload/src/upload'

const { t } = useI18n()
const projStore = useProjStore()

const emit = defineEmits(['update:modelValue', 'upload:progress', 'upload:success'])
const props = withDefaults(
  defineProps<{
    modelValue: Material[] // v-model默认的名字
    'onUpload:progress'?: (percent: number) => void
    'onUpload:success'?: (uploadFile: UploadFile, material: Material) => void
    product?: string
    category?: 'video' | 'image' | 'audio' | 'voice'
    categories?: ('video' | 'image' | 'audio' | 'voice')[]
    fileFormat?: string
    limit?: number
    minWidthAndHeight?: number
    maxWidthAndHeight?: number
    maxFileSize?: number
    trigger?: 'hover' | 'click' | 'contextmenu'
    class?: string
    isCustom?: boolean
    beforeUpload?: (uploadFile: UploadRawFile) => Promise<boolean>
  }>(),
  {
    product: 'video-translation',
    category: 'video',
    categories: () => ['video', 'image'],
    fileFormat: 'MP4, MOV, WEBM, WEBP, JPG, JPEG, PNG',
    limit: 1,
    minWidthAndHeight: 0, // px
    maxWidthAndHeight: 4096, // px
    trigger: 'hover',
    class: ''
  }
)
defineExpose({
  getUploadRef: () => {
    return uploadRef.value?.getUploadRef()
  },
  getUploadTypeRef: () => {
    return uploadTypeRef.value
  }
})

const materialList = computed({
  get: () => props.modelValue,
  set: (nv) => {
    emit('update:modelValue', nv)
  }
})

const uploadList = ref<UploadUserFile[]>([]) // 上传过程中
const uploadTypeRef = ref<DropdownInstance>()

const selectedList = ref<Material[]>([]) // 库中选择
const uploadProgress = ref(0)
const uploadRef = ref<typeof UploadMaterial>()
const libraryRef = ref<typeof LibraryMaterial>()
const replaceOriginal = ref<Material | null>()

const getUploadInput = (myUploadRef: typeof uploadRef) => {
  const ref = myUploadRef.value?.getUploadRef()?.value as UploadInstance | null
  const div = ref?.$el as HTMLDivElement
  let input: HTMLInputElement | null = null
  if (div) {
    input = div.querySelector('.el-upload__input')
  }
  return input
}
const uploadInputAction = (event: Event) => {
  const input = event.target as HTMLInputElement | null
  if (input && input.dataset.command === 'chose-from-device') {
    input.dataset.command = ''
    return
  }
  event.preventDefault()
}
onMounted(() => {
  const inputFile = getUploadInput(uploadRef)
  if (inputFile) {
    inputFile.onkeydown = inputFile.onclick = uploadInputAction
  }

  watch(
    () => materialList.value.length,
    (val) => {
      if (!val) {
        uploadProgress.value = 0
      }
    }
  )

  watch(
    () => uploadList.value.length,
    (val) => {
      emit('upload:progress', val ? 0.01 : 0)
    }
  )
})
const handleCommand = (command: string | number | object) => {
  if (command === 'chose-from-device') {
    const input = getUploadInput(uploadRef)
    if (input) {
      input.dataset.command = 'chose-from-device'
      input.value = ''
      input.click()
    } else {
      console.error(command)
    }
  } else {
    //replaceOriginal.value = null // Comment out for handleOpen
    selectedList.value = [...materialList.value]
    libraryRef.value?.open(selectedList.value, replaceOriginal.value, props.category)
  }
}

const onUploadProgress = (percentage: number) => {
  uploadProgress.value = percentage

  emit('upload:progress', props.limit === 1 && percentage === 100 ? 99.99 : percentage)
}
const onUploadSuccess = (uploadFile: UploadUserFile, material: Material) => {
  resetReplaceOriginal()

  if (props.limit === 1) {
    // for handleOpen
    uploadRef.value?.getUploadRef()?.value!.clearFiles()
    materialList.value = [material]
    emit('upload:progress', 100)
    return
  }

  materialList.value.some((obj, index) => {
    const file = obj as UploadUserFile
    if (file.uid && file.uid === uploadFile.uid) {
      if (isInSelectedList(material, materialList.value)) {
        ElMessage.info(t('已选用了重复的素材'))
        const ref = uploadRef.value?.getUploadRef()?.value as UploadInstance | null
        if (ref && uploadFile.raw) {
          ref.handleRemove(uploadFile.raw)
        }
        return true
      }
      materialList.value.splice(index, 1, material)
      return true
    }
    return false
  })

  emit('upload:success', uploadFile, material)
}
const createObjectURL = (uploadFile: UploadUserFile) => {
  if (!uploadFile.url && uploadFile.raw) {
    uploadFile.url = URL.createObjectURL(uploadFile.raw)
  }
  return uploadFile.url
}
const canceleUpload = (uploadFile: UploadUserFile) => {
  const ref = uploadRef.value?.getUploadRef()?.value as UploadInstance | null
  ref?.abort(uploadFile as UploadFile)

  resetReplaceOriginal()

  if (props.limit === 1) {
    uploadRef.value?.getUploadRef()?.value!.clearFiles()
    uploadProgress.value = 0
    return
  }

  materialList.value.some((obj, index) => {
    const file = obj as UploadUserFile
    if (file.uid === uploadFile.uid) {
      materialList.value.splice(index, 1)
      return true
    }
    return false
  })
}

const resetReplaceOriginal = () => {
  replaceOriginal.value = null
}

const replaceMaterial = (material: Material) => {
  replaceOriginal.value = material
  //selectedList.value = [...materialList.value] // Comment out for handleOpen
  //libraryRef.value?.open(selectedList.value, replaceOriginal.value, material.category) // Comment out for handleOpen

  uploadTypeRef.value?.handleOpen()
}
const isInSelectedList = (material: Material, materials?: Material[]) => {
  let found = false
  if (!materials) {
    materials = selectedList.value
  }
  materials.some((obj) => {
    found = obj.id === material.id
    return found
  })
  return found
}
const replaceAction = (material: Material) => {
  materialList.value.some((obj, index) => {
    if (obj.id === replaceOriginal.value?.id) {
      materialList.value.splice(index, 1, material)
      return true
    }
    return false
  })
  resetReplaceOriginal()
}

const selectedDone = (list: Material[]) => {
  materialList.value = list
  selectedList.value = []
}

const getUploadingState = () => {
  return uploadProgress.value > 0 && uploadProgress.value !== 100
}

const isUploading = computed(getUploadingState)

const popperClasses = computed(() => {
  const arr = ['choose-file-popper']
  if (props.isCustom || materialList.value.length) {
    arr.push('choose-file-popper-in-mini')
  }
  return arr.join(' ')
})
</script>

<template>
  <div
    :class="`choose-file-wrapper${props.isCustom ? ' is-custom' : ''} ${props.class} ${props.isCustom || materialList.length || uploadList.length ? 'upload-wrapper-mini' : 'no-file'}`"
  >
    <div class="upload-wrapper" v-loading="isUploading" element-loading-background="transparent">
      <UploadMaterial
        ref="uploadRef"
        class="choose-file-container"
        :class="{ 'choose-file-max': materialList.length >= props.limit }"
        :projId="projStore.projId"
        @upload:progress="onUploadProgress"
        @upload:success="onUploadSuccess"
        :product="props.product"
        :category="props.category"
        :categories="props.categories"
        :file-format="props.fileFormat"
        :limit="props.limit"
        :min-width-and-height="props.minWidthAndHeight"
        :max-width-and-height="props.maxWidthAndHeight"
        :max-file-size="props.maxFileSize"
        drag
        xv-model:file-list="materialList"
        v-model:file-list="uploadList"
        :before-upload="props.beforeUpload"
      >
        <slot name="extinfo"></slot>
        <slot>
          <div class="choose-file-info">
            <el-icon class="choose-file-icon"><IconUpload /></el-icon>
            <div class="choose-file-intro" v-if="props.limit == 1">
              {{ t('拖放或选择一个文件') }}
            </div>
            <div class="choose-file-intro" v-else>
              {{ t('拖放或选择最多{max}个文件', { max: props.limit }) }}
            </div>
            <div class="choose-file-tips">{{ props.fileFormat }}</div>
          </div>
        </slot>
        <el-dropdown
          ref="uploadTypeRef"
          class="choose-file-dropdown"
          :popper-class="popperClasses"
          :placement="props.isCustom || materialList.length ? 'bottom-start' : 'bottom-end'"
          @command="handleCommand"
          :xdisabled="materialList.length >= props.limit || isUploading"
          :disabled="isUploading"
          :trigger="props.trigger"
        >
          <div class="choose-file-placeholder">
            <div class="choose-file-upload-progress" v-if="isUploading">
              <span v-if="uploadProgress == 100">{{ t('处理中') }}...</span>
              <span v-else>{{ `${uploadProgress.toFixed(2)}%` }}</span>
            </div>
            <div class="el-button-group" v-if="!isUploading">
              <el-button type="primary">{{
                ['audio', 'voice'].includes(props.category)
                  ? t('上传音频')
                  : props.limit == 1
                    ? t('上传一个视频')
                    : t('上传视频')
              }}</el-button>
              <el-button type="primary" class="el-dropdown__caret-button">
                <el-icon><ArrowDown /></el-icon>
              </el-button>
            </div>
            <el-icon class="choose-file-plus" v-if="!isUploading"><Plus /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="chose-from-device" :icon="IconFolder">
                {{ t('来自本地磁盘') }}
              </el-dropdown-item>
              <el-dropdown-item command="chose-from-library" :icon="IconLibraryMaterial">
                {{ t('来自我的素材库') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </UploadMaterial>
    </div>
    <template v-if="!uploadList.length">
      <div class="choose-file-item" v-for="material in materialList" :key="material.id">
        <div class="thumbnail audio" v-if="['audio', 'voice'].includes(material.category)">
          <audio controls preload="metadata" :src="material.url"></audio>
        </div>
        <el-image
          class="thumbnail"
          fit="contain"
          :src="material.url"
          v-if="material.category == 'image'"
        />
        <el-image
          class="thumbnail"
          fit="contain"
          :src="material.url"
          v-if="material.category == 'video'"
        />
        <div class="media-duration" v-if="material.duration">
          {{ humanDuration(material.duration) }}
        </div>
        <div class="handle-media">
          <div @click="videoPlay(material)" v-if="material.category == 'video'">
            <el-icon><VideoPlay /></el-icon>
          </div>
          <div @click="replaceMaterial(material)">
            <el-icon><IconEditPen /></el-icon>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        class="choose-file-item"
        v-for="material in uploadList"
        :key="(material as UploadUserFile).uid"
      >
        <div
          class="thumbnail audio"
          v-if="(material as UploadUserFile).raw?.type.split('/')[0] == 'audio'"
        >
          <audio controls :src="createObjectURL(material)"></audio>
        </div>
        <el-image
          class="thumbnail"
          fit="contain"
          :src="createObjectURL(material)"
          v-if="(material as UploadUserFile).raw?.type.split('/')[0] == 'image'"
        />
        <div
          class="thumbnail"
          v-if="(material as UploadUserFile).raw?.type.split('/')[0] == 'video'"
        >
          <video
            controls
            playsinline
            controlsList="nodownload"
            :src="createObjectURL(material)"
          ></video>
        </div>
        <div class="media-duration" v-if="(material as UploadUserFile).percentage">
          <span v-if="(material as UploadUserFile).percentage == 100">{{ t('处理中') }}...</span>
          <span v-else>{{ (material as UploadUserFile).percentage }}%</span>
        </div>
        <div class="handle-media">
          <div @click="canceleUpload(material)">
            <el-icon><Close /></el-icon>
          </div>
        </div>
      </div>
    </template>

    <div class="is-process" v-if="uploadProgress == 100 && uploadList.length">
      <el-icon><SoundExtract /></el-icon>
      <div class="text">{{ t('从视频中抽取音频') }}...</div>
    </div>
  </div>

  <LibraryMaterial
    :product="props.product"
    :categories="props.categories"
    :limit="props.limit"
    :replace-original="replaceOriginal"
    @selected:done="selectedDone"
    @selected:replace="replaceAction"
    ref="libraryRef"
  />
</template>

<style lang="less">
.el-popper.choose-file-popper {
  --el-dropdown-menuItem-hover-fill: var(--my-color-y2);
  margin-top: -6px;

  &.choose-file-popper-in-mini {
    margin: -38px 0 0 45px;
  }

  .el-popper__arrow {
    display: none;
  }
}
</style>
<style scoped lang="less">
.choose-file-wrapper {
  width: 100%;

  .upload-wrapper {
    max-width: var(--my-max-upload-width);
    --my-border-width: 1px;
    height: calc(
      100vh - var(--el-main-padding) * 4 - var(--el-header-height) - var(--my-border-width) * 2 -
        var(--my-el-steps-height)
    );
    border: var(--my-border-width) dashed var(--my-color-primary-30);
    border-radius: 12px;
    background: var(--my-color-primary-6);
    margin: var(--el-main-padding) auto;

    :deep(.choose-file-container) {
      height: 100%;

      .el-upload {
        height: 100%;

        .el-upload-dragger {
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          //border: 1px dashed var(--my-color-primary-30);
          //--el-fill-color-blank: var(--my-color-primary-6);
          border: none;
          --el-fill-color-blank: transparent;

          border-radius: 12px;
          cursor: default;
          --el-upload-dragger-padding-horizontal: 0px;
          --el-upload-dragger-padding-vertical: 0px;

          .choose-file-info {
            .choose-file-icon {
              color: var(--my-color-black-60);
              font-size: 56px;
              margin-bottom: 16px;
            }

            .choose-file-intro {
              font-size: 20px;
              line-height: 26x;
              color: var(--my-color-black-89-90);
              margin-bottom: 8px;
            }

            .choose-file-tips {
              font-size: 14px;
              line-height: 22px;
              color: var(--my-color-black-40);
              margin-bottom: 32px;
            }
          }

          .choose-file-dropdown {
            // for progress text height and color
            line-height: 34px;

            &.is-disabled {
              --el-text-color-placeholder: var(--my-color-black-89-90);
            }

            .choose-file-placeholder {
              .el-button,
              &:focus-visible {
                outline: none;
              }

              .choose-file-plus {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.upload-wrapper-mini {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .upload-wrapper {
      width: 118px;
      height: 88px;
      border-radius: 8px;
      border-style: solid;
      background: transparent;
      margin: 0;

      :deep(.choose-file-container) {
        .el-upload {
          .el-upload-dragger {
            border-radius: 8px;
            position: relative;

            .choose-file-info {
              position: absolute;
              bottom: 6px;

              .choose-file-intro,
              .choose-file-icon {
                display: none;
              }

              .choose-file-intro,
              .choose-file-tips {
                font-size: 8px;
                line-height: 1;
                color: var(--my-color-black-40);
                margin-bottom: 0;
              }
            }

            .choose-file-dropdown {
              line-height: unset;

              position: absolute;
              width: 100%;
              height: 100%;

              .choose-file-placeholder {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: relative;

                .choose-file-upload-progress {
                  position: absolute;
                  top: 0;
                  font-size: 12px;
                }

                .el-button-group {
                  display: none;
                }

                .choose-file-plus {
                  font-size: 24px;
                  color: var(--el-color-primary);
                  display: inline-flex;
                }
              }
            }
          }
        }

        &.choose-file-max {
          .el-upload {
            .el-upload-dragger {
              .choose-file-info {
                .choose-file-intro {
                  display: block;
                }

                .choose-file-tips {
                  display: none;
                }
              }

              .choose-file-dropdown {
                .choose-file-placeholder {
                  .choose-file-plus {
                    color: var(--el-color-info);
                  }
                }
              }
            }
          }
        }
      }
    }

    &.is-custom {
      .upload-wrapper {
        position: relative !important;
        width: 100%;
        height: unset;
        border: 0;

        .choose-file-container {
          :deep(.el-upload) {
            .el-upload-dragger {
              display: block;

              .choose-file-dropdown {
                left: 0;
                top: 0;
                cursor: pointer;

                .choose-file-placeholder {
                  .choose-file-plus {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .choose-file-item {
        display: none;
      }
    }

    &:not(.is-custom) {
      .choose-file-item {
        &.choose-file-item-placeholder {
          display: flex; // for handleOpen
        }
      }
    }

    .is-process {
      position: absolute;
      left: 60px;
      top: 20px;
      background: var(--my-color-white-100);
      border-radius: 9px;
      display: flex;
      justify-content: center;

      .el-icon {
        font-size: 140px;
        height: unset;

        svg {
          height: 100%;
        }
      }

      .text {
        position: absolute;
        top: 0;
        font-size: 10px;
        color: var(--el-color-primary);
      }
    }
  }

  &:not(.no-file) {
    .upload-wrapper {
      position: absolute !important; // for handleOpen
      width: 0; // for handleOpen
      height: 40px; // for handleOpen
      border: 0; // for handleOpen
    }
  }
}

.choose-file-item {
  display: flex;
  position: relative;

  &.choose-file-item-placeholder {
    display: none; // for handleOpen
  }

  &.choose-file-item-pointer {
    cursor: pointer;
  }

  .thumbnail {
    width: 260px;
    height: 180px;
    border-radius: 8px;
    background: var(--my-color-black-100);

    &.audio {
      height: 90px;

      background: var(--my-color-black-10);
      display: flex;
      align-items: center;
      justify-content: center;

      audio {
        width: 100%;
      }
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  .media-duration {
    position: absolute;
    left: 6px;
    bottom: 6px;
    height: 16px;
    line-height: 16px;
    padding: 0 4px;
    border-radius: 4px;
    font-size: 10px;
    background: var(--my-color-black-50);
    color: var(--my-color-white-100);
  }

  .handle-media {
    position: absolute;
    right: 6px;
    top: 6px;
    --my-handle-icon-size: 16px;
    font-size: var(--my-handle-icon-size);
    color: var(--el-color-primary);

    display: none; // flex
    align-items: center;
    justify-content: center;
    gap: 6px;

    > div {
      --my-handle-wrapper-padding: 4px;
      width: calc(var(--my-handle-wrapper-size) + var(--my-handle-wrapper-padding));
      height: calc(var(--my-handle-wrapper-size) + var(--my-handle-wrapper-padding));
      background: var(--my-color-white-100);
      padding: var(--my-handle-wrapper-padding);
      border-radius: 4px;
      cursor: pointer;
    }

    .el-icon {
      display: flex;
    }
  }

  &:hover {
    .handle-media {
      display: flex;
    }
  }
}

@media screen and (max-width: 768px) {
  .choose-file-wrapper {
    .upload-wrapper {
      height: calc(
        100vh - var(--el-main-padding) * 3 - var(--el-header-height) - var(--my-border-width) * 2 -
          var(--my-el-steps-height)
      );
      margin: var(--el-main-padding) 0 0;
    }

    &.upload-wrapper-mini {
      gap: 8px;

      .upload-wrapper {
        height: 100%;
        margin: 0;

        :deep(.choose-file-container) {
          .el-upload {
            .el-upload-dragger {
              .choose-file-info {
                bottom: 3px;

                .choose-file-intro,
                .choose-file-tips {
                  font-size: 6px;
                }
              }

              .choose-file-dropdown {
                .choose-file-placeholder {
                  .choose-file-upload-progress {
                    font-size: 10px;
                    line-height: 1;
                    top: 3px;
                  }

                  .choose-file-plus {
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }

      .choose-file-item {
        .handle-media {
          display: flex;
          --my-handle-icon-size: 12px;
        }
      }
    }
  }
}
</style>
