<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6671 8.00016C13.6671 11.1298 11.13 13.6668 8.00041 13.6668C4.87079 13.6668 2.33374 11.1298 2.33374 8.00016C2.33374 4.87055 4.87079 2.3335 8.00041 2.3335C11.13 2.3335 13.6671 4.87055 13.6671 8.00016ZM14.6671 8.00016C14.6671 11.6821 11.6823 14.6668 8.00041 14.6668C4.31851 14.6668 1.33374 11.6821 1.33374 8.00016C1.33374 4.31826 4.31851 1.3335 8.00041 1.3335C11.6823 1.3335 14.6671 4.31826 14.6671 8.00016ZM7.50024 5C7.50024 4.72386 7.27639 4.5 7.00024 4.5C6.7241 4.5 6.50024 4.72386 6.50024 5V9C6.50024 9.27614 6.7241 9.5 7.00024 9.5H11.0002C11.2764 9.5 11.5002 9.27614 11.5002 9C11.5002 8.72386 11.2764 8.5 11.0002 8.5H7.50024V5Z"
      fill="currentColor"
    />
  </svg>
</template>
