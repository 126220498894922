<script setup lang="ts">
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  onActivated,
  onDeactivated,
  computed,
  watch,
  nextTick,
  h,
  getCurrentInstance
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import {
  type FormInstance,
  type FormRules,
  type TableInstance,
  type UploadInstance,
  type UploadFile,
  type UploadUserFile,
  ElButton
} from 'element-plus'

import { ElMessage, type MessageBoxState } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import { Plus, Warning } from '@element-plus/icons-vue'
/*import {
  Delete as IconDelete,
} from '@element-plus/icons-vue'*/

import IconDelete from '@/components/icons/IconDelete.vue'
import IconTranslate from '@/components/icons/IconTranslate.vue'
import IconDiamond from '@/components/icons/IconDiamond.vue'
import SoundConvert from '@/components/icons/SoundConvert.svg?raw'
import ExtInfo from '@/components/ExtInfo.vue'

import UploadMaterialWrapper from '@/components/UploadMaterialOne.vue'
import SoundPlayer from '@/components/SoundPlayer.vue'
import VoiceTemplateSelect from '@/components/VoiceTemplateSelect.vue'
import VoiceClone from '@/components/VoiceClone.vue'
import VideoTemplateSelect from '@/components/VideoTemplateSelect.vue'
import VideoPreview from '@/components/VideoPreview.vue'

import {
  type Material,
  type VoiceTemplate,
  type VideoTemplate,
  type TranslateScript,
  type VideoTranslationTaskCreateMessage,
  type VideoTranslationCostDetail,
  createVideoTranslateTask,
  genTranslateScript,
  doTranslateScripts,
  doTranslate,
  type Voice,
  autoDetectLanguage
} from '@/api'
import { costQuotasMap } from '@/config'
import { getLanguages, type LanguageOption } from '@/common'
import router from '@/router'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { humanDuration } from '@/utils'
import {
  alertCantDetectLanguage,
  alertLanguageUnSupport,
  alertOriginAndTargetLanguageSame
} from '@/utils/alertConfirm'
import { showSubmitConfirm, showVTSubmitConfirm } from '@/utils/creditsCostConfirm'
import { useUserStore } from '@/stores/user'

const { t } = useI18n()
const route = useRoute()
const projStore = useProjStore()
const userStore = useUserStore()

onActivated(() => {
  replaceUrlParams()
})

let requestTimers: Array<number | undefined> = []
const clearRequestTimeouts = () => {
  if (requestTimers?.length) {
    while (requestTimers.length) {
      clearTimeout(requestTimers.pop())
    }
  }
}

let requestTask: AbortController | null = null
const isLoading = ref(false)

const extInfoRef = ref()
const isSubmitting = ref(false)
const uploadRef = ref<typeof UploadMaterialWrapper>()
const uploadProgress = ref(0)
const currentStep = ref(1)
const tableRef = ref<TableInstance>()
const translateEditFocus = ref('-1-0')
const translateEditOriginalErrors = ref<TranslateScript[]>([])
const translateEditTranslatedErrors = ref<TranslateScript[]>([])
const translateObj = ref<TranslateScript | null>()
const formItemScriptListError = ref('')
const formItemVoiceListError = ref('')
const soundPlayerRef = ref<typeof SoundPlayer>()
//const voicePlayerRef = ref<typeof SoundPlayer>()
const voicePlayerRef = ref<HTMLAudioElement>()
const currentTime = ref(-1)
const dialogVisibleChooseVoice = ref(false)
const dialogVisibleCloneVoice = ref(false)
const dialogVisibleVideoTemplate = ref(false)
const dialogVisibleVideoPreview = ref(false)
const formBackStr = ref('')
const asrReady = ref(false)
const loadingAutoDetect = ref(false)

const targetLanguages = getLanguages('video-translation')
const originalLanguages = (
  [
    /*{
      label: () => t('自动侦测'),
      value: 'auto-detect'
    },*/
  ] as ReturnType<typeof getLanguages>
).concat(getLanguages('video-translation', true))
const numberOfSpeackers: {
  label: string
  value: number
}[] = [
  {
    label: t('一个发言人'),
    value: 1
  },
  {
    label: t('{number}个发言人', { number: 2 }),
    value: 2
  },
  {
    label: t('{number}个发言人', { number: 3 }),
    value: 3
  }
]

const formRef = ref<FormInstance>()

interface Form {
  // step 1
  materialList: Material[]
  targetLanguage?: LanguageOption
  originalLanguage: LanguageOption
  numberOfSpeacker: number
  // step 2
  scriptList: TranslateScript[]
  voiceList: VoiceTemplate[]
  videoTemplates: VideoTemplate[]
  videoAnimation: VideoTemplate['animations'][0] | null
  lipSync: boolean
  useHd: boolean
}

const getDefaultFormData = () => ({
  // step 1
  materialList: [],
  originalLanguage: originalLanguages[0],
  targetLanguage: undefined,
  numberOfSpeacker: numberOfSpeackers[0].value,
  // step 2
  scriptList: [],
  voiceList: [],
  videoTemplates: [],
  videoAnimation: null,
  lipSync: false,
  useHd: false
})
const form = reactive<Form>(getDefaultFormData())
const checkVideoDuration = (rule: object, value: Material[], callback: (error?: Error) => void) => {
  if (!value.length) {
    callback()
    return
  }
  let noAudio = false
  let duration = 0
  value.some((material) => {
    if (!material.audio) {
      noAudio = true
    }
    duration += material.duration / 1000
    return noAudio
  })

  if (noAudio) {
    callback(new Error(t('请确认视频中是否存在音频')))
    return
  }
  const min = 10
  const max = 120
  if (duration < min || duration > max) {
    callback(new Error(t('视频时长需要在{min}-{max}秒之间', { min, max })))
    return
  }
  callback()
}
const rules = reactive<FormRules<Form>>({
  // age: [{ validator: checkAge, trigger: 'blur' }],
  // projId: [],
  materialList: [{ validator: checkVideoDuration }],
  targetLanguage: [],
  originalLanguage: [],
  numberOfSpeacker: []
})
const checkScript = (rule: object, value: TranslateScript[], callback: (error?: Error) => void) => {
  let bad = false
  translateEditOriginalErrors.value = []
  translateEditTranslatedErrors.value = []
  value.forEach((script) => {
    if (!script.source_text.trim()) {
      translateEditOriginalErrors.value.push(script)
      bad = true
    }
    if (!script.text.trim()) {
      translateEditTranslatedErrors.value.push(script)
      bad = true
    }
  })
  if (bad) {
    if (translateEditOriginalErrors.value.length && translateEditTranslatedErrors.value.length) {
      callback(new Error(t('原始脚本和译后脚本有缺失部分，请补充')))
    } else if (translateEditOriginalErrors.value.length) {
      callback(new Error(t('原始脚本有缺失部分，请补充')))
    } else {
      callback(new Error(t('译后脚本有缺失部分，请补充')))
    }
    return
  }
  callback()
}
const rulesStep2 = reactive<FormRules<Form>>({
  scriptList: [{ validator: checkScript }],
  voiceList: [],
  videoTemplates: [],
  lipSync: [],
  useHd: []
})

const replaceUrlParams = () => {
  //router.replace({ params: { projId: projStore.projId || '-' } })
  history.replaceState(
    {},
    '',
    location.hash
      .replace(/\/-$/, `/${projStore.projId || '-'}`)
      .replace(/\/\d+$/, `/${projStore.projId || '-'}`)
  )
}

onMounted(() => {
  watch(
    () => form.materialList.length,
    (val) => {
      if (!val) {
        uploadProgress.value = 0
      }
    }
  )
  watch(
    () => form.materialList,
    () => {
      formRef.value?.validateField('materialList')
    }
  )

  watch(
    () => form.originalLanguage.value,
    (val) => {
      if (form.targetLanguage?.value && val === form.targetLanguage.value) {
        targetLanguages.some((language) => {
          if (language.value !== val) {
            form.targetLanguage = language
            return true
          }
          return false
        })
      }
    }
  )

  watch(
    () => form.scriptList.length,
    (val) => {
      if (val) {
        formItemScriptListError.value = ''
      }
    }
  )

  watch(
    () => form.voiceList.length,
    (val) => {
      if (val) {
        formItemVoiceListError.value = ''
      } else {
        // 如果清空声音列表，需要联动清空lipsync
        form.lipSync = false
      }
    }
  )
})

const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  abortRequest()
  clearRequestTimeouts()
})

const onUploadProgress = (percentage: number) => {
  uploadProgress.value = percentage
}
const isUploading = computed(() => {
  return uploadProgress.value > 0 && uploadProgress.value !== 100
})

const hasVoiceConfig = computed(() => {
  return form.voiceList?.length || form.videoTemplates?.length
})

const enableSubmit = computed(() => {
  if (isUploading.value) {
    return false
  }

  // 第一步有个特殊的逻辑，需要选中源和目标
  if (currentStep.value === 1) {
    return form.originalLanguage && form.targetLanguage && form.materialList?.[0]?.audio
  }

  // 第三步有个特殊的逻辑，需要voicelist和字幕至少选择一个才可以进行下一步
  if (currentStep.value === 2) {
    return hasVoiceConfig.value
  }

  return true
})

const doCreate = async () => {
  if (isSubmitting.value || !enableSubmit.value) {
    return
  }

  const { resolution } = form.materialList[0]

  const seconds = form.materialList[0].duration / 1000
  const minutes = Math.ceil(seconds / 60)
  const basic = costQuotasMap['video-translation-basic']
  const costDetail: VideoTranslationCostDetail = {
    basic: basic * minutes
  }
  let costQuotas = basic
  const clone = getVoiceClone()
  if (clone?.audio_files.length) {
    const voiceClone = costQuotasMap['video-translation-voice-clone']
    costQuotas += voiceClone
    costDetail.voiceClone = voiceClone * minutes
  }
  if (form.lipSync) {
    const lipSync = costQuotasMap['video-translation-lip-sync']
    costQuotas += lipSync
    costDetail.lipSync = lipSync * minutes
  }
  if (form.useHd) {
    const hd = costQuotasMap['video-translation-hd']
    costQuotas += hd
    costDetail.hd = hd * minutes
  }
  costQuotas *= minutes

  if (costQuotas <= 0) {
    doCreateAction(costQuotas, costDetail)
    return
  }

  if (projStore.total_remaining < costQuotas) {
    isSubmitting.value = true
    await fetchSubscriptionPlanAndCreditDetails(projStore)
    isSubmitting.value = false
  }

  showVTSubmitConfirm(
    {
      title: t('提交视频翻译任务'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      seconds,
      minutes,
      resolution,
      costDetail,
      feedbackTypes: userStore.feedbackTypes
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true

          doCreateAction(costQuotas, costDetail, instance, done)
        } else {
          done()
        }
      }
    }
  )
}
const costQuotasLipSync = computed(() => {
  if (!form.materialList.length) {
    return 0
  }
  const seconds = form.materialList[0].duration / 1000
  const minutes = Math.ceil(seconds / 60)
  const voiceClone = costQuotasMap['video-translation-lip-sync']
  return voiceClone * minutes
})
const costQuotasHd = computed(() => {
  if (!form.materialList.length) {
    return 0
  }
  const seconds = form.materialList[0].duration / 1000
  const minutes = Math.ceil(seconds / 60)
  const voiceClone = costQuotasMap['video-translation-hd']
  return voiceClone * minutes
})
const getVoiceClone: () => Voice['clone'] | void = () => {
  if (!form.voiceList?.length) {
    return
  }

  const isFromOriginalVideo = form.voiceList[0].address === form.materialList[0].address
  const clone = {
    from_video: isFromOriginalVideo, // 仅用于前端还原展示
    audio_files: form.voiceList
      .map((material) => {
        return isFromOriginalVideo ? material.audio : material.address
      })
      .filter((str) => !!str)
  }
  return clone
}
const doCreateAction = (
  costQuotas: number,
  costDetail: VideoTranslationCostDetail,
  instance?: MessageBoxState,
  done?: () => void
) => {
  abortRequest()
  isSubmitting.value = true

  const controller = new AbortController()
  requestTask = controller
  const config: VideoTranslationTaskCreateMessage = {
    id: '', // 后台赋值
    memo: {
      proj_id: projStore.projId,
      task_id: '', // 后台赋值
      request_id: '', // 后台赋值
      //... // 后台赋值
      video_id: form.materialList[0].id, // 仅用于前端还原展示
      video_duration: form.materialList[0].duration, // 用于前端还原展示、计价
      video_resolution: form.materialList[0].resolution, // 用于前端还原展示、计价
      cost_detail: costDetail // 用于前端还原展示、计价
    },
    video: form.materialList[0].address,
    audio: form.materialList[0].audio,
    language: {
      source: form.originalLanguage.value,
      target: form.targetLanguage?.value!
    },
    scripts: form.scriptList.map((script) => {
      return {
        source_text: script.source_text, // 仅用于前端还原展示
        text: script.text,
        start: script.start_time,
        end: script.end_time
      }
    }),
    text: {
      font_path: 's3://s3-vmeg-pro/web/fonts/',
      template: form.videoTemplates[0]?.config || null,
      //title: '',
      subtitle: !!form.videoTemplates[0],
      animation: form.videoAnimation?.name
    },
    //watermarks: [],
    lipsync: {
      enable: !!form.lipSync
    },
    //hd: {},
    output: {
      build_folder: '' // 后台赋值
      //video: '', // 后台赋值
      //thumbnail: '' // 后台赋值
      //fps: 30 // 默认与原视频一致
    }
  }
  if (form.useHd) {
    config.hd = {
      enable: true
    }
  }

  if (form.voiceList?.length) {
    const commonConfig = {
      speed: 1,
      tags: form.voiceList[0]?.tags || []
    }

    if (!config.voice?.voice_id) {
      const clone = getVoiceClone()
      if (clone?.audio_files.length) {
        config.voice = {
          ...commonConfig,
          clone,
          provider: 'minimax' // // xtts | minimax | elevenlabs 支持声音克隆
        }
      } else {
        config.voice = {
          ...commonConfig,
          provider: form.voiceList[0]?.provider || '',
          voice_id: form.voiceList[0]?.voiceId || undefined
        }
      }
    }
  }

  createVideoTranslateTask(
    {
      projId: projStore.projId,
      title: form.materialList[0].name,
      config
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('任务提交异常'))
        return
      }
      ElMessage.success(res.data.message || t('任务提交成功'))
      resetForm(formRef.value)
      if (costQuotas > 0) {
        projStore.$patch({
          total_remaining: projStore.total_remaining - costQuotas
        })
        fetchSubscriptionPlanAndCreditDetails(projStore)
      }
      router.push({
        name: 'vmeg.video-translation.my-tasks',
        params: { projId: projStore.projId }
      })

      if (done) {
        done()
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('任务提交失败'))
    })
    .finally(() => {
      if (instance) {
        instance.confirmButtonLoading = false
      }

      isSubmitting.value = false
    })
}
const backToStep1 = () => {
  if (currentStep.value === 1) {
    return
  }
  formBackStr.value = getDataStr()
  stopAllSound()
  currentStep.value = 1
}
const getDataStr = () => {
  return JSON.stringify(
    Object.assign({}, form, {
      scriptList: []
    })
  )
}
const resetStep2 = () => {
  form.scriptList = []
  form.voiceList = []
  form.videoTemplates = []
  form.videoAnimation = null
  form.lipSync = false
  form.useHd = false
}
const gotoStep2 = () => {
  uploadRef.value?.getUploadTypeRef()?.handleClose()
  currentStep.value = 2
  if (formBackStr.value) {
    if (formBackStr.value === getDataStr()) {
      formBackStr.value = ''
      if (!form.scriptList.length && !requestTimers.length) {
        fetchTranslateScript()
      }
      return
    }
    resetStep2()
  }

  clearRequestTimeouts()
  fetchTranslateScript()
}
let totalTimeout = 0
const fetchTranslateScript = (
  forceRedo: boolean = false,
  isDoTranslateScripts: boolean = false
) => {
  asrReady.value = false

  abortRequest()
  isLoading.value = true

  const controller = new AbortController()
  requestTask = controller
  ;(isDoTranslateScripts
    ? doTranslateScripts(
        {
          projId: projStore.projId,
          originalLanguage: form.originalLanguage.value,
          targetLanguage: form.targetLanguage?.value!,
          audio: form.materialList[0].audio,
          scripts: form.scriptList.map((s) => {
            const script = Object.assign({}, s)
            delete script.$index
            delete script.$empty
            script.text = ''
            return script
          })
        },
        {
          signal: controller.signal
        }
      )
    : genTranslateScript(
        {
          projId: projStore.projId,
          originalLanguage: form.originalLanguage.value,
          targetLanguage: form.targetLanguage?.value!,
          audio: form.materialList[0].audio,
          forceRedo
        },
        {
          signal: controller.signal
        }
      )
  )
    .then((res) => {
      asrReady.value = res.data.asrReady || false
      if (res.data.code) {
        isLoading.value = false
        ElMessage.error(res.data.message || t('获取视频脚本异常'))
        return
      }
      if (res.data.data) {
        isLoading.value = false

        setScriptList(res.data.data)

        translateEditFocus.value = '0-2'
        tableRef.value?.doLayout()
        return
      }
      totalTimeout = 15 * 60 * 1000 // 15 minutes
      const timeout = 10 * 1000 // 10 seconds
      requestTimers.push(
        window.setTimeout(() => {
          totalTimeout -= timeout
          queryTranslateScript(timeout)
        }, timeout)
      )
    })
    .catch((err) => {
      isLoading.value = false
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('获取视频脚本失败'))
    })
}
const setScriptList = (scripts: TranslateScript[]) => {
  form.scriptList = scripts.map((script) => {
    script.$empty = !(script.text || '').trim()
    return script
  })
}
const translateAll = async () => {
  /*ElMessageBox.confirm(
    t('如果之前已经修改过原始脚本和译后脚本，将被自动识别和翻译结果覆盖'),
    t('确定要重新翻译全部脚本吗？'),
    {
      confirmButtonText: t('确认')
    }
  )
    .then(() => {
      fetchTranslateScript(true)
    })
    .catch(() => {})*/
  if (isLoading.value) {
    return
  }

  const costQuotas = costQuotasMap['translate-script']

  if (costQuotas <= 0) {
    translateAllAction(costQuotas)
    return
  }

  if (projStore.total_remaining < costQuotas) {
    isLoading.value = true
    await fetchSubscriptionPlanAndCreditDetails(projStore)
    isLoading.value = false
  }

  showSubmitConfirm(
    {
      title: t('重新翻译全部脚本'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      feedbackTypes: userStore.feedbackTypes
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true

          translateAllAction(costQuotas, instance, done)
        } else {
          done()
        }
      }
    }
  )
}
let costInfo: {
  costQuotas: number
} | null = null
const translateAllAction = (costQuotas: number, instance?: MessageBoxState, done?: () => void) => {
  costInfo = {
    costQuotas
  }
  if (instance) {
    instance.confirmButtonLoading = false
  }
  if (done) {
    done()
  }
  fetchTranslateScript(false, true)
}
const translateAllDone = () => {
  if (!costInfo) {
    return
  }
  projStore.$patch({
    total_remaining: projStore.total_remaining - costInfo.costQuotas
  })
  fetchSubscriptionPlanAndCreditDetails(projStore)
  costInfo = null
}
const queryTranslateScript = (timeout: number) => {
  abortRequest()
  isLoading.value = true

  const controller = new AbortController()
  requestTask = controller
  genTranslateScript(
    {
      projId: projStore.projId,
      originalLanguage: form.originalLanguage.value,
      targetLanguage: form.targetLanguage?.value!,
      audio: form.materialList[0].audio,
      queryResult: true
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (['ASR_OR_TRANSLATE_ERROR', 'DETECT_LANGUAGE_ERROR'].includes(res.data.errorCode)) {
        isLoading.value = false
        formItemScriptListError.value =
          `${t('获取视频脚本失败')}.` +
          `${res.data.errorCode === 'DETECT_LANGUAGE_ERROR' ? ` ${t('请检查原始语言选择是否正确')}` : ''}`
        ElMessage.error(formItemScriptListError.value)
        return
      }
      asrReady.value = res.data.asrReady || false
      if (res.data.data) {
        isLoading.value = false

        setScriptList(res.data.data)

        translateEditFocus.value = '0-2'
        tableRef.value?.doLayout()

        translateAllDone()
        return
      }
      if (totalTimeout <= 0) {
        isLoading.value = false
        ElMessage.error(`${t('获取视频脚本异常')}.`)
        return
      }
      requestTimers.push(
        window.setTimeout(() => {
          totalTimeout -= timeout
          queryTranslateScript(timeout)
        }, timeout)
      )
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        isLoading.value = false
        return
      }
      if (totalTimeout <= 0) {
        isLoading.value = false
        ElMessage.error(err.message || `${t('获取视频脚本失败')}.`)
        return
      }
      requestTimers.push(
        window.setTimeout(() => {
          totalTimeout -= timeout
          queryTranslateScript(timeout)
        }, timeout)
      )
    })
}

const findMatchedLanguage = (language: string): LanguageOption | undefined => {
  // 因为server返回的是语言key前缀，例如zh，en，跟本地的不一定能完全match，所以做前缀匹配即可
  return originalLanguages.find((lan) => lan.value.indexOf(language) > -1)
}

type PassDetect = boolean
const detectLanguageAndFix: () => Promise<PassDetect> = async () => {
  // 选了确定的源就不需要检测了
  if (form.originalLanguage.value !== 'auto') {
    return true
  }

  let resp
  loadingAutoDetect.value = true
  try {
    resp = await autoDetectLanguage({
      projId: projStore.projId,
      audio: form.materialList[0].audio
    })
  } catch (e) {
    // 不需要处理，后面判断里统一提示错误
  } finally {
    loadingAutoDetect.value = false
  }

  const { language, confidence } = resp?.data?.data || {}

  // 以下两种情况会统一处理为无法识别，并引导用户去自行选择
  // 1.系统异常导致识别失败
  // 2.置信度较低
  if (!language || !confidence || confidence < 0.6) {
    await alertCantDetectLanguage()
    return false
  }

  const matchedLanguage = findMatchedLanguage(language)

  if (language === form.targetLanguage?.value!) {
    await alertOriginAndTargetLanguageSame()
    return false
  }

  if (!matchedLanguage) {
    await alertLanguageUnSupport(language, () => {
      extInfoRef?.value?.showTips()
    })
    return false
  }

  form.originalLanguage = matchedLanguage
  return true
}

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return
  }
  formEl.validate(async (isValid) => {
    if (isValid) {
      if (currentStep.value === 1) {
        // 检查视频源语言和目标语言是否匹配
        const pass = await detectLanguageAndFix()

        pass && gotoStep2()
        return
      }

      if (!form.scriptList.length) {
        formItemScriptListError.value = t('原始脚本和译后脚本异常，请稍后再试')
        throw Error(t('原始脚本和译后脚本异常，请稍后再试'))
      }
      // if (!form.voiceList.length) {
      //   formItemVoiceListError.value = t('{object}必选', { object: t('AI声音') })
      //   return Promise.reject()
      // }
      doCreate()
      return
    }
    return
  })
}
const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return
  }

  const ref = uploadRef.value?.getUploadRef()?.value as UploadInstance | null
  if (ref) {
    form.materialList.forEach((material) => {
      const file = material as UploadUserFile
      ref.abort(file as UploadFile)
    })
    ref.clearFiles()
  }
  uploadRef.value?.getUploadTypeRef()?.handleClose()

  formEl.resetFields()

  formItemScriptListError.value = ''
  formItemVoiceListError.value = ''

  Object.assign(form, getDefaultFormData())
  // 这里要延迟清除一下校验状态，否则可能在vt第二步的目标语言那里会提示必填
  setTimeout(() => {
    formEl.clearValidate()
  })

  isLoading.value = false
  isSubmitting.value = false
  uploadProgress.value = 0 // isUploading.value = false
  currentStep.value = 1
  outerClick() // translateEditFocus.value = '-1-0'
  translateEditOriginalErrors.value = []
  translateEditTranslatedErrors.value = []
  translateObj.value = null
  stopAllSound()
  currentTime.value = -1
  formBackStr.value = ''
  asrReady.value = false
  dialogVisibleVideoPreview.value = false

  abortRequest()
  clearRequestTimeouts()
}
onDeactivated(() => {
  stopAllSound()
})

const getMediaSrc = (material: Material) => {
  if (!material) {
    return ''
  }
  const { url, audio, address } = material
  if (!audio && !address) {
    return url
  }
  if (audio && (audio.includes('https://') || audio.includes('http://'))) {
    return audio
  }
  const filename = (audio ? audio : address).split('/').slice(-1).join('')
  return `${url.split('/').slice(0, -1).join('/')}/${filename}`
}

const onSoundPlay = (id: string) => {
  if (id === soundPlayerRef.value?.id) {
    voicePlayerRef.value?.pause()
  } else if (id === voicePlayerRef.value?.id) {
    soundPlayerRef.value?.pause()
  }
}
const onSoundTimeUpdate = (currTime: number) => {
  currentTime.value = currTime

  if (tableRef.value) {
    const el = document.querySelector('.is-in-current-time') as HTMLDivElement | null
    if (el && el.dataset?.rowIndex) {
      tableScrollToRow(tableRef.value, Number(el.dataset.rowIndex), true)
    }
  }
}

function tableScrollToRow(
  tableElement: TableInstance,
  rowIndex: number,
  isPrecise: boolean = false
) {
  // rowIndex：定位到行号
  // isPrecise：是否精确计算行高，默认是false不计算，只有第一行的行高

  const wrapper = tableElement.$el.querySelector('.el-scrollbar__wrap') as HTMLDivElement
  const theTableRows = wrapper.querySelectorAll('.el-table__row')

  let isVisible = false
  let scrollTop = 0
  for (let i = 0; i < theTableRows.length; i++) {
    const { offsetHeight } = theTableRows[i] as HTMLDivElement
    if (i === rowIndex) {
      if (
        scrollTop + offsetHeight - wrapper.scrollTop < wrapper.offsetHeight &&
        wrapper.scrollTop < scrollTop
      ) {
        isVisible = true
      }
      break
    }
    scrollTop += offsetHeight
    if (!isPrecise) {
      scrollTop *= rowIndex - 2
      break
    }
  }
  if (!isVisible) {
    tableElement.setScrollTop(scrollTop)
  }
}

const isInCrrentTime = (script: TranslateScript) => {
  return script.start_time <= currentTime.value && script.end_time >= currentTime.value
}

const stopAllSound = () => {
  soundPlayerRef.value?.stop()
  //voicePlayerRef.value?.stop()
  voicePlayerRef.value?.pause()
}

const tableRowClassName = (data: { row: TranslateScript; rowIndex: number }) => {
  return isInCrrentTime(data.row) ? 'hover-row' : ''
}
const tableCellClassName = (data: {
  row: TranslateScript
  column: any
  rowIndex: number
  columnIndex: number
}) => {
  data.row.$index = data.rowIndex
}
const tableCellClick = (
  row: TranslateScript,
  column: any,
  cell: HTMLTableCellElement,
  event: Event
) => {
  event.stopPropagation()
  translateEditFocus.value = `${row.$index}-${column.getColumnIndex()}`
  nextTick(() => {
    cell.querySelector('textarea')?.focus()
  })
}
const outerClick = () => {
  translateEditFocus.value = '-1-0'
}

const translateOne = async (data: { row: TranslateScript; $index: number }) => {
  if (translateObj.value) {
    return
  }

  const costQuotas = costQuotasMap['translate-text']

  if (costQuotas <= 0) {
    translateOneAction(costQuotas)
    return
  }

  if (projStore.total_remaining < costQuotas) {
    await fetchSubscriptionPlanAndCreditDetails(projStore)
  }

  showSubmitConfirm(
    {
      title: t('重新翻译单条脚本'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      feedbackTypes: userStore.feedbackTypes
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          translateObj.value = data.row

          translateOneAction(costQuotas)
        }
        done()
      }
    }
  )
}
const translateOneAction = (costQuotas: number) => {
  if (!translateObj.value) {
    return
  }

  abortRequest()

  const controller = new AbortController()
  requestTask = controller
  doTranslate(
    {
      projId: projStore.projId,
      originalLanguage: form.originalLanguage.value,
      targetLanguage: form.targetLanguage?.value!,
      text: translateObj.value.source_text
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code || !res.data.data?.text) {
        ElMessage.error(res.data.message || t('翻译视频脚本失败'))
        return
      }
      form.scriptList.some((script) => {
        if (script.$index === translateObj.value?.$index) {
          script.text = res.data.data.text
          formRef.value?.validateField('scriptList')
          return true
        }
        return false
      })
      if (costQuotas > 0) {
        projStore.$patch({
          total_remaining: projStore.total_remaining - costQuotas
        })
        fetchSubscriptionPlanAndCreditDetails(projStore)
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('翻译视频脚本失败'))
    })
    .finally(() => {
      translateObj.value = null
    })
}

const selectedVoice = (materials: VoiceTemplate[]) => {
  form.voiceList = materials
}
const openCloneVoice = () => {
  dialogVisibleCloneVoice.value = true
}

const selectedSubtitle = (
  materials: VideoTemplate[],
  animation: VideoTemplate['animations'][0]
) => {
  form.videoTemplates = materials
  if (isSubtitleStyle) {
    form.videoAnimation = animation
  }
}

const getWordCount = (text: string) => {
  return ['zh-CN', 'ja-JP'].includes(form.targetLanguage?.value!)
    ? text.length
    : text.split(' ').length
}
const unmatchWordCount = (script: TranslateScript) => {
  if (script.word_range?.length !== 2) {
    return false
  }
  const words = getWordCount(script.text)
  return words < script.word_range[0] || words > script.word_range[1]
}
const isNoTitle = true
const isSubtitleStyle = false
const getThumbnailUrl = (url: string) => {
  if (isSubtitleStyle) {
    return url
      .replace('/templates/full-style/', '/templates/subtitle-style/')
      .replace('/1x/H', '/3x/')
      .replace('/1x/W', '/3x/')
  }
  return url.replace('/1x/', '/2x/')
}
const getBgUrl = (url: string) => {
  return `${url.split('/templates/')[0]}/templates/full-bg/1x/${url.split('/').slice(-1)}`.replace(
    '.png',
    '.webp'
  )
}

const showVTOptionTips = computed(() => {
  return currentStep.value == 2 && !hasVoiceConfig.value
})
</script>

<template>
  <el-form
    :class="{ 'form-wrapper-mini': form.materialList.length || isUploading }"
    ref="formRef"
    :model="form"
    :rules="currentStep == 2 ? rulesStep2 : rules"
    label-position="top"
    require-asterisk-position="right"
    scroll-to-error
    :scroll-into-view-options="{ behavior: 'smooth' }"
    @click="outerClick"
  >
    <el-form-item class="choose-file-form-item steps">
      <el-steps :active="form.materialList.length ? currentStep : 0" finish-status="success">
        <el-step :title="t('上传视频')" />
        <el-step
          :title="t('翻译信息')"
          :class="{ clickable: currentStep == 2 }"
          @click="backToStep1"
        />
        <el-step
          :title="t('脚本预览')"
          :class="{ clickable: currentStep == 1 && form.materialList.length }"
          @click="currentStep == 1 && form.materialList.length && submitForm(formRef)"
        />
        <el-step :title="t('渲染视频')" />
      </el-steps>
    </el-form-item>

    <el-form-item
      class="choose-file-form-item"
      :class="{ 'g-display-none': currentStep > 1 }"
      :label="t('媒体')"
      prop="materialList"
    >
      <UploadMaterialWrapper
        ref="uploadRef"
        v-model="form.materialList"
        @upload:progress="onUploadProgress"
        product="video-translation"
        :categories="['video']"
        :file-format="t('MP4、MOV、WEBM')"
      >
        <template #extinfo>
          <ExtInfo ref="extInfoRef" />
        </template>
      </UploadMaterialWrapper>
    </el-form-item>
    <el-form-item :class="{ 'g-display-none': currentStep > 1 }">
      <el-col :span="12">
        <el-form-item :label="t('原始语言')" prop="originalLanguage" required>
          <el-select v-model="form.originalLanguage">
            <template v-for="item in originalLanguages" :key="item.value">
              <el-option :label="item.label()" :value="item" :value-key="item.value" />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="t('目标语言')" prop="targetLanguage" required>
          <el-select v-model="form.targetLanguage" :placeholder="t('请选择目标语言')">
            <template v-for="item in targetLanguages" :key="item.value">
              <el-option
                :label="item.label()"
                :value="item"
                :value-key="item.value"
                v-if="item.value != form.originalLanguage.value"
              />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="t('发言人数')" prop="numberOfSpeacker" style="display: none">
          <el-select v-model="form.numberOfSpeacker">
            <el-option
              v-for="item in numberOfSpeackers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-form-item>

    <el-form-item
      class="choose-file-form-item"
      :class="{ 'g-display-none': currentStep != 2 }"
      :label="t('脚本预览')"
      prop="scriptList"
      :error="formItemScriptListError"
    >
      <div class="voice-player-wrapper">
        <!-- <SoundPlayer
          ref="voicePlayerRef"
          :src="getMediaSrc(form.materialList[0])"
          id="voicePlayerRef"
          @play="onSoundPlay('voicePlayerRef')"
          @timeupdate="onSoundTimeUpdate"
          v-if="currentStep == 2 || formBackStr"
          :size="24"
        /> -->
        <audio
          ref="voicePlayerRef"
          :src="getMediaSrc(form.materialList[0])"
          id="voicePlayerRef"
          @play="onSoundPlay('voicePlayerRef')"
          @timeupdate="
            () => {
              onSoundTimeUpdate(voicePlayerRef?.currentTime!)
            }
          "
          v-if="currentStep == 2 || formBackStr"
          style="height: 24px"
          controls
          controlslist="nodownload"
        ></audio>
      </div>
      <el-table
        ref="tableRef"
        :data="form.scriptList"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
        @cell-click="tableCellClick"
        :max-height="300"
        v-loading="isLoading"
        :element-loading-text="`${t('声音转写和翻译')}...`"
        :element-loading-svg="SoundConvert"
        element-loading-svg-view-box="0, 0, 240, 200"
        :class="asrReady ? 'custom-loading-translate' : 'custom-loading-transcribe'"
      >
        <el-table-column :label="t('时间')" width="100" fixed>
          <template #default="scope">
            <div
              :class="{ 'is-in-current-time': isInCrrentTime(scope.row) }"
              :data-row-index="scope.$index"
              v-if="(scope.row as TranslateScript).start_time !== undefined"
            >
              <div>{{ humanDuration((scope.row as TranslateScript).start_time * 1000, 1) }}</div>
              <div>{{ humanDuration((scope.row as TranslateScript).end_time * 1000, 1) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            {{ t('原始脚本') }} <small>({{ form.originalLanguage.label() }})</small>
          </template>
          <template #default="scope">
            <el-input
              :class="{ 'is-error': translateEditOriginalErrors.includes(scope.row) }"
              type="textarea"
              v-model="(scope.row as TranslateScript).source_text"
              v-if="translateEditFocus == `${scope.$index}-${scope.cellIndex}`"
            />
            <div
              class="textarea-placeholder el-textarea__inner"
              :class="{ 'is-error': translateEditOriginalErrors.includes(scope.row) }"
              v-else
            >
              {{ (scope.row as TranslateScript).source_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            {{ t('译后脚本') }} <small>({{ form.targetLanguage?.label() }})</small>
          </template>
          <template #default="scope">
            <el-input
              :class="{ 'is-error': translateEditTranslatedErrors.includes(scope.row) }"
              type="textarea"
              v-model="(scope.row as TranslateScript).text"
              v-if="translateEditFocus == `${scope.$index}-${scope.cellIndex}`"
            />
            <div
              class="textarea-placeholder el-textarea__inner"
              :class="{ 'is-error': translateEditTranslatedErrors.includes(scope.row) }"
              v-else
            >
              {{ (scope.row as TranslateScript).text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template #header>
            <el-tooltip
              :content="t('重新翻译全部脚本')"
              effect="light"
              v-if="form.scriptList.length"
            >
              <el-icon class="translate-all" @click="translateAll">
                <IconTranslate />
              </el-icon>
            </el-tooltip>
          </template>
          <template #default="scope">
            <div class="my-opration">
              <el-tooltip :content="t('重新翻译')" effect="light">
                <el-button
                  type="primary"
                  link
                  :disabled="!(scope.row as TranslateScript).source_text?.trim()"
                  @click="translateOne(scope)"
                >
                  <IconTranslate :class="{ loading: translateObj?.$index === scope.$index }" />
                </el-button>
              </el-tooltip>
              <el-tooltip effect="light" v-if="unmatchWordCount(scope.row)">
                <template #content>
                  <div class="my-tips">
                    <div class="my-tip-title">{{ t('语速不合适') }}</div>
                    <div class="my-tip-content">
                      <div>
                        <div>{{ t('当前') }}</div>
                        <div class="my-tip-word-count">
                          {{ getWordCount((scope.row as TranslateScript).text) }}
                        </div>
                      </div>
                      <div>
                        <div>{{ t('最佳') }}</div>
                        <div class="my-tip-word-range">
                          {{ (scope.row as TranslateScript).word_range?.join('-') }}
                        </div>
                      </div>
                      <div>{{ t('字') }}</div>
                    </div>
                  </div>
                </template>
                <el-icon>
                  <Warning />
                </el-icon>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item
      class="choose-file-form-item"
      :class="{ 'g-display-none': currentStep != 2 }"
      prop="voiceList"
      :error="formItemVoiceListError"
    >
      <template #label>
        {{ t('翻译音频') }}
        <el-icon style="display: none">
          <IconDiamond :id="`id_linear_${Math.random()}`" :highlight="true" />
        </el-icon>
      </template>
      <el-icon
        class="add-plus"
        @click="dialogVisibleChooseVoice = true"
        v-if="!form.voiceList.length"
      >
        <Plus />
      </el-icon>
      <div class="add-done" v-else>
        <div class="list-item-wrapper">
          <SoundPlayer
            ref="soundPlayerRef"
            :src="getMediaSrc(form.voiceList[0])"
            id="soundPlayerRef"
            @play="onSoundPlay('soundPlayerRef')"
          />
          <div class="list-item-content">
            <div class="list-item-title">{{ form.voiceList[0].name }}</div>
            <div class="list-item-tags">{{ form.voiceList[0].tags?.join(', ') }}</div>
          </div>
        </div>
        <el-icon class="del-icon" @click="form.voiceList = []">
          <IconDelete />
        </el-icon>
      </div>
    </el-form-item>
    <el-form-item
      class="choose-file-form-item"
      :class="{ 'g-display-none': currentStep != 2 }"
      prop="lipSync"
    >
      <template #label>
        {{ t('让视频生动') }}
        <el-icon style="display: none">
          <IconDiamond :id="`id_linear_${Math.random()}`" :highlight="true" />
        </el-icon>
      </template>
      <div class="my-cost-tips">
        <el-checkbox
          class="my-checkbox g-checkbox"
          v-model="form.lipSync"
          :label="t('唇型同步')"
          :disabled="!form.voiceList?.length"
        />
        <div v-if="form.lipSync">
          <span class="my-light-text">{{ t('消耗') }}</span>
          {{ `${costQuotasLipSync != 1 ? costQuotasLipSync + ' ' + t('积分值') : t('1积分')}` }}
        </div>
      </div>
    </el-form-item>
    <el-form-item
      class="choose-file-form-item"
      :class="{ 'g-display-none': currentStep != 2 }"
      :label="t('增强人脸视频')"
      prop="useHd"
      style="display: none"
    >
      <div class="my-cost-tips">
        <el-checkbox class="my-checkbox g-checkbox" v-model="form.useHd" :label="t('高清')" />
        <div v-if="form.useHd">
          <span class="my-light-text">{{ t('消耗') }}</span>
          {{ `${costQuotasHd != 1 ? costQuotasHd + ' ' + t('积分值') : t('1积分')}` }}
        </div>
      </div>
    </el-form-item>
    <el-form-item
      class="choose-file-form-item"
      :class="{ 'g-display-none': currentStep != 2 }"
      prop="videoTemplates"
    >
      <template #label>
        {{ t('翻译字幕') }}
        <el-icon style="display: none">
          <IconDiamond :id="`id_linear_${Math.random()}`" :highlight="true" />
        </el-icon>
      </template>
      <el-icon
        class="add-plus"
        @click="dialogVisibleVideoTemplate = true"
        v-if="!form.videoTemplates.length"
      >
        <Plus />
      </el-icon>
      <div class="add-done" v-else>
        <div class="list-item-image">
          <el-image
            fit="contain"
            :src="getBgUrl(form.videoTemplates[0].thumbnail)"
            v-if="!isSubtitleStyle"
          />
          <el-image
            :class="{
              'is-subtitle-style': isSubtitleStyle,
              'is-no-title': !isSubtitleStyle && isNoTitle
            }"
            :preview-src-list="
              isSubtitleStyle
                ? [getThumbnailUrl(form.videoTemplates[0].thumbnail)].concat(
                    form.videoAnimation?.image ? [form.videoAnimation.image] : []
                  )
                : []
            "
            :preview-teleported="!isSubtitleStyle"
            fit="contain"
            :src="getThumbnailUrl(form.videoTemplates[0].thumbnail)"
          />
        </div>
        <div class="list-item-image" v-if="form.videoAnimation?.image">
          <el-image
            class="is-subtitle-style"
            :preview-src-list="[
              form.videoAnimation.image,
              getThumbnailUrl(form.videoTemplates[0].thumbnail)
            ]"
            fit="contain"
            :src="form.videoAnimation.image"
          />
        </div>
        <el-icon class="del-icon" @click="form.videoTemplates = []">
          <IconDelete />
        </el-icon>
      </div>
    </el-form-item>

    <el-form-item class="fixed-shadow"></el-form-item>
    <el-form-item class="fixed-main">
      <div class="fixed-content">
        <p class="action-tips" v-if="showVTOptionTips">
          Note: If you choose neither audio translation nor subtitle translation, you will not be
          able to submit the task.
        </p>
        <div class="button-group">
          <el-button
            type="primary"
            :loading="isSubmitting || loadingAutoDetect"
            :disabled="!enableSubmit"
            @click="submitForm(formRef)"
          >
            <span v-if="currentStep == 2">{{ t('渲染') }}</span>
            <span v-else>{{ t('下一步') }}</span>
          </el-button>
          <el-button
            :disabled="!enableSubmit || isSubmitting || !form.scriptList.length"
            @click="dialogVisibleVideoPreview = true"
            v-if="currentStep == 2"
          >
            {{ t('视频预览') }}
          </el-button>
          <el-button text @click="resetForm(formRef)">
            {{ t('取消') }}
          </el-button>
        </div>
      </div>
    </el-form-item>
  </el-form>

  <VoiceTemplateSelect
    v-model="dialogVisibleChooseVoice"
    @open-clone-voice="openCloneVoice"
    @selected:done="selectedVoice"
    @stop-all-sound="stopAllSound"
    :language="form.targetLanguage?.value!"
  />
  <VoiceClone
    v-model="dialogVisibleCloneVoice"
    :material-list="form.materialList"
    @selected:done="selectedVoice"
  />

  <VideoTemplateSelect
    v-model="dialogVisibleVideoTemplate"
    @selected:done="selectedSubtitle"
    :language="form.targetLanguage?.value!"
    :resolution="form.materialList[0]?.resolution"
    :is-no-title="isNoTitle"
    :is-subtitle-style="isSubtitleStyle"
  />

  <el-dialog
    v-model="dialogVisibleVideoPreview"
    :title="
      (form.materialList[0]?.previewVideo ? `30${t('time.symbol.second', 's')} ` : '') +
      t('视频预览')
    "
    width="auto"
    :close-on-click-modal="false"
    draggable
    align-center
    destroy-on-close
  >
    <div style="color: var(--my-color-black-60); margin: -20px 0 20px">
      <small>{{ t('视频预览不会执行声音克隆和唇型同步') }}</small>
    </div>
    <VideoPreview
      :material="form.materialList[0]"
      :translate-scripts="form.scriptList"
      :voice-template="form.voiceList[0]"
      :target-language="form.targetLanguage?.value!"
      :video-template="form.videoTemplates[0]"
      :split-by-grapheme="['zh-CN', 'ja-JP'].includes(form.targetLanguage?.value!)"
    />
    <template #footer>
      <el-button @click="dialogVisibleVideoPreview = false">{{ t('取消') }}</el-button>
      <el-button
        type="primary"
        :loading="isSubmitting"
        :disabled="isUploading"
        @click="submitForm(formRef)"
      >
        {{ t('渲染') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<style scoped lang="less">
.custom-loading-translate,
.custom-loading-transcribe {
  :deep(.el-loading-mask) {
    .el-loading-spinner {
      --el-loading-spinner-size: 118px;

      .circular {
        animation: none;
        background: var(--my-color-white-100);
      }

      .el-loading-text {
        margin-top: calc(0px - var(--el-loading-spinner-size));
        font-size: 8px;
      }
    }
  }
}

.my-cost-tips {
  display: flex;
  align-items: center;
  gap: 12px;

  white-space: nowrap;
  line-height: 1;

  .my-light-text {
    color: var(--my-color-black-40);
  }
}

.my-tips {
  font-family:
    SF Pro Text,
    var(--el-font-family);
  font-size: 14px;
  line-height: 22px;
  padding: 10px;

  .my-tip-title {
    font-weight: 600;
    color: var(--my-color-black-89-90);
  }

  .my-tip-content {
    color: var(--my-color-black-60);
    font-weight: 500;
    display: flex;
    gap: 12px;

    > div {
      display: flex;
      gap: 8px;
    }

    .my-tip-word-count {
      color: var(--my-color-x13);
      display: flex;
    }

    .my-tip-word-range {
      color: var(--my-color-x15);
      display: flex;
    }
  }
}

.el-form {
  --my-max-upload-width: 800px;
  --my-el-steps-height: 32px;
  width: 100%;
  height: calc(100% - var(--my-el-steps-height));

  .el-form-item {
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    &:not(.choose-file-form-item),
    :deep(.el-form-item__label) {
      display: none;
    }

    :deep(.el-form-item__content) {
      width: 100%;
      height: 100%;

      .el-table {
        --el-table-header-bg-color: var(--my-color-y2);

        .el-table__header-wrapper {
          border-radius: 8px;

          th.el-table__cell.is-leaf {
            border: 0;

            .translate-all {
              color: var(--el-color-primary);
              font-size: 18px;
              cursor: pointer;
              margin-left: 4px;

              &:hover {
                color: var(--my-color-primary-60);
              }
            }
          }
        }

        .el-table__body {
          .el-table__cell {
            padding: 8px 0;
            padding: 0;

            .cell {
              padding: 0 12px;
              padding: 8px 0;

              .is-in-current-time {
                color: var(--el-color-primary);
              }

              > div:not(.el-textarea) {
                padding: 0 12px;
              }

              > div.textarea-placeholder {
                background-color: transparent;
                box-shadow: none;
                padding: 5px 11px;
                min-height: 52px;
              }

              .el-textarea__inner {
                box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;

                &:focus {
                  box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset;
                }
              }

              .is-error {
                &.textarea-placeholder.el-textarea__inner,
                .el-textarea__inner {
                  box-shadow: 0 0 0 1px var(--el-color-danger) inset;
                }
              }

              .my-opration {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                .el-icon.el-tooltip__trigger {
                  color: var(--my-color-x14);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .add-plus {
        font-size: 20px;
        color: var(--el-color-primary);
        width: 58px;
        height: 58px;
        border: 1px solid var(--my-color-primary-50);
        border-radius: 8px;
        cursor: pointer;
      }

      .add-done {
        display: flex;
        align-items: center;
        gap: 12px;

        .list-item-wrapper {
          display: flex;
          align-items: center;
          height: 58px;
          border: 1px solid var(--my-color-black-20);
          border-radius: 8px;
          gap: 12px;
          padding: 0 14px;

          .list-item-content {
            font-family:
              SF Pro Text,
              var(--el-font-family);

            .list-item-title {
              font-size: 16px;
              line-height: 22px;
              color: var(--my-color-black-89-90);
            }

            .list-item-tags {
              font-size: 14px;
              line-height: 20px;
              color: var(--my-color-black-40);
            }
          }
        }

        .list-item-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 58px;
          height: 58px;
          border: 1px solid var(--my-color-black-20);
          border-radius: 8px;

          position: relative;

          .el-image {
            position: absolute;

            width: 56px;
            height: 56px;
            border-radius: 6px;

            &.is-no-title {
              clip: rect(20px, 56px, 56px, 0);
            }

            &.is-subtitle-style {
              .el-image-viewer__wrapper {
                .el-image-viewer__canvas {
                  .el-image-viewer__img {
                    border-radius: 26px;
                  }
                }
              }
            }
          }
        }

        .del-icon {
          font-size: 16px;
          color: var(--el-color-primary);
          cursor: pointer;
        }
      }

      .my-checkbox.el-checkbox {
        --el-border-color-my-1: var(--my-color-x16);
        --el-checkbox-border-radius: 3px;

        .el-checkbox__input {
          position: relative;
          width: unset;
          //height: unset;

          .el-checkbox__inner {
            position: relative;
            top: unset;
            right: unset;
            --el-checkbox-input-width: 16px;
            --el-checkbox-input-height: 16px;
          }

          &.is-checked,
          &:not(.is-disabled) {
            .el-checkbox__inner {
              &:after {
                left: 5px;
                top: 1px;
              }
            }
          }
        }
      }
    }

    &.steps {
      height: var(--my-el-steps-height);

      display: flex;
      justify-content: center;

      :deep(.el-form-item__content) {
        max-width: var(--my-max-upload-width);

        display: block;
        height: var(--my-el-steps-height);
      }

      .el-steps {
        height: var(--my-el-steps-height);
        overflow: hidden;
        justify-content: center;

        .el-step {
          flex-basis: 25% !important;

          &.clickable {
            :deep(.el-step__head) {
              .el-step__icon {
                cursor: pointer;
              }
            }

            :deep(.el-step__main) {
              .el-step__title {
                cursor: pointer;
              }
            }
          }

          &:last-child {
            flex-basis: content !important;

            :deep(.el-step__main) {
              .el-step__title {
                padding-right: 0;
              }
            }
          }

          :deep(.el-step__head) {
            .el-step__line {
              height: 1px;
              top: 15px;
              right: 16px;
            }

            .el-step__icon {
              width: 28px;
              height: 28px;
              font-size: 18px;
            }

            &.is-process {
              color: var(--my-color-white-100);
              border-color: var(--el-color-primary);

              .el-step__icon {
                background: var(--el-color-primary);
              }
            }

            &.is-success {
              color: var(--el-color-primary);
              border-color: var(--el-color-primary);

              .el-step__icon {
                .el-step__icon-inner.is-status {
                  margin-top: -4px;
                  transform: translateY(2px);
                }
              }
            }
          }

          :deep(.el-step__main) {
            position: relative;
            left: 24px;
            top: -32px;

            .el-step__title {
              display: inline-block;
              font-size: 18px;
              padding-left: 12px;
              padding-right: 16px;
              background: var(--my-color-white-100);
              line-height: var(--my-el-steps-height);

              &.is-process {
                font-weight: 500;
                //color: var(--el-text-color-placeholder);
              }

              &.is-success {
                font-weight: 500;
                color: var(--my-color-black-89-90);
              }
            }
          }
        }
      }
    }
  }
}

.el-form.form-wrapper-mini {
  height: unset;

  .el-form-item {
    height: unset;
    margin-bottom: 18px;

    --my-form-footer-height: 120px;

    position: relative;

    .voice-player-wrapper {
      position: absolute;
      top: -32px;
      left: 130px;
    }

    &.steps {
      margin-bottom: 32px;

      :deep(.el-form-item__content) {
        max-width: unset;
      }

      .el-steps {
        justify-content: left;
      }
    }

    &.fixed-shadow {
      height: calc(var(--my-form-footer-height) - var(--el-main-padding));
      margin-bottom: 0;
    }

    &.fixed-main {
      position: fixed;
      bottom: 0;
      margin-bottom: 0;
      background-color: var(--el-bg-color);
      margin-left: calc(1px - var(--el-main-padding)); // 1px: .app-aside .aside-fixed border-right
      box-shadow: 0 -4px 12px 0 var(--my-color-black-6);
      z-index: 2;

      .fixed-content {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding: var(--el-main-padding);

        .action-tips {
          width: 100%;
          margin: 0;
          color: #f3aa48;
        }

        .button-group {
          .el-button {
            margin-left: 0;
            margin-right: 24px;

            &:not(.is-text) {
              min-width: 100px;
            }
          }
        }
      }
    }

    &:not(.choose-file-form-item),
    :deep(.el-form-item__label) {
      display: block;

      .el-icon {
        vertical-align: middle;
      }
    }

    :deep(.el-form-item__content) {
      height: unset;
    }
  }
}

.el-form {
  .el-input-group--prepend {
    :deep(.el-input-group__prepend) {
      background-color: var(--el-fill-color-blank);

      .el-select {
        --el-select-width: 50px;

        .el-select__wrapper {
          padding-right: 10px;
          gap: 2px;
        }
      }
    }
  }

  .el-form-item {
    :deep(.el-form-item__content) {
      .el-select__wrapper {
        .el-select__prefix {
          color: var(--el-input-icon-color, --el-text-color-placeholder);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .el-form {
    &.form-wrapper-mini {
      .el-form-item {
        &.steps {
          margin-bottom: 18px;
        }
      }
    }

    .el-form-item {
      &.steps {
        .el-steps {
          .el-step {
            flex-basis: content !important;
            flex-grow: 1;

            :deep(.el-step__head) {
              .el-step__line {
                display: none;
              }

              .el-step__icon {
                width: 14px;
                height: 14px;
                font-size: 10px; // font-size: 14px;
              }
            }

            :deep(.el-step__main) {
              left: 16px;

              .el-step__title {
                font-size: 10px; // font-size: 14px;
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
