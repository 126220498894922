import { defineStore } from 'pinia'
import type { AxiosResponse } from 'axios'

import {
  type BaseResponse,
  type Project,
  type VideoTranslationCostDetail,
  getSubscriptionPlanAndCreditDetails
} from '@/api'

export const useProjStore = defineStore('proj', {
  state: () => {
    const obj: ProjQuata = {
      projId: '',
      maxLevel: 0,
      isLoading: false,

      total_remaining: 0, // float/double
      total_frozen: 0, // float/double

      no_watermark: false,
      autogen_feature: false,
      gen_speed: '',
      storage: 0, // GB
      res_1080p: false,

      count: 0,
      items: []
    }
    return obj
  }
})

export type ProjStore = ReturnType<typeof useProjStore>

export interface Features {
  no_watermark: boolean
  autogen_feature: boolean
  gen_speed: 'normal' | 'high' | ''
  storage: number // GB
  res_1080p: boolean
}

export interface CreditDetailMemoSubscription {
  category: string
  interval: string
  quota: number
  reason?: string
}

export type CreditDetailMemo =
  | CreditDetailMemoGenerate
  | CreditDetailMemoVideoTranslation
  | CreditDetailMemoTranslateScript
  | CreditDetailMemoTranslateText
  | CreditDetailMemoDownload

export interface CreditDetailMemoGenerate {
  action: 'generate-video' | 'regenerate-video'
  request_id: string
  task_id: string
  title: string
}

export interface CreditDetailMemoVideoTranslation {
  action: 'video-translation' | 'retry-video-translation'
  request_id: string
  task_id: string
  title: string
  cost_detail: VideoTranslationCostDetail
}

export interface CreditDetailMemoTranslateScript {
  action: 'translate-script'
  request_id: string
  source_language: string
  target_language: string
  source_type: string // subtitles | s3://xxx.mp3
  result_path: string
}

export interface CreditDetailMemoTranslateText {
  action: 'translate-text'
  request_id: string
  source_language: string
  target_language: string
  source_text: string
  result_text: string
}

export interface CreditDetailMemoDownload {
  action: 'download-video'
  request_id: string
  task_id: string
  video_id: string
  filesize: number
  url: string
}

export interface FrozenQuota {
  frozen_id: string
  quota: number // float/double
  expire_time: number // 10 digits
  memo: CreditDetailMemoGenerate | CreditDetailMemoVideoTranslation
}

export type SubscriptionPlan = {
  sub_id: string
  price_id: string // 免费套餐：free

  quota_init: number // float/double
  quota_remaining: number // float/double
  frozen_quotas: FrozenQuota[]

  create_time: number // 10 digits
  last_consume_time: number // 10 digits
  last_charge_time: number // 10 digits
  effective_time: number // 10 digits
  expire_time: number // 10 digits

  sub_status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active' // 有效
    | 'past_due'
    | 'canceled' // 过期
    | 'unpaid'
    | 'paused'
    | 'ongoing' // 前端添加的状态
  cancel_at_period_end: boolean

  category: string // plan category title
  level: number // free: 0, standard: 1

  quota: number // float/double
  interval: 'day' | 'week' | 'month' | 'year' | ''
} & Features

export type SubscriptionPlanAndCreditDetail = {
  error?: string | null
  code?: number

  total_remaining: number // float/double
  total_frozen: number // float/double

  count: number // items.length
  items: SubscriptionPlan[]
} & Features

export type ProjQuata = {
  projId: string
  maxLevel: number // plan level
  isLoading: boolean
} & SubscriptionPlanAndCreditDetail

export const getPlanStatus = (plan: SubscriptionPlan, projStore: ProjStore) => {
  const now = Date.now()
  // 1.1、免费套餐在有active付费套餐时（projStore.maxLevel>0），自身未过期为ongoing状态
  // 1.2、免费套餐在有active付费套餐时（projStore.maxLevel>0），自身已过期为expired状态
  // 1.3、免费套餐在无active付费套餐时（projStore.maxLevel==0），为active状态
  if (plan.price_id === 'free') {
    if (projStore.maxLevel) {
      if (plan.expire_time * 1000 > now) {
        return 'ongoing'
      }
      return 'canceled' // expired
    }
    return 'active'
  }
  // 2.1、当前付费套餐cancel_at_period_end时，自身未过期为ongoing状态
  if (plan.cancel_at_period_end) {
    if (plan.expire_time * 1000 > now) {
      return 'ongoing'
    }
    // 2.2、当前付费套餐cancel_at_period_end时，自身已过期为expired状态
    return 'canceled' // expired Strip支付的后台会自动设置为canceled且cancel_at_period_end==false，微信支付的不会
  }
  return plan.sub_status
}

export const isValidPlan = (plan: SubscriptionPlan, projStore: ProjStore) => {
  if (plan.price_id === 'free') {
    return ['active', 'ongoing'].includes(getPlanStatus(plan, projStore))
  }
  return plan.sub_status === 'active' && plan.expire_time * 1000 > Date.now()
}

const getOrderedPlans = (items: SubscriptionPlan[], projStore: ProjStore) => {
  const plans: SubscriptionPlan[] = []
  items.forEach((plan) => {
    if (getPlanStatus(plan, projStore) === 'active') {
      plans.push(plan)
    }
  })
  items.forEach((plan) => {
    if (!plans.includes(plan) && getPlanStatus(plan, projStore) === 'ongoing') {
      plans.push(plan)
    }
  })
  items.forEach((plan) => {
    if (!plans.includes(plan)) {
      plans.push(plan)
    }
  })
  return plans
}

export const fetchSubscriptionPlanAndCreditDetails = (
  projStore: ProjStore
): Promise<AxiosResponse<BaseResponse<SubscriptionPlanAndCreditDetail>>> => {
  projStore.$patch({
    isLoading: true
  })
  return new Promise((resolve, reject) => {
    getSubscriptionPlanAndCreditDetails({
      projId: projStore.projId
    })
      .then((res) => {
        resolve(res)
        if (res.data.code) {
          console.error(res.data.message || '加载异常')
          return
        }
        const { data } = res.data
        let maxLevel = 0
        data.items.forEach((plan) => {
          if (isValidPlan(plan, projStore)) {
            maxLevel = Math.max(maxLevel, plan.level)
          }
        })
        projStore.$patch({
          ...data,
          items: getOrderedPlans(data.items.reverse(), projStore),
          maxLevel,
          isLoading: false,
          error: undefined,
          code: undefined
        })
      })
      .catch((err) => {
        reject(err)
        if (err.code === 'ERR_CANCELED' || err.status === 401) {
          return
        }
        console.error(err.message || '加载失败')
      })
  })
}

export const updateProjData = (projStore: ProjStore, data: Project): void => {
  projStore.$patch({
    projId: data.id
  })
}
