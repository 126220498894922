<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.54545 5C6.11194 5 4 6.83911 4 9.27273V26.7273C4 29.1609 6.11194 31 8.54545 31H12.0571C12.6094 31 13.0571 30.5523 13.0571 30C13.0571 29.4477 12.6094 29 12.0571 29H8.54545C7.06277 29 6 27.9086 6 26.7273V9.27273C6 8.09139 7.06277 7 8.54545 7H27.4545C28.9372 7 30 8.09139 30 9.27273V22.8785C30 23.4308 30.4477 23.8785 31 23.8785C31.5523 23.8785 32 23.4308 32 22.8785V9.27273C32 6.83911 29.8881 5 27.4545 5H8.54545ZM30.0001 27.9996C30.0001 27.4473 29.5524 26.9996 29.0001 26.9996C28.4478 26.9996 28.0001 27.4473 28.0001 27.9996V29.9996C28.0001 30.5519 28.4478 30.9996 29.0001 30.9996C29.5524 30.9996 30.0001 30.5519 30.0001 29.9996V27.9996ZM25.0001 24.9996C25.5524 24.9996 26.0001 25.4473 26.0001 25.9996V31.9996C26.0001 32.5519 25.5524 32.9996 25.0001 32.9996C24.4478 32.9996 24.0001 32.5519 24.0001 31.9996V25.9996C24.0001 25.4473 24.4478 24.9996 25.0001 24.9996ZM22.0001 26.9996C22.0001 26.4473 21.5524 25.9996 21.0001 25.9996C20.4478 25.9996 20.0001 26.4473 20.0001 26.9996V30.9996C20.0001 31.5519 20.4478 31.9996 21.0001 31.9996C21.5524 31.9996 22.0001 31.5519 22.0001 30.9996V26.9996ZM17.0001 27.4996C17.5524 27.4996 18.0001 27.9473 18.0001 28.4996V29.4996C18.0001 30.0519 17.5524 30.4996 17.0001 30.4996C16.4478 30.4996 16.0001 30.0519 16.0001 29.4996V28.4996C16.0001 27.9473 16.4478 27.4996 17.0001 27.4996ZM22.0957 19.656C23.3871 18.9296 23.3871 17.0704 22.0957 16.344L15.8315 12.8204C14.565 12.1079 13 13.0232 13 14.4764V21.5236C13 22.9768 14.5649 23.8921 15.8315 23.1796L22.0957 19.656ZM15 14.6473L20.9603 18L15 21.3526V14.6473Z"
      fill="#7B4DFF"
    />
  </svg>
</template>
