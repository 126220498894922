<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="rotate"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.00024 2.625C12.5211 2.625 15.3752 5.47918 15.3752 9C15.3752 9.16586 15.3689 9.33016 15.3565 9.49269L14.4674 8.60353C14.2477 8.38386 13.8915 8.38386 13.6719 8.60353C13.4522 8.8232 13.4522 9.17936 13.6719 9.39903L15.3382 11.0654C15.4818 11.2089 15.6915 11.264 15.887 11.2095C16.0825 11.1551 16.2335 10.9995 16.2821 10.8024C16.4247 10.2245 16.5002 9.62067 16.5002 9C16.5002 4.85786 13.1424 1.5 9.00024 1.5C6.45939 1.5 4.21384 2.76402 2.8581 4.69501C2.67959 4.94927 2.741 5.30009 2.99525 5.4786C3.2495 5.6571 3.60032 5.5957 3.77883 5.34145C4.93298 3.69758 6.84139 2.625 9.00024 2.625ZM2.11352 6.79047C1.918 6.84495 1.767 7.00052 1.71837 7.19757C1.57576 7.77551 1.50025 8.37932 1.50025 9C1.50025 13.1421 4.85811 16.5 9.00024 16.5C11.5458 16.5 13.7949 15.2313 15.1499 13.2943C15.3279 13.0397 15.2659 12.689 15.0114 12.511C14.7568 12.3329 14.4061 12.3949 14.228 12.6495C13.0745 14.2985 11.1631 15.375 9.00024 15.375C5.47943 15.375 2.62525 12.5208 2.62525 9C2.62525 8.83414 2.63157 8.66984 2.64397 8.50731L3.53903 9.40236C3.7587 9.62203 4.11485 9.62203 4.33453 9.40236C4.55419 9.18269 4.55419 8.82654 4.33453 8.60687L2.66224 6.93458C2.51873 6.79107 2.30903 6.736 2.11352 6.79047Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.75015 4.83984C8.51888 4.83984 8.31117 4.9814 8.2266 5.19666L5.4766 12.1967C5.36301 12.4858 5.50532 12.8123 5.79447 12.9259C6.08362 13.0395 6.4101 12.8972 6.5237 12.608L7.56209 9.96484H10.4382L11.4766 12.608C11.5902 12.8972 11.9167 13.0395 12.2058 12.9259C12.495 12.8123 12.6373 12.4858 12.5237 12.1967L9.7737 5.19666C9.68913 4.9814 9.48143 4.83984 9.25015 4.83984H8.75015ZM9.99625 8.83984L9.00015 6.30433L8.00405 8.83984H9.99625Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="less">
.loading {
  .rotate {
    animation: loading-rotate 1s linear infinite;
    transform-origin: center;
  }
}

@keyframes loading-rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
