<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id?: string
    highlight?: boolean
  }>(),
  {
    id: `id_linear_${Math.random()}`,
    highlight: false
  }
)
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.54898 2.90081C4.80386 2.56047 5.20421 2.36013 5.62941 2.36013H10.3731C10.7983 2.36013 11.1987 2.56047 11.4535 2.90081L13.8064 6.04246C13.9774 6.27085 13.9621 6.58856 13.7698 6.79941L8.73335 12.3227C8.34841 12.7449 7.68417 12.7457 7.29821 12.3245L2.23506 6.79863C2.04193 6.58784 2.02617 6.26944 2.19754 6.04062L4.54898 2.90081ZM5.62941 1.2832C4.86498 1.2832 4.14523 1.64339 3.68699 2.25526L1.33555 5.39506C0.85375 6.0384 0.898064 6.93356 1.44105 7.52616L6.5042 13.052C7.3177 13.9398 8.71775 13.9381 9.52911 13.0483L14.5656 7.52503C15.1061 6.93224 15.1493 6.03902 14.6684 5.3969L12.3155 2.25526C11.8573 1.64339 11.1376 1.2832 10.3731 1.2832H5.62941ZM6.49237 5.48253C6.28209 5.27225 5.94115 5.27225 5.73087 5.48253C5.52059 5.69281 5.52059 6.03375 5.73087 6.24403L7.61916 8.13232C7.82944 8.3426 8.17038 8.3426 8.38066 8.13232L10.269 6.24403C10.4792 6.03375 10.4792 5.69281 10.269 5.48253C10.0587 5.27225 9.71774 5.27225 9.50745 5.48253L7.99991 6.99007L6.49237 5.48253Z"
      :fill="props.highlight ? `url(#${props.id})` : 'currentColor'"
    />
    <defs>
      <linearGradient
        :id="props.id"
        x1="13.3842"
        y1="-0.59234"
        x2="11.5245"
        y2="13.9944"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF763C" />
        <stop offset="0.488983" stop-color="#FF3C82" />
        <stop offset="1" stop-color="#7B4DFF" />
      </linearGradient>
    </defs>
  </svg>
</template>
