import { defineStore } from 'pinia'
import type { Project } from '@/api'

export interface UserState {
  id: string
  name: string
  email: string
  phone: string
  avatar: string
  locale: string
  roles: string
  exp: number
  token: string
  feedbackTypes: string[]
}

export const useUserStore = defineStore('user', {
  state: () => {
    const state: UserState = {
      id: '',
      name: '',
      email: '',
      phone: '',
      avatar: '',
      locale: '',
      roles: '',
      exp: 0,
      token: '',
      feedbackTypes: []
    }
    return state
  }
})

export type UserStore = ReturnType<typeof useUserStore>

export interface SignedInfo {
  user: {
    sub: string
    name: string
    email: string | null
    phone: string | null
    avatar: string | null
    locale: string | null
    roles: string | null
    iss: string
    aud: string[]
    iat: string | number // DateTime string | number
    exp: string | number // DateTime string | number
  }
  token: string | null
  ttl: number
  projectInfo: Project
  feedbackTypes: string[]
}

export const updateUserStore = (userStore: UserStore, data: SignedInfo) => {
  const { sub: id, name, email, phone, avatar, locale, roles } = data.user
  const { ttl } = data

  window.vmegAegis?.setConfig({ uin: id })

  userStore.$patch({
    id,
    name,
    email: email || '',
    phone: phone || '',
    avatar: avatar || '',
    locale: locale || '',
    roles: roles || '',
    token: data.token || '',
    feedbackTypes: data.feedbackTypes || [],
    exp: Math.round(Date.now() / 1000) + (ttl - 3600) // 有效期比后台少1小时保证有效性
  })
}

export const mergeUserStore = (userStore: UserStore, newProps: Partial<UserState>) => {
  userStore.$patch({
    ...newProps
  })
}
