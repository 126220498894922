<script setup lang="ts">
import i18nHome from '@/locales/i18n-home'
import { discordUrl, joinAffiliateUrl } from '@/constrants/vars'
import router from '@/router'
import { ref } from 'vue'

const { t } = i18nHome.global

const isCN = import.meta.env.VITE_DOMAIN === 'gthree.cn'

const showHelp = ref<boolean>(false)
// 监听路由对象的变化
router.afterEach((to) => {
  showHelp.value = !['signin', 'signup'].includes(String(to?.name))
})
</script>

<template>
  <div class="affix-container" v-if="showHelp">
    <template v-if="isCN">
      <div class="help-container wx">
        <div class="help-icon"></div>
        <div class="help-text">{{ t('We Chat') }}</div>
      </div>
      <div class="qrcode-wx"></div>
    </template>
    <template v-else>
      <a class="help-container out-site affiliate" :href="joinAffiliateUrl" target="_blank">
        <div class="help-icon"></div>
        <div class="help-text">Affiliate Program</div>
      </a>
      <a class="help-container out-site discord" :href="discordUrl" target="_blank">
        <div class="help-icon"></div>
        <div class="help-text">{{ t('Need Help?') }}</div>
      </a>
    </template>
  </div>
</template>

<style scoped lang="less">
.affix-container {
  position: fixed;
  right: 12px;
  z-index: 2;
  bottom: 50px;
  width: max-content;
  margin: 0 12px 12px auto;

  .help-container {
    position: relative;
    background: var(--my-color-white-100);
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: var(--my-color-black-70);
    border: 1px solid var(--my-color-x5);
    box-shadow: 0 4px 4px 0 var(--my-color-black-25, #00000040);
    border-radius: 66px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .help-icon {
      margin-bottom: 10px;
    }

    &.wx {
      width: 40px;
      height: 116px;
      cursor: pointer;

      .help-icon {
        width: 24px;
        height: 24px;
        background: url(@/assets/home/wx.svg) no-repeat;
      }

      .help-text {
        padding: 0 6px;
      }
    }

    &.discord {
      .help-icon {
        background: url(@/assets/home/discord@2x.png) no-repeat;
      }
    }

    &.affiliate {
      margin-bottom: 32px;

      .help-icon {
        background: url(@/assets/affiliate@2x.png) no-repeat;
      }

      .help-text {
        font-size: 14px;
      }
    }

    &.out-site {
      width: 66px;
      height: 123px;
      text-decoration: none;

      .help-icon {
        width: 36px;
        height: 36px;
        background-size: contain;
      }
    }

    + .qrcode-wx {
      position: absolute;
      right: 55px;
      top: 0;
      width: 100px;
      height: 100px;
      background: var(--my-color-white-100) url(@/assets/home/qrcode-qywx.jpg) no-repeat center
        center;
      background-size: 90%;
      padding: 8px;
      border: 1px solid var(--my-color-x5);
      box-shadow: 0 4px 4px 0 var(--my-color-black-25, #00000040);
      border-radius: 8px;
      display: none;
    }

    &:hover {
      + .qrcode-wx {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .affix-container {
    display: none;
  }
}
</style>
