<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#7B4DFF" fill-opacity="0.15" />
    <rect
      x="13.8889"
      y="13.8889"
      width="20.2222"
      height="20.2222"
      rx="2.33333"
      stroke="#7B4DFF"
      stroke-width="1.55556"
    />
    <rect x="17.7778" y="30.2222" width="12.4444" height="1.55556" rx="0.777778" fill="#7B4DFF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.1848 17.8717C18.295 17.8118 18.4213 17.7778 18.5555 17.7778H24H29.4444C29.874 17.7778 30.2222 18.1261 30.2222 18.5556V20.1112C30.2222 20.5407 29.874 20.8889 29.4444 20.8889C29.0149 20.8889 28.6667 20.5407 28.6667 20.1112V19.3334H24.7778L24.7778 26.3334H26.3333C26.7629 26.3334 27.1111 26.6816 27.1111 27.1112C27.1111 27.5407 26.7629 27.8889 26.3333 27.8889H24.0012L24 27.8889L23.9988 27.8889H21.6667C21.2371 27.8889 20.8889 27.5407 20.8889 27.1112C20.8889 26.6816 21.2371 26.3334 21.6667 26.3334H23.2222V19.3334H19.3333V20.1112C19.3333 20.5407 18.9851 20.8889 18.5555 20.8889C18.126 20.8889 17.7778 20.5407 17.7778 20.1112V18.5556V18.5556C17.7778 18.5019 17.7832 18.4495 17.7936 18.3989"
      fill="#7B4DFF"
    />
  </svg>
</template>
