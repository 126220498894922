<script setup lang="ts">
import { type Ref, inject, ref, onMounted, onUnmounted } from 'vue'
import { fabric } from 'fabric'

import type { CoreData, EditorElement, Placement } from '@/videoEditor/types'
import { isHtmlVideoElement, isHtmlImageElement } from '@/videoEditor/utils'
import Store from '@/videoEditor/store'

/** 依赖注入 start */
// 核心数据
const coreData: CoreData | undefined = inject(Store.coreData)

// 动画播放帧率
const animationDrawingFps: Ref<number> | undefined = inject(Store.animationDrawingFps)

// 当前选中素材
const selectedElement: Ref<EditorElement | null> | undefined = inject(Store.selectedElement)
/** 依赖注入 end */

// defineEmits defineExpose defineProps
const emit = defineEmits(['object:modified'])
const props = defineProps<{
  stageConfig: {
    width: number
    height: number
    scale: number
  }
  isNeedLoadVideoFile?: boolean
  backgroundColor?: string
}>()

const htmlAudioElements: Map<string, HTMLAudioElement> = new Map()
const htmlVideoElements: Map<string, HTMLVideoElement> = new Map()
const htmlImageElements: Map<string, HTMLImageElement> = new Map()
const htmlCanvasElements: Map<string, HTMLCanvasElement> = new Map()

const htmlMediaElementsWaiting: Map<string, HTMLMediaElement> = new Map()
const isWaiting = ref(false)

const animationDurationInMs = ref(0)
const animationCurrentTimeInMs = ref(0)
const isAnimationPlaying = ref(false)
const animationPlaybackRate = ref(1)

let canvas: fabric.Canvas
let bgColor: string = ''

const refreshElements = () => {
  if (!coreData || !canvas) {
    return
  }

  initMediaElements()

  setAnimationPlayOrPause(false)

  canvas.off('object:modified')
  canvas.remove(...canvas.getObjects())

  for (let i = coreData.tracks.length - 1; i >= 0; i--) {
    const track = coreData.tracks[i]
    for (const element of track.elements) {
      switch (element.type) {
        case 'video': {
          const videoElement = htmlVideoElements.get(element.id)
          if (!videoElement || !isHtmlVideoElement(videoElement)) {
            continue
          }

          const videoObject = new fabric.CoverVideo(videoElement, {
            ...element.properties,
            name: element.id,
            type: element.subType || element.type,
            left: element.placement.left,
            top: element.placement.top,
            width: element.placement.width,
            height: element.placement.height,
            angle: element.placement.angle,
            scaleX: element.placement.scaleX,
            scaleY: element.placement.scaleY,
            originX: element.placement.originX,
            originY: element.placement.originY,
            objectCaching: false,
            lockUniScaling: true,
            // filters,
            // @ts-ignore
            customFilter: element.attributes.effect?.type
          })

          element.fabricObject = videoObject

          videoElement.width = element.placement.width
          videoElement.height = element.placement.height

          canvas.add(videoObject)
          canvas.on('object:modified', (event: fabric.IEvent) => {
            const { target } = event
            if (!target) {
              return
            }
            if (target.name !== videoObject.name) {
              return
            }
            const placement = element.placement
            const newPlacement: Placement = {
              ...placement,
              left: target.left ?? placement.left,
              top: target.top ?? placement.top,
              width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
              height:
                target.height && target.scaleY ? target.height * target.scaleY : placement.height,
              angle: target.angle ?? placement.angle,
              scaleX: 1,
              scaleY: 1,
              originX: target.originX ?? placement.originX,
              originY: target.originY ?? placement.originY
            }
            const newElement = {
              ...element,
              placement: newPlacement
            }
            updateEditorElement(event, newElement)
          })
          break
        }
        case 'sticker':
        case 'image': {
          const imageElement = htmlImageElements.get(element.id)
          if (!imageElement || !isHtmlImageElement(imageElement)) {
            continue
          }

          const imageObject = new fabric.CoverImage(imageElement, {
            ...element.properties,
            name: element.id,
            type: element.subType || element.type,
            left: element.placement.left,
            top: element.placement.top,
            angle: element.placement.angle,
            originX: element.placement.originX,
            originY: element.placement.originY,
            objectCaching: false,
            lockUniScaling: true,
            // filters,
            // @ts-ignore
            customFilter: element.attributes.effect?.type
          })

          // imageObject.applyFilters();
          element.fabricObject = imageObject

          const image = {
            w: imageElement.naturalWidth,
            h: imageElement.naturalHeight
          }
          imageObject.width = image.w
          imageObject.height = image.h
          imageElement.width = image.w
          imageElement.height = image.h
          imageObject.scaleToHeight(image.w)
          imageObject.scaleToWidth(image.h)
          const toScale = {
            x: element.placement.width / image.w,
            y: element.placement.height / image.h
          }
          imageObject.scaleX = toScale.x * element.placement.scaleX
          imageObject.scaleY = toScale.y * element.placement.scaleY

          canvas.add(imageObject)
          canvas.on('object:modified', (event: fabric.IEvent) => {
            const { target } = event
            if (!target) {
              return
            }
            if (target.name !== imageObject.name) {
              return
            }
            const placement = element.placement
            let fianlScale = 1
            if (target.scaleX && target.scaleX > 0) {
              fianlScale = target.scaleX / toScale.x
            }
            const newPlacement: Placement = {
              ...placement,
              left: target.left ?? placement.left,
              top: target.top ?? placement.top,
              angle: target.angle ?? placement.angle,
              scaleX: fianlScale,
              scaleY: fianlScale,
              originX: target.originX ?? placement.originX,
              originY: target.originY ?? placement.originY
            }
            const newElement = {
              ...element,
              placement: newPlacement
            }
            updateEditorElement(event, newElement)
          })
          break
        }
        case 'audio': {
          /*const audioElement = htmlAudioElements.get(element.id)
          if (!audioElement || !isHtmlAudioElement(audioElement)) {
            continue
          }

          console.log(audioElement.id, audioElement.src)*/

          // 下方代码用于调试
          /*const audioObject = new fabric.Image(element.id, {
            width: element.placement.width,
            height: element.placement.height
          })

          element.fabricObject = audioObject

          canvas.add(audioObject)*/
          break
        }
        case 'text': {
          const textObject = new fabric.Textbox(element.properties.text || '', {
            ...element.properties,
            name: element.id,
            type: element.subType || element.type,
            left: element.placement.left,
            top: element.placement.top,
            width: element.placement.width,
            height: element.placement.height,
            angle: element.placement.angle,
            scaleX: element.placement.scaleX,
            scaleY: element.placement.scaleY,
            originX: element.placement.originX,
            originY: element.placement.originY,
            objectCaching: false,
            lockUniScaling: true
          })

          element.fabricObject = textObject

          canvas.add(textObject)
          canvas.on('object:modified', (event: fabric.IEvent) => {
            const target = event.target as fabric.Textbox | undefined
            if (!target) {
              return
            }
            if (target.name !== textObject.name) {
              return
            }
            const placement = element.placement
            const newPlacement: Placement = {
              ...placement,
              left: target.left ?? placement.left,
              top: target.top ?? placement.top,
              width: target.width ?? placement.width,
              height: target.height ?? placement.height,
              angle: target.angle ?? placement.angle,
              scaleX: target.scaleX ?? placement.scaleX,
              scaleY: target.scaleY ?? placement.scaleY,
              originX: target.originX ?? placement.originX,
              originY: target.originY ?? placement.originY
            }
            const newElement = {
              ...element,
              placement: newPlacement,
              properties: {
                ...element.properties,
                text: target.text
              }
            }
            updateEditorElement(event, newElement)
          })
          break
        }
        default: {
          console.error('Not implemented')
        }
      }
      if (element.fabricObject) {
        element.fabricObject.on('selected', () => {
          if (selectedElement) {
            selectedElement.value = element
          }
          setControlsCustomization(element)
        })
      }
    }
  }
  if (selectedElement?.value) {
    setActiveObject(selectedElement.value)
  }
  animationDrawFrames(animationCurrentTimeInMs.value, 'draw')
}
const setSelectedElement = (element: EditorElement | null) => {
  if (selectedElement) {
    selectedElement.value = element
  }
  if (!canvas) {
    return
  }
  if (element?.fabricObject) {
    if (element.fabricObject.selectable) {
      setActiveObject(element)
    } else {
      discardActiveObject()
    }
  } else {
    discardActiveObject()
  }
}
const updateSelectedElement = () => {
  if (!selectedElement?.value?.id) {
    discardActiveObject()
    return
  }
  coreData?.tracks.some((track) => {
    let found = false
    track.elements.find((element) => {
      if (element.id === selectedElement.value?.id) {
        selectedElement.value = element
        found = true
        return true
      }
      return false
    })
    return found
  })
}
const updateEditorElement = (event: fabric.IEvent, editorElement: EditorElement) => {
  coreData?.tracks.forEach((track) => {
    track.elements = track.elements.map((element) =>
      element.id === editorElement.id ? editorElement : element
    )
  })
  updateSelectedElement()
  emit('object:modified', event)
  refreshElements()
}
const removeEditorElement = (id: string) => {
  coreData?.tracks.forEach((track) => {
    track.elements = track.elements.filter((element) => {
      return element.id !== id
    })
  })
  if (htmlAudioElements.has(id)) {
    const dom = htmlAudioElements.get(id)
    if (dom) {
      dom.remove()
    }
    htmlAudioElements.delete(id)
  }
  if (htmlVideoElements.has(id)) {
    const dom = htmlVideoElements.get(id)
    if (dom) {
      dom.remove()
    }
    htmlVideoElements.delete(id)
  }
  if (htmlImageElements.has(id)) {
    const dom = htmlImageElements.get(id)
    if (dom) {
      dom.remove()
    }
    htmlImageElements.delete(id)
  }
  if (htmlCanvasElements.has(id)) {
    const dom = htmlCanvasElements.get(id)
    if (dom) {
      dom.remove()
    }
    htmlCanvasElements.delete(id)
  }

  refreshElements()
}

const initMediaElements = () => {
  if (!coreData) {
    return
  }

  coreData.tracks.forEach((track) => {
    track.elements.forEach((element) => {
      if (element.type === 'audio') {
        if (htmlAudioElements.has(element.id)) {
          return
        }
        const dom = document.createElement('audio')
        dom.id = element.id
        dom.src = element.attributes.src
        dom.preload = 'metadata'
        dom.onerror = (event) => {
          console.error(event)
          element.attributes.error = event
        }
        htmlAudioElements.set(dom.id, dom)
      } else if (element.type === 'video') {
        if (htmlVideoElements.has(element.id)) {
          return
        }
        const dom = document.createElement('video')
        dom.id = element.id
        dom.src = element.attributes.src
        dom.preload = 'metadata'
        dom.setAttribute('playsinline', 'true')
        dom.setAttribute('webkit-playsinline', 'true')
        dom.onerror = (event) => {
          console.error(event)
          element.attributes.error = event
        }
        htmlVideoElements.set(dom.id, dom)
      } else if (element.type === 'image' || element.type === 'sticker') {
        if (htmlImageElements.has(element.id)) {
          return
        }
        const dom = new Image()
        dom.id = element.id
        dom.src = element.attributes.src
        dom.onerror = (event) => {
          console.error(event)
          element.attributes.error = event
        }
        htmlImageElements.set(dom.id, dom)
      }
    })
  })
}

let animationCurrentKeyFrame = 0
let animationPlayStartedTimeInMs = 0
let animationPlayStartedTimeInMsFromKeyFrame = 0

const getAnimationCurrentTimeInMsFromKeyFrame = () => {
  return (animationCurrentKeyFrame * 1000) / (animationDrawingFps?.value || 30)
}
const setAnimationCurrentTimeInMsAndKeyFrame = (newTimeInMs: number) => {
  const timeInMs =
    newTimeInMs > animationDurationInMs.value ? animationDurationInMs.value : newTimeInMs
  animationCurrentTimeInMs.value = timeInMs
  animationCurrentKeyFrame = Math.ceil((timeInMs / 1000) * (animationDrawingFps?.value || 30))
}

const setPlaybackRate = (playbackRate: number) => {
  if (!playbackRate || playbackRate <= 0) {
    return
  }

  animationPlaybackRate.value = playbackRate

  animationPlayStartedTimeInMs = Date.now() - animationCurrentTimeInMs.value / playbackRate

  const elapsedTimeInMs = (Date.now() - animationPlayStartedTimeInMs) * playbackRate
  if (Math.floor(animationCurrentTimeInMs.value) === Math.floor(elapsedTimeInMs)) {
    // reset seek
    animationPlayStartedTimeInMsFromKeyFrame = 0
  }
}

const animationPlayFrames = () => {
  if (!isAnimationPlaying.value) {
    return
  }

  const elapsedTimeInMs = (Date.now() - animationPlayStartedTimeInMs) * animationPlaybackRate.value
  const newTimeInMs = animationPlayStartedTimeInMsFromKeyFrame + elapsedTimeInMs
  animationDrawFrames(newTimeInMs, 'draw')

  if (newTimeInMs >= animationDurationInMs.value) {
    setAnimationPlayOrPause(false)
    return
  }

  fabric.util.requestAnimFrame(animationPlayFrames)
}
const animationDrawFrames = (
  newTimeInMs: number,
  actionType: 'draw' | 'pause' | 'preview' | 'seek'
) => {
  // updateTimeTo
  if (actionType !== 'preview') {
    setAnimationCurrentTimeInMsAndKeyFrame(newTimeInMs)
  }

  setBackgroundColor()

  coreData?.tracks.forEach((track) => {
    track.elements.forEach((element) => {
      // @ts-ignore
      if (element.attributes.error) {
        return
      }

      if (track.type === 'audios') {
        const isInAnimationPlayTimeRange = refreshNodeVisibleState(newTimeInMs, element)

        const audio = htmlAudioElements.get(element.id)
        if (!audio) {
          return
        }

        if (isInAnimationPlayTimeRange && isAnimationPlaying.value) {
          audio.muted = !!track.isMuted
          audio.playbackRate = animationPlaybackRate.value
          if (audio.paused) {
            audio.play()
          }
          return
        }

        if (['preview', 'seek'].includes(actionType)) {
          if (isInAnimationPlayTimeRange) {
            audio.currentTime =
              (newTimeInMs - element.timeFrame.start + element.timeFrame.clipStart) / 1000
          } else if (actionType === 'seek') {
            audio.currentTime = element.timeFrame.clipStart / 1000
          }
        }

        if (!audio.paused) {
          audio.pause()

          if (actionType === 'draw') {
            // 重置切片音频到需要播放的第一帧
            audio.currentTime = element.timeFrame.clipStart / 1000
          }
        }
      } else if (track.type === 'videos') {
        if (!element.fabricObject) {
          return
        }

        const isInAnimationPlayTimeRange = refreshNodeVisibleState(newTimeInMs, element)

        if (element.type !== 'video') {
          return
        }

        const video = htmlVideoElements.get(element.id)
        if (!video) {
          return
        }

        if (isInAnimationPlayTimeRange && isAnimationPlaying.value) {
          video.muted = !!track.isMuted
          video.playbackRate = animationPlaybackRate.value
          if (video.paused) {
            video.play()
          }
          return
        }

        video.removeEventListener('seeked', renderAll)
        video.addEventListener('seeked', renderAll)

        if (['preview', 'seek'].includes(actionType)) {
          if (isInAnimationPlayTimeRange) {
            video.currentTime =
              (newTimeInMs - element.timeFrame.start + element.timeFrame.clipStart) / 1000
          } else if (actionType === 'seek') {
            video.currentTime = element.timeFrame.clipStart / 1000
          }
        }

        if (!video.paused) {
          video.pause()

          if (actionType === 'draw') {
            if (newTimeInMs >= animationDurationInMs.value) {
              // 播放结束，保留最后一帧画面
              video.currentTime = element.timeFrame.clipEnd / 1000
              element.fabricObject.visible = true
            } else {
              // 重置切片画面到需要显示的第一帧，否则播放前再重置会看到上次播放的最后帧闪隐
              video.currentTime = element.timeFrame.clipStart / 1000
            }
          }
        }
      } else if (['texts', 'captions', 'stickers'].includes(track.type)) {
        if (!element.fabricObject) {
          return
        }

        refreshNodeVisibleState(newTimeInMs, element)
      }
    })
  })

  renderAll()
}
const refreshNodeVisibleState = (newTimeInMs: number, element: EditorElement) => {
  const isInAnimationPlayTimeRange =
    element.timeFrame.start <= newTimeInMs && newTimeInMs <= element.timeFrame.end
  if (element.fabricObject) {
    element.fabricObject.visible = isInAnimationPlayTimeRange
  }
  return isInAnimationPlayTimeRange
}

const animationInitDrawFrames = (element: EditorElement | null = null) => {
  const setState = (element: EditorElement) => {
    // @ts-ignore
    if (element.attributes.error || !['audio', 'video'].includes(element.type)) {
      return
    }

    const media = (element.type === 'audio' ? htmlAudioElements : htmlVideoElements).get(element.id)
    if (!media) {
      return
    }

    const offsetStart = getNodeVisibleState(element)
      ? animationCurrentTimeInMs.value - element.timeFrame.start
      : 0
    media.currentTime = (element.timeFrame.clipStart + offsetStart) / 1000
  }

  if (element) {
    setState(element)
  } else {
    coreData?.tracks.forEach((track) => {
      if (['audios', 'videos'].includes(track.type)) {
        track.elements.forEach(setState)
      }
    })
  }

  renderAll()
}

const setAnimationPlayOrPause = (isPlay: boolean) => {
  isAnimationPlaying.value = isPlay

  if (isPlay) {
    if (animationCurrentTimeInMs.value >= animationDurationInMs.value) {
      setAnimationCurrentTimeInMsAndKeyFrame(0)
      // 因为播放结束，保留最后一帧画面，此处全部重置切片画面到需要显示的第一帧，否则播放前再重置会看到上次播放的最后帧闪隐
      animationInitDrawFrames()
    }
    animationPlayStartedTimeInMs = Date.now()
    animationPlayStartedTimeInMsFromKeyFrame = getAnimationCurrentTimeInMsFromKeyFrame()
    fabric.util.requestAnimFrame(animationPlayFrames)
    return
  }

  animationDrawFrames(animationCurrentTimeInMs.value, 'pause')
}

const doAnimationSeek = (timeInMs: number) => {
  if (timeInMs < 0) {
    timeInMs = 0
  }
  if (timeInMs > animationDurationInMs.value) {
    timeInMs = animationDurationInMs.value
  }
  previewAnimationSeek(timeInMs, true)
  animationDrawFrames(timeInMs, 'seek')
}
const previewAnimationSeek = (timeInMs: number, isBeforeDoSeek: boolean = false) => {
  if (timeInMs < 0) {
    timeInMs = 0
  }
  if (timeInMs > animationDurationInMs.value) {
    timeInMs = animationDurationInMs.value
  }
  if (isAnimationPlaying.value) {
    setAnimationPlayOrPause(false)
  }
  if (isBeforeDoSeek) {
    return
  }
  animationDrawFrames(timeInMs, 'preview')
}

const setMediaDefaultStartAndEnd = (elements: EditorElement[]) => {
  let start = 0
  let end = 0
  elements.forEach((element) => {
    const clipBeforeGap = element.timeFrame.clipBeforeGap || 0
    element.timeFrame.start = start + clipBeforeGap
    const clipDurationInMs = element.timeFrame.clipEnd - element.timeFrame.clipStart + clipBeforeGap
    start += clipDurationInMs
    end += clipDurationInMs
    element.timeFrame.end = end
  })
}

const getMediaFile = (url: string): Promise<Blob | null> => {
  return new Promise((resolve) => {
    fetch(url, {
      mode: 'cors'
    })
      .then((response) => {
        if (response.status !== 200) {
          return resolve(null)
        }
        resolve(response.blob())
      })
      .catch(() => {
        resolve(null)
      })
  })
}

const calcAnimationDuration = () => {
  if (coreData) {
    let maxAnimationDurationInMs = 0
    coreData.tracks.forEach((track) => {
      track.elements.forEach((element) => {
        maxAnimationDurationInMs = Math.max(maxAnimationDurationInMs, element.timeFrame.end)
      })
    })
    animationDurationInMs.value = maxAnimationDurationInMs
  }
}

const initOrResetAnimationState = async (isNeedResetStartAndEnd: boolean) => {
  if (!isNeedResetStartAndEnd) {
    calcAnimationDuration()
  }

  if (coreData) {
    for (const track of coreData.tracks) {
      if (isNeedResetStartAndEnd) {
        setMediaDefaultStartAndEnd(track.elements)
      }

      if (props.isNeedLoadVideoFile && track.type === 'videos') {
        for (const element of track.elements) {
          if (element.type === 'video' && !element.attributes.file) {
            console.log(element.id, 'loading...')
            const data: Blob | null = await getMediaFile(element.attributes.src)
            console.log(element.id, 'loaded', data)
            if (data) {
              element.attributes.file = new File([data], element.id)
            }
          }
        }
      }
    }
  }

  if (isNeedResetStartAndEnd) {
    calcAnimationDuration()
  }

  refreshElements()
}

const getNodeVisibleState = (element: EditorElement) => {
  return refreshNodeVisibleState(animationCurrentTimeInMs.value, element)
}
const setActiveObject = (element: EditorElement) => {
  if (canvas && element.fabricObject?.selectable) {
    canvas.setActiveObject(element.fabricObject)

    setControlsCustomization(element)
  }
}
const setControlsCustomization = (element: EditorElement) => {
  if (!element.fabricObject) {
    return
  }

  element.fabricObject.centeredScaling = true

  // fabricObject.hasControls = false
  let controls = ['ml', 'mb', 'mr', 'mt']
  if (element.subType === 'caption') {
    controls = ['tl', 'bl', 'tr', 'br', 'ml', 'mb', 'mr', 'mt', 'mtr']
  } else if (element.type === 'video') {
    controls = controls.concat(['mtr'])
  }
  controls.forEach((property) => {
    element.fabricObject?.setControlVisible(property, false)
  })
}
const discardActiveObject = () => {
  if (canvas) {
    canvas.discardActiveObject()
  }
}
const renderAll = () => {
  if (canvas) {
    canvas.renderAll()
  }
}
const getCanvas = () => {
  return canvas
}
const setStageSize = (stageConfig: typeof props.stageConfig) => {
  const { width, height, scale } = stageConfig
  if (canvas) {
    canvas.setDimensions({
      width,
      height
    })
    canvas.setZoom(scale)
  }
}

const initMediaEvents = () => {
  const handleEvents = (dom: HTMLMediaElement) => {
    dom.onseeking = () => {
      htmlMediaElementsWaiting.set(dom.id, dom)
      isWaiting.value = true
    }
    dom.onseeked = () => {
      htmlMediaElementsWaiting.delete(dom.id)
      isWaiting.value = htmlMediaElementsWaiting.entries.length > 0
    }
    dom.onwaiting = () => {
      htmlMediaElementsWaiting.set(dom.id, dom)
      isWaiting.value = true

      isAnimationPlaying.value = false
      htmlAudioElements.forEach((dom) => {
        if (!htmlMediaElementsWaiting.has(dom.id) && !dom.paused) {
          dom.pause()
        }
      })
      htmlVideoElements.forEach((dom) => {
        if (!htmlMediaElementsWaiting.has(dom.id) && !dom.paused) {
          dom.pause()
        }
      })
    }
    dom.onplaying = () => {
      htmlMediaElementsWaiting.delete(dom.id)
      isWaiting.value = htmlMediaElementsWaiting.entries.length > 0

      if (!isAnimationPlaying.value && !isWaiting.value) {
        setAnimationPlayOrPause(true)
      }
    }
  }
  htmlVideoElements.forEach(handleEvents)
  htmlAudioElements.forEach(handleEvents)

  // 设置切片音频/视频到需要播放的第一帧
  animationInitDrawFrames()
}

const setBackgroundColor = (color: string = '') => {
  if (color) {
    bgColor = color
  }
  if (canvas) {
    canvas.backgroundColor = bgColor
  }
}

defineExpose({
  htmlAudioElements,
  htmlVideoElements,
  htmlImageElements,
  htmlCanvasElements,
  isWaiting,
  initMediaElements,
  initMediaEvents,

  animationCurrentTimeInMs,
  animationDurationInMs,
  isAnimationPlaying,
  animationPlaybackRate,

  setAnimationPlayOrPause,
  previewAnimationSeek,
  doAnimationSeek,

  calcAnimationDuration,
  initOrResetAnimationState,
  animationInitDrawFrames,

  setSelectedElement,
  removeEditorElement,
  refreshElements,

  getNodeVisibleState,
  discardActiveObject,
  renderAll,
  getCanvas,
  setStageSize,
  setPlaybackRate,

  setBackgroundColor
})

onMounted(() => {
  canvas = new fabric.Canvas('viewport-canvas')
  setStageSize(props.stageConfig)
  canvas.selection = false
  canvas.on('mouse:down', (event: fabric.IEvent<MouseEvent>) => {
    if (!event.target) {
      setSelectedElement(null)
    }
  })

  if (props.backgroundColor) {
    setBackgroundColor(props.backgroundColor)
  }
})
onUnmounted(() => {
  setSelectedElement(null)
})
</script>

<template>
  <canvas id="viewport-canvas"></canvas>
</template>

<style scoped lang="less">
canvas {
  background: black;
}
</style>
