import { ref } from 'vue'
import type { fabric } from 'fabric'
import type { ElMain } from 'element-plus'

import '@/videoEditor/fabric-utils'
import type {
  CoreData,
  Dimension,
  Subtitle,
  EditorElement,
  AudioEditorElement,
  VideoEditorElement,
  ImageEditorElement,
  StickerEditorElement,
  TextEditorElement,
  Effect
} from '@/videoEditor/types'
import { getUid, setMediaElementFragmentURI } from '@/videoEditor/utils'

import type AnimationPlayer from '@/components/videoEditor/AnimationPlayer.vue'

/** 依赖注入 start */
// 核心数据
let coreData: CoreData | undefined = undefined
/** 依赖注入 end */

/** Common start */
export const stageConfig = ref({
  width: 1920,
  height: 1080,
  scale: 1
})

export const refAnimationPlayer = ref<InstanceType<typeof AnimationPlayer>>()
/** Common end */

export const onMountedExec = (coreDataInst: CoreData | undefined) => {
  coreData = coreDataInst

  onMountedActions()
}
export const onUnmountedExec = () => {
  onUnmountedActions()
}

/** Control API start */
export const initElements = (isNeedResetStartAndEnd: boolean) => {
  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  player.isWaiting = false

  player.initOrResetAnimationState(isNeedResetStartAndEnd)

  player.initMediaEvents()
}
export const previewAnimationSeek = (timeInMs: number) => {
  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  player.previewAnimationSeek(timeInMs)
}
export const doAnimationSeek = (timeInMs: number) => {
  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  player.doAnimationSeek(timeInMs)
}
/** Control API end */

/** Element API start */
export interface MyMediaOptions {
  // element properties
  subType?: EditorElement['subType']

  // element and fabric common properties
  type?: string
  name?: string

  // placement and fabric common properties
  left?: number
  top?: number
  width?: number
  height?: number
  originX?: string
  originY?: string

  // fabric common properties
  selectable?: boolean

  // fabric properties
  // ...

  // timeFrame
  start?: number
  end?: number
  clipStart: number
  clipEnd: number
  clipBeforeGap?: number

  // attributes
  src: string
  effect?: Effect
}
export interface MyTextOptions {
  // element properties
  subType?: EditorElement['subType']

  // element and fabric common properties
  type?: string
  name?: string

  // placement and fabric common properties
  left?: number
  top?: number
  width?: number
  height?: number
  originX?: string
  originY?: string

  // fabric common properties
  selectable?: boolean

  // fabric properties
  text: string
  editable?: boolean
  fontSize?: number
  fontFamily?: string
  fontWeight?: fabric.ITextboxOptions['fontWeight']
  fill?: fabric.ITextboxOptions['fill']
  stroke?: string
  strokeLineJoin?: string
  strokeWidth?: number
  styles?: object
  textBackgroundColor?: string
  shadow?: fabric.TextOptions['shadow']

  charSpacing?: number
  lineHeight?: number
  paintFirst?: string
  textAlign?: string

  splitByGrapheme?: boolean

  // ext properties

  // timeFrame
  start?: number
  end?: number
  clipStart: number
  clipEnd: number
  clipBeforeGap?: number

  // attributes
  effect?: Effect
  subtitles?: Subtitle[]
}
export const addAudio = (
  options: MyMediaOptions,
  trackId: string,
  track: EditorElement[],
  callback: (element: AudioEditorElement, dom: HTMLAudioElement) => void
) => {
  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  const type = 'audio'
  const id = `${type}-${getUid()}`
  const { src } = options

  const dom = document.createElement('audio')
  dom.id = id
  dom.src = `${src}#t=${options.clipStart / 1000}${options.clipEnd > 0 ? `,${options.clipEnd / 1000}` : ''}`
  dom.preload = 'metadata'
  player.htmlAudioElements.set(dom.id, dom)

  const element: AudioEditorElement = {
    id,
    name: options.name || id,
    type,
    subType: options.subType,
    trackId,
    placement: {
      left: options.left || 0,
      top: options.top || 0,
      width: options.width || 100,
      height: options.height || 100,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      originX: options.originX || 'left', // Default Value: left
      originY: options.originY || 'top' // Default Value: top
    },
    timeFrame: {
      duration: 0, // auto
      start: options.start || 0,
      end: options.end || 0,
      clipStart: options.clipStart,
      clipEnd: options.clipEnd,
      clipBeforeGap: options.clipBeforeGap || undefined
    },
    properties: {
      // selectable: options.selectable ?? true // Default Value: true
    },
    attributes: {
      src: dom.src,
      effect: options.effect || undefined
    }
  }

  dom.addEventListener('loadedmetadata', () => {
    const { duration } = dom
    const durationInMs = duration * 1000
    let clipEnd = options.clipEnd
    if (!clipEnd || clipEnd <= 0) {
      clipEnd = durationInMs
    }

    element.timeFrame.duration = durationInMs
    element.timeFrame.clipEnd = clipEnd
    setMediaElementFragmentURI(element)

    if (element.timeFrame.end <= 0) {
      element.timeFrame.end = element.timeFrame.start + durationInMs
    }

    track.push(element)
    callback(element, dom)
  })
  dom.onerror = (event) => {
    console.error(event)
    element.attributes.error = event
    if (!track.includes(element)) {
      track.push(element)
    }
    callback(element, dom)
  }
}
export const addVideo = (
  options: MyMediaOptions,
  trackId: string,
  track: EditorElement[],
  callback: (element: VideoEditorElement, dom: HTMLVideoElement) => void,
  needPlacementAttrs: string[] = []
) => {
  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  const type = 'video'
  const id = `${type}-${getUid()}`
  const { src } = options

  const dom = document.createElement('video')
  dom.id = id
  dom.src = `${src}#t=${options.clipStart / 1000}${options.clipEnd > 0 ? `,${options.clipEnd / 1000}` : ''}`
  dom.preload = 'metadata'
  dom.setAttribute('playsinline', 'true')
  dom.setAttribute('webkit-playsinline', 'true')
  player.htmlVideoElements.set(dom.id, dom)

  const element: VideoEditorElement = {
    id,
    name: options.name || id,
    type,
    subType: options.subType,
    trackId,
    placement: {
      left: options.left || 0,
      top: options.top || 0,
      width: options.width || 100,
      height: options.height || 100,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      originX: options.originX || 'left', // Default Value: left
      originY: options.originY || 'top' // Default Value: top
    },
    timeFrame: {
      duration: 0, // auto
      start: options.start || 0,
      end: options.end || 0,
      clipStart: options.clipStart,
      clipEnd: options.clipEnd,
      clipBeforeGap: options.clipBeforeGap || undefined
    },
    properties: {
      selectable: options.selectable ?? true // Default Value: true
    },
    attributes: {
      src: dom.src,
      effect: options.effect || undefined
    }
  }

  dom.addEventListener('loadedmetadata', () => {
    const { width: w, height: h, scale } = stageConfig.value
    let width = dom.videoWidth || w
    let height = dom.videoHeight || h
    const radio = width / height

    if (needPlacementAttrs.includes('height')) {
      if (!options.width) {
        throw new Error('需要width才能自动计算height')
      }
      if (options.width > width) {
        options.width = width
      }
      height = options.width / radio
      width = options.width
    } else {
      if (width >= height) {
        // 横屏
        width = Math.min(w, width)
        height = width / radio
      } else {
        // 竖屏
        height = Math.min(h, height)
        width = height * radio
      }
    }

    if (!needPlacementAttrs.length || needPlacementAttrs.includes('left')) {
      if (element.placement.originX === 'center') {
        element.placement.left = w / scale / 2
      } else if (element.placement.originX === 'right') {
        element.placement.left = (w + width) / scale / 2
      } else {
        element.placement.left = (w - width) / scale / 2
      }
    }
    if (!needPlacementAttrs.length || needPlacementAttrs.includes('top')) {
      if (element.placement.originY === 'center') {
        element.placement.top = h / scale / 2
      } else if (element.placement.originY === 'bottom') {
        element.placement.top = (h + height) / scale / 2
      } else {
        element.placement.top = (h - height) / scale / 2
      }
    }
    element.placement.width = width / scale
    element.placement.height = height / scale

    const { duration } = dom
    const durationInMs = duration * 1000
    let clipEnd = options.clipEnd
    if (!clipEnd || clipEnd <= 0) {
      clipEnd = durationInMs
    }
    element.timeFrame.duration = durationInMs
    element.timeFrame.clipEnd = clipEnd
    setMediaElementFragmentURI(element)

    if (element.timeFrame.end <= 0) {
      element.timeFrame.end = element.timeFrame.start + durationInMs
    }

    track.push(element)
    callback(element, dom)
  })
  dom.onerror = (event) => {
    console.error(event)
    element.attributes.error = event
    if (!track.includes(element)) {
      track.push(element)
    }
    callback(element, dom)
  }
}
export const addImage = (
  options: MyMediaOptions,
  trackId: string,
  track: EditorElement[],
  callback: (element: ImageEditorElement | StickerEditorElement, dom: HTMLImageElement) => void,
  needPlacementAttrs: string[] = [],
  type: 'image' | 'sticker' = 'image'
) => {
  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  const id = `${type}-${getUid()}`
  const { src } = options

  const dom = new Image()
  dom.id = id
  dom.src = src
  player.htmlImageElements.set(dom.id, dom)

  const element: ImageEditorElement | StickerEditorElement = {
    id,
    name: options.name || id,
    type,
    subType: options.subType,
    trackId,
    placement: {
      left: options.left || 0,
      top: options.top || 0,
      width: options.width || 100,
      height: options.height || 100,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      originX: options.originX || 'left', // Default Value: left
      originY: options.originY || 'top' // Default Value: top
    },
    timeFrame: {
      duration: -1, // no duration
      start: options.start || 0,
      end: options.end || 0,
      clipStart: options.clipStart,
      clipEnd: options.clipEnd,
      clipBeforeGap: options.clipBeforeGap || undefined
    },
    properties: {
      selectable: options.selectable ?? true // Default Value: true
    },
    attributes: {
      src,
      effect: options.effect || undefined
    }
  }

  dom.onload = () => {
    const { width: w, height: h, scale } = stageConfig.value
    let width = dom.naturalWidth || w
    let height = dom.naturalHeight || h
    const radio = width / height

    if (type === 'sticker' || needPlacementAttrs.includes('height')) {
      if (!options.width) {
        throw new Error('需要width才能自动计算height')
      }
      if (options.width > width) {
        options.width = width
      }
      height = options.width / radio
      width = options.width
    } else {
      if (width >= height) {
        // 横屏
        width = Math.min(w, width)
        height = width / radio
      } else {
        // 竖屏
        height = Math.min(h, height)
        width = height * radio
      }
    }

    if ((type !== 'sticker' && !needPlacementAttrs.length) || needPlacementAttrs.includes('left')) {
      if (element.placement.originX === 'center') {
        element.placement.left = w / scale / 2
      } else if (element.placement.originX === 'right') {
        element.placement.left = (w + width) / scale / 2
      } else {
        element.placement.left = (w - width) / scale / 2
      }
    }
    if ((type !== 'sticker' && !needPlacementAttrs.length) || needPlacementAttrs.includes('top')) {
      if (element.placement.originY === 'center') {
        element.placement.top = h / scale / 2
      } else if (element.placement.originY === 'bottom') {
        element.placement.top = (h + height) / scale / 2
      } else {
        element.placement.top = (h - height) / scale / 2
      }
    }
    element.placement.width = width / (type === 'sticker' ? 1 : scale)
    element.placement.height = height / (type === 'sticker' ? 1 : scale)

    track.push(element)
    callback(element, dom)
  }
  dom.onerror = (event) => {
    console.error(event)
    element.attributes.error = event
    if (!track.includes(element)) {
      track.push(element)
    }
    callback(element, dom)
  }
}
export const addText = (
  options: MyTextOptions,
  trackId: string,
  track: EditorElement[],
  callback: (element: TextEditorElement) => void
) => {
  const type = 'text'
  const id = `${type}-${getUid()}`

  const element: TextEditorElement = {
    id,
    name: options.name || id,
    type,
    subType: options.subType,
    trackId,
    placement: {
      left: options.left || 0,
      top: options.top || 0,
      width: options.width || 100,
      height: options.height || 100,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      originX: options.originX || 'left', // Default Value: left
      originY: options.originY || 'top' // Default Value: top
    },
    timeFrame: {
      duration: -1, // no duration
      start: options.start || 0,
      end: options.end || 0,
      clipStart: options.clipStart,
      clipEnd: options.clipEnd,
      clipBeforeGap: options.clipBeforeGap || undefined
    },
    properties: {
      selectable: options.selectable ?? true,

      text: options.text,
      editable: options.editable ?? true, // Default Value: true

      fontSize: options.fontSize || 40, // Default Value: 40
      fontFamily: options.fontFamily || '', // Default Value: Times New Roman
      fontWeight: options.fontWeight || 400, // Default Value: normal

      fill: options.fill || '#FFFFFFFF', // Default Value: rgb(0,0,0)
      stroke: options.stroke || '#000000AF', // Default Value: null (found in source code)
      strokeLineJoin: options.strokeLineJoin || 'round', // Default Value: miter
      strokeWidth: options.strokeWidth || 1, // Default Value: 1
      styles: options.styles || {}, // Default Value: null (found in source code)
      textBackgroundColor: options.textBackgroundColor || undefined,
      shadow: options.shadow || undefined,

      charSpacing: options.charSpacing || 0, // Default Value: 0 (found in source code)
      lineHeight: options.lineHeight || 1.16, // Default Value: 1.16
      paintFirst: options.paintFirst || 'stroke', // Default Value: fill
      textAlign: options.textAlign || 'center', // Default Value: left

      splitByGrapheme: options.splitByGrapheme ?? true // Default Value: false (found in source code)

      // ext properties
    },
    attributes: {
      effect: options.effect || undefined,
      subtitles: options.subtitles || undefined
    }
  }

  track.push(element)
  callback(element)
}
/** Element API end */

/** 加载字体 start */
export const checkFont = (fontFamily: string) => {
  const values = document.fonts.values()

  /*for (const font of values) {
    if (font.family === fontFamily) {
      return true
    }
  }*/
  let item = values.next()
  while (!item.done) {
    if (item.value.family === fontFamily) {
      return true
    }
    item = values.next()
  }

  return false
}
export const loadingFont = ref(false)
export const loadFont = async (fontFamily: string) => {
  if (!fontFamily || checkFont(fontFamily)) {
    return Promise.resolve()
  }

  loadingFont.value = true
  const fontFace = new FontFace(
    fontFamily,
    `url(https://d24i1cah63ad4k.cloudfront.net/web/fonts/${encodeURIComponent(fontFamily)})`
  )
  document.fonts.add(fontFace)
  try {
    return await fontFace.load()
  } finally {
    loadingFont.value = false
  }
}
/** 加载字体 end */

/** UI控制 start */
export const setStageSize = (dimension: Dimension, stageConfigObj: Dimension) => {
  const { width: originWidth, height: originHeight } = dimension
  let width = originWidth
  let height = originHeight
  const { width: w, height: h } = stageConfigObj
  const radio = width / height
  if (width >= height) {
    // 横屏
    width = Math.min(w, width)
    height = width / radio
    if (height > h) {
      height = h
      width = height * radio
    }
  } else {
    // 竖屏
    height = Math.min(h, height)
    width = height * radio
    if (width > w) {
      width = w
      height = width / radio
    }
  }
  const scale = width / (originWidth / (dimension.scale || 1))

  Object.assign(stageConfig.value, {
    width,
    height,
    scale
  })

  if (!coreData) {
    return
  }

  coreData.globalSetting.stage = stageConfig.value

  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  player.setStageSize(stageConfig.value)
}
let windowResizeTimer: ReturnType<typeof setTimeout> | null = null
export const onWindowResize = () => {
  if (windowResizeTimer) {
    clearTimeout(windowResizeTimer)
  }
  windowResizeTimer = setTimeout(() => {
    changeStageSize({
      width: stageConfig.value.width / stageConfig.value.scale,
      height: stageConfig.value.height / stageConfig.value.scale
    })
  }, 300)
}
const onMountedActions = () => {
  window.addEventListener('resize', onWindowResize)
}
const onUnmountedActions = () => {
  window.removeEventListener('resize', onWindowResize)
}
export const onBeforeUnmountActions = () => {
  const player = refAnimationPlayer.value
  if (!player) {
    return
  }

  player.setAnimationPlayOrPause(false)

  player.htmlAudioElements.forEach((dom: HTMLAudioElement) => {
    dom.remove()
  })
  player.htmlAudioElements.clear()
  player.htmlVideoElements.forEach((dom: HTMLVideoElement) => {
    dom.remove()
  })
  player.htmlVideoElements.clear()
  player.htmlImageElements.forEach((dom: HTMLImageElement) => {
    dom.remove()
  })
  player.htmlImageElements.clear()
  player.htmlCanvasElements.forEach((dom: HTMLCanvasElement) => {
    dom.remove()
  })
  player.htmlCanvasElements.clear()
}
export const refPlayerWrapper = ref<InstanceType<typeof ElMain>>()
export const refPlayerToolbar = ref<HTMLElement>()
export const changeStageSize = (dimension: Dimension) => {
  const wrapper = refPlayerWrapper.value
  const toolbar = refPlayerToolbar.value
  if (wrapper?.$el && toolbar) {
    const width = wrapper.$el.clientWidth
    const height = wrapper.$el.clientHeight - toolbar.clientHeight

    setStageSize(dimension, {
      width,
      height
    })
  }
}
/** UI控制 end */
