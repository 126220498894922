import en from './en'

const zh: {
  [key: string]: string | object
} = {}
for (const [key, value] of Object.entries(en)) {
  if (typeof value === 'object') {
    continue
  }
  zh[key] = key
}

export default Object.assign(zh, {
  'generate-video': '生成视频',
  'regenerate-video': '重新生成视频',
  'video-translation': '视频翻译',
  'retry-video-translation': '重新视频翻译',
  'translate-script': '重新翻译全部脚本',
  'translate-text': '翻译一条脚本',
  'download-video': '下载视频',

  // incomplete: '',
  // incomplete_expired: '',
  // trialing: '',
  active: '有效订阅',
  // past_due: '',
  // canceled: '',
  // unpaid: '',
  // paused: '',

  new_subscription: '订阅',
  recurring_charge: '续费',
  consume: '扣费',
  frozen: '冻结',
  unfrozen: '解冻',
  auto_unfrozen: '解冻',
  commit: '扣费',
  cancel_subscription: '取消订阅',
  update_subscription: '更新订阅',

  Free: '免费',
  Standard: '标准',
  Enterprise: '企业',
  month: '月',
  year: '年',
  Coupon: '礼品卡',

  time: {
    symbol: {
      second: '秒'
    }
  }
}) as typeof en
