<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { genFileId } from 'element-plus'
import type { UploadInstance, UploadProps, UploadRawFile, UploadFile } from 'element-plus'

import { ElMessage, ElMessageBox } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import type { BaseResponse, Material } from '@/api'
import { config } from '@/config'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { generateUUID } from '@/utils'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()

const emit = defineEmits(['upload:success', 'upload:progress'])
const props = withDefaults(
  defineProps<{
    projId: string
    'onUpload:progress'?: (percent: number) => void
    'onUpload:success': (uploadFile: UploadFile, material: Material) => void
    product?: string
    category?: 'video' | 'image' | 'audio' | 'voice' | ''
    categories?: ('video' | 'image' | 'audio' | 'voice')[]
    fileFormat?: string
    autoUpload?: boolean
    limit?: number
    minWidthAndHeight?: number
    maxWidthAndHeight?: number
    maxFileSize?: number
    beforeUpload?: (uploadFile: UploadRawFile) => Promise<boolean>
  }>(),
  {
    product: 'clips-to-videos',
    category: '',
    categories: () => ['video', 'image'],
    fileFormat: 'MP4, MOV, WEBM, WEBP, JPG, JPEG, PNG',
    autoUpload: true,
    limit: 1,
    minWidthAndHeight: 500, // px
    maxWidthAndHeight: 3840, // px
    maxFileSize: 200 // MB
  }
)
defineExpose({
  getUploadRef: () => {
    return uploadRef
  }
})

const accepts = {
  video: ['video/mp4', 'video/webm', 'video/quicktime'],
  image: ['image/jpeg', 'image/png', 'image/apng', 'image/webp'],
  audio: [
    'audio/mpeg', // mp3
    'audio/wav'
  ],
  voice: [
    'audio/mpeg', // mp3
    'audio/wav'
  ]
}
const accept: string[] = []
props.categories.forEach((category) => {
  accept.push(...(accepts[category] || []))
})
const uploadUrl = `${config.baseUrl}/api/material/upload?req_id={req_id}`

const uploadRef = ref<UploadInstance>()
const uploadAction = ref<UploadProps['action']>(uploadUrl)
const uploadHeaders: UploadProps['headers'] = {}
if (config.useJwtInHeader && userStore.token) {
  uploadHeaders.Authorization = `Bearer ${userStore.token}`
}
const withCredentials: UploadProps['withCredentials'] =
  config.baseUrl.split('://')[1] !== location.host
const uploadData: UploadProps['data'] = (rawFile) => {
  const data: {
    [key: string]: string | number
  } = {}
  data['projId'] = props.projId
  data['product'] = props.product
  data['category'] = props.category
  data['filesize'] = rawFile.size
  data['lastModified'] = rawFile.lastModified
  // @ts-expect-error
  data['width'] = rawFile.width
  // @ts-expect-error
  data['height'] = rawFile.height
  // @ts-expect-error
  data['duration'] = rawFile.duration
  return data
}
const handleProgress: UploadProps['onProgress'] = (evt, uploadFile, uploadFiles) => {
  let total = 0
  let uploaded = 0
  uploadFiles.forEach((file) => {
    if (!file.raw) {
      return
    }
    if (file.uid === uploadFile.uid) {
      file.percentage = evt.percent
    }
    const { size } = file.raw
    total += size
    uploaded += (file.percentage! / 100) * size
  })
  emit('upload:progress', (uploaded / total) * 100)
}
const getImageInfo = (
  url: string
): Promise<{
  width: number
  height: number
  duration?: number
}> => {
  return new Promise((resolve, reject) => {
    let image: HTMLImageElement | null = new Image()
    image.onload = () => {
      resolve({
        width: image?.naturalWidth!,
        height: image?.naturalHeight!
      })
      image = null
    }
    image.onerror = (event: string | Event) => {
      reject(event)
      image = null
    }
    image.src = url
  })
}
const getVideoInfo = (
  url: string
): Promise<{
  width: number
  height: number
  duration: number
}> => {
  return new Promise((resolve, reject) => {
    let video: HTMLVideoElement | null = document.createElement('video')
    video.preload = 'metadata'
    video.setAttribute('playsinline', 'true')
    video.onloadedmetadata = () => {
      resolve({
        width: video?.videoWidth!,
        height: video?.videoHeight!,
        duration: video?.duration!
      })
      video = null
    }
    video.onerror = (event: string | Event) => {
      reject(event)
      video = null
    }
    video.src = url
  })
}
const handleChange: UploadProps['onChange'] = (uploadFile) => {
  if (uploadFile.status === 'ready') {
    if (!props.autoUpload && uploadFile.raw && !beforeUpload(uploadFile.raw)) {
      handleRemove(uploadFile.raw)
    }
  } else if (uploadFile.status === 'success') {
    /*if (uploadFile.raw) {
      handleRemove(uploadFile.raw)
    }*/

    const response = uploadFile.response as BaseResponse<Material>

    if (response.code === 401) {
      ElMessageBox.confirm(t('登录过期，需重新登录'), '', {
        type: 'warning'
      })
        .then(() => {
          router.push({ name: 'signin', params: { from: route.fullPath } })
        })
        .catch(() => {})
      return
    }
    if (response.code || !response.data?.url) {
      console.error('onChange success?', uploadFile, response)
      ElMessage.error(response.message || t('上传失败'))
      return
    }

    emit('upload:success', uploadFile, response.data)
  } else if (uploadFile.status === 'fail') {
    if (uploadFile.raw) {
      handleRemove(uploadFile.raw)
    }

    const response = uploadFile.response as BaseResponse<null> | null
    ElMessage.error(response?.message || t('上传异常'))
  }
}
const handleExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  if (props.limit === 1) {
    uploadRef.value!.clearFiles()
    const file = files[0] as UploadRawFile
    file.uid = genFileId()
    uploadRef.value!.handleStart(file)
    return
  }
  ElMessage.error({
    message: t('最多上传{max}个文件', { max: props.limit - uploadFiles.length }),
    duration: 10 * 1000
  })
}
const beforeUpload: UploadProps['beforeUpload'] = async (rawFile) => {
  // 外部自定义逻辑
  if (props.beforeUpload) {
    const checkPass = await props.beforeUpload(rawFile)
    if (!checkPass) return false
  }

  if (!accept.includes(rawFile.type)) {
    ElMessage.error({
      message: t('必须是{format}格式', { format: props.fileFormat }),
      duration: 10 * 1000
    })
    return false
  } else if (rawFile.size / 1024 / 1024 > props.maxFileSize) {
    ElMessage.error({
      message: t('文件体积不能大于{size}', { size: `${props.maxFileSize}MB` }),
      duration: 10 * 1000
    })
    return false
  }
  const url = URL.createObjectURL(rawFile)
  let info = null
  if (rawFile.type.startsWith('video/')) {
    info = await getVideoInfo(url)
  } else if (rawFile.type.startsWith('image/')) {
    info = await getImageInfo(url)
  }
  if (info) {
    Object.assign(rawFile, info)
  }
  const isVideoAndImage = props.categories.includes('video') && props.categories.includes('image')
  if (info && (info.width < props.minWidthAndHeight || info.height < props.minWidthAndHeight)) {
    ElMessage.error({
      message: t(
        isVideoAndImage
          ? '视频或图片高、宽不能小于{size}。当前分辨率：{resolution}'
          : '视频高、宽不能小于{size}。当前分辨率：{resolution}',
        {
          size: `${props.minWidthAndHeight}px`,
          resolution: `${info.width}x${info.height}`
        }
      ),
      duration: 10 * 1000
    })
    return false
  }
  if (info && (info.width > props.maxWidthAndHeight || info.height > props.maxWidthAndHeight)) {
    ElMessage.error({
      message: t(
        isVideoAndImage
          ? '视频或图片高、宽不能大于{size}。当前分辨率：{resolution}'
          : '视频高、宽不能大于{size}。当前分辨率：{resolution}',
        {
          size: `${props.maxWidthAndHeight}px`,
          resolution: `${info.width}x${info.height}`
        }
      ),
      duration: 10 * 1000
    })
    return false
  }
  uploadAction.value = uploadUrl.replace('{req_id}', generateUUID().replace(/-/g, ''))
  return true
}

const handleRemove = (rawFile: UploadRawFile) => {
  uploadRef.value?.handleRemove(rawFile)
}
</script>

<template>
  <el-upload
    ref="uploadRef"
    :action="uploadAction"
    :headers="uploadHeaders"
    :multiple="props.limit > 1"
    :data="uploadData"
    :with-credentials="withCredentials"
    :show-file-list="false"
    :accept="accept.join(',')"
    :on-progress="handleProgress"
    :on-change="handleChange"
    :on-exceed="handleExceed"
    :before-upload="beforeUpload"
    :auto-upload="props.autoUpload"
    :limit="props.limit"
    v-bind="$attrs"
  >
    <template #trigger>
      <slot></slot>
    </template>
  </el-upload>
</template>

<style scoped lang="less"></style>
