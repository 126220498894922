<script setup lang="ts">
import { ref, onMounted, onUnmounted, onActivated, onDeactivated, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ElMessage, ElMessageBox, type MessageBoxState } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import { ArrowRight, Picture } from '@element-plus/icons-vue'
/*import {
  Check as IconCheck,
  CircleClose as IconCircleClose,
  Clock as IconClock
} from '@element-plus/icons-vue'*/

import IconClock from '@/components/icons/IconClock.vue'
import IconCircleClose from '@/components/icons/IconCircleClose.vue'
import IconCheck from '@/components/icons/IconCheck.vue'
import IconStateRunning from '@/components/icons/IconStateRunning.vue'
import IconEmpty from '@/components/icons/IconEmpty.vue'
import IconVideo from '@/components/icons/IconVideo.vue'

import {
  type GenerateVideoTask,
  getGenerateTaskList,
  regenerateVideoTask,
  cancelGenerateVideoTask
} from '@/api'
import { costQuotasMap } from '@/config'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { generateConfirmMessage, showSubmitConfirm } from '@/utils/creditsCostConfirm'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()

userStore.$subscribe((mutation, state) => {
  if (!state.id) {
    unmounted()
  }
})

const isInit = ref(true) // 解决window.history.state.forward是否存在判断是否返回不准确问题
const activated = ref(true) // 避免失活后全局监听事件仍然执行
onActivated(() => {
  if (!userStore.roles?.includes('admin')) {
    replaceUrlParams()
  }

  activated.value = true
  if (!route.meta.isBack && !isInit.value) {
    // 如果不是返回还是需要请求更新数据的
    abortRequest()
    currentPage.value = 1
    isLoading.value = false

    dataList.value = []
    total.value = 0
    fetchData()
  }
  isInit.value = false
})
onDeactivated(() => {
  activated.value = false
})

let requestTask: AbortController | null = null
const isLoading = ref(false)
const currentPage = ref(1)
const dataList = ref<GenerateVideoTask[]>([])
const total = ref(0)
const background = ref(false)
const pagerCount = ref(7)
const layout = ref('prev, pager, next, jumper, ->, total')
const pageSizes = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const pageSize = ref(pageSizes[0])
/*const searchTitle = ref('')
const searchSubmitedText = ref('')
const isSearching = ref(false)*/

const replaceUrlParams = () => {
  //router.replace({ params: { projId: form.projId || '-' } })
  history.replaceState(
    {},
    '',
    location.hash
      .replace(/\/-$/, `/${projStore.projId || '-'}`)
      .replace(/\/\d+$/, `/${projStore.projId || '-'}`)
  )
}

onMounted(() => {
  fetchData()
})

const thumbnailsMax = 4
const fetchData = () => {
  abortRequest()
  dataList.value = []
  total.value = 0
  isLoading.value = true

  const controller = new AbortController()
  requestTask = controller

  //searchSubmitedText.value = searchTitle.value

  getGenerateTaskList(
    {
      projId: projStore.projId,
      product: 'clips-to-videos',
      //searchTitle: searchTitle.value,
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      orderBy: 'id',
      order: 'desc'
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      total.value = res.data.data.total
      if (res.data.data.current === currentPage.value && res.data.data.size === pageSize.value) {
        dataList.value = res.data.data.records || []

        dataList.value = dataList.value.map((item) => {
          for (let i = 0; i < thumbnailsMax; i++) {
            if (!item.thumbnailUrls[i]) {
              item.thumbnailUrls[i] = ''
            }
          }
          item.thumbnailUrls = item.thumbnailUrls.slice(0, thumbnailsMax)
          return item
        })
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isLoading.value = false
      //isSearching.value = false
    })
}
const handleSizeChange = (val: number) => {
  pageSize.value = val
  currentPage.value = 1
  fetchData()
}
const handleCurrentChange = (val: number) => {
  currentPage.value = val
  fetchData()
}
const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  unmounted()
})
const unmounted = () => {
  abortRequest()
}
/*const searchSubmit = () => {
  isSearching.value = true
  currentPage.value = 1
  fetchData()
}*/

const gotoPageDetails = (item: GenerateVideoTask) => {
  router.push({
    name: 'vmeg.my-tasks.details',
    params: { projId: projStore.projId, taskId: item.id }
  })
}

const submitId = ref('')
const cancelTask = (item: GenerateVideoTask) => {
  submitId.value = item.id
  cancelGenerateVideoTask({
    id: item.id,
    projId: item.projId
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('取消异常'))
        return
      }
      ElMessage.success(res.data.message || t('取消成功'))
      item.state = res.data.data.state
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('取消失败'))
    })
    .finally(() => {
      submitId.value = ''
    })
}

const redoTask = async (item: GenerateVideoTask) => {
  if (submitId.value) {
    return
  }

  const costQuotas = costQuotasMap['clips-to-videos']

  if (costQuotas <= 0) {
    doCreateAction(item, costQuotas)
    return
  }

  if (projStore.total_remaining < costQuotas) {
    submitId.value = item.id
    await fetchSubscriptionPlanAndCreditDetails(projStore)
    submitId.value = ''
  }

  showSubmitConfirm(
    {
      title: t('将剪辑提交到视频任务'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      feedbackTypes: userStore.feedbackTypes
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true

          doCreateAction(item, costQuotas, instance, done)
        } else {
          done()
        }
      }
    }
  )
}
const doCreateAction = (
  item: GenerateVideoTask,
  costQuotas: number,
  instance?: MessageBoxState,
  done?: () => void
) => {
  submitId.value = item.id
  regenerateVideoTask({
    id: item.id,
    projId: item.projId
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('重新生成异常'))
        return
      }
      ElMessage.success(res.data.message || t('重新生成成功'))
      item.state = res.data.data.state
      item.createTime = res.data.data.createTime
      if (costQuotas > 0) {
        projStore.$patch({
          total_remaining: projStore.total_remaining - costQuotas
        })
        fetchSubscriptionPlanAndCreditDetails(projStore)
      }
      if (done) {
        done()
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('重新生成失败'))
    })
    .finally(() => {
      if (instance) {
        instance.confirmButtonLoading = false
      }

      submitId.value = ''
    })
}
</script>

<template>
  <el-breadcrumb class="g-breadcrumb" :separator-icon="ArrowRight">
    <el-breadcrumb-item
      :to="{ name: 'admin.user-projects' }"
      v-if="userStore.roles?.includes('admin')"
    >
      管理员-用户项目
    </el-breadcrumb-item>
    <el-breadcrumb-item>{{ t('我的任务') }}</el-breadcrumb-item>
  </el-breadcrumb>

  <div class="task-list" v-loading="isLoading">
    <div class="task-list-item" v-for="item in dataList" :key="item.id">
      <div
        class="task-list-item-imgs"
        :class="{
          'is-single': thumbnailsMax < 2,
          'is-canceled': item.state == 'canceled',
          'is-failed': item.state == 'failed',
          'is-finished': item.state == 'finished'
        }"
        @click="gotoPageDetails(item)"
      >
        <template v-for="(url, index) in item.thumbnailUrls" :key="index">
          <el-image class="thumbnail" fit="cover" :src="url" v-if="url">
            <template #error>
              <el-icon><Picture /></el-icon>
            </template>
          </el-image>
          <el-image class="thumbnail" v-else>
            <template #error>
              <el-icon><IconVideo /></el-icon>
            </template>
          </el-image>
        </template>

        <div class="state is-pending" v-if="item.state == 'pending'">
          <el-icon><IconClock /></el-icon>
          {{ t('等待中') }}
        </div>
        <div class="state is-running" v-if="item.state == 'running'">
          <el-icon><IconStateRunning /></el-icon>
          {{ t('运行中') }}
        </div>
        <div class="state is-failed" v-if="['canceled', 'failed'].includes(item.state)">
          <el-icon><IconCircleClose /></el-icon>
          {{ item.state == 'canceled' ? t('已取消') : t('已失败') }}
        </div>
      </div>

      <div class="task-list-item-info">
        <div class="left">
          <div class="title">{{ item.title }}</div>
          <div class="time">
            <div>
              {{
                new Date(
                  (item.createTime || '').replace(' ', 'T').split('.')[0] + '+00:00'
                ).toLocaleString()
              }}
            </div>
          </div>
        </div>
        <div class="right">
          <div
            class="info"
            v-if="item.state == 'finished' || (item.quantityCompleted && item.state == 'running')"
          >
            <el-icon v-if="item.state == 'finished'"><IconCheck /></el-icon>
            {{ t('{count}个视频', { count: item.quantityCompleted }) }}
          </div>
          <div class="button" v-if="item.state == 'pending'">
            <el-button
              size="small"
              :loading="submitId == item.id"
              :disabled="!!submitId && submitId != item.id"
              @click="cancelTask(item)"
            >
              {{ t('取消') }}
            </el-button>
          </div>
          <div class="button" v-if="['canceled', 'failed'].includes(item.state)">
            <el-button
              type="primary"
              size="small"
              :loading="submitId == item.id"
              :disabled="!!submitId && submitId != item.id"
              @click="redoTask(item)"
            >
              {{ t('重新生成') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-empty class="g-empty" v-if="!isLoading && !dataList.length">
      <template #image>
        <el-icon><IconEmpty /></el-icon>
      </template>
    </el-empty>
  </div>

  <el-pagination
    class="g-pagination"
    :background="background"
    v-model:page-size="pageSize"
    :total="total"
    :pager-count="pagerCount"
    v-model:current-page="currentPage"
    :layout="layout"
    :page-sizes="pageSizes"
    :disabled="isLoading"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    v-if="total > pageSizes[0]"
  />
</template>

<style scoped lang="less">
.task-list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  .task-list-item {
    .task-list-item-imgs {
      --my-thumbnail-size: 127.5px;
      --my-thumbnail-gap: 1px;

      width: calc(var(--my-thumbnail-size) * 2 + var(--my-thumbnail-gap));
      height: calc(var(--my-thumbnail-size) * 2 + var(--my-thumbnail-gap));
      background: var(--my-color-x7);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;

      display: flex;
      flex-wrap: wrap;
      gap: var(--my-thumbnail-gap);

      position: relative;

      .thumbnail {
        width: var(--my-thumbnail-size);
        height: var(--my-thumbnail-size);
        background: var(--my-color-x9);

        font-size: 32px;
        color: var(--my-color-primary-12);

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .state {
        position: absolute;
        bottom: 8px;
        right: 8px;
        padding: 2px 8px 2px 4px;
        border-radius: 30px;
        background: var(--my-color-white-89-90);

        display: flex;
        align-items: center;
        gap: 4px;

        font-size: 12px;
        line-height: 16px;
        color: var(--my-color-black-60);

        &.is-running,
        &.is-pending {
          color: var(--el-color-primary);
        }

        .el-icon {
          font-size: 16px;
        }
      }

      &.is-single,
      &.is-canceled,
      &.is-failed,
      &.is-finished {
        --my-thumbnail-size: 128px;
        --my-thumbnail-gap: 0px;
      }

      &.is-canceled,
      &.is-failed {
        .thumbnail {
          color: var(--my-color-black-10);
          background: var(--my-color-x8);
        }
      }

      &.is-single,
      &.is-canceled,
      &.is-failed {
        .thumbnail {
          font-size: 44px;
          width: 100%;
          height: 100%;

          &:not(:first-child) {
            display: none;
          }
        }
      }
    }

    .task-list-item-info {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;

      .left {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 156px;
        overflow: hidden;

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: var(--my-color-black-89-90);

          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        .time {
          display: flex;
          justify-content: space-between;

          font-size: 12px;
          line-height: 16px;
          color: var(--my-color-black-60);
        }
      }

      .right {
        .info {
          font-size: 12px;
          line-height: 16px;
          color: var(--my-color-black-60);
          display: flex;
          align-items: center;
          gap: 3px;

          .el-icon {
            font-size: 16px;
            color: var(--my-color-black-40);
          }
        }

        .button {
          height: 100%;
          display: flex;
          align-items: center;

          .el-button {
            padding: 0 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .task-list {
    --my-gap: 12px;
    gap: var(--my-gap);

    .task-list-item {
      .task-list-item-imgs {
        --my-thumbnail-size: calc(
          (100vw - var(--my-gap) - (var(--el-main-padding) + var(--my-thumbnail-gap)) * 2) / 4
        );

        .thumbnail {
          font-size: 18px;
        }

        &.is-single,
        &.is-canceled,
        &.is-failed,
        &.is-finished {
          --my-thumbnail-size: calc((100vw - var(--my-gap) - var(--el-main-padding) * 2) / 4);
        }

        &.is-canceled,
        &.is-failed {
          .thumbnail {
            font-size: 38px;
          }
        }
      }

      .task-list-item-info {
        flex-direction: column;
        gap: 8px;
        padding: 8px 0 4px;

        .left {
          width: 165px;

          .title {
            line-height: 22px;
          }
        }

        .right {
          --my-info-height: 32px;

          .info {
            height: var(--my-info-height);
          }

          .button {
            .el-button {
              --el-button-size: var(--my-info-height);
              padding: 0 12px;
            }
          }
        }
      }
    }
  }
}
</style>
